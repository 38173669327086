import _ from "lodash";

var watcherFormReducerinitialState = {
  allMetrics: {},
  allDimensions: [],
  watcherID: undefined,
  watcherName: undefined,
  watcherNote: "",
  metricName: undefined,
  intervalType: undefined,
  comparisonType: undefined,
  threshold: undefined,
  startMinutesAgo: 29,
  endMinutesAgo: 0,
  gaServiceLevel: undefined,
  emailRecipients: [],
  smsRecipients: [],
  slackRecipients: [],
  enabledTimes: {
    monday: { enabled: true, from: "00:00", to: "23:59" },
    tuesday: { enabled: true, from: "00:00", to: "23:59" },
    wednesday: { enabled: true, from: "00:00", to: "23:59" },
    thursday: { enabled: true, from: "00:00", to: "23:59" },
    friday: { enabled: true, from: "00:00", to: "23:59" },
    saturday: { enabled: true, from: "00:00", to: "23:59" },
    sunday: { enabled: true, from: "00:00", to: "23:59" },
  },
  google_analytics_profile_id: undefined,
  flashMessage: {
    text: undefined,
    errors: [],
  },
  googleAnalyticsFilters: [],
  gaMetricsCache: undefined,
  tabIndex: 1,
};

const watcherFormReducer = function (state, action) {
  if (state == null) {
    state = _.clone(watcherFormReducerinitialState, true);
  }
  switch (action.type) {
    case "SET_ALL_METRICS":
      return _.assign({}, state, {
        allMetrics: action.metrics,
      });
    case "SET_ALL_DIMENSIONS":
      return _.assign({}, state, {
        allDimensions: action.dimensions,
      });
    case "SET_WATCHER_NAME":
      return _.assign({}, state, {
        watcherName: action.watcherName,
      });
    case "SELECT_GA_PROFILE":
      return _.assign({}, state, {
        google_analytics_profile_id: action.google_analytics_profile_id,
      });
    case "SELECT_GA_SERVICE_LEVEL":
      return _.assign({}, state, {
        gaServiceLevel: action.gaServiceLevel,
      });
    case "GA_PROFILE_CACHE":
      return _.assign({}, state, {
        gaMetricsCache: action.data,
      });
    case "SELECT_METRIC_NAME":
      return _.assign({}, state, {
        metricName: action.metricName,
      });
    case "SELECT_INTERVAL_TYPE":
      return _.assign({}, state, {
        intervalType: action.intervalType,
      });
    case "SELECT_COMPARISON_TYPE":
      return _.assign({}, state, {
        comparisonType: action.comparisonType,
      });
    case "SET_THRESHOLD":
      return _.assign({}, state, {
        threshold: action.threshold,
      });
    case "SET_START_MINUTES_AGO":
      return _.assign({}, state, {
        startMinutesAgo: action.startMinutesAgo,
      });
    case "SET_END_MINUTES_AGO":
      return _.assign({}, state, {
        endMinutesAgo: action.endMinutesAgo,
      });
    case "SET_EMAIL_RECIPIENTS":
      return _.assign({}, state, {
        emailRecipients: action.emailRecipients,
      });
    case "SET_SMS_RECIPIENTS":
      return _.assign({}, state, {
        smsRecipients: action.smsRecipients,
      });
    case "SET_SLACK_RECIPIENTS":
      return _.assign({}, state, {
        slackRecipients: action.slackRecipients,
      });
    case "SET_FLASH_MESSAGE":
      return _.assign({}, state, {
        flashMessage: action.flashMessage,
      });
    case "SET_FLASH_MESSAGE_WARNING":
      return _.assign({}, state, {
        flashMessageWarning: action.flashMessageWarning,
      });
    case "SET_WATCHER_ID":
      return _.assign({}, state, {
        watcherID: action.watcherID,
      });
    case "SET_GOOGLE_ANALYTICS_FILTERS":
      return _.assign({}, state, {
        googleAnalyticsFilters: action.googleAnalyticsFilters,
      });
    case "SET_WATCHER_SCHEDULE":
      return _.assign({}, state, {
        enabledTimes: action.schedule,
      });
    case "SET_WATCHER_NOTE":
      return _.assign({}, state, {
        watcherNote: action.watcherNote,
      });
    case "SET_TAB_INDEX":
      return _.assign({}, state, {
        tabIndex: action.tabIndex,
      });
    case "SET_WATCHER_ENABLE_TIMING":
      var changedState = JSON.parse(JSON.stringify(state));
      changedState.enabledTimes[action.day][action.key] = action.value;
      return _.assign({}, changedState);
    default:
      return state;
  }
};

// watcherFormReducerinitialState is exported to be used in tests
export { watcherFormReducer, watcherFormReducerinitialState };
