import React,{ useState, useEffect } from 'react';


const useReportAlerts = (reportID) => {
  const [alerts, setAlerts] = useState({ warningText:"", warnings: [] });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReportAlerts = async () => {
      const url = `/api/v1/reports/${reportID}/report_alerts`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          cache: 'no-cache',
        })

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const payload = await response.json();
        
        const warnings = payload.warnings || [];
        const warningText = payload.warningText ?? ""
         
        setAlerts({warningText, warnings });
        store.dispatch(setFlashMessageWarning ( warningText, warnings, "Schedule"));
        
      } catch (err) {
        setError(err.message);
      }
    };
    if(reportID){
        fetchReportAlerts();
    }
  }, [reportID]);

  return { alerts, error };
};

export default useReportAlerts;