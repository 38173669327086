import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import GenericListEditor from "../../../../widgets/data/list_editor";
import FilterValidation from "../../../../helpers/filter_validation";
import AccountSelector from "../../account_selector";
import WidgetPreviewFetcher from "../../../../helpers/widget_preview/widget_preview_fetcher";

const dimensions = [
  { label: "Campaign", value: "campaign" },
  { label: "Campaign Group", value: "campaign_group" },
  { label: "Creative", value: "creative" },
  { label: "Impression Device Type", value: "IMPRESSION_DEVICE_TYPE" },
];

const filterDimensionList = [
  { label: "Campaign Name", value: "campaign" },
  { label: "Campaign Group Name", value: "campaign_group" },
  { label: "Creative", value: "creative" },
  // { label: "Creative", value: "creative" } // Too complex for the very rare use case it'll be. Skipping for now
];

const noSecondaryMetric = ["user-audience"];
class ListEditor extends React.Component {
  constructor(props) {
    super(props);
  }

  saveAndClose() {
    let errors = [];

    if (!this.props.metric_name) {
      errors.push("Select the metric you want");
    }

    if (!FilterValidation.validate(this.props.filters)) {
      errors.push("At least one filter has an unspecified value");
    }

    if (errors.length > 0) {
      store.dispatch(setFlashMessage("We could not save the widget:", errors));
      return;
    }

    store.dispatch(setFlashMessage(undefined, []));

    this.props.save();
    this.close();
  }

  close() {
    new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    this.props.closeWidgetEditModal();
  }

  render() {
    const metrics = store.getState().widgetReducer.linkedInMetricsForCanvas;
    let metricsList = [];
    let currentFilterDimensionList = filterDimensionList;

    if (this.props.dimension !== undefined) {
      metricsList = metrics.filter((metric) => metric.metricLevel.includes(this.props.dimension));

      currentFilterDimensionList = currentFilterDimensionList.filter((filter) => filter.value == this.props.dimension);
    }

    return (
      <GenericListEditor
        {...this.props}
        name="LinkedIn Ads List"
        dimensions={dimensions}
        metricsList={metricsList}
        filterOperators={[
          { value: "equals", label: "equal" },
          { value: "not_equals", label: "do NOT equal" },
          { value: "contains", label: "contains" },
          { value: "not_contains", label: "does NOT contain" },
        ]}
        filterDimensionList={currentFilterDimensionList}
        filterHasORenabled={false}
        noSecondaryMetric={noSecondaryMetric}
        saveAndClose={this.saveAndClose.bind(this)}
        closeWidgetEditModal={this.close.bind(this)}
        deleteWidget={this.props.deleteWidget.bind(this)}
        offerPreviousPeriodComparison
        customAccountSelector={
          <AccountSelector
            disconnectStore
            onChange={this.props.updateCustomAccount}
            value={this.props.custom_account}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateCustomAccount: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("custom_account", value, ownProps.widgetIndex));
    },
    updateComparisonColumnName: (event) => {
      dispatch(
        updateEditorWidgetConfig(
          "secondary_column_is_previous_period_comparison",
          event.target.checked,
          ownProps.widgetIndex
        )
      );
    },
    updateComparisonColumnLabel: (event) => {
      dispatch(updateEditorWidgetConfig("comparison_column_label", event.target.value, ownProps.widgetIndex));
    },
    updateLabel: (event) => {
      dispatch(updateEditorWidgetConfig("label", event.target.value, ownProps.widgetIndex));
    },
    updateDimension: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label;
      }

      dispatch(updateEditorWidgetConfig("dimension", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("dimension_label", label, ownProps.widgetIndex));
    },
    updateDimensionLabel: (event) => {
      dispatch(updateEditorWidgetConfig("dimension_label", event.target.value, ownProps.widgetIndex));
    },
    updateMetricLabel: (event) => {
      dispatch(updateEditorWidgetConfig("metric_label", event.target.value, ownProps.widgetIndex));
    },
    updateMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label.split(" -- ")[0];
      }

      dispatch(updateEditorWidgetConfig("metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("metric_label", label, ownProps.widgetIndex));
    },
    updateSecondaryLabel: (event) => {
      dispatch(updateEditorWidgetConfig("secondary_metric_label", event.target.value, ownProps.widgetIndex));
    },
    updateSecondaryMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label.split(" -- ")[0];
      }

      dispatch(updateEditorWidgetConfig("secondary_metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("secondary_metric_label", label, ownProps.widgetIndex));
    },
    updateThirdLabel: (event) => {
      dispatch(updateEditorWidgetConfig("third_metric_label", event.target.value, ownProps.widgetIndex));
    },
    updateThirdMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label.split(" -- ")[0];
      }

      dispatch(updateEditorWidgetConfig("third_metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("third_metric_label", label, ownProps.widgetIndex));
    },
    updateLimit: (event) => {
      dispatch(updateEditorWidgetConfig("limit", event.target.value, ownProps.widgetIndex));
    },
    updateDateFilter: (ranges, isEnabledDateFilter) => {
      if(isEnabledDateFilter){
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, ownProps.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, ownProps.widgetIndex));
      }
      
    },
    updateFiltersEnabled: (checked) => {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, ownProps.widgetIndex));
    },

    save: () => {
      dispatch(saveWidgetConfigFromEditor(ownProps.widgetIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListEditor);
