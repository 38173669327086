import React from "react";
import { MapStateToProps, MapDispatchToProps, connect } from "react-redux";

const variablesRegex = /%([\w\d]+)%/g;

const variableOccurences = (widgets) => {
  let occurences = [];
  Object.keys(widgets).forEach((widgetID) => {
    const widget = widgets[widgetID];
    if (widget.text == undefined) return;
    const variables = [...widget.text.matchAll(variablesRegex)].map((match) => match[1]);

    variables.forEach((name) => {
      const occurenceIndex = occurences.findIndex((o) => o.name == name);
      if (occurenceIndex == -1) {
        occurences.push({
          name,
          widgetIDs: new Set([widgetID]),
          count: 1,
        });
      } else {
        occurences[occurenceIndex].widgetIDs.add(widgetID);
        occurences[occurenceIndex].count++;
      }
    });
  });
  return occurences;
};

const ReportCustomVariables = ({ tabIndex = 0, variables, widgets, dispatch }) => {
  const { setCustomVariables } = window;

  const occurences = variableOccurences(widgets);
  if (occurences.length <= 0) return null;

  const setVariable = (key, value) => {
    const newVariables = {
      ...variables,
      [key]: value,
    };

    // The following line keeps unset variables out of the store
    // Variables that become *unused* but are set will remain
    if (value == "") delete newVariables[key];
    dispatch(setCustomVariables(newVariables));
  };

  return (
    <div>
      <h3 className="tw-medium-header text-center my-4 mt-16">Custom variables</h3>
      <div className="field mw-report-site-name mb-4">
        {occurences.map(({ name, count }) => {
          const value = variables[name] || "";
          return (
            <div key={name}>
              <label className="tw-label flex">
                {name} <span className="ml-1 text-gray-500">({count}x)</span>
              </label>
              <input
                className="tw-field"
                tabIndex={tabIndex}
                placeholder="Value not defined"
                type="text"
                onChange={({ target }) => setVariable(name, target.value)}
                value={value}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    variables: state.reportFormReducer.custom_variables,
    widgets: state.widgetReducer.widgetConfigs,
  };
};

export default connect(mapStateToProps)(ReportCustomVariables);
