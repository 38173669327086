import axios from "axios";

export default class MetricLoader {
  constructor() {}

  fetch(databaseProviderType, callback) {
    axios.get("/api/v1/provider_accounts").then((response) => {
      if (response.status === 200) {
        const data = response.data.data;
        let accounts = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            let provider = data[i];

            if (provider.type == databaseProviderType) {
              accounts.push(provider);
            }
          }
        }

        callback(accounts);
      }
    });
  }
}
