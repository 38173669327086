import React from "react";
var createReactClass = require("create-react-class");
import Select from "react-select";
import _ from "lodash";

const ComparisonType = createReactClass({
  getInitialState: function () {
    return {
      options: [],
      comparisonType: undefined,
    };
  },

  comparisonTypesForIntervals: {
    real_time: ["goes_above", "falls_below"],
    real_time_vs_30_minutes_ago: ["changes_by", "changes_by_percentage"],
    last_hour: ["goes_above", "falls_below"],
    last_two_hours: ["goes_above", "falls_below"],
    last_three_hours: ["goes_above", "falls_below"],
    last_six_hours: ["goes_above", "falls_below"],
    two_hours_ago: ["goes_above", "falls_below"],
    three_hours_ago: ["goes_above", "falls_below"],
    six_hours_ago: ["goes_above", "falls_below"],
    today: ["goes_above", "falls_below"],
    today_vs_last_7_days: ["changes_by", "changes_by_percentage"],
    yesterday: ["goes_above", "falls_below"],
    last_week: ["goes_above", "falls_below"],
    last_month: ["goes_above", "falls_below"],
    last_hour_vs_hour_before: ["changes_by", "changes_by_percentage"],
    two_hours_ago_vs_hour_before: ["changes_by", "changes_by_percentage"],
    three_hours_ago_vs_hour_before: ["changes_by", "changes_by_percentage"],
    yesterday_vs_day_before: ["changes_by", "changes_by_percentage"],
    yesterday_vs_average_previous_7_days: ["changes_by", "changes_by_percentage"],
    yesterday_vs_same_day_last_week: ["changes_by", "changes_by_percentage"],
    last_week_vs_week_before: ["changes_by", "changes_by_percentage"],
    last_month_vs_month_before: ["changes_by", "changes_by_percentage"],
    last_month_vs_same_month_last_year: ["changes_by", "changes_by_percentage"],
  },

  onChange: function (data) {
    let val = undefined;
    if (data) {
      val = data.value;
    }
    this.setState({ comparisonType: val });
    store.dispatch(selectComparisonType(val));
  },

  componentDidMount: function () {
    this.storeSubscription = store.subscribe(
      function () {
        let currentStoreState = store.getState();
        let intervalType = currentStoreState.watcherFormReducer.intervalType;
        if (intervalType == undefined) {
          if (this.state.options != []) {
            this.setState({ options: [] });
          }

          if (this.state.comparisonType != undefined) {
            this.setState({ comparisonType: undefined }, function () {
              this.onChange({ value: undefined });
            });
          }

          return;
        }

        let newComparisonType = currentStoreState.watcherFormReducer.comparisonType;
        if (this.state.comparisonType !== newComparisonType) {
          this.setState({ comparisonType: newComparisonType });
        }

        let comparisonTypes = this.comparisonTypesForIntervals[intervalType];
        let options = comparisonTypes.map(function (obj) {
          var label;

          switch (obj) {
            case "changes_by":
              return { value: "changes_by", label: "Changes by (absolute)" };
            case "changes_by_percentage":
              return {
                value: "changes_by_percentage",
                label: "Changes by (percentage)",
              };
            case "falls_below":
              return { value: "falls_below", label: "Falls below" };
            case "goes_above":
              return { value: "goes_above", label: "Goes above" };
          }
        });
        if (!_.isEqual(this.state.options, options)) {
          this.setState({ options: options, comparisonType: undefined });
          if (currentStoreState.watcherFormReducer.comparisonType) {
            store.dispatch(selectComparisonType(undefined));
          }
        }
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    return (
      <div className="mw-comparison-type">
        <label className="tw-label">Comparison type</label>
        <Select
          value={this.state.comparisonType}
          tabIndex={tabIndex}
          label="Comparison type"
          options={this.state.options}
          onChange={this.onChange}
        />
      </div>
    );
  },
});

export default ComparisonType;
