import React from "react";
var createReactClass = require("create-react-class");
import $ from "jquery";

const WatcherName = createReactClass({
  getInitialState: function () {
    return { value: undefined };
  },

  onChange: function (event) {
    store.dispatch(setWatcherName(event.target.value));
  },

  componentDidMount: function () {
    if (store.getState().watcherFormReducer.watcherName) {
      this.setState({
        value: store.getState().watcherFormReducer.watcherName,
      });
    }
    this.storeSubscription = store.subscribe(
      function () {
        this.setState({
          value: store.getState().watcherFormReducer.watcherName,
        });
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    return (
      <div className="">
        <label className="tw-label">Name</label>
        <input
          ref="input"
          className="tw-field"
          tabIndex={tabIndex}
          placeholder="Name"
          type="text"
          onChange={this.onChange}
          value={this.state.value || ""}
        />
      </div>
    );
  },
});

export default WatcherName;
