import React from "react";
import ReactDOM from "react-dom";
var createReactClass = require("create-react-class");
import { NonRealtimeInveralTypes } from "../helpers/interval_types";
import $ from "jquery";
import _ from "lodash";
import APIError from "../helpers/api_error";

var Template = createReactClass({
  apiURL: "/api/v1/alerts",

  getInitialState: function () {
    return {
      intervalTypes: _.clone(NonRealtimeInveralTypes),
      value: undefined,
      messageDisplay: "none",
      message: "",
      error: false,
    };
  },

  componentWillMount: function () {
    this.setState({ value: this.props.defaultValue });
    this.currentProfileID = undefined;
  },

  componentDidMount: function () {
    this.storeSubscription = store.subscribe(
      function () {
        let newProfileID = store.getState().watcherFormReducer.google_analytics_profile_id;

        if (newProfileID && newProfileID != this.currentProfileID) {
          this.reset();
        }

        this.currentProfileID = newProfileID;
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  onChange: function (event) {
    this.setState({ value: event.target.value });
  },

  onKeyPress: function (event) {
    if (event.key === "Enter") {
      this.create();
    }
  },

  create: function () {
    let params = {
      name: this.props.title,
      metric_name: this.props.metricName,
      interval_type: this.props.intervalType,
      comparison_type: this.props.comparisonType,
      threshold: this.state.value,
      email_recipients: [MetricsWatch.currentUser.email],
      google_analytics_profile_id: store.getState().watcherFormReducer.google_analytics_profile_id,
      template_name: this.props.refID,
    };

    if (!params.google_analytics_profile_id) {
      this.displayMessage("Please select a site first.", true);
      return;
    }

    if (!params.threshold) {
      this.displayMessage("Please set a value in the field.", true);
      return;
    }

    $.ajax({
      url: this.apiURL,
      dataType: "json",
      method: "POST",
      data: params,
      success: function (data) {
        this.disable();
        this.displayMessage("Yay! Alert created.");
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("WatcherCreateFromTemplate", xhr, status, err);
      }.bind(this),
    });
  },

  disable: function () {
    this.refs.input.setAttribute("disabled", "true");
    this.refs.button.setAttribute("disabled", "true");
  },

  reset: function () {
    this.setState({ messageDisplay: "none" });

    this.refs.input.removeAttribute("disabled");
    this.refs.button.removeAttribute("disabled");
    this.setState({ value: this.props.defaultValue });
  },

  displayMessage: function (msg, isError) {
    this.setState({ messageDisplay: "block", message: msg });

    if (isError === true) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
    }
  },

  render: function () {
    return (
      <div className="template tw-content-box mb-4">
        <div className="template-body m-2">
          <h5 className="tw-small-header">{this.props.title}</h5>
          <p className="my-2">{this.props.question}</p>
          <input
            className="template-value tw-field w-40 inline"
            ref="input"
            type="number"
            placeholder="Threshold"
            value={this.state.value || ""}
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
          />
          <button ref="button" className="tw-submit-button ml-4 inline" onClick={this.create}>
            Create
          </button>
          <div
            ref="message"
            className={`mt-2 ${this.state.error ? "text-red-400" : "text-green-400"}`}
            style={{ display: this.state.messageDisplay }}
          >
            {this.state.message}
          </div>
        </div>
      </div>
    );
  },
});

export default Template;
