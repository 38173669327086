import axios from "axios";
import consumer from "./channels/consumer";
import { appsignal } from "./appsignal";

consumer.subscriptions.create("MetricProviderChannel", {
  getConnectionStatus() {
    return this.connectionStatus;
  },
  setConnectionStatus(newStatus) {
    this.connectionStatus = newStatus;
    return this.getConnectionStatus();
  },
  received(data) {
    if (data.status === "success") {
      const refreshButton = document.querySelectorAll(`[data-provider-id='${data.provider_id}']`)[0];

      refreshButton
        .closest(".mw-ui-integration-row")
        .getElementsByClassName("mw-ui-account-list-last-updated")[0].innerHTML = "Account list just updated";
      changeRefreshAccountButton(data.provider_id, "All fresh!");
    } else if (data.status === "error") {
      document.location.href = "/providers?error=refresh_error";
    }
  },

  connected: function () {
    // console.log("connected");
    this.setConnectionStatus("connected");
  },

  disconnected: function () {
    if (document.location.href.includes("/providers")) {
      const error_code = "MW-F-ERR-03";
      const errorMessage = `${error_code}: MetricProviderChannel disconnected`;
      let e = {
        name: `MetricProviderChannel`,
        message: errorMessage,
      };
      Bugsnag.notify(e);
      appsignal.sendError(e);

      // Set current connection status to disconnected
      this.setConnectionStatus("disconnected");

      // Delay the redirect to give time for the connection to be restored
      // Before introducing this mechanism, the page would self-redirect after clicking any item in the menu
      // when using Firefox. People were stuck on that page with Firefox!
      setTimeout(() => {
        if (this.getConnectionStatus() == "disconnected" && document.location.href.includes("/providers")) {
          document.location.href = "/providers?error=disconnected";
        }
      }, 2000);
    }
  },

  rejected: function () {
    const error_code = "MW-F-ERR-04";
    const errorMessage = `${error_code}: MetricProviderChannel connection rejected`;
    let e = {
      name: `MetricProviderChannel`,
      message: errorMessage,
    };
    Bugsnag.notify(e);
    appsignal.sendError(e);
  },
});

document.addEventListener("DOMContentLoaded", function () {
  let refreshButtons = allRefreshButtons();

  let refresh = function () {
    this.innerHTML = "Loading";
    this.setAttribute("disabled", "");

    let provider_id = this.getAttribute("data-provider-id");

    const url = `/api/v1/metrics_providers/refresh/${provider_id}`;

    axios
      .post(url, {
        provider_id: provider_id,
      })
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        document.location.href = "/providers";
      });
  };

  for (var i = 0; i < refreshButtons.length; i++) {
    refreshButtons[i].addEventListener("click", refresh, false);
  }
});

const changeRefreshAccountButton = function (providerId, text, status = "success") {
  let refreshButtons = allRefreshButtons();

  for (var i = 0; i < refreshButtons.length; i++) {
    if (String(providerId) === refreshButtons[i].getAttribute("data-provider-id")) {
      refreshButtons[i].innerHTML = text;
      if (status === "error") {
        refreshButtons[i].style.color = "white";
        refreshButtons[i].style.backgroundColor = "rgba(245, 101, 101, var(--tw-bg-opacity))";
      }
    }
  }
};

const allRefreshButtons = function () {
  return document.getElementsByClassName("refresh-account-cache");
};
