import React from "react";
import { connect } from "react-redux";
import FilterValidation from "../../../../helpers/filter_validation";
import GoogleSheetsBarGraphEditor from "./gs_bar_graph_editor";
import GoogleSheetsApi from "../../api";
import LoadSpinner from "../../../../components/utility/load_spinner";
import WidgetPreviewFetcher from "../../../../helpers/widget_preview/widget_preview_fetcher";
import GoogleSheetsActions from "../../google_sheets_actions";

let providerAccount;
let sheetId;
let currentlyRendering = false;
let dataTypeList = [];
let gsActions;

class GoogleSheetsBarGraphEditors extends React.Component {
  constructor(props) {
    super(props);

    this.api = new GoogleSheetsApi();

    gsActions = new GoogleSheetsActions();

    if (typeof this.props.gsDataType !== "undefined") {
      dataTypeList = this.props.gsDataType;
    }
    this.flagAccountSaved = false;
    if (typeof this.props.googleSheetsAccount !== "undefined") {
      this.flagAccountSaved = true;
    }
  }

  saveAndClose() {
    let errors = [];
    if (
      !this.props.gsFileName ||
      !this.props.gsSheetId ||
      !this.props.googleSheetsAccount ||
      !this.props.selectedSheet
    ) {
      errors.push("All fields are required. Please complete the form before saving and closing.");
    }
    if (!this.props.metric_name || !this.props.valueHandlingMetricOne) {
      errors.push("Select the metric you want and details");
    }

    if (
      this.props.secondaryMetricEnabled &&
      (!this.props.secondary_metric_name || !this.props.secondaryChartType || !this.props.valueHandlingMetricTwo)
    ) {
      errors.push("Secondary metric details are missing");
    }

    if (!FilterValidation.validate(this.props.filters)) {
      errors.push("At least one filter has an unspecified value");
    }

    if (errors.length > 0) {
      store.dispatch(setFlashMessage("We could not save the widget:", errors));
      return;
    }

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    store.dispatch(saveWidgetConfigFromEditor(this.props.widgetIndex));

    new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

    this.props.closeWidgetEditModal();
  }

  close() {
    if (!this.flagAccountSaved) {
      store.dispatch(
        setFlashMessage("All field are required", [
          "Please complete the form and save before closing, or delete the widget. You can't close without saving a new widget.",
        ])
      );
    } else {
      store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
      this.props.closeWidgetEditModal();
    }
  }

  render() {
    let dimensionSupportsSecondaryMetric = true;
    let googleSheetsEditorsInfo = {};
    var operators = [
      { value: "EQUAL", label: "equal" },
      { value: "NOT_EQUAL", label: "do NOT equal" },
      { value: "CONTAIN", label: "contains" },
      { value: "NOT_CONTAIN", label: "does NOT contain" },
      { value: "STARTS_WITH", label: "starts with" },
    ];

    providerAccount = this.props.googleSheetsAccount;
    sheetId = this.props.gsSheetId;

    googleSheetsEditorsInfo = {
      provider: "googlesheets",
      providerLabel: "Google Sheets Account",
      providerAccountId: this.props.googleSheetsAccount,
      fileName: { label: "File Name", value: this.props.gsFileName },
      valueHanding: this.props.valueHandling,
      sheetsList: this.props.gsSheetsList,
    };

    let filtersList = this.props.filtersList;
    if (this.props.dimension === "MW--period" || this.props.dimension === undefined) {
      filtersList = null;
    } else if (filtersList !== undefined) {
      filtersList = filtersList.filter((filter) => filter.value.includes(this.props.dimension));
    }
    return (
      <div>
        {currentlyRendering === true && <LoadSpinner extraClasses="absolute" />}
        <GoogleSheetsBarGraphEditor
          {...this.props}
          name="Google Sheets Chart"
          dimensions={this.props.dimensions}
          filterOperators={[
            { value: "LIKE", label: "contains" },
            { value: "NOT LIKE", label: "does NOT contain" },
          ]}
          filterDimensionList={filtersList}
          filterHasORenabled={false}
          metricsList={this.props.gsMetricsList}
          dimensionSupportsSecondaryMetric={dimensionSupportsSecondaryMetric}
          saveAndClose={this.saveAndClose.bind(this)}
          closeWidgetEditModal={this.close.bind(this)}
          deleteWidget={this.props.deleteWidget.bind(this)}
          googleSheetsEditorsInfo={googleSheetsEditorsInfo}
        />

        <p className="text-sm text-gray-500 mt-4">
          Please refer to{" "}
          <a
            href="https://help.metricswatch.com/article/5-how-to-use-the-google-sheets-integration"
            target="_blank"
            className="underline text-blue-500"
          >
            the Google Sheets integration documentation to learn more about how to format your data for Metrics Watch
          </a>
          .
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const removeSecondaryMetrics = (ownProps) => {
    dispatch(updateEditorWidgetConfig("secondaryMetricEnabled", false, ownProps.widgetIndex));
    dispatch(updateEditorWidgetConfig("secondary_metric_name", undefined, ownProps.widgetIndex));
    dispatch(updateEditorWidgetConfig("secondaryChartType", undefined, ownProps.widgetIndex));
  };

  return {
    updateLabel: (e) => {
      dispatch(updateEditorWidgetConfig("label", e.target.value, ownProps.widgetIndex));
    },
    updateMetric: (e) => {
      let dataTypeSelected = gsActions.return_data_type_by_metric(e.value, dataTypeList);
      dispatch(updateEditorWidgetConfig("metric_name", e.value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("metric_data_type", dataTypeSelected, ownProps.widgetIndex));
    },
    updateDimension: (e) => {
      dispatch(setFlashMessage(undefined, []));
      let dimension = e.value;

      dispatch(updateEditorWidgetConfig("metric_name", undefined, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("secondary_metric_name", undefined, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("dimension", dimension, ownProps.widgetIndex));
    },
    updateIndexAxis: (e) => {
      dispatch(updateEditorWidgetConfig("indexAxis", e.value, ownProps.widgetIndex));
    },
    updateLimit: (e) => {
      dispatch(updateEditorWidgetConfig("limit", e.target.value, ownProps.widgetIndex));
    },
    updateChartType: (e) => {
      dispatch(updateEditorWidgetConfig("chartType", e.value, ownProps.widgetIndex));
    },

    updateSecondaryMetric: (e) => {
      let dataTypeSelected = gsActions.return_data_type_by_metric(e.value, dataTypeList);

      dispatch(updateEditorWidgetConfig("secondary_metric_data_type", dataTypeSelected, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("secondary_metric_name", e.value, ownProps.widgetIndex));
    },
    updateSecondaryChartType: (e) => {
      dispatch(updateEditorWidgetConfig("secondaryChartType", e.value, ownProps.widgetIndex));
    },
    updateSecondaryAxisEnabled: (e) => {
      dispatch(updateEditorWidgetConfig("secondaryAxisEnabled", e.target.checked, ownProps.widgetIndex));
    },
    addSecondaryMetrics: () => {
      dispatch(updateEditorWidgetConfig("secondaryMetricEnabled", true, ownProps.widgetIndex));
    },
    removeSecondaryMetrics: () => {
      removeSecondaryMetrics(ownProps);
    },
    selectFile: () => {
      currentlyRendering = true;
      dispatch(updateEditorWidgetConfig("selectedSheet", "", ownProps.widgetIndex));
      if (!providerAccount) {
        store.dispatch(setFlashMessage("Please select an account.", ["Google sheets account"]));
        currentlyRendering = false;
        return;
      }
      gsActions.pick_file(
        dispatch,
        ownProps,
        providerAccount,
        () => {
          currentlyRendering = false;
          store.dispatch(setFlashMessage(undefined, []));
          dispatch(updateEditorWidgetConfig("selectedSheet", "", ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("metric_name", "", ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("label", "", ownProps.widgetIndex));
        },
        () => {
          currentlyRendering = false;
          // I had dispatch because if don't dispact before change variable, the loading continue. ????
          // JP do you have a better solution??
          dispatch(updateEditorWidgetConfig("googleSheetsAccount", providerAccount, ownProps.widgetIndex));
        }
      );
    },
    // the name onChangeProviderSelect is standard for reusable single metrics widget
    onChangeProviderSelector: (selected) => {
      currentlyRendering = true;
      providerAccount = selected.value;
      dispatch(updateEditorWidgetConfig("googleSheetsAccount", selected.value, ownProps.widgetIndex));
      gsActions.pick_file(
        dispatch,
        ownProps,
        providerAccount,
        () => {
          currentlyRendering = false;
          store.dispatch(setFlashMessage(undefined, []));
          dispatch(updateEditorWidgetConfig("selectedSheet", "", ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("metric_name", "", ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("label", "", ownProps.widgetIndex));
        },
        () => {
          currentlyRendering = false;
          // I had dispatch because if don't dispact before change variable, the loading continue. ????
          // JP do you have a better solution??
          dispatch(updateEditorWidgetConfig("googleSheetsAccount", providerAccount, ownProps.widgetIndex));
        }
      );
    },
    onChangeValueHandlingSelector: (selected, metricNumber) => {
      dispatch(updateEditorWidgetConfig("valueHandlingMetric" + metricNumber, selected.value, ownProps.widgetIndex));
    },
    updateDateFilter: (ranges, isEnabledDateFilter) => {
      if(isEnabledDateFilter){
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, ownProps.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, ownProps.widgetIndex));
      }
      
    },
    updateFiltersEnabled: (checked) => {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, ownProps.widgetIndex));
    },

    onChangeSheetsSelector: (selected) => {
      currentlyRendering = true;
      dispatch(updateEditorWidgetConfig("selectedSheet", selected.value, ownProps.widgetIndex));
      gsActions.setSheetValues(selected, dispatch, ownProps, sheetId, providerAccount, () => {
        currentlyRendering = false;
        dispatch(updateEditorWidgetConfig("metric_name", "", ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("label", "", ownProps.widgetIndex));
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleSheetsBarGraphEditors);
