import React from "react";
import { connect } from "react-redux";

import GenericSingleMetricEditor from "../../../../widgets/data/single_metric_editor";
import WidgetPreviewFetcher from "../../../../helpers/widget_preview/widget_preview_fetcher";
import AccountSelector from "../../../../legacy-components/account_selectors/mailchimp_account_selector";

class SingleMetricEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      metrics: [
        { label: "Subscribers", value: "member_count" },
        { label: "Open rate", value: "open_rate" },
        { label: "Click rate", value: "click_rate" },
      ],
    };
  }

  saveAndClose() {
    this.props.save();
    this.close();
  }

  close() {
    new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    this.props.closeWidgetEditModal();
  }

  render() {
    return (
      <GenericSingleMetricEditor
        {...this.props}
        name="Mailchimp Single Metric Widget"
        metrics={this.state.metrics}
        saveAndClose={this.saveAndClose.bind(this)}
        deleteWidget={this.props.deleteWidget.bind(this)}
        closeWidgetEditModal={this.close.bind(this)}
        customAccountSelector={
          <AccountSelector
            disconnectStore
            onChange={this.props.updateCustomAccount}
            value={this.props.custom_account}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateCustomAccount: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("custom_account", value, ownProps.widgetIndex));
    },
    updateLabel: (event) => {
      dispatch(updateEditorWidgetConfig("label", event.target.value, ownProps.widgetIndex));
    },
    updateMetric: (selected) => {
      dispatch(updateEditorWidgetConfig("metric_name", selected.value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("label", selected.label, ownProps.widgetIndex));
    },
    updateDateFilter: (ranges, isEnabledDateFilter) => {
      if(isEnabledDateFilter){
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, ownProps.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, ownProps.widgetIndex));
      }
      
    },
    updateFiltersEnabled: (checked) => {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, ownProps.widgetIndex));
    },

    save: () => {
      dispatch(saveWidgetConfigFromEditor(ownProps.widgetIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleMetricEditor);
