import React from "react";
import ReactModal from "react-modal";

const Modal = ({
  visible,
  closeCallback,
  offerToNavigateBack = false,
  noDefaultButtons = false,
  shouldCloseOnOverlayClick = true,
  title,
  htmlMessage,
  message = "",
  children,
  widthCanBeLarge,
  closeLabel = "Close",
  fullScreen,
}) => {
  const close = () => closeCallback();

  if (htmlMessage !== undefined) {
    message = htmlMessage;
  } else if (children) {
    message = children;
  } else {
    message = <p>{message}</p>;
  }

  let modalSizeClasses = widthCanBeLarge ? "max-w-5xl" : "max-w-md";
  if (fullScreen) {
    modalSizeClasses = "max-w-full max-h-full";
  }

  return (
    <ReactModal
      isOpen={visible}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      overlayClassName={"fixed z-50 inset-0 overflow-y-scroll flex justify-center p-8 bg-opacity-75 bg-gray-800"}
      className={`tw-flash modal-background my-auto mx-8 flex-1 shadow-2xl modal-content ${modalSizeClasses}`}
      onRequestClose={close}
      closeTimeoutMS={150}
      appElement={document.querySelector("body")}
    >
      <div className="p-2">
        {title && <h4 className="tw-small-header mb-4 text-blue-900">{title}</h4>}
        {message}
        {offerToNavigateBack && (
          <button onClick={() => history.back()} className="tw-button mt-4 bg-blue-200 hover:bg-blue-300">
            Go back
          </button>
        )}
        {!offerToNavigateBack && !noDefaultButtons && (
          <button onClick={close} className="tw-button mt-4 bg-blue-200 hover:bg-blue-300">
            {closeLabel}
          </button>
        )}
      </div>
      {/* Using react-modal's vanilla CSS to get transitions
          right despite the initial state being set as visible */}
      <style>
        {`
          .ReactModal__Overlay {
            opacity: 0;
            transition: opacity 150ms ease-in-out;
          }

          .ReactModal__Overlay--after-open {
            opacity: 1;
          }

          .ReactModal__Overlay--before-close {
            opacity: 0;
          }

          .ReactModal__Overlay .ReactModal__Content {
            opacity: 0;
            transform: translateY(10px);
            transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
          }

          .ReactModal__Overlay--after-open .ReactModal__Content{
            opacity: 1;
            transform: translateY(0);
          }

          .ReactModal__Overlay--before-close .ReactModal__Content{
            opacity: 0;
            transform: translateY(10px);
          }
        `}
      </style>
    </ReactModal>
  );
};

export default Modal;
