import React from "react";
import { connect } from "react-redux";

class CompareDailyOptions extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    // let available = this.props.scheduled(this.props.interval);
    return (
      <div className="field">
        <div>
          <label className="tw-label">
            <p>
              <input
                type="checkbox"
                className="mr-2 tw-checkbox"
                checked={this.props.compare_to_same_day_of_last_week}
                onChange={this.props.updateCompareSameDayOfLastWeek}
                // disabled={!available}
              />
              Compare to same day of last week (instead of previous day).
            </p>
          </label>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let compare_to_same_day_of_last_week = false;
  if (state.reportFormReducer.compare_daily_options) {
    compare_to_same_day_of_last_week = state.reportFormReducer.compare_daily_options.compare_to_same_day_of_last_week;
  }
  return {
    compare_to_same_day_of_last_week: compare_to_same_day_of_last_week,
    interval: state.reportFormReducer.interval,
    scheduled: function (interval) {
      return store.getState().reportFormReducer["interval_" + interval];
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateCompareSameDayOfLastWeek: (event) => {
      dispatch(setCompareDailyOptionsSameDayOfLastWeek(event.target.checked));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareDailyOptions);
