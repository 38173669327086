const WatcherFilterFormatter = {
  prepareToAPIFormat(filters) {
    return filters.map((f) => {
      if (Array.isArray(f)) {
        return this.prepareToAPIFormat(f);
      } else {
        return {
          dimension: f.selectedDimension,
          operator: f.selectedOperator,
          value: f.specifiedValue,
        };
      }
    });
  },

  loadFromAPIFormat(filters) {
    return filters.map((f) => {
      if (Array.isArray(f)) {
        return this.loadFromAPIFormat(f);
      } else {
        return {
          selectedDimension: f.dimension,
          selectedOperator: f.operator,
          specifiedValue: f.value,
        };
      }
    });
  },
};

export default WatcherFilterFormatter;
