import React from "react";

import ListWidget from "./widget";
import ListEditor from "./editor";
import WidgetBase from "../../../../widgets/widget_base";

export default class List extends WidgetBase {
  constructor() {
    super();
  }

  name() {
    return "Google Sheets List";
  }

  type() {
    return "google-sheets-list";
  }

  width() {
    return "large";
  }

  height() {
    return 3;
  }

  config(chartType) {
    return {
      widgetType: this.type(),
      name: this.name(),
      label: "",
      metric_name: "",
      metric_label: "",
      dimension: "",
      dimension_label: "",
      limit: 10,
      filters: [],
    };
  }

  widgetComponent(widgetIndex) {
    return React.createElement(
      ListWidget,
      {
        widgetIndex: widgetIndex,
        key: widgetIndex,
      },
      null
    );
  }

  editorComponent(widgetIndex, closeAction, deleteWidget) {
    return React.createElement(
      ListEditor,
      {
        widgetIndex: widgetIndex,
        key: widgetIndex,
        closeWidgetEditModal: closeAction,
        deleteWidget: deleteWidget,
      },
      null
    );
  }
}
