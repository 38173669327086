import React from "react";
var createReactClass = require("create-react-class");
import Select from "react-select";
import $ from "jquery";
import APIError from "../../helpers/api_error";
import { reportFormValidation } from "../../helpers/report_form_validation";
import { isEmpty } from "lodash";
import CommonAccountSelection from "../common_account_selection";

const FacebookPagesAccountSelector = createReactClass({
  getInitialState: function () {
    return {
      facebookPagesAccount: undefined,
      modalVisible: false,
      options: [],
    };
  },
  componentDidMount: function () {
    this.loadFbPagesAccount();
    if (this.props.disconnectStore && this.props.value !== null && this.props.value !== undefined) {
      this.setState({ facebookPagesAccount: this.props.value });
    } else {
      this.setState({options: store.getState().integrationsReducer.integrationsAccountLists["facebook"]});
      this.storeSubscription = store.subscribe(
        function () {
          let newValue = store.getState().reportFormReducer.facebook_pages_id;
          if (this.state.facebookPagesAccount !== newValue) {
            this.setState({ facebookPagesAccount: newValue });
          }
        }.bind(this)
      );
    }
  },

  componentDidUpdate: function (prevProps) {
    if (this.props.disconnectStore && (prevProps.value !== null && prevProps.value !== undefined)) {
      this.setState({ facebookPagesAccount: prevProps.value });
    }

    const integrationsAccountLists = store.getState().integrationsReducer.integrationsAccountLists;
    const accountList = integrationsAccountLists["facebook"];
    if (!isEmpty(accountList) && (this.state.facebookPagesAccount === undefined || this.state.facebookPagesAccount === null)) {
      this.setState({ options: accountList });
      this.setState({ facebookPagesAccount: accountList[0].value });
      store.dispatch(setFacebookPagesAccount(accountList[0].value));
    }
  },

  componentWillUnmount: function () {
    if (!this.props.disconnectStore) {
      this.storeSubscription();
    }
  },

  onChange: function (data) {
    let val = undefined;
    if (data) {
      val = data.value;
    }
    if (this.props.disconnectStore) {
      this.props.onChange(data);
    } else {
      store.dispatch(setFacebookPagesAccount(val));
      this.setState({ facebookPagesAccount: val });
    }
  },

  apiURL: function () {
    return "/api/v1/provider_accounts";
  },

  loadFbPagesAccount: function () {
    $.ajax({
      url: this.apiURL(),
      dataType: "json",
      cache: false,
      success: function (data) {
        this.prepareOptions(data);
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("FBPagesAccountSelector", xhr, status, err);
      }.bind(this),
    });
  },

  prepareOptions: function (data) {
    var options = [];
    for (var i = 0; i < data.data.length; i++) {
      var provider = data.data[i];
      if (provider.type === "metrics_providers-facebook_pages" && Array.isArray(provider.attributes.provider_accounts)) {
        for (var j = 0; j < provider.attributes.provider_accounts.length; j++) {
          var fb_acc = provider.attributes.provider_accounts[j];
          options.push({ value: fb_acc.id, label: fb_acc.name });
        }
      }
    }
    store.dispatch(setIntegrationAccountList("facebook", options));
  },
  handleIntegrationButton: function () {
    let integrations = window.integrationStore.findIntegrationByOAuthProviderName("facebook");
    integrations.oAuthProviderPreRedirectNotice();
    window.location = integrations.oAuthProviderRedirectPath();
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    let options = store.getState().integrationsReducer.integrationsAccountLists["facebook"];
    let display = "none";
    if (reportFormValidation.requiredIntegrations().includes("facebook")) {
      display = "block";
    }

    return (
      <div className="mw-fb-pages-account field" style={{ display: display }}>
         <CommonAccountSelection
          label={"Facebook Pages accounts"}
          disconnectStore={this.props.disconnectStore}
          tabIndex={tabIndex}
          accountSelected={this.onChange}
          value={this.state.facebookPagesAccount}
          options={options}
          handleIntegrationButton={this.handleIntegrationButton}
        />
      </div>
    );
  },
});

export default FacebookPagesAccountSelector;