import React from "react";
var createReactClass = require("create-react-class");

const Threshold = createReactClass({
  getInitialState: function () {
    return { value: undefined };
  },

  onChange: function (event) {
    let value = event.target.value;
    this.setState({ value: value });
    store.dispatch(setThreshold(value));
  },

  componentDidMount: function () {
    if (store.getState().watcherFormReducer.threshold) {
      this.setState({ value: store.getState().watcherFormReducer.threshold });
    }

    this.storeSubscription = store.subscribe(
      function () {
        this.setState({ value: store.getState().watcherFormReducer.threshold });
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    return (
      <div className="my-6 mw-threshold">
        <label className="tw-label">Threshold</label>
        {store.getState().watcherFormReducer.comparisonType == "changes_by" && (
          <p className="text-xs mb-4 text-gray-500">
            For "Changes by (absolute)" comparisons, negative changes can be set by using a negative threshold. Example:
            "-2" for a drop of 2.
          </p>
        )}

        {store.getState().watcherFormReducer.comparisonType == "changes_by_percentage" && (
          <p className="text-xs mb-4 text-gray-500">
            For "Changes by (percentage)" comparisons, negative changes can be set by using a negative threshold.
            Example: "-2" for a drop of 2%.
          </p>
        )}
        <input
          ref="input"
          className="tw-field"
          tabIndex={tabIndex}
          placeholder="Threshold"
          type="number"
          onChange={this.onChange}
          value={this.state.value || ""}
        />
      </div>
    );
  },
});

export default Threshold;
