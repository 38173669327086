import React from "react";
var createReactClass = require("create-react-class");
import { connect, Provider } from "react-redux";
import { getDateYYYYMMDD, prepareReportNewsFormDataFromStoreForApi } from "../reducers/report_news";
import { showErrorMessageIfAnyInXhr } from "../helpers/utils";

import { DateRange } from "react-date-range";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import { frCA, enUS } from "date-fns/locale";

import $ from "jquery";
import APIError from "../helpers/api_error";
import ButtonWithIcon from "./generic/button_with_icon.js";
import LoadSpinner from "../components/utility/load_spinner.jsx";
import FlashMessage from "./flash_message.js";

const ReportNews = createReactClass({
  getInitialState: function () {
    return {
      loading: true,
      dateRangeInputFocused: "startDate",
      title: "",
      start_date: getDateYYYYMMDD(false),
      end_date: getDateYYYYMMDD(true),
      text: "",
      image: "",
    };
  },

  loadReportNews: function () {
    var editingReportNews = this.props.editingReportNews;
    $.ajax({
      url: "/api/v1/report_news_items/" + editingReportNews,
      dataType: "json",
      method: "GET",
      success: function (data) {
        store.dispatch(gotReportNewsConfig(data.data));
        this.setState({ loading: false });
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("ReportNewsGet", xhr, status, err);
      }.bind(this),
    });
  },

  componentDidMount: function () {
    var editingReportNews = this.props.editingReportNews;
    if (editingReportNews) {
      this.loadReportNews();
    } else {
      this.setState({ loading: false }); // If it is a new item.
    }
  },

  saveReportNews: function () {
    var editingReportNews = this.props.editingReportNews;

    $.ajax({
      url: "/api/v1/report_news_items" + (editingReportNews ? "/" + editingReportNews : ""),
      dataType: "json",
      method: editingReportNews ? "PATCH" : "POST",
      data: prepareReportNewsFormDataFromStoreForApi(this.props),
      success: function (data) {
        document.location.href = "/report_news?" + (editingReportNews ? "updated" : "saved");
      }.bind(this),
      error: function (xhr, status, err) {
        showErrorMessageIfAnyInXhr(xhr.responseText);
        new APIError("ReportNewsSave", xhr, status, err);
      }.bind(this),
    });
  },

  onDateRangeInputChange(val) {
    store.dispatch(updateReportNewsAttribute("start_date", formatISO(val.selection.startDate)));
    store.dispatch(updateReportNewsAttribute("end_date", formatISO(val.selection.endDate)));
  },

  componentWillReceiveProps: function (nextProps) {
    this.setState(nextProps);
  },

  render: function () {
    if (this.state.loading === true) {
      return <LoadSpinner />;
    }

    return (
      <div>
        <div className="field">
          <FlashMessage />

          <label className="tw-label">Title</label>
          <input
            className="tw-field mb-4"
            value={this.state.title}
            onChange={function (event) {
              store.dispatch(updateReportNewsAttribute("title", event.target.value));
              this.setState({ title: event.target.value });
            }.bind(this)}
          />
        </div>
        <div className="field">
          <label className="tw-label">
            Image URL
            <i
              style={{
                color: "grey",
              }}
            >
              (optional)
            </i>
          </label>
          <input
            className="tw-field mb-4"
            value={this.state.image}
            onChange={function (event) {
              store.dispatch(updateReportNewsAttribute("image", event.target.value));
              this.setState({ image: event.target.value });
            }.bind(this)}
          />
        </div>
        <div className="field">
          <label className="tw-label">Text</label>
          <textarea
            className="tw-field mb-4"
            value={this.state.text}
            onChange={function (event) {
              store.dispatch(updateReportNewsAttribute("text", event.target.value));
              this.setState({ text: event.target.value });
            }.bind(this)}
          />
        </div>
        <div className="field mb-4">
          <label className="tw-label">News Schedule</label>
          <p className="text-sm text-gray-500 mb-2">
            This news item will be added to all your reports between the dates you choose here.
          </p>
          <DateRange
            locale={this.props.lang == "fr" ? frCA : enUS}
            ranges={[
              {
                startDate: parseISO(this.state.start_date),
                endDate: parseISO(this.state.end_date),
                key: "selection",
              },
            ]}
            editableDateInputs={true}
            onInit={this.onDateRangeInputChange}
            onChange={this.onDateRangeInputChange}
          />
        </div>

        <ButtonWithIcon
          text="Save"
          icon="fa-check"
          extraClasses="tw-submit-button mr-2"
          onClick={this.saveReportNews}
        />
      </div>
    );
  },
});

function mapReportNewsStateToProps(state, ownProps) {
  return state.reportNewsFormReducer;
}

var ReportNewsConnect = connect(mapReportNewsStateToProps)(ReportNews);
var ReportNewsWithProvider = createReactClass({
  render: function () {
    return (
      <Provider store={store}>
        <ReportNewsConnect editingReportNews={this.props.match.params.id} />
      </Provider>
    );
  },
});

export default ReportNewsWithProvider;
