import IntegrationBase from "../integration_base";
import SingleMetric from "./widgets/single_metric/single_metric.js";
import List from "./widgets/list/list.js";
import BarGraph from "./widgets/bar_graph/bar_graph.js";
import PieChart from "./widgets/pie_chart/pie_chart.js";
import IntegrationIcon from "./logo/Mailchimp_Logo-Vertical_Black.png";

export default class Mailchimp extends IntegrationBase {
  constructor() {
    super();
    this.registerWidget(new SingleMetric());
    this.registerWidget(new List());
    this.registerWidget(new BarGraph());
    this.registerWidget(new PieChart());
  }

  name() {
    return "Mailchimp";
  }

  iconURL() {
    return IntegrationIcon;
  }

  oAuthProviderName() {
    return "mailchimp";
  }

  reducerAccountIDVariableName() {
    return "mailchimp_list_id";
  }

  reportStoreDispatchFunction() {
    return setMailchimpList;
  }
}
