import { differenceInDays, subYears, subMonths } from "date-fns";
import Cookies from "js-cookie";

function validate_date_range_per_integration(requiredIntegrations) {
  const startDate = new Date(Cookies.get("reportStartDate"));
  const endDate = new Date(Cookies.get("reportEndDate"));

  // Validating integrations with max 90 days of data at a time
  const integrationsWithMax90days = ["Facebook Pages"];
  const daysInRange = differenceInDays(endDate, startDate);
  const hasMax90daysIntegration = integrationsWithMax90days.some((val) => requiredIntegrations.includes(val));
  if (hasMax90daysIntegration && daysInRange > 90) {
    return `Facebook can not have more than 90 days of data. This is a limitation from Facebook. You can change the dates.`;
  }

  // Validating integrations that can't go further than 2 years ago in the data (from current date)
  const integrationsWithMax2yearsOfHistory = ["Facebook Pages", "Instagram"];
  const date2yearsAgo = subYears(new Date(), 2);
  const haxMax2yearsIntegration = integrationsWithMax2yearsOfHistory.some((val) => requiredIntegrations.includes(val));
  if (haxMax2yearsIntegration && startDate < date2yearsAgo) {
    return `Facebook and/or Instagram can not include data from more than 2 years ago. You can change the dates.`;
  }

  // Validating integrations that can't go further than 37 months ago in the data (from current date)
  const integrationsWithMax37MonthsOfHistory = ["FB Ads"];
  const date37monthsAgo = subMonths(new Date(), 37);
  const hasMax37monthsIntegration = integrationsWithMax37MonthsOfHistory.some((val) =>
    requiredIntegrations.includes(val)
  );
  if (hasMax37monthsIntegration && startDate < date37monthsAgo) {
    return `Facebook Ads can not include data from more than 37 months ago. You can change the dates.`;
  }

  // Validating integrations that can't go further than 14 months
  const integrationsWithMax14MonthsOfHistory = ["LinkedIn Pages"];
  const date14monthsAgo = subMonths(new Date(), 14);
  const hasMax14monthsIntegration = integrationsWithMax14MonthsOfHistory.some((val) =>
    requiredIntegrations.includes(val)
  );
  if (hasMax14monthsIntegration && startDate < date14monthsAgo) {
    return `Linkedin Pages can not include data from more than 14 months ago. You can change the dates.`;
  }

  return null;
}

export { validate_date_range_per_integration };
