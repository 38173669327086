import { appsignal } from "../appsignal";

class APIError {
  constructor(context, xhr, status, err) {
    if (status == "error" && xhr.status == 401) {
      window.location.reload();
    } else {
      let e = {
        name: `${context}APIError`,
        message: 'err: "' + err + '" Status: "' + xhr.status + '" responseText:   ' + xhr.responseText,
      };
      Bugsnag.notify(e);
      appsignal.sendError(e);
    }
  }
}

export default APIError;
