import React from "react";

import VerticalBarGraph from "./vertical-bar-graph";

// It is only called if the live builder is disabled
const Placeholder = ({ name }) => (
  <div
    className="h-16 w-full flex flex-row items-center justify-center rounded"
    style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
  >
    <p>Placeholder for a {name}!</p>
  </div>
);

const SingleMetricUpper = ({ color, arrowColor = "#fff", number, up }) => (
  <p className="text-3xl font-extrabold">
    <span className="inline-block h-6 w-6 mr-2 rounded-full text-center" style={{ backgroundColor: color }}>
      <svg
        width="14"
        viewBox="0 0 16 18"
        stroke="currentColor"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={"mx-auto mt-1" + (up ? " transform rotate-180" : "")}
        style={{ color: arrowColor }}
      >
        <path d="M8 1.92893L8 15.3335" strokeWidth="2" strokeLinecap="round" />
        <path
          d="M3.75736 11.8284L8 16.071L12.2426 11.8284"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
    {number}
  </p>
);

const SingleMetricLower = () => (
  <>
    <p className="text-xs">
      Change: <strong>0%</strong>
    </p>
    <p className="text-xs">
      Previous period: <strong>123</strong>
    </p>
  </>
);

const ListPreview = ({
  color,
  metricName = "Count",
  dimensionName = "Publication",
  secondary_metric_label = false,
  third_metric_label,
  secondary_column_is_previous_period_comparison,
  comparison_column_label,
}) => {
  const rowStyle = { borderColor: color };
  return (
    <table className="table-auto w-full">
      <colgroup>
        <col style={{ width: "100%", paddingRight: 8 }} />
        <col />
        {secondary_column_is_previous_period_comparison === true && <col style={{ paddingLeft: 8 }} />}
        {secondary_column_is_previous_period_comparison !== true && secondary_metric_label && (
          <col style={{ paddingLeft: 8 }} />
        )}
        {secondary_column_is_previous_period_comparison !== true && third_metric_label && (
          <col style={{ paddingLeft: 8 }} />
        )}
      </colgroup>
      <thead>
        <tr className="border-b-2" style={rowStyle}>
          <th className="text-left">{dimensionName}</th>
          <th className="text-right">{metricName}</th>
          {secondary_column_is_previous_period_comparison === true && (
            <th className="text-right pl-4">{comparison_column_label}</th>
          )}
          {secondary_column_is_previous_period_comparison !== true && secondary_metric_label && (
            <th className="text-right pl-4">{secondary_metric_label}</th>
          )}
          {secondary_column_is_previous_period_comparison !== true && third_metric_label && (
            <th className="text-right pl-4">{third_metric_label}</th>
          )}
        </tr>
      </thead>
      <tbody>
        <tr className="border-b" style={rowStyle}>
          <td>Item</td>
          <td className="text-right">123</td>
          {secondary_column_is_previous_period_comparison === true && <td className="text-right">345</td>}
          {secondary_column_is_previous_period_comparison !== true && secondary_metric_label && (
            <td className="text-right">345</td>
          )}
          {secondary_column_is_previous_period_comparison !== true && third_metric_label && (
            <td className="text-right">789</td>
          )}
        </tr>
        <tr className="border-b" style={rowStyle}>
          <td>Item</td>
          <td className="text-right">3456</td>
          {secondary_column_is_previous_period_comparison === true && <td className="text-right">23</td>}
          {secondary_column_is_previous_period_comparison !== true && secondary_metric_label && (
            <td className="text-right">23</td>
          )}
          {secondary_column_is_previous_period_comparison !== true && third_metric_label && (
            <td className="text-right">789</td>
          )}
        </tr>
        <tr className="border-b" style={rowStyle}>
          <td>Item</td>
          <td className="text-right">234</td>
          {secondary_column_is_previous_period_comparison === true && <td className="text-right">345</td>}
          {secondary_column_is_previous_period_comparison !== true && secondary_metric_label && (
            <td className="text-right">345</td>
          )}
          {secondary_column_is_previous_period_comparison !== true && third_metric_label && (
            <td className="text-right">789</td>
          )}
        </tr>
        <tr className="border-b" style={rowStyle}>
          <td>Item</td>
          <td className="text-right">1234</td>
          {secondary_column_is_previous_period_comparison === true && <td className="text-right">456</td>}
          {secondary_column_is_previous_period_comparison !== true && secondary_metric_label && (
            <td className="text-right">456</td>
          )}
          {secondary_column_is_previous_period_comparison !== true && third_metric_label && (
            <td className="text-right">789</td>
          )}
        </tr>
      </tbody>
    </table>
  );
};

const AllGoals = () => (
  <>
    <h3 className="label text-xl font-bold">All goals with data (Absolute)</h3>
    <p className="text-sm">This will generate one widget per goal that had conversions recently.</p>
  </>
);

export default {
  SingleMetricUpper,
  SingleMetricLower,
  ListPreview,
  VerticalBarGraph,
  AllGoals,
};
