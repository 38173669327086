import { validate_date_range_per_integration } from "../integration_date_validation";
import IntegrationAccountsPreparator from "./integration_accounts_preparator";
import { appsignal } from "../../appsignal";
import axios from "axios";

export default class WidgetPreviewFetcher {
  fetch(widgetIndex, widgetConfigs = null) {
    const reportID = store.getState().reportFormReducer.reportID;
    const url = `/api/v1/reports/${reportID}/widgets/${widgetIndex}`;

    if (!widgetConfigs) {
      widgetConfigs = store.getState().widgetReducer.widgetConfigs;
    }

    let error = validate_date_range_per_integration(widgetConfigs[widgetIndex].name);

    axios
      .post(url, {
        error: error,
        widget_index: widgetIndex,
        widget_config: widgetConfigs,
        integration_accounts: new IntegrationAccountsPreparator().prepare(),
      })
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        Bugsnag.notify(error);
        appsignal.sendError(error);
      });
  }
}
