import React from "react";
var createReactClass = require("create-react-class");
import Select from "react-select";
import $ from "jquery";

const ReportDayOfWeek = createReactClass({
  getInitialState: function () {
    return {
      options: [
        { value: "sunday", label: "Sunday" },
        { value: "monday", label: "Monday" },
        { value: "tuesday", label: "Tuesday" },
        { value: "wednesday", label: "Wednesday" },
        { value: "thursday", label: "Thursday" },
        { value: "friday", label: "Friday" },
        { value: "saturday", label: "Saturday" },
      ],
      day_of_week: undefined,
    };
  },

  onChange: function (data) {
    let val = data.value;
    this.setState({ day_of_week: val });
    store.dispatch(setReportDOW(val));
  },

  componentDidMount: function () {
    this.setState({
      day_of_week: store.getState().reportFormReducer.day_of_week,
    });

    this.storeSubscription = store.subscribe(
      function () {
        this.setState({
          day_of_week: store.getState().reportFormReducer.day_of_week,
        });
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    return (
      <div className="field" id="reportDOW">
        <label className="tw-label">Day of week</label>
        <Select
          tabIndex={tabIndex}
          ref="select"
          label="Day of week"
          options={this.state.options}
          onChange={this.onChange}
          value={this.state.day_of_week}
        />
      </div>
    );
  },
});

export default ReportDayOfWeek;
