import React from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";

class RichTextWidget extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="widget full-width-widget border border-dashed border-gray-300 hover:border-gray-500 rounded">
        <div>
          <div className="widget-classification flex" style={{ backgroundColor: "#fff" }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="8 0 16 24" stroke="currentColor" width="10">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>

            <div>
              <span className="provider">Layout</span>
              Rich Text Editor
            </div>
          </div>
          <p className="italic text-sm mb-4">
            Note: this is a preview. You need to enter edit mode with the edit icon at the bottom right to change the
            content.
          </p>
          <div className="rich-text-widget" dangerouslySetInnerHTML={{ __html: this.props.text }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return state.widgetReducer.widgetConfigs[ownProps.widgetIndex];
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateText: (value) => {
      dispatch(updateWidgetConfig("text", value, ownProps.widgetIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RichTextWidget);
