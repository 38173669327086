export default class IntegrationStore {
  constructor() {
    this.integrations = [];
    this.globalWidgetStore = {};
    this.widgetTypeToNameStore = {};
  }

  registerIntegration(integration) {
    this.integrations.push(integration);

    for (let widget = 0; widget < integration.widgetStore.length; widget++) {
      const w = integration.widgetStore[widget];
      this.globalWidgetStore[w.name()] = {
        type: w.type(),
        name: w.name(),
        widget: w,
      };
      this.widgetTypeToNameStore[w.type()] = w.name();
    }
  }

  findIntegrationByOAuthProviderName(oAuthProviderName) {
    for (var i = 0; i < this.integrations.length; i++) {
      let integration = this.integrations[i];

      if (integration.oAuthProviderName() === oAuthProviderName) {
        return integration;
      }
    }

    throw `Integration "${oAuthProviderName}" not found via "findIntegrationByOAuthProviderName"`;
  }

  findIntegrationByReducerAccountIDVariableName(accountVariableName, returnFalseIfNotFound = false) {
    for (var i = 0; i < this.integrations.length; i++) {
      let integration = this.integrations[i];

      if (integration.reducerAccountIDVariableName() === accountVariableName) {
        return integration;
      }
    }

    if (returnFalseIfNotFound) {
      return false;
    }

    throw `Integration "${accountVariableName}" not found via "findIntegrationByReducerAccountIDVariableName"`;
  }

  allWidgetNames() {
    return Object.getOwnPropertyNames(this.globalWidgetStore);
  }

  allWidgetTypes() {
    let types = [];
    this.allWidgetNames().forEach(
      function (name) {
        types.push(this.globalWidgetStore[name].type);
      }.bind(this)
    );

    return types;
  }

  getWidgetByName(name) {
    const widgetDefinition = this.globalWidgetStore[name];
    if (widgetDefinition) {
      return widgetDefinition.widget;
    }
    return undefined;
  }

  getWidgetByType(type) {
    const widgetName = this.widgetTypeToNameStore[type];
    if (widgetName) {
      return this.getWidgetByName(widgetName);
    }

    return undefined;
  }
}
