import axios from "axios";

export default class DimensionLoader {
  constructor() {}

  fetch(integrationProviderName, callback) {
    axios.get(`/api/v1/dimensions?provider=${integrationProviderName}`).then((response) => {
      if (response.status === 200) {
        callback(response.data);
      }
    });
  }
}
