import React from "react";
var createReactClass = require("create-react-class");
import Select from "react-select";
import $ from "jquery";
import Modal from "../components/modal";
import APIError from "../helpers/api_error";
import { reportFormValidation } from "../helpers/report_form_validation";
import { isEmpty } from "lodash";
import CommonAccountSelection from "./common_account_selection";

const GoogleAnalyticsProfiles = createReactClass({
  apiURL: function () {
    if (this.props.form == "watcher-ga4") {
      return "/api/v1/metrics_providers";
    } else {
      return "/api/v1/ga_profiles";
    }
  },

  onChange: function (data) {
    // This is for when we embed the selector in a different context, like a secondary account
    // that is widget-specific
    if (this.props.disconnectStore) {
      this.props.onChange(data);
    } else {
      if (data) {
        store.dispatch(selectGoogleAnalyticsProfile(data.value));
        store.dispatch(setGoogleAnalyticsServiceLevel(data.serviceLevel));
      }
      store.dispatch(selectMetricName(undefined));
    }
  },

  getInitialState: function () {
    return {
      google_analytics_profile_id: undefined,
      modalVisible: false,
      integrationAccounts: [],
    };
  },

  componentDidMount: function () {
    this.loadProfiles();
    if (this.props.disconnectStore) {
      this.setState({ google_analytics_profile_id: this.props.value });
    } else {
      this.storeSubscription = store.subscribe(
        function () {
          let newGAProfileID = store.getState().watcherFormReducer.google_analytics_profile_id;
          if (newGAProfileID != this.state.google_analytics_profile_id) {
            this.setState({ google_analytics_profile_id: newGAProfileID });
          }
        }.bind(this)
      );
    }
  },

  componentWillUnmount: function () {
    if (!this.props.disconnectStore) {
      this.storeSubscription();
    }
  },

  componentWillReceiveProps: function (nextProps) {
    if (this.props.disconnectStore) {
      this.setState({ google_analytics_profile_id: nextProps.value });
    }

    const integrationsAccountLists = store.getState().integrationsReducer.integrationsAccountLists;
    const accountList = integrationsAccountLists[this.state.integration];
    if (!isEmpty(accountList)) {
      if (this.state.google_analytics_profile_id === undefined) {
        this.setState({ google_analytics_profile_id: accountList[0].value });
      }
    }
  },

  loadProfiles: function () {
    $.ajax({
      url: this.apiURL(),
      dataType: "json",
      cache: false,
      success: function (data) {
        this.prepareOptions(data);
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("GAProfiles", xhr, status, err);
      }.bind(this),
    });
  },

  prepareGA4Options: function (data) {
    let options = [];
    for (let index = 0; index < data.data.length; index++) {
      const integrationAccount = data.data[index];
      let ga4Accounts = integrationAccount.attributes.provider_accounts;
      if (integrationAccount.type == "metrics_providers-google_analytics4s" && ga4Accounts) {
        for (let j = 0; j < ga4Accounts.length; j++) {
          const account = ga4Accounts[j];
          const properties = ga4Accounts[j].properties;

          for (let propertyIndex = 0; propertyIndex < properties.length; propertyIndex++) {
            const property = properties[propertyIndex];
            options.push({
              value: property.name,
              label: `${account.displayName} - ${property.displayName}`,
              serviceLevel: property.serviceLevel,
            });
          }
        }
      }
    }

    if (options.length === 0) {
      if (this.props.form != "report") {
        this.showLoadingModal();
      }
    } else {
      this.hideLoadingModal();
    }
    this.setState({ integrationAccounts: options });
    store.dispatch(setIntegrationAccountList("google", options));
  },

  prepareOptions: function (data) {
    if (!this.props.disconnectStore) {
      // If we are editing an alert...
      let storeValue = store.getState().watcherFormReducer.google_analytics_profile_id;
      if (storeValue) {
        this.setState({ google_analytics_profile_id: storeValue });
      }
    }

    if (this.props.form == "watcher-ga4") {
      this.prepareGA4Options(data);
      return;
    }

    if (data.data.length === 0) {
      if (this.props.form != "report") {
        this.showLoadingModal();
      }
    } else {
      this.hideLoadingModal();
    }

    let options = data.data.map(function (opt) {
      return {
        value: opt.profile_id,
        label: opt.name + " - " + opt.profile_name,
      };
    });
    this.setState({ integrationAccounts: options });
    if (options.length > 0) {
      this.setState({ google_analytics_profile_id: options[0].value });
      store.dispatch(selectGoogleAnalyticsProfile(options[0].value));
    }
    store.dispatch(setIntegrationAccountList("google", options));
  },

  showLoadingModal: function () {
    if (!this.state.modalVisible) {
      clearInterval(this.retryInterval);
      this.retryInterval = setInterval(
        function () {
          this.loadProfiles();
        }.bind(this),
        2000
      );
      this.setState({ modalVisible: true });
    }
  },

  hideLoadingModal: function () {
    clearInterval(this.retryInterval);
    if (this.state.modalVisible) {
      this.setState({ modalVisible: false });
    }
  },
  handleIntegrationButton: function () {
    let integrations = window.integrationStore.findIntegrationByOAuthProviderName("google");
    integrations.oAuthProviderPreRedirectNotice();
    window.location = integrations.oAuthProviderRedirectPath();
  },
  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    let options = this.state.integrationAccounts
    let display = "block";
    if (this.props.form == "report" && !reportFormValidation.requiredIntegrations().includes("google")) {
      display = "none";
    }

    return (
      <div className="mw-ga-profile" style={{ display: display }}>
        <Modal
          visible={this.state.modalVisible}
          title="Please wait a moment..."
          message="We are currently getting data from Google for this account. It usually doesn't take more than a minute or two. This will be closed when it is ready for use, or you can close it and add alerts for another account while waiting."
          offerToNavigateBack
        />
        <CommonAccountSelection
          label="Google Analytics Property"
          disconnectStore={this.props.disconnectStore}
          tabIndex={tabIndex}
          accountSelected={this.onChange}
          value={this.state.google_analytics_profile_id}
          options={this.state.integrationAccounts}
          handleIntegrationButton={this.handleIntegrationButton}
        />
      </div>
    );
  },
});

export default GoogleAnalyticsProfiles;

