/*
This JSON is generated with GEM money Version 6.12 in Ruby with this code : 

currencies = []
    Money::Currency.table.values.each do |currency|
      currencies += [{label: currency[:name] + " (" + currency[:iso_code] + ")", value: currency[:iso_code]}]
    end
currencies.to_json
*/

const currenciesList = [
  { label: "United Arab Emirates Dirham (AED)", value: "AED" },
  { label: "Afghan Afghani (AFN)", value: "AFN" },
  { label: "Albanian Lek (ALL)", value: "ALL" },
  { label: "Armenian Dram (AMD)", value: "AMD" },
  { label: "Netherlands Antillean Gulden (ANG)", value: "ANG" },
  { label: "Angolan Kwanza (AOA)", value: "AOA" },
  { label: "Argentine Peso (ARS)", value: "ARS" },
  { label: "Australian Dollar (AUD)", value: "AUD" },
  { label: "Aruban Florin (AWG)", value: "AWG" },
  { label: "Azerbaijani Manat (AZN)", value: "AZN" },
  { label: "Bosnia and Herzegovina Convertible Mark (BAM)", value: "BAM" },
  { label: "Barbadian Dollar (BBD)", value: "BBD" },
  { label: "Bangladeshi Taka (BDT)", value: "BDT" },
  { label: "Bulgarian Lev (BGN)", value: "BGN" },
  { label: "Bahraini Dinar (BHD)", value: "BHD" },
  { label: "Burundian Franc (BIF)", value: "BIF" },
  { label: "Bermudian Dollar (BMD)", value: "BMD" },
  { label: "Brunei Dollar (BND)", value: "BND" },
  { label: "Bolivian Boliviano (BOB)", value: "BOB" },
  { label: "Brazilian Real (BRL)", value: "BRL" },
  { label: "Bahamian Dollar (BSD)", value: "BSD" },
  { label: "Bhutanese Ngultrum (BTN)", value: "BTN" },
  { label: "Botswana Pula (BWP)", value: "BWP" },
  { label: "Belarusian Ruble (BYN)", value: "BYN" },
  { label: "Belarusian Ruble (BYR)", value: "BYR" },
  { label: "Belize Dollar (BZD)", value: "BZD" },
  { label: "Canadian Dollar (CAD)", value: "CAD" },
  { label: "Congolese Franc (CDF)", value: "CDF" },
  { label: "Swiss Franc (CHF)", value: "CHF" },
  { label: "Unidad de Fomento (CLF)", value: "CLF" },
  { label: "Chilean Peso (CLP)", value: "CLP" },
  { label: "Chinese Renminbi Yuan (CNY)", value: "CNY" },
  { label: "Colombian Peso (COP)", value: "COP" },
  { label: "Costa Rican Colón (CRC)", value: "CRC" },
  { label: "Cuban Convertible Peso (CUC)", value: "CUC" },
  { label: "Cuban Peso (CUP)", value: "CUP" },
  { label: "Cape Verdean Escudo (CVE)", value: "CVE" },
  { label: "Czech Koruna (CZK)", value: "CZK" },
  { label: "Djiboutian Franc (DJF)", value: "DJF" },
  { label: "Danish Krone (DKK)", value: "DKK" },
  { label: "Dominican Peso (DOP)", value: "DOP" },
  { label: "Algerian Dinar (DZD)", value: "DZD" },
  { label: "Egyptian Pound (EGP)", value: "EGP" },
  { label: "Eritrean Nakfa (ERN)", value: "ERN" },
  { label: "Ethiopian Birr (ETB)", value: "ETB" },
  { label: "Euro (EUR)", value: "EUR" },
  { label: "Fijian Dollar (FJD)", value: "FJD" },
  { label: "Falkland Pound (FKP)", value: "FKP" },
  { label: "British Pound (GBP)", value: "GBP" },
  { label: "Georgian Lari (GEL)", value: "GEL" },
  { label: "Ghanaian Cedi (GHS)", value: "GHS" },
  { label: "Gibraltar Pound (GIP)", value: "GIP" },
  { label: "Gambian Dalasi (GMD)", value: "GMD" },
  { label: "Guinean Franc (GNF)", value: "GNF" },
  { label: "Guatemalan Quetzal (GTQ)", value: "GTQ" },
  { label: "Guyanese Dollar (GYD)", value: "GYD" },
  { label: "Hong Kong Dollar (HKD)", value: "HKD" },
  { label: "Honduran Lempira (HNL)", value: "HNL" },
  { label: "Croatian Kuna (HRK)", value: "HRK" },
  { label: "Haitian Gourde (HTG)", value: "HTG" },
  { label: "Hungarian Forint (HUF)", value: "HUF" },
  { label: "Indonesian Rupiah (IDR)", value: "IDR" },
  { label: "Israeli New Sheqel (ILS)", value: "ILS" },
  { label: "Indian Rupee (INR)", value: "INR" },
  { label: "Iraqi Dinar (IQD)", value: "IQD" },
  { label: "Iranian Rial (IRR)", value: "IRR" },
  { label: "Icelandic Króna (ISK)", value: "ISK" },
  { label: "Jamaican Dollar (JMD)", value: "JMD" },
  { label: "Jordanian Dinar (JOD)", value: "JOD" },
  { label: "Japanese Yen (JPY)", value: "JPY" },
  { label: "Kenyan Shilling (KES)", value: "KES" },
  { label: "Kyrgyzstani Som (KGS)", value: "KGS" },
  { label: "Cambodian Riel (KHR)", value: "KHR" },
  { label: "Comorian Franc (KMF)", value: "KMF" },
  { label: "North Korean Won (KPW)", value: "KPW" },
  { label: "South Korean Won (KRW)", value: "KRW" },
  { label: "Kuwaiti Dinar (KWD)", value: "KWD" },
  { label: "Cayman Islands Dollar (KYD)", value: "KYD" },
  { label: "Kazakhstani Tenge (KZT)", value: "KZT" },
  { label: "Lao Kip (LAK)", value: "LAK" },
  { label: "Lebanese Pound (LBP)", value: "LBP" },
  { label: "Sri Lankan Rupee (LKR)", value: "LKR" },
  { label: "Liberian Dollar (LRD)", value: "LRD" },
  { label: "Lesotho Loti (LSL)", value: "LSL" },
  { label: "Libyan Dinar (LYD)", value: "LYD" },
  { label: "Moroccan Dirham (MAD)", value: "MAD" },
  { label: "Moldovan Leu (MDL)", value: "MDL" },
  { label: "Malagasy Ariary (MGA)", value: "MGA" },
  { label: "Macedonian Denar (MKD)", value: "MKD" },
  { label: "Myanmar Kyat (MMK)", value: "MMK" },
  { label: "Mongolian Tögrög (MNT)", value: "MNT" },
  { label: "Macanese Pataca (MOP)", value: "MOP" },
  { label: "Mauritanian Ouguiya (MRU)", value: "MRU" },
  { label: "Mauritian Rupee (MUR)", value: "MUR" },
  { label: "Maldivian Rufiyaa (MVR)", value: "MVR" },
  { label: "Malawian Kwacha (MWK)", value: "MWK" },
  { label: "Mexican Peso (MXN)", value: "MXN" },
  { label: "Malaysian Ringgit (MYR)", value: "MYR" },
  { label: "Mozambican Metical (MZN)", value: "MZN" },
  { label: "Namibian Dollar (NAD)", value: "NAD" },
  { label: "Nigerian Naira (NGN)", value: "NGN" },
  { label: "Nicaraguan Córdoba (NIO)", value: "NIO" },
  { label: "Norwegian Krone (NOK)", value: "NOK" },
  { label: "Nepalese Rupee (NPR)", value: "NPR" },
  { label: "New Zealand Dollar (NZD)", value: "NZD" },
  { label: "Omani Rial (OMR)", value: "OMR" },
  { label: "Panamanian Balboa (PAB)", value: "PAB" },
  { label: "Peruvian Sol (PEN)", value: "PEN" },
  { label: "Papua New Guinean Kina (PGK)", value: "PGK" },
  { label: "Philippine Peso (PHP)", value: "PHP" },
  { label: "Pakistani Rupee (PKR)", value: "PKR" },
  { label: "Polish Złoty (PLN)", value: "PLN" },
  { label: "Paraguayan Guaraní (PYG)", value: "PYG" },
  { label: "Qatari Riyal (QAR)", value: "QAR" },
  { label: "Romanian Leu (RON)", value: "RON" },
  { label: "Serbian Dinar (RSD)", value: "RSD" },
  { label: "Russian Ruble (RUB)", value: "RUB" },
  { label: "Rwandan Franc (RWF)", value: "RWF" },
  { label: "Saudi Riyal (SAR)", value: "SAR" },
  { label: "Solomon Islands Dollar (SBD)", value: "SBD" },
  { label: "Seychellois Rupee (SCR)", value: "SCR" },
  { label: "Sudanese Pound (SDG)", value: "SDG" },
  { label: "Swedish Krona (SEK)", value: "SEK" },
  { label: "Singapore Dollar (SGD)", value: "SGD" },
  { label: "Saint Helenian Pound (SHP)", value: "SHP" },
  { label: "Slovak Koruna (SKK)", value: "SKK" },
  { label: "Sierra Leonean Leone (SLL)", value: "SLL" },
  { label: "Somali Shilling (SOS)", value: "SOS" },
  { label: "Surinamese Dollar (SRD)", value: "SRD" },
  { label: "South Sudanese Pound (SSP)", value: "SSP" },
  { label: "São Tomé and Príncipe Dobra (STD)", value: "STD" },
  { label: "Salvadoran Colón (SVC)", value: "SVC" },
  { label: "Syrian Pound (SYP)", value: "SYP" },
  { label: "Swazi Lilangeni (SZL)", value: "SZL" },
  { label: "Thai Baht (THB)", value: "THB" },
  { label: "Tajikistani Somoni (TJS)", value: "TJS" },
  { label: "Turkmenistani Manat (TMT)", value: "TMT" },
  { label: "Tunisian Dinar (TND)", value: "TND" },
  { label: "Tongan Paʻanga (TOP)", value: "TOP" },
  { label: "Turkish Lira (TRY)", value: "TRY" },
  { label: "Trinidad and Tobago Dollar (TTD)", value: "TTD" },
  { label: "New Taiwan Dollar (TWD)", value: "TWD" },
  { label: "Tanzanian Shilling (TZS)", value: "TZS" },
  { label: "Ukrainian Hryvnia (UAH)", value: "UAH" },
  { label: "Ugandan Shilling (UGX)", value: "UGX" },
  { label: "United States Dollar (USD)", value: "USD" },
  { label: "Uruguayan Peso (UYU)", value: "UYU" },
  { label: "Uzbekistan Som (UZS)", value: "UZS" },
  { label: "Venezuelan Bolívar Soberano (VES)", value: "VES" },
  { label: "Vietnamese Đồng (VND)", value: "VND" },
  { label: "Vanuatu Vatu (VUV)", value: "VUV" },
  { label: "Samoan Tala (WST)", value: "WST" },
  { label: "Central African Cfa Franc (XAF)", value: "XAF" },
  { label: "Silver (Troy Ounce) (XAG)", value: "XAG" },
  { label: "Gold (Troy Ounce) (XAU)", value: "XAU" },
  { label: "European Composite Unit (XBA)", value: "XBA" },
  { label: "European Monetary Unit (XBB)", value: "XBB" },
  { label: "European Unit of Account 9 (XBC)", value: "XBC" },
  { label: "European Unit of Account 17 (XBD)", value: "XBD" },
  { label: "East Caribbean Dollar (XCD)", value: "XCD" },
  { label: "Special Drawing Rights (XDR)", value: "XDR" },
  { label: "West African Cfa Franc (XOF)", value: "XOF" },
  { label: "Palladium (XPD)", value: "XPD" },
  { label: "Cfp Franc (XPF)", value: "XPF" },
  { label: "Platinum (XPT)", value: "XPT" },
  { label: "Codes specifically reserved for testing purposes (XTS)", value: "XTS" },
  { label: "Yemeni Rial (YER)", value: "YER" },
  { label: "South African Rand (ZAR)", value: "ZAR" },
  { label: "Zambian Kwacha (ZMK)", value: "ZMK" },
  { label: "Zambian Kwacha (ZMW)", value: "ZMW" },
  { label: "Bitcoin Cash (BCH)", value: "BCH" },
  { label: "Bitcoin (BTC)", value: "BTC" },
  { label: "Jersey Pound (JEP)", value: "JEP" },
  { label: "Guernsey Pound (GGP)", value: "GGP" },
  { label: "Isle of Man Pound (IMP)", value: "IMP" },
  { label: "UIC Franc (XFU)", value: "XFU" },
  { label: "British Penny (GBX)", value: "GBX" },
  { label: "Chinese Renminbi Yuan Offshore (CNH)", value: "CNH" },
  { label: "Estonian Kroon (EEK)", value: "EEK" },
  { label: "Ghanaian Cedi (GHS)", value: "GHS" },
  { label: "Lithuanian Litas (LTL)", value: "LTL" },
  { label: "Latvian Lats (LVL)", value: "LVL" },
  { label: "Mauritanian Ouguiya (MRO)", value: "MRO" },
  { label: "Maltese Lira (MTL)", value: "MTL" },
  { label: "Turkmenistani Manat (TMM)", value: "TMM" },
  { label: "Japanese Yen (JPY)", value: "JPY" },
  { label: "Zimbabwean Dollar (ZWD)", value: "ZWD" },
  { label: "Zimbabwean Dollar (ZWL)", value: "ZWL" },
  { label: "Zimbabwean Dollar (ZWN)", value: "ZWN" },
  { label: "Zimbabwean Dollar (ZWR)", value: "ZWR" },
  { label: "Venezuelan Bolívar (VEF)", value: "VEF" },
];

export { currenciesList };
