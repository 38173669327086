import React from "react";

import Widget from "./widget";
import Editor from "./editor";
import WidgetBase from "../../../widgets/widget_base";

export default class List extends WidgetBase {
  constructor(widget, integration) {
    super(widget, integration);
  }

  name() {
    return this.widget.widgetName;
  }

  type() {
    return this.widget.widgetType;
  }

  width() {
    return "large";
  }

  height() {
    return 3;
  }

  config() {
    return {
      widgetType: this.type(),
      name: this.name(),
      label: this.widget.defaultLabel,
      metric_name: this.widget.defaultMetricName,
      metric_label: this.widget.defaultMetricLabel,
      dimension_label: this.widget.defaultDimensionLabel,
      dimension: this.widget.defaultDimensionName,
      limit: 5,
      filters: [],
    };
  }

  widgetComponent(widgetIndex) {
    return React.createElement(
      Widget,
      {
        widgetIndex: widgetIndex,
        key: widgetIndex,
        providerName: this.integration.name(),
      },
      null
    );
  }

  editorComponent(widgetIndex, closeAction, deleteWidget) {
    return React.createElement(
      Editor,
      {
        widgetIndex: widgetIndex,
        key: widgetIndex,
        closeWidgetEditModal: closeAction,
        deleteWidget: deleteWidget,
        // noSecondaryMetric: this.integration.noSecondaryMetric,
        metrics: this.integration.config.metrics(),
        dimensions: this.integration.config.dimensions(),
        filterOperators: this.integration.config.filterOperators,
        filterDimensionList: this.integration.config.filterDimensionList(),
        filterHasORenabled: this.integration.config.filterHasORenabled,
        widgetName: this.name(),
        providerName: this.integration.name(),
        oAuthProviderName: this.integration.oAuthProviderName(),
      },
      null
    );
  }
}
