import React from "react";
var createReactClass = require("create-react-class");

const WatcherNote = createReactClass({
  getInitialState: function () {
    return { value: undefined };
  },

  onChange: function (event) {
    store.dispatch(setWatcherNote(event.target.value));
  },

  componentDidMount: function () {
    if (store.getState().watcherFormReducer.watcherNote) {
      this.setState({ value: store.getState().watcherFormReducer.watcherNote });
    }

    this.storeSubscription = store.subscribe(
      function () {
        this.setState({
          value: store.getState().watcherFormReducer.watcherNote,
        });
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    return (
      <div className="">
        <label className="tw-label">Note</label>
        <textarea
          ref="textarea"
          className="tw-field"
          rows="12"
          tabIndex={tabIndex}
          placeholder="(optional) Add a note for the people that will receive with this alert. It could be instructions, for example."
          onChange={this.onChange}
          value={this.state.value}
        />
      </div>
    );
  },
});

export default WatcherNote;
