import React from "react";
import { connect } from "react-redux";
import GenericBarGraphEditor from "../../../../widgets/data/bar_graph_editor";
import FilterValidation from "../../../../helpers/filter_validation";
import WidgetPreviewFetcher from "../../../../helpers/widget_preview/widget_preview_fetcher";
import AccountSelector from "../../account_selector";

let allMetrics = [];

const filterDimensionList = [
  { label: "Campaign Name", value: "campaign" },
  { label: "Campaign Group Name", value: "campaign_group" },
  { label: "Impression Device Type", value: "IMPRESSION_DEVICE_TYPE" },
  // { label: "Creative", value: "creative" } // Too complex for the very rare use case it'll be. Skipping for now
];

class LinkedInAdsPieChartEditor extends React.Component {
  constructor(props) {
    super(props);

    allMetrics = store.getState().widgetReducer.linkedInMetricsForCanvas;
  }

  saveAndClose() {
    let errors = [];

    if (!this.props.metric_name) {
      errors.push("Select the metric you want");
    }

    if (this.props.secondaryMetricEnabled && (!this.props.secondary_metric_name || !this.props.secondaryChartType)) {
      errors.push("Secondary metric details are missing");
    }

    if (!FilterValidation.validate(this.props.filters)) {
      errors.push("At least one filter has an unspecified value");
    }

    if (errors.length > 0) {
      store.dispatch(setFlashMessage("We could not save the widget:", errors));
      return;
    }

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    store.dispatch(saveWidgetConfigFromEditor(this.props.widgetIndex));

    this.close();
  }

  close() {
    new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    this.props.closeWidgetEditModal();
  }

  render() {
    return (
      <GenericBarGraphEditor
        {...this.props}
        name="LinkedIn Ads Chart"
        dimensions={filterDimensionList}
        metricsList={allMetrics}
        filterOperators={[
          { value: "equals", label: "equal" },
          { value: "not_equals", label: "do NOT equal" },
          { value: "contains", label: "contains" },
          { value: "not_contains", label: "does NOT contain" },
        ]}
        filterDimensionList={filterDimensionList}
        filterHasORenabled={false}
        saveAndClose={this.saveAndClose.bind(this)}
        closeWidgetEditModal={this.close.bind(this)}
        deleteWidget={this.props.deleteWidget.bind(this)}
        customAccountSelector={
          <AccountSelector
            disconnectStore
            onChange={this.props.updateCustomAccount}
            value={this.props.custom_account}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateCustomAccount: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("custom_account", value, ownProps.widgetIndex));
    },
    updateLabel: (e) => {
      dispatch(updateEditorWidgetConfig("label", e.target.value, ownProps.widgetIndex));
    },
    updateMetric: (e) => {
      dispatch(updateEditorWidgetConfig("metric_name", e.value, ownProps.widgetIndex));
    },
    updateDimension: (e) => {
      dispatch(updateEditorWidgetConfig("dimension", e.value, ownProps.widgetIndex));
    },
    updateIndexAxis: (e) => {
      dispatch(updateEditorWidgetConfig("indexAxis", e.value, ownProps.widgetIndex));
    },
    updateLimit: (e) => {
      dispatch(updateEditorWidgetConfig("limit", e.target.value, ownProps.widgetIndex));
    },
    updateChartType: (e) => {
      dispatch(updateEditorWidgetConfig("chartType", e.value, ownProps.widgetIndex));
    },

    updateSecondaryMetric: (e) => {
      dispatch(updateEditorWidgetConfig("secondary_metric_name", e.value, ownProps.widgetIndex));
    },
    updateSecondaryChartType: (e) => {
      dispatch(updateEditorWidgetConfig("secondaryChartType", e.value, ownProps.widgetIndex));
    },
    updateSecondaryAxisEnabled: (e) => {
      dispatch(updateEditorWidgetConfig("secondaryAxisEnabled", e.target.checked, ownProps.widgetIndex));
    },
    updateDateFilter: (ranges, isEnabledDateFilter) => {
      if(isEnabledDateFilter){
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, ownProps.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, ownProps.widgetIndex));
      }
      
    },
    updateFiltersEnabled: (checked) => {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, ownProps.widgetIndex));
    },

    addSecondaryMetrics: (e) => {
      dispatch(updateEditorWidgetConfig("secondaryMetricEnabled", true, ownProps.widgetIndex));
    },
    removeSecondaryMetrics: (e) => {
      dispatch(updateEditorWidgetConfig("secondaryMetricEnabled", false, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("secondary_metric_name", undefined, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("secondaryChartType", undefined, ownProps.widgetIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedInAdsPieChartEditor);
