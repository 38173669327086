import React, { useRef, useEffect } from "react";

// Note: importing ChartJS without treeshaking
import Chart from "chart.js/auto";

const ChartComponent = ({ className, type, data, options, width, height }) => {
  const canvasRef = useRef();

  useEffect(() => {
    if (canvasRef.current) {
      new Chart(canvasRef.current.getContext("2d"), { type, data, options });
    }
  }, [canvasRef.current, type, data, options]);

  return (
    <canvas
      className={className}
      ref={canvasRef}
      width={width}
      height={height}
      style={{ maxHeight: "320px", width: width }}
    />
  );
};

export default ChartComponent;
