import React from "react";
import WidgetBase from "../widget_base";
import LargeTextBoxWidget from "./large-text-box_widget.jsx";
import LargeTextBoxEditor from "./large-text-box_editor.jsx";

export default class Separator extends WidgetBase {
  constructor() {
    super();
  }

  name() {
    return "Layout -- Large Text Box";
  }

  type() {
    return "layout-large-text-box";
  }

  width() {
    return "large";
  }

  height() {
    return 3;
  }

  config(chartType) {
    return {
      widgetType: this.type(),
      widgetName: this.name(),
      text: "Your text here. Click to edit.\n\nOh, and it can be on multiple lines!",
    };
  }

  duplicateWidget(widgetId) {
    store.dispatch(duplicateWidgetOnCanvas(widgetId));
  }

  editorComponent(widgetIndex, closeAction, deleteWidget) {
    return React.createElement(
      LargeTextBoxEditor,
      {
        widgetIndex: widgetIndex,
        key: widgetIndex,
        closeWidgetEditModal: closeAction,
        deleteWidget: deleteWidget,
      },
      null
    );
  }

  renderInGrid(widgetLayout, showWidgetEditModal, deleteSelectedWidget) {
    return (
      <div
        className="canvas-grid-item layout-large-text-box"
        id={widgetLayout.i}
        key={widgetLayout.i}
        data-grid={widgetLayout}
      >
        <div>
          <div className="canvas-widget-edit-save">
            <a
              onClick={function () {
                return this.duplicateWidget(widgetLayout.i);
              }.bind(this)}
              className=""
            >
              <i className="fas fa-copy" />
            </a>
            <a
              onClick={function () {
                return showWidgetEditModal(widgetLayout.i);
              }.bind(this)}
            >
              <i className="fas fa-edit" />
            </a>
            <a
              onClick={function () {
                return deleteSelectedWidget(widgetLayout.i);
              }.bind(this)}
            >
              <i className="fas fa-times" />
            </a>
          </div>
        </div>
        <LargeTextBoxWidget widgetIndex={widgetLayout.i} key={widgetLayout.i} />
      </div>
    );
  }
}
