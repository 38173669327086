import React from "react";
import { connect } from "react-redux";

class SingleMetricComparisonDisabledSetting extends React.Component {
  render() {
    return (
      <>
        <label className="tw-label" htmlFor="agency_setting_slim_header">
          <input
            id="use-ppc-markup"
            className="mr-2 tw-checkbox"
            type="checkbox"
            checked={this.props.single_metric_comparison_disabled}
            onChange={this.props.updateSingleMetricsComparisonSettings}
          />
          Disable comparison in Single Metrics widgets
        </label>
      </>
    );
  }
}

function mapStateToProps(state) {
  return state.reportFormReducer;
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSingleMetricsComparisonSettings: (event) => {
      dispatch(setSingleMetricComparisonDisabled(event.target.checked));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleMetricComparisonDisabledSetting);
