document.addEventListener("DOMContentLoaded", function () {
  let classname = document.getElementsByClassName("fix-account");

  let openModal = function () {
    let oauthProviderName = this.getAttribute("data-provider-oauth-provider-name");

    window.location = `/providers/auth/${oauthProviderName}`;
  };

  for (var i = 0; i < classname.length; i++) {
    classname[i].addEventListener("click", openModal, false);
  }
});
