import { deepPurpleLogo, deepPurpleTheme } from "./themes/deep_purple";
import { darkBlueTheme, darkBlueLogo } from "./themes/dark_blue";
import { fireTheme, fireLogo } from "./themes/fire";
import { pinkPastelTheme, pinkPastelLogo } from "./themes/pink_pastel";
import { neonBlueLogo, neonBlueTHeme } from "./themes/neon_blue";
import { violetTurquoiseTheme, violetTurquoiseLogo } from "./themes/violet_turquoise";
import { brownVintageLogo, brownVintageTheme } from "./themes/brown_vintage";
import { springTheme, springLogo } from "./themes/spring";
import { defaultTheme, defaultThemeLogo } from "./themes/default";

const Themes = {
  deep_purple: {
    theme: deepPurpleTheme,
    logo: deepPurpleLogo,
  },

  dark_blue: {
    theme: darkBlueTheme,
    logo: darkBlueLogo,
  },

  violet_turquoise: {
    theme: violetTurquoiseTheme,
    logo: violetTurquoiseLogo,
  },

  pink_pastel: {
    theme: pinkPastelTheme,
    logo: pinkPastelLogo,
  },

  fire: {
    theme: fireTheme,
    logo: fireLogo,
  },

  neon_blue: {
    theme: neonBlueTHeme,
    logo: neonBlueLogo,
  },

  brown_vintage: {
    theme: brownVintageTheme,
    logo: brownVintageLogo,
  },

  spring: {
    theme: springTheme,
    logo: springLogo,
  },

  default: {
    theme: defaultTheme,
    logo: defaultThemeLogo,
  },
};

export default Themes;
