window.setAllMetrics = function (metrics) {
  return { type: "SET_ALL_METRICS", metrics: metrics };
};

window.setAllDimensions = function (dimensions) {
  return { type: "SET_ALL_DIMENSIONS", dimensions: dimensions };
};

window.setWatcherID = function (id) {
  return { type: "SET_WATCHER_ID", watcherID: id };
};

window.setWatcherEnabledTimings = function (value, day, key) {
  return {
    type: "SET_WATCHER_ENABLE_TIMING",
    value: value,
    day: day,
    key: key,
  };
};

window.setAlertSchedule = function (schedule) {
  return {
    type: "SET_WATCHER_SCHEDULE",
    schedule: schedule,
  };
};

window.setWatcherName = function (val) {
  return { type: "SET_WATCHER_NAME", watcherName: val };
};

window.setGaMetricsCache = function (data) {
  return { type: "GA_PROFILE_CACHE", data: data };
};

window.setWatcherNote = function (val) {
  return { type: "SET_WATCHER_NOTE", watcherNote: val };
};

window.setThreshold = function (val) {
  return { type: "SET_THRESHOLD", threshold: val };
};

window.setStartMinutesAgo = function (val) {
  return { type: "SET_START_MINUTES_AGO", startMinutesAgo: val };
};

window.setEndMinutesAgo = function (val) {
  return { type: "SET_END_MINUTES_AGO", endMinutesAgo: val };
};

window.setSmsRecipients = function (val) {
  return { type: "SET_SMS_RECIPIENTS", smsRecipients: val };
};

window.setSlackRecipients = function (val) {
  return { type: "SET_SLACK_RECIPIENTS", slackRecipients: val };
};

window.selectMetricName = function (val) {
  return { type: "SELECT_METRIC_NAME", metricName: val };
};

window.selectIntervalType = function (val) {
  return { type: "SELECT_INTERVAL_TYPE", intervalType: val };
};

window.selectGoogleAnalyticsProfile = function (val) {
  return { type: "SELECT_GA_PROFILE", google_analytics_profile_id: val };
};

window.setGoogleAnalyticsServiceLevel = function (val) {
  return { type: "SELECT_GA_SERVICE_LEVEL", gaServiceLevel: val };
};

window.selectGA4Profile = function (val) {
  return { type: "SELECT_GA4_PROFILE", google_analytics_profile_id: val };
};

window.setTabIndex = function (index) {
  return {
    type: "SET_TAB_INDEX",
    tabIndex: index,
  };
};

window.setGoogleAnalyticsFilters = function (val) {
  return { type: "SET_GOOGLE_ANALYTICS_FILTERS", googleAnalyticsFilters: val };
};

window.selectComparisonType = function (val) {
  return { type: "SELECT_COMPARISON_TYPE", comparisonType: val };
};

window.setEmailRecipients = function (val) {
  return { type: "SET_EMAIL_RECIPIENTS", emailRecipients: val };
};
