import $ from "jquery";
import APIError from "../../helpers/api_error";

export default class AccountLoader {
  loadAccounts() {
    $.ajax({
      url: "/api/v1/provider_accounts",
      dataType: "json",
      cache: false,
      success: function (data) {
        this.prepareOptions(data);
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("GoogleSheetsAccounts", xhr, status, err);
      }.bind(this),
    });
  }

  prepareOptions(data) {
    var options = [];

    for (var i = 0; i < data.data.length; i++) {
      var provider = data.data[i];
      if (
        provider.type == "metrics_providers-google_sheets" &&
        typeof provider.attributes.provider_accounts === "object"
      ) {
        options.push({
          value: String(provider.attributes.provider_accounts.google_sheets_provider_id),
          label: provider.attributes.provider_accounts.google_sheets_provider_name,
        });
      }
    }
    store.dispatch(setIntegrationAccountList("googlesheets", options));
  }
}
