window.setFlashMessage = function (text, errors) {
  if (Array.isArray(errors) === false) {
    throw 'Argument Error: "errors" is not array';
  }

  return { type: "SET_FLASH_MESSAGE", flashMessage: { text: text, errors: errors } };
};
window.setFlashMessageWarning = function (text, warnings, tabRef) {
  if (Array.isArray(warnings) === false) {
    throw 'Argument Error: "warnings" is not array';
  }
  return { type: "SET_FLASH_MESSAGE_WARNING", flashMessageWarning: { text: text, warnings: warnings, tabRef: tabRef } };
};