var googleAnalyticsDimensionList = [
  { value: "ga:userType", label: "User - User Type" },
  { value: "ga:sessionCount", label: "User - Count of Sessions" },
  { value: "ga:daysSinceLastSession", label: "User - Days Since Last Session" },
  { value: "ga:userDefinedValue", label: "User - User Defined Value" },
  { value: "ga:sessionDurationBucket", label: "Sessions - Session Duration" },
  { value: "ga:referralPath", label: "Traffic Sources - Referral Path" },
  { value: "ga:fullReferrer", label: "Traffic Sources - Full Referrer" },
  { value: "ga:campaign", label: "Traffic Sources - Campaign" },
  { value: "ga:source", label: "Traffic Sources - Source" },
  { value: "ga:medium", label: "Traffic Sources - Medium" },
  { value: "ga:sourceMedium", label: "Traffic Sources - Source / Medium" },
  { value: "ga:keyword", label: "Traffic Sources - Keyword" },
  { value: "ga:adContent", label: "Traffic Sources - Ad Content" },
  { value: "ga:socialNetwork", label: "Traffic Sources - Social Network" },
  { value: "ga:hasSocialSourceReferral", label: "Traffic Sources - Social Source Referral" },
  { value: "ga:campaignCode", label: "Traffic Sources - Campaign Code" },
  { value: "ga:adGroup", label: "Adwords - Ad Group" },
  { value: "ga:adSlot", label: "Adwords - Ad Slot" },
  { value: "ga:adDistributionNetwork", label: "Adwords - Ad Distribution Network" },
  { value: "ga:adMatchType", label: "Adwords - Query Match Type" },
  { value: "ga:adKeywordMatchType", label: "Adwords - Keyword Match Type" },
  { value: "ga:adMatchedQuery", label: "Adwords - Matched Search Query" },
  { value: "ga:adPlacementDomain", label: "Adwords - Placement Domain" },
  { value: "ga:adPlacementUrl", label: "Adwords - Placement URL" },
  { value: "ga:adFormat", label: "Adwords - Ad Format" },
  { value: "ga:adTargetingType", label: "Adwords - Targeting Type" },
  { value: "ga:adTargetingOption", label: "Adwords - Placement Type" },
  { value: "ga:adDisplayUrl", label: "Adwords - Display URL" },
  { value: "ga:adDestinationUrl", label: "Adwords - Destination URL" },
  { value: "ga:adwordsCustomerID", label: "Adwords - AdWords Customer ID" },
  { value: "ga:adwordsCampaignID", label: "Adwords - AdWords Campaign ID" },
  { value: "ga:adwordsAdGroupID", label: "Adwords - AdWords Ad Group ID" },
  { value: "ga:adwordsCreativeID", label: "Adwords - AdWords Creative ID" },
  { value: "ga:adwordsCriteriaID", label: "Adwords - AdWords Criteria ID" },
  { value: "ga:adQueryWordCount", label: "Adwords - Query Word Count" },
  { value: "ga:isTrueViewVideoAd", label: "Adwords - TrueView Video Ad" },
  { value: "ga:goalCompletionLocation", label: "Goal Conversions - Goal Completion Location" },
  { value: "ga:goalPreviousStep1", label: "Goal Conversions - Goal Previous Step - 1" },
  { value: "ga:goalPreviousStep2", label: "Goal Conversions - Goal Previous Step - 2" },
  { value: "ga:goalPreviousStep3", label: "Goal Conversions - Goal Previous Step - 3" },

  { value: "ga:browser", label: "Platform or Device - Browser" },
  { value: "ga:browserVersion", label: "Platform or Device - Browser Version" },
  { value: "ga:operatingSystem", label: "Platform or Device - Operating System" },
  { value: "ga:operatingSystemVersion", label: "Platform or Device - Operating System Version" },
  { value: "ga:mobileDeviceBranding", label: "Platform or Device - Mobile Device Branding" },
  { value: "ga:mobileDeviceModel", label: "Platform or Device - Mobile Device Model" },
  { value: "ga:mobileInputSelector", label: "Platform or Device - Mobile Input Selector" },
  { value: "ga:mobileDeviceInfo", label: "Platform or Device - Mobile Device Info" },
  { value: "ga:mobileDeviceMarketingName", label: "Platform or Device - Mobile Device Marketing Name" },
  { value: "ga:deviceCategory", label: "Platform or Device - Device Category" },
  { value: "ga:dataSource", label: "Platform or Device - Data Source" },

  { value: "ga:continent", label: "Geography - Continent" },
  { value: "ga:subContinent", label: "Geography - Sub Continent" },
  { value: "ga:country", label: "Geography - Country" },
  { value: "ga:region", label: "Geography - Region" },
  { value: "ga:metro", label: "Geography - Metro" },
  { value: "ga:city", label: "Geography - City" },
  { value: "ga:latitude", label: "Geography - Latitude" },
  { value: "ga:longitude", label: "Geography - Longitude" },
  { value: "ga:networkDomain", label: "Geography - Network Domain" },
  { value: "ga:networkLocation", label: "Geography - Service Provider" },
  { value: "ga:cityId", label: "Geography - City ID" },
  { value: "ga:countryIsoCode", label: "Geography - Country ISO Code" },
  { value: "ga:regionId", label: "Geography - Region ID" },
  { value: "ga:regionIsoCode", label: "Geography - Region ISO Code" },
  { value: "ga:subContinentCode", label: "Geography - Sub Continent Code" },

  { value: "ga:flashVersion", label: "System - Flash Version" },
  { value: "ga:javaEnabled", label: "System - Java Support" },
  { value: "ga:language", label: "System - Language" },
  { value: "ga:screenColors", label: "System - Screen Colors" },
  { value: "ga:sourcePropertyDisplayName", label: "System - Source Property Display Name" },
  { value: "ga:sourcePropertyTrackingId", label: "System - Source Property Tracking ID" },
  { value: "ga:screenResolution", label: "System - Screen Resolution" },

  { value: "ga:socialActivityEndorsingUrl", label: "Social Activities - Endorsing URL" },
  { value: "ga:socialActivityDisplayName", label: "Social Activities - Display Name" },
  { value: "ga:socialActivityPost", label: "Social Activities - Social Activity Post" },
  { value: "ga:socialActivityTimestamp", label: "Social Activities - Social Activity Timestamp" },
  { value: "ga:socialActivityUserHandle", label: "Social Activities - Social User Handle" },
  { value: "ga:socialActivityUserPhotoUrl", label: "Social Activities - User Photo URL" },
  { value: "ga:socialActivityUserProfileUrl", label: "Social Activities - User Profile URL" },
  { value: "ga:socialActivityContentUrl", label: "Social Activities - Shared URL" },
  { value: "ga:socialActivityTagsSummary", label: "Social Activities - Social Tags Summary" },
  { value: "ga:socialActivityAction", label: "Social Activities - Originating Social Action" },
  { value: "ga:socialActivityNetwork", label: "Social Activities - ActionSocial Network and Action" },

  { value: "ga:hostname", label: "Page Tracking - Hostname" },
  { value: "ga:pagePath", label: "Page Tracking - Page" },
  { value: "ga:pagePathLevel1", label: "Page Tracking - Page path level 1" },
  { value: "ga:pagePathLevel2", label: "Page Tracking - Page path level 2" },
  { value: "ga:pagePathLevel3", label: "Page Tracking - Page path level 3" },
  { value: "ga:pagePathLevel4", label: "Page Tracking - Page path level 4" },
  { value: "ga:pageTitle", label: "Page Tracking - Page Title" },
  { value: "ga:landingPagePath", label: "Page Tracking - Landing Page" },
  { value: "ga:secondPagePath", label: "Page Tracking - Second Page" },
  { value: "ga:exitPagePath", label: "Page Tracking - Exit Page" },
  { value: "ga:previousPagePath", label: "Page Tracking - Previous Page Path" },
  { value: "ga:pageDepth", label: "Page Tracking - Page Depth" },

  { value: "ga:appInstallerId", label: "App tracking - App Installer ID" },
  { value: "ga:appVersion", label: "App tracking - App Version" },
  { value: "ga:appName", label: "App tracking - App Name" },
  { value: "ga:appId", label: "App tracking - App ID" },
  { value: "ga:screenName", label: "App tracking - Screen Name" },
  { value: "ga:screenDepth", label: "App tracking - Screen Depth" },
  { value: "ga:landingScreenName", label: "App tracking - Landing Screen" },
  { value: "ga:exitScreenName", label: "App tracking - Exit Screen" },

  { value: "ga:eventCategory", label: "Event Tracking - Event Category" },
  { value: "ga:eventAction", label: "Event Tracking - Event Action" },
  { value: "ga:eventLabel", label: "Event Tracking - Event Label" },

  { value: "ga:transactionId", label: "Ecommerce - Transaction ID" },
  { value: "ga:affiliation", label: "Ecommerce - Affiliation" },
  { value: "ga:sessionsToTransaction", label: "Ecommerce - Sessions to Transaction" },
  { value: "ga:daysToTransaction", label: "Ecommerce - Days to Transaction" },
  { value: "ga:productSku", label: "Ecommerce - Product SKU" },
  { value: "ga:productName", label: "Ecommerce - Product" },
  { value: "ga:productCategory", label: "Ecommerce - Product Category" },
  { value: "ga:currencyCode", label: "Ecommerce - Currency Code" },
  { value: "ga:checkoutOptions", label: "Ecommerce - Checkout Options" },
  { value: "ga:internalPromotionCreative", label: "Ecommerce - Internal Promotion Creative" },
  { value: "ga:internalPromotionId", label: "Ecommerce - Internal Promotion ID" },
  { value: "ga:internalPromotionName", label: "Ecommerce - Internal Promotion Name" },
  { value: "ga:internalPromotionPosition", label: "Ecommerce - Internal Promotion Position" },
  { value: "ga:orderCouponCode", label: "Ecommerce - Order Coupon Code" },
  { value: "ga:productBrand", label: "Ecommerce - Product Brand" },
  { value: "ga:productCategoryHierarchy", label: "Ecommerce - Product Category (Enhanced Ecommerce)" },
  { value: "ga:productCategoryLevelXX", label: "Ecommerce - Product Category Level XX" },
  { value: "ga:productCouponCode", label: "Ecommerce - Product Coupon Code" },
  { value: "ga:productListName", label: "Ecommerce - Product List Name" },
  { value: "ga:productListPosition", label: "Ecommerce - Product List Position" },
  { value: "ga:productVariant", label: "Ecommerce - Product Variant" },
  { value: "ga:shoppingStage", label: "Ecommerce - Shopping Stage" },
  { value: "ga:socialInteractionNetwork", label: "Social Interactions - Social Source" },
  { value: "ga:socialInteractionAction", label: "Social Interactions - Social Action" },
  { value: "ga:socialInteractionNetworkAction", label: "Social Interactions - Social Source and Action" },
  { value: "ga:socialInteractionTarget", label: "Social Interactions - Social Entity" },
  { value: "ga:socialEngagementType", label: "Social Interactions - Social Type" },
  { value: "ga:userTimingCategory", label: "User Timings - Timing Category" },
  { value: "ga:userTimingLabel", label: "User Timings - Timing Label" },
  { value: "ga:userTimingVariable", label: "User Timings - Timing Variable" },
  { value: "ga:exceptionDescription", label: "Exceptions - Exception Description" },
  { value: "ga:experimentId", label: "Content Experiments - Experiment ID" },
  { value: "ga:experimentVariant", label: "Content Experiments - Variation" },
  { value: "ga:date", label: "Time - Date" },
  { value: "ga:year", label: "Time - Year" },
  { value: "ga:month", label: "Time - Month of the year" },
  { value: "ga:week", label: "Time - Week of the Year" },
  { value: "ga:day", label: "Time - Day of the month" },
  { value: "ga:hour", label: "Time - Hour" },
  { value: "ga:minute", label: "Time - Minute" },
  { value: "ga:nthMonth", label: "Time - Month Index" },
  { value: "ga:nthWeek", label: "Time - Week Index" },
  { value: "ga:nthDay", label: "Time - Day Index" },
  { value: "ga:nthMinute", label: "Time - Minute Index" },
  { value: "ga:dayOfWeek", label: "Time - Day of Week" },
  { value: "ga:dayOfWeekName", label: "Time - Day of Week Name" },
  { value: "ga:dateHour", label: "Time - Hour of Day" },
  { value: "ga:yearMonth", label: "Time - Month of Year" },
  { value: "ga:yearWeek", label: "Time - Week of Year" },
  { value: "ga:isoWeek", label: "Time - ISO Week of the Year" },
  { value: "ga:isoYear", label: "Time - ISO Year" },
  { value: "ga:isoYearIsoWeek", label: "Time - ISO Week of ISO Year" },
  { value: "ga:nthHour", label: "Time - Hour Index" },
  { value: "ga:userAgeBracket", label: "Audience - Age" },
  { value: "ga:userGender", label: "Audience - Gender" },
  { value: "ga:interestOtherCategory", label: "Audience - Other Category" },
  { value: "ga:interestAffinityCategory", label: "Audience - Affinity Category (reach)" },
  { value: "ga:interestInMarketCategory", label: "Audience - In-Market Segment" },
  { value: "ga:channelGrouping", label: "Channel Grouping - Default Channel Grouping" },
  { value: "ga:dimension1", label: "Custom Dimensions - Index 1" },
  { value: "ga:dimension2", label: "Custom Dimensions - Index 2" },
  { value: "ga:dimension3", label: "Custom Dimensions - Index 3" },
  { value: "ga:dimension4", label: "Custom Dimensions - Index 4" },
  { value: "ga:dimension5", label: "Custom Dimensions - Index 5" },
  { value: "ga:dimension6", label: "Custom Dimensions - Index 6" },
  { value: "ga:dimension7", label: "Custom Dimensions - Index 7" },
  { value: "ga:dimension8", label: "Custom Dimensions - Index 8" },
  { value: "ga:dimension9", label: "Custom Dimensions - Index 9" },
  { value: "ga:dimension10", label: "Custom Dimensions - Index 10" },
  { value: "ga:dimension11", label: "Custom Dimensions - Index 11" },
  { value: "ga:dimension12", label: "Custom Dimensions - Index 12" },
  { value: "ga:dimension13", label: "Custom Dimensions - Index 13" },
  { value: "ga:dimension14", label: "Custom Dimensions - Index 14" },
  { value: "ga:dimension15", label: "Custom Dimensions - Index 15" },
  { value: "ga:dimension16", label: "Custom Dimensions - Index 16" },
  { value: "ga:dimension17", label: "Custom Dimensions - Index 17" },
  { value: "ga:dimension18", label: "Custom Dimensions - Index 18" },
  { value: "ga:dimension19", label: "Custom Dimensions - Index 19" },
  { value: "ga:dimension20", label: "Custom Dimensions - Index 20" },
  { value: "ga:dimension21", label: "Custom Dimensions - Index 21" },
  { value: "ga:dimension22", label: "Custom Dimensions - Index 22" },
  { value: "ga:dimension23", label: "Custom Dimensions - Index 23" },
  { value: "ga:dimension24", label: "Custom Dimensions - Index 24" },
  { value: "ga:dimension25", label: "Custom Dimensions - Index 25" },
  { value: "ga:dimension26", label: "Custom Dimensions - Index 26" },
  { value: "ga:dimension27", label: "Custom Dimensions - Index 27" },
  { value: "ga:dimension28", label: "Custom Dimensions - Index 28" },
  { value: "ga:dimension29", label: "Custom Dimensions - Index 29" },
  { value: "ga:dimension30", label: "Custom Dimensions - Index 30" },
  { value: "ga:dimension31", label: "Custom Dimensions - Index 31" },
  { value: "ga:dimension32", label: "Custom Dimensions - Index 32" },
  { value: "ga:dimension33", label: "Custom Dimensions - Index 33" },
  { value: "ga:dimension34", label: "Custom Dimensions - Index 34" },
  { value: "ga:dimension35", label: "Custom Dimensions - Index 35" },
  { value: "ga:dimension36", label: "Custom Dimensions - Index 36" },
  { value: "ga:dimension37", label: "Custom Dimensions - Index 37" },
  { value: "ga:dimension38", label: "Custom Dimensions - Index 38" },
  { value: "ga:dimension39", label: "Custom Dimensions - Index 39" },
  { value: "ga:dimension40", label: "Custom Dimensions - Index 40" },
  { value: "ga:dimension41", label: "Custom Dimensions - Index 41" },
  { value: "ga:dimension42", label: "Custom Dimensions - Index 42" },
  { value: "ga:dimension43", label: "Custom Dimensions - Index 43" },
  { value: "ga:dimension44", label: "Custom Dimensions - Index 44" },
  { value: "ga:dimension45", label: "Custom Dimensions - Index 45" },
  { value: "ga:dimension46", label: "Custom Dimensions - Index 46" },
  { value: "ga:dimension47", label: "Custom Dimensions - Index 47" },
  { value: "ga:dimension48", label: "Custom Dimensions - Index 48" },
  { value: "ga:dimension49", label: "Custom Dimensions - Index 49" },
  { value: "ga:dimension50", label: "Custom Dimensions - Index 50" },
  { value: "ga:dimension51", label: "Custom Dimensions - Index 51" },
  { value: "ga:dimension52", label: "Custom Dimensions - Index 52" },
  { value: "ga:dimension53", label: "Custom Dimensions - Index 53" },
  { value: "ga:dimension54", label: "Custom Dimensions - Index 54" },
  { value: "ga:dimension55", label: "Custom Dimensions - Index 55" },
  { value: "ga:dimension56", label: "Custom Dimensions - Index 56" },
  { value: "ga:dimension57", label: "Custom Dimensions - Index 57" },
  { value: "ga:dimension58", label: "Custom Dimensions - Index 58" },
  { value: "ga:dimension59", label: "Custom Dimensions - Index 59" },
  { value: "ga:dimension60", label: "Custom Dimensions - Index 60" },
  { value: "ga:dimension61", label: "Custom Dimensions - Index 61" },
  { value: "ga:dimension62", label: "Custom Dimensions - Index 62" },
  { value: "ga:dimension63", label: "Custom Dimensions - Index 63" },
  { value: "ga:dimension64", label: "Custom Dimensions - Index 64" },
  { value: "ga:dimension65", label: "Custom Dimensions - Index 65" },
  { value: "ga:dimension66", label: "Custom Dimensions - Index 66" },
  { value: "ga:dimension67", label: "Custom Dimensions - Index 67" },
  { value: "ga:dimension68", label: "Custom Dimensions - Index 68" },
  { value: "ga:dimension69", label: "Custom Dimensions - Index 69" },
  { value: "ga:dimension70", label: "Custom Dimensions - Index 70" },
  { value: "ga:dimension71", label: "Custom Dimensions - Index 71" },
  { value: "ga:dimension72", label: "Custom Dimensions - Index 72" },
  { value: "ga:dimension73", label: "Custom Dimensions - Index 73" },
  { value: "ga:dimension74", label: "Custom Dimensions - Index 74" },
  { value: "ga:dimension75", label: "Custom Dimensions - Index 75" },
  { value: "ga:dimension76", label: "Custom Dimensions - Index 76" },
  { value: "ga:dimension77", label: "Custom Dimensions - Index 77" },
  { value: "ga:dimension78", label: "Custom Dimensions - Index 78" },
  { value: "ga:dimension79", label: "Custom Dimensions - Index 79" },
  { value: "ga:dimension80", label: "Custom Dimensions - Index 80" },
  { value: "ga:dimension81", label: "Custom Dimensions - Index 81" },
  { value: "ga:dimension82", label: "Custom Dimensions - Index 82" },
  { value: "ga:dimension83", label: "Custom Dimensions - Index 83" },
  { value: "ga:dimension84", label: "Custom Dimensions - Index 84" },
  { value: "ga:dimension85", label: "Custom Dimensions - Index 85" },
  { value: "ga:dimension86", label: "Custom Dimensions - Index 86" },
  { value: "ga:dimension87", label: "Custom Dimensions - Index 87" },
  { value: "ga:dimension88", label: "Custom Dimensions - Index 88" },
  { value: "ga:dimension89", label: "Custom Dimensions - Index 89" },
  { value: "ga:dimension90", label: "Custom Dimensions - Index 90" },
  { value: "ga:dimension91", label: "Custom Dimensions - Index 91" },
  { value: "ga:dimension92", label: "Custom Dimensions - Index 92" },
  { value: "ga:dimension93", label: "Custom Dimensions - Index 93" },
  { value: "ga:dimension94", label: "Custom Dimensions - Index 94" },
  { value: "ga:dimension95", label: "Custom Dimensions - Index 95" },
  { value: "ga:dimension96", label: "Custom Dimensions - Index 96" },
  { value: "ga:dimension97", label: "Custom Dimensions - Index 97" },
  { value: "ga:dimension98", label: "Custom Dimensions - Index 98" },
  { value: "ga:dimension99", label: "Custom Dimensions - Index 99" },
  { value: "ga:dimension100", label: "Custom Dimensions - Index 100" },
  { value: "ga:dimension101", label: "Custom Dimensions - Index 101" },
  { value: "ga:dimension102", label: "Custom Dimensions - Index 102" },
  { value: "ga:dimension103", label: "Custom Dimensions - Index 103" },
  { value: "ga:dimension104", label: "Custom Dimensions - Index 104" },
  { value: "ga:dimension105", label: "Custom Dimensions - Index 105" },
  { value: "ga:dimension106", label: "Custom Dimensions - Index 106" },
  { value: "ga:dimension107", label: "Custom Dimensions - Index 107" },
  { value: "ga:dimension108", label: "Custom Dimensions - Index 108" },
  { value: "ga:dimension109", label: "Custom Dimensions - Index 109" },
  { value: "ga:dimension110", label: "Custom Dimensions - Index 110" },
  { value: "ga:dimension111", label: "Custom Dimensions - Index 111" },
  { value: "ga:dimension112", label: "Custom Dimensions - Index 112" },
  { value: "ga:dimension113", label: "Custom Dimensions - Index 113" },
  { value: "ga:dimension114", label: "Custom Dimensions - Index 114" },
  { value: "ga:dimension115", label: "Custom Dimensions - Index 115" },
  { value: "ga:dimension116", label: "Custom Dimensions - Index 116" },
  { value: "ga:dimension117", label: "Custom Dimensions - Index 117" },
  { value: "ga:dimension118", label: "Custom Dimensions - Index 118" },
  { value: "ga:dimension119", label: "Custom Dimensions - Index 119" },
  { value: "ga:dimension120", label: "Custom Dimensions - Index 120" },
  { value: "ga:dimension121", label: "Custom Dimensions - Index 121" },
  { value: "ga:dimension122", label: "Custom Dimensions - Index 122" },
  { value: "ga:dimension123", label: "Custom Dimensions - Index 123" },
  { value: "ga:dimension124", label: "Custom Dimensions - Index 124" },
  { value: "ga:dimension125", label: "Custom Dimensions - Index 125" },
  { value: "ga:dimension126", label: "Custom Dimensions - Index 126" },
  { value: "ga:dimension127", label: "Custom Dimensions - Index 127" },
  { value: "ga:dimension128", label: "Custom Dimensions - Index 128" },
  { value: "ga:dimension129", label: "Custom Dimensions - Index 129" },
  { value: "ga:dimension130", label: "Custom Dimensions - Index 130" },
  { value: "ga:dimension131", label: "Custom Dimensions - Index 131" },
  { value: "ga:dimension132", label: "Custom Dimensions - Index 132" },
  { value: "ga:dimension133", label: "Custom Dimensions - Index 133" },
  { value: "ga:dimension134", label: "Custom Dimensions - Index 134" },
  { value: "ga:dimension135", label: "Custom Dimensions - Index 135" },
  { value: "ga:dimension136", label: "Custom Dimensions - Index 136" },
  { value: "ga:dimension137", label: "Custom Dimensions - Index 137" },
  { value: "ga:dimension138", label: "Custom Dimensions - Index 138" },
  { value: "ga:dimension139", label: "Custom Dimensions - Index 139" },
  { value: "ga:dimension140", label: "Custom Dimensions - Index 140" },
  { value: "ga:dimension141", label: "Custom Dimensions - Index 141" },
  { value: "ga:dimension142", label: "Custom Dimensions - Index 142" },
  { value: "ga:dimension143", label: "Custom Dimensions - Index 143" },
  { value: "ga:dimension144", label: "Custom Dimensions - Index 144" },
  { value: "ga:dimension145", label: "Custom Dimensions - Index 145" },
  { value: "ga:dimension146", label: "Custom Dimensions - Index 146" },
  { value: "ga:dimension147", label: "Custom Dimensions - Index 147" },
  { value: "ga:dimension148", label: "Custom Dimensions - Index 148" },
  { value: "ga:dimension149", label: "Custom Dimensions - Index 149" },
  { value: "ga:dimension150", label: "Custom Dimensions - Index 150" },
  { value: "ga:dimension151", label: "Custom Dimensions - Index 151" },
  { value: "ga:dimension152", label: "Custom Dimensions - Index 152" },
  { value: "ga:dimension153", label: "Custom Dimensions - Index 153" },
  { value: "ga:dimension154", label: "Custom Dimensions - Index 154" },
  { value: "ga:dimension155", label: "Custom Dimensions - Index 155" },
  { value: "ga:dimension156", label: "Custom Dimensions - Index 156" },
  { value: "ga:dimension157", label: "Custom Dimensions - Index 157" },
  { value: "ga:dimension158", label: "Custom Dimensions - Index 158" },
  { value: "ga:dimension159", label: "Custom Dimensions - Index 159" },
  { value: "ga:dimension160", label: "Custom Dimensions - Index 160" },
  { value: "ga:dimension161", label: "Custom Dimensions - Index 161" },
  { value: "ga:dimension162", label: "Custom Dimensions - Index 162" },
  { value: "ga:dimension163", label: "Custom Dimensions - Index 163" },
  { value: "ga:dimension164", label: "Custom Dimensions - Index 164" },
  { value: "ga:dimension165", label: "Custom Dimensions - Index 165" },
  { value: "ga:dimension166", label: "Custom Dimensions - Index 166" },
  { value: "ga:dimension167", label: "Custom Dimensions - Index 167" },
  { value: "ga:dimension168", label: "Custom Dimensions - Index 168" },
  { value: "ga:dimension169", label: "Custom Dimensions - Index 169" },
  { value: "ga:dimension170", label: "Custom Dimensions - Index 170" },
  { value: "ga:dimension171", label: "Custom Dimensions - Index 171" },
  { value: "ga:dimension172", label: "Custom Dimensions - Index 172" },
  { value: "ga:dimension173", label: "Custom Dimensions - Index 173" },
  { value: "ga:dimension174", label: "Custom Dimensions - Index 174" },
  { value: "ga:dimension175", label: "Custom Dimensions - Index 175" },
  { value: "ga:dimension176", label: "Custom Dimensions - Index 176" },
  { value: "ga:dimension177", label: "Custom Dimensions - Index 177" },
  { value: "ga:dimension178", label: "Custom Dimensions - Index 178" },
  { value: "ga:dimension179", label: "Custom Dimensions - Index 179" },
  { value: "ga:dimension180", label: "Custom Dimensions - Index 180" },
  { value: "ga:dimension181", label: "Custom Dimensions - Index 181" },
  { value: "ga:dimension182", label: "Custom Dimensions - Index 182" },
  { value: "ga:dimension183", label: "Custom Dimensions - Index 183" },
  { value: "ga:dimension184", label: "Custom Dimensions - Index 184" },
  { value: "ga:dimension185", label: "Custom Dimensions - Index 185" },
  { value: "ga:dimension186", label: "Custom Dimensions - Index 186" },
  { value: "ga:dimension187", label: "Custom Dimensions - Index 187" },
  { value: "ga:dimension188", label: "Custom Dimensions - Index 188" },
  { value: "ga:dimension189", label: "Custom Dimensions - Index 189" },
  { value: "ga:dimension190", label: "Custom Dimensions - Index 190" },
  { value: "ga:dimension191", label: "Custom Dimensions - Index 191" },
  { value: "ga:dimension192", label: "Custom Dimensions - Index 192" },
  { value: "ga:dimension193", label: "Custom Dimensions - Index 193" },
  { value: "ga:dimension194", label: "Custom Dimensions - Index 194" },
  { value: "ga:dimension195", label: "Custom Dimensions - Index 195" },
  { value: "ga:dimension196", label: "Custom Dimensions - Index 196" },
  { value: "ga:dimension197", label: "Custom Dimensions - Index 197" },
  { value: "ga:dimension198", label: "Custom Dimensions - Index 198" },
  { value: "ga:dimension199", label: "Custom Dimensions - Index 199" },
  { value: "ga:dimension200", label: "Custom Dimensions - Index 200" },
];

var googleAnalyticsDimensionFilterOperators = [
  { value: "==", label: "match" },
  { value: "!=", label: "do NOT match" },
  { value: "=@", label: "contains" },
  { value: "!@", label: "does NOT contain" },
  { value: "=~", label: "contains a match from regex" },
  { value: "!~", label: "does NOT contain a match from regex" },
];
var googleAnalyticsRealtimeDimensionList = [
  { value: "rt:userType", label: 'User type. It can be "new" or "returning".' },

  { value: "rt:referralPath", label: "Traffic sources - Referral path (only for referrals)" },
  {
    value: "rt:campaign",
    label: 'Traffic sources - Campaign. It is the value of the "utm_campaign" campaign tracking parameter.',
  },
  {
    value: "rt:source",
    label:
      "Traffic sources - Source of referrals. When using manual campaign tracking, the value of the utm_source campaign tracking parameter. When using AdWords autotagging, the value is google. Otherwise the domain of the source referring the user to your property (e.g. document.referrer). The value may also contain a port address. If the user arrived without a referrer, the value is (direct).",
  },
  {
    value: "rt:medium",
    label:
      "Traffic sources - Medium. The type of referrals to your property. When using manual campaign tracking, the value of the utm_medium campaign tracking parameter. When using AdWords autotagging, the value is ppc. If the user comes from a search engine detected by Google Analytics, the value is organic. If the referrer is not a search engine, the value is referral. If the user came directly to the property, and document.referrer is empty, the value is (direct).",
  },
  {
    value: "rt:trafficType",
    label:
      'Traffic sources - Traffic type. This dimension is similar to Medium for constant values such as "organic", "referral", "direct", etc. It is different for custom referral types. As an example, if you add the "utm_campaign" parameter to your URL with value "email", Medium will be "email" but Traffic Type will be "custom".',
  },
  {
    value: "rt:keyword",
    label:
      'Traffic sources - Keyword. For manual campaign tracking, it is the value of the "utm_term" campaign tracking parameter. Otherwise, its value is "(not set)".',
  },

  { value: "rt:browser", label: "Platform / Device - Browser" },
  { value: "rt:browserVersion", label: "Platform / Device - Browser version" },
  { value: "rt:operatingSystem", label: "Platform / Device - Operating system" },
  { value: "rt:operatingSystemVersion", label: "Platform / Device - Operating system version" },
  {
    value: "rt:deviceCategory",
    label: 'Platform / Device - Device category. Values can be: "Desktop", "Tablet", or "Mobile".',
  },
  {
    value: "rt:mobileDeviceBranding",
    label:
      "Platform / Device - Mobile device branding. Mobile manufacturer or branded name (e.g: Samsung, HTC, Verizon, T-Mobile).",
  },
  { value: "rt:mobileDeviceModel", label: "Platform / Device - Mobile device model (e.g.: Nexus S)" },

  { value: "rt:country", label: "Geography - Country (derived from IP address)" },
  { value: "rt:region", label: "Geography - Region (derived from IP address)" },
  { value: "rt:city", label: "Geography - City (derived from IP address)" },
  { value: "rt:latitude", label: "Geography - Latitude (derived from IP address)" },
  { value: "rt:longitude", label: "Geography - Longitude (derived from IP address)" },

  { value: "rt:pagePath", label: "Page tracking - Page path" },
  { value: "rt:pageTitle", label: "Page tracking - Page title" },

  { value: "rt:appName", label: "App tracking - Name of the application" },
  { value: "rt:appVersion", label: "App tracking - Version of the application" },
  { value: "rt:screenName", label: "App tracking - Name of a screen" },

  { value: "rt:eventAction", label: "Event Tracking - Event Action" },
  { value: "rt:eventCategory", label: "Event Tracking - Event Category" },
  { value: "rt:eventLabel", label: "Event Tracking - Event Label" },
];

export { googleAnalyticsDimensionList, googleAnalyticsDimensionFilterOperators, googleAnalyticsRealtimeDimensionList };
