import React from "react";
var createReactClass = require("create-react-class");
import Select from "react-select";

import { reportFormValidation } from "../../helpers/report_form_validation";
import Button from "../../legacy-components/generic/button.js";

const GoogleSheetsAccountSelector = createReactClass({
  getInitialState: function () {
    return {
      googleSheetsAccount: undefined,
      modalVisible: false,
    };
  },

  componentDidMount: function () {
    this.storeSubscription = store.subscribe(
      function () {
        let newValue = store.getState().reportFormReducer.google_sheets_account_id;
        if (this.state.googleSheetsAccount !== newValue) {
          this.setState({ googleSheetsAccount: newValue });
        }
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";

    let display = "none";
    if (reportFormValidation.requiredIntegrations().includes("googlesheets")) {
      display = "block";
    }

    return (
      <div className="mw-key-metric_name w-full">
        <label className="tw-label">Google Sheets account</label>
        <div className="flex flex-row">
          <Select
            className="w-full mr-2"
            value={this.props.googleSheetsAccount}
            tabIndex={tabIndex}
            label="Google Sheets account"
            options={store.getState().integrationsReducer.integrationsAccountLists.googlesheets}
            onChange={(e) => {
              // always use this name in map props for function in this case
              this.props.onChangeProviderSelector(e);
            }}
          />
          <Button onClick={this.props.selectFile} text="Select&nbsp;file" />
        </div>
      </div>
    );
  },
});

export default GoogleSheetsAccountSelector;
