import React from "react";
import WidgetBase from "../widget_base";

export default class Separator extends WidgetBase {
  constructor() {
    super();
  }

  name() {
    return "Layout -- Separator";
  }

  type() {
    return "layout-separator";
  }

  width() {
    return "large";
  }

  height() {
    return 1;
  }

  config(chartType) {
    return {
      widgetType: this.type(),
      widgetName: this.name(),
    };
  }

  duplicateWidget(widgetId) {
    store.dispatch(duplicateWidgetOnCanvas(widgetId));
  }

  renderInGrid(widgetLayout, showWidgetEditModal, deleteSelectedWidget) {
    return (
      <div
        className="canvas-grid-item border border-dashed border-gray-300 hover:border-gray-500 flex flex-column rounded px-4"
        id={widgetLayout.i}
        key={widgetLayout.i}
        data-grid={widgetLayout}
      >
        <hr className="layout-widget border-bottom border border-gray-300 w-full my-auto" />
        <div className="canvas-widget-edit-save">
          <a
            onClick={function () {
              return this.duplicateWidget(widgetLayout.i);
            }.bind(this)}
            className=""
          >
            <i className="fas fa-copy" />
          </a>
          <a
            onClick={function () {
              return deleteSelectedWidget(widgetLayout.i);
            }.bind(this)}
          >
            <i className="fas fa-times" />
          </a>
        </div>
      </div>
    );
  }
}
