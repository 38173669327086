import React from "react";
import Drawer from "../components/utility/drawer";
import WidgetButton from "../components/utility/widget_button";

export default class IntegrationWidgetDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.integration = integrationStore.findIntegrationByOAuthProviderName(this.props.oAuthProviderName);
    this.featureFlagName = this.integration.featureFlagName();
  }

  render() {
    // If the integration is not enabled, don't show the drawer
    if (this.featureFlagName && !Subscriba.hasFeature(this.featureFlagName)) {
      return <></>;
    }

    return (
      <Drawer title={this.integration.name()}>
        {/* Single Metric */}
        {(this.integration.widgets().singleMetric.enabled || this.integration.widgets().singleMetric.soon) && (
          <WidgetButton
            gridElement={this.props.gridElement}
            addWidget={this.props.addWidget}
            storeState={this.props.storeState}
            label="Single Metric"
            widgetName={this.integration.widgets().singleMetric.widgetName}
            icon="Single Metric"
            isBeta={this.integration.widgets().singleMetric.isBeta}
            soon={this.integration.widgets().singleMetric.soon}
          />
        )}

        {/* List */}
        {(this.integration.widgets().list.enabled || this.integration.widgets().list.soon) && (
          <WidgetButton
            gridElement={this.props.gridElement}
            addWidget={this.props.addWidget}
            storeState={this.props.storeState}
            label="List"
            widgetName={this.integration.widgets().list.widgetName}
            icon="List"
            isBeta={this.integration.widgets().list.isBeta}
            soon={this.integration.widgets().list.soon}
          />
        )}

        {/* Pie */}
        {(this.integration.widgets().pieAndDoughnut.enabled || this.integration.widgets().pieAndDoughnut.soon) && (
          <WidgetButton
            soon={this.integration.widgets().pieAndDoughnut.soon}
            isBeta={this.integration.widgets().pieAndDoughnut.isBeta}
            gridElement={this.props.gridElement}
            addWidget={this.props.addWidget}
            storeState={this.props.storeState}
            label="Pie"
            widgetName={this.integration.widgets().pieAndDoughnut.widgetName}
            chartType="pie"
            icon="Pie Chart"
          />
        )}

        {/* Doughnut */}
        {(this.integration.widgets().pieAndDoughnut.enabled || this.integration.widgets().pieAndDoughnut.soon) && (
          <WidgetButton
            soon={this.integration.widgets().pieAndDoughnut.soon}
            isBeta={this.integration.widgets().pieAndDoughnut.isBeta}
            gridElement={this.props.gridElement}
            addWidget={this.props.addWidget}
            storeState={this.props.storeState}
            label="Doughnut"
            widgetName={this.integration.widgets().pieAndDoughnut.widgetName}
            chartType="doughnut"
            icon="Doughnut Chart"
          />
        )}

        {/* Bar */}
        {(this.integration.widgets().mixedChart.enabled || this.integration.widgets().mixedChart.soon) && (
          <WidgetButton
            soon={this.integration.widgets().mixedChart.soon}
            isBeta={this.integration.widgets().mixedChart.isBeta}
            gridElement={this.props.gridElement}
            addWidget={this.props.addWidget}
            storeState={this.props.storeState}
            label="Bar"
            widgetName={this.integration.widgets().mixedChart.widgetName}
            icon="Bar Graph"
          />
        )}

        {/* Line */}
        {(this.integration.widgets().mixedChart.enabled || this.integration.widgets().mixedChart.soon) && (
          <WidgetButton
            soon={this.integration.widgets().mixedChart.soon}
            isBeta={this.integration.widgets().mixedChart.isBeta}
            gridElement={this.props.gridElement}
            addWidget={this.props.addWidget}
            storeState={this.props.storeState}
            label="Line"
            widgetName={this.integration.widgets().mixedChart.widgetName}
            chartType="line"
            icon="Line Chart"
          />
        )}

        {/* Area */}
        {(this.integration.widgets().mixedChart.enabled || this.integration.widgets().mixedChart.soon) && (
          <WidgetButton
            soon={this.integration.widgets().mixedChart.soon}
            isBeta={this.integration.widgets().mixedChart.isBeta}
            gridElement={this.props.gridElement}
            addWidget={this.props.addWidget}
            storeState={this.props.storeState}
            label="Area"
            widgetName={this.integration.widgets().mixedChart.widgetName}
            chartType="area"
            icon="Area Chart"
          />
        )}

        {/* Mixed */}
        {(this.integration.widgets().mixedChart.enabled || this.integration.widgets().mixedChart.soon) && (
          <WidgetButton
            soon={this.integration.widgets().mixedChart.soon}
            isBeta={this.integration.widgets().mixedChart.isBeta}
            gridElement={this.props.gridElement}
            addWidget={this.props.addWidget}
            storeState={this.props.storeState}
            label="Mixed Chart"
            widgetName={this.integration.widgets().mixedChart.widgetName}
            icon="Mixed Chart"
          />
        )}
      </Drawer>
    );
  }
}
