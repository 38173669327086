/* eslint no-console:0 */

import _ from "lodash";
import React from "react";
import ReactDOM from "react-dom";

import "../src/javascript/appsignal";

// Rails UJS stuff so that the delete prompts and stuff still works
import Rails from "@rails/ujs";
Rails.start();

// // helper to import all files of a directory
// function importAll(r) {
//   r.keys().forEach(r);
// }

// importAll(require.context("../src/javascript/actions/", true, /\.js$/));
// importAll(require.context("../src/javascript/store/", true, /\.js$/));

import "../src/javascript/actions/flash_message";
import "../src/javascript/actions/integration_actions";
import "../src/javascript/actions/ppc_markup_actions";
import "../src/javascript/actions/reports_actions";
import "../src/javascript/actions/watcher_actions";
import "../src/javascript/actions/widget_actions";
import "../src/javascript/store/store";

// Subscription, onboarding, trial and feature flaggin
import "../src/javascript/subscriba/subscriba";
import "../src/javascript/feature_flag";
import "../src/javascript/helpers/duplicate_report";

import Onboarding from "../src/javascript/onboarding";
var _onboarding = new Onboarding(); // this inits the class, which will do its job

import "../src/javascript/account_cache_refresh";
import "../src/javascript/fix_account";

// Load the Ad Blocker Detector
import BlockerDetector from "../src/javascript/blocker_detector";
new BlockerDetector();

// Load the current announcement
import Announcement from "../src/javascript/announcement_modal";
new Announcement();

// Various things
import "../src/javascript/facebook-ads-token-refresher";

import Sidebar from "../src/javascript/sidebar";
new Sidebar();

// Routing and set the components in the DOM via the reoutes file.
import Routes from "./routes";
document.addEventListener("DOMContentLoaded", () => {
  const node = document.getElementById("react_container");
  if (node == undefined) {
    return;
  }

  ReactDOM.render(<Routes />, node);
  ReactDOM.render;
});

//
// LAYOUT WIDGETS STORE
//

import LayoutWidgetStore from "../src/javascript/widgets/layout/layout_widget_store";
window.layoutWidgetStore = new LayoutWidgetStore();

import Separator from "../src/javascript/widgets/layout/separator/separator";
window.layoutWidgetStore.registerWidget(new Separator());

import PDFPageBreak from "../src/javascript/widgets/layout/pdf-page-break/pdf-page-break";
window.layoutWidgetStore.registerWidget(new PDFPageBreak());

import Title from "../src/javascript/widgets/layout/title/title";
window.layoutWidgetStore.registerWidget(new Title());

import LargeTextBox from "../src/javascript/widgets/layout/large-text-box/large-text-box";
window.layoutWidgetStore.registerWidget(new LargeTextBox());

import RichText from "../src/javascript/widgets/layout/rich-text/rich-text";
window.layoutWidgetStore.registerWidget(new RichText());

//
// INTEGRATIONS
//

// Integration store
import IntegrationStore from "../src/javascript/integrations/integration_store";
window.integrationStore = new IntegrationStore();

// Mailchimp
import MailchimpIntegration from "../src/javascript/integrations/mailchimp/integration";
let mailchimpIntegration = new MailchimpIntegration();
mailchimpIntegration.registerIntegration(integrationStore);

// Facebook Social / Pages
import FacebookPagesIntegration from "../src/javascript/integrations/facebook_pages/integration";
let fbPagesIntegration = new FacebookPagesIntegration();
fbPagesIntegration.registerIntegration(integrationStore);

// Instagram
import InstagramIntegration from "../src/javascript/integrations/instagram/integration";
let instagramIntegration = new InstagramIntegration();
instagramIntegration.registerIntegration(integrationStore);

// Google Ads
import GoogleAdsIntegration from "../src/javascript/integrations/google-ads/integration";
let googleAdsIntegration = new GoogleAdsIntegration();
googleAdsIntegration.registerIntegration(integrationStore);

// LinkedIn Ads
import LinkedInAdsIntegration from "../src/javascript/integrations/linked_in_ads/integration";
let linkedInAdsIntegration = new LinkedInAdsIntegration();
linkedInAdsIntegration.registerIntegration(integrationStore);

// LinkedIn Pages
import LinkedInPagesIntegration from "../src/javascript/integrations/linked_in_pages/integration";
let linkedInPagesIntegration = new LinkedInPagesIntegration();
linkedInPagesIntegration.registerIntegration(integrationStore);

// Google Sheets
import GoogleSheetsIntegration from "../src/javascript/integrations/google_sheets/integration";
let googleSheetsIntegration = new GoogleSheetsIntegration();
googleSheetsIntegration.registerIntegration(integrationStore);

// GA4
import GA4Integration from "../src/javascript/integrations/google-analytics-4/integration";
let ga4Integration = new GA4Integration();
ga4Integration.registerIntegration(integrationStore);

// Microsoft Advertising
import MicrosoftAdvertising from "../src/javascript/integrations/microsoft-advertising/integration";
let msAds = new MicrosoftAdvertising();
msAds.registerIntegration(integrationStore);

// Google Business Profile
import GoogleBusinessProfile from "../src/javascript/integrations/google_business_profile/integration";
let gbp = new GoogleBusinessProfile();
gbp.registerIntegration(integrationStore);

// Facebook Ads
import FacebookAdsIntegration from "../src/javascript/integrations/facebook_ads/integration";
let fbAdsIntegration = new FacebookAdsIntegration();
fbAdsIntegration.registerIntegration(integrationStore);

// Google Analytics
// IMPORTANT: this one is only partially in the integration store. Widgets are not there,
//            just the base of the integration so that we can use some parts.
import GoogleAnalyticsIntegration from "../src/javascript/integrations/google-analytics/integration";
let gaIntegration = new GoogleAnalyticsIntegration();
gaIntegration.registerIntegration(integrationStore);

// Google Search Console
// IMPORTANT: this one is only partially in the integration store. Widgets are not there,
//            just the base of the integration so that we can use some parts.
import GoogleSearchConsoleIntegration from "../src/javascript/integrations/google-search-console/integration";
let googleSearchConsoleIntegration = new GoogleSearchConsoleIntegration();
googleSearchConsoleIntegration.registerIntegration(integrationStore);
