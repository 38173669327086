const randomValue = () => Math.floor(Math.random() * 1200);

export const coloredRatioData = (theme) => ({
  labels: ["Some", "Sample", "Data", "Points", "Demo", "Values"],
  datasets: [
    {
      data: [randomValue(), randomValue(), randomValue(), randomValue(), randomValue()].sort((a, b) => b - a), // Main color for the larger value
      backgroundColor: [theme.graph1, theme.graph2, theme.graph3, theme.graph4, theme.graph5, theme.graph6],
      borderColor: "transparent",
    },
  ],
});

export const coloredTimeData = (theme) => ({
  labels: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
  datasets: [
    {
      data: [
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
      ],
      backgroundColor: theme.graph1,
    },
  ],
});

export const coloredAreaData = (theme) => ({
  labels: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
  datasets: [
    {
      data: [
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
      ],
      fill: true,
      backgroundColor: theme.graph1,
    },
  ],
});

export const coloredMixedData = (theme, chartType, secondaryChartType) => ({
  labels: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
  datasets: [
    {
      type: chartType === "area" ? "line" : chartType, // "area" doesn't really exist, it's a line, filed
      data: [
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
      ],
      fill: chartType === "area" ? true : false, // "area" doesn't really exist, it's a line, filed
      backgroundColor: theme.graph1,
    },
    {
      type: secondaryChartType === "area" ? "line" : secondaryChartType, // "area" doesn't really exist, it's a line, filed
      data: [
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
        randomValue(),
      ],
      fill: secondaryChartType === "area" ? true : false, // "area" doesn't really exist, it's a line, filed
      backgroundColor: theme.graph2,
    },
  ],
});
