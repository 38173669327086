import React from "react";
var createReactClass = require("create-react-class");
import Select from "react-select";

const ReportLanguage = createReactClass({
  getInitialState: function () {
    return {
      options: [
        { value: "fr", label: "French" },
        { value: "en", label: "English" },
      ],
      language: undefined,
    };
  },

  onChange: function (data) {
    let val = undefined;
    if (data) {
      val = data.value;
    }
    this.setState({ language: val });
    store.dispatch(setReportLanguage(val));
  },

  componentDidMount: function () {
    this.setReportLanguage();

    this.storeSubscription = store.subscribe(
      function () {
        this.setReportLanguage();
      }.bind(this)
    );
  },

  setReportLanguage: function () {
    this.setState({ language: store.getState().reportFormReducer.language });
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    return (
      <div className="mw-report-interval field">
        <label className="tw-label required">Report Language</label>
        <Select
          tabIndex={tabIndex}
          ref="select"
          label="Language"
          options={this.state.options}
          onChange={this.onChange}
          value={this.state.language}
        />
      </div>
    );
  },
});

export default ReportLanguage;
