import axios from "axios";
import {reportFormValidation } from "../helpers/report_form_validation";


const reportAPI = {
    updateToAPI: async function () {
    const { reportID } = store.getState().reportFormReducer;
    const destination = `${this.apiURL()}/${reportID}`;
    const apiParams = this.getCurrentData();

    try {
      await axios.put(destination, apiParams);
      window.scrollTo(0, 0);
    } catch {
      store.dispatch(setFlashMessage("Report could not be saved in Draft.", ["Unknown error"]));
    }
  },
  getCurrentData: function () {
    var widgetLayouts = store.getState().widgetReducer.widgetLayouts;
    var widgetConfigs = store.getState().widgetReducer.widgetConfigs;
    let widgets = reportFormValidation.formattedWidgetsJSON(widgetLayouts, widgetConfigs);
    const dataForAPI = {
      widgets: widgets,
      name: store.getState().reportFormReducer.name,
      isDraft: store.getState().reportFormReducer.isDraft,
    }
    return dataForAPI
  },

  apiURL: function () {
    return "/api/v1/reports";
  }
}

export default reportAPI
