import React, { useState, useEffect } from "react";
import _ from "lodash";
import Modal from "../modal";

const IntegrationsList = ({ openModal = false, initialProduct = "report" }) => {
  const [modalOpen, setModalOpen] = useState(openModal);
  const [step, setStep] = useState(1);
  const [product, setProduct] = useState(initialProduct);
  const [currentIntegration, setCurrentIntegration] = useState(initialProduct);

  let allIntegrations = _.sortBy(window.integrationStore?.integrations, ["name"]);
  const MetricsWatch = window.MetricsWatch || {}; 
  
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const openModalParam = queryParams.get("openModal");
    if (openModalParam === "true" && product !== null) {
      setModalOpen(true);
      setProduct("report");
      // Optionally, set the product based on another query parameter if needed
      // const productParam = queryParams.get("product");
      // if (productParam) setProduct(productParam);
    }
  }, []);

  const closeModal = () => {
    setModalOpen(false);
  };

  const step1 = (
    <div>
      <h2 className="text-4xl font-bold mb-12 text-center">Which {product} integration do you want to connect?</h2>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
        {allIntegrations.map(function (integration, index) {
          if (
            product === "report" &&
            integration.featureFlagName() !== undefined &&
            !Subscriba.hasFeature(integration.featureFlagName())
          ) {
            return false;
          }

          if (integration.integrationFor().indexOf(product) === -1) {
            return false;
          }

          return (
            <li
              className="col-span-1 flex flex-col text-center bg-white hover:bg-gray-50 rounded-lg shadow transition-color duration-300 ease-out p-1 px-2"
              key={`integration-box-${index}`}
            >
              <div className="-mt-px flex">
                <div className="w-0 flex-1 flex">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (integration.oAuthProviderPreRedirectNotice()) {
                        setCurrentIntegration(integration);
                        setStep(2);
                      } else {
                        window.location = integration.oAuthProviderRedirectPath();
                      }
                    }}
                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-blue-mw font-medium border border-transparent rounded-bl-lg hover:text-blue-500 focus:outline-none focus:ring-blue-400 focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                  >
                    <img
                      className=""
                      src={window.location.pathname.includes('reports') ? `../../${integration.iconURL()}` : integration.iconURL()}
                      alt={integration.name()}
                      style={{ maxHeight: "32px", maxWidth: "32px" }}
                    />
                    <span className="mx-3">{integration.name()}</span>
                  </a>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );

  const step2 = () => {
    return (
      <div className="text-center">
        {currentIntegration.oAuthProviderPreRedirectNotice()}
        {currentIntegration.signInButton()}
      </div>
    );
  };

  return (
    <div className="flex">
      <div className="">
        {MetricsWatch.reportLimit > 0 && (
          <a
            className={window.location.pathname.includes('reports') ? "tw-submit-button" : "tw-submit-button mb-8 mr-2"}
            onClick={() => {
              setProduct("report");
              setStep(1);
              setModalOpen(true);
            }}
          >
            {MetricsWatch.alertLimit > 0 ? "Connect a report integration" : "Connect an integration"}
          </a>
        )}
        {MetricsWatch.alertLimit > 0 && (
          <a
            className="tw-submit-button mb-8"
            onClick={() => {
              setProduct("alert");
              setStep(1);
              setModalOpen(true);
            }}
          >
            Connect an alert integration
          </a>
        )}

        <Modal visible={modalOpen} closeCallback={closeModal} widthCanBeLarge>
          {step === 1 && step1}
          {step === 2 && step2()}
        </Modal>
      </div>
    </div>
  );
};

export default IntegrationsList;
