import React from "react";
var createReactClass = require("create-react-class");

const ReportPreviewRecipient = createReactClass({
  getInitialState: function () {
    return { value: undefined };
  },

  onChange: function (event) {
    store.dispatch(setPreviewRecipient(event.target.value));
  },

  componentDidMount: function () {
    if (store.getState().reportFormReducer.preview_recipient) {
      this.setState({
        value: store.getState().reportFormReducer.preview_recipient,
      });
    }

    this.storeSubscription = store.subscribe(
      function () {
        this.setState({
          value: store.getState().reportFormReducer.preview_recipient,
        });
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    return (
      <div className="report-preview-recipient">
        <label className="tw-label">Custom preview recipient</label>
        <input
          ref="input"
          className="tw-field mb-2"
          tabIndex={tabIndex}
          placeholder="email@example.com"
          type="text"
          onChange={this.onChange}
          value={this.state.value || ""}
        />
        <p className="text-sm">
          <em>
            By default, previews will are sent to the account owner's email address (that's probably you!). If you want
            the preview report to be sent to a different email address, you can set it here.
          </em>
        </p>
      </div>
    );
  },
});

export default ReportPreviewRecipient;
