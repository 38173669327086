import React from "react";
import _ from "lodash";
var createReactClass = require("create-react-class");
import Select from "react-select";
import { AllIntervalTypes, AllGA4IntervalTypes } from "../helpers/interval_types";

const IntervalType = createReactClass({
  getInitialState: function () {
    let options = [];
    if (this.props.ga_version == "ga4") {
      options = AllGA4IntervalTypes;
    } else {
      options = AllIntervalTypes;
    }

    return {
      options: options,
      intervalType: undefined,
    };
  },

  onChange: function (data) {
    let val = undefined;
    if (data) {
      val = data.value;
    }
    this.setState({ intervalType: val });
    store.dispatch(selectIntervalType(val));
  },

  componentDidMount: function () {
    this.storeSubscription = store.subscribe(
      function () {
        let previousIntervalType = this.state.intervalType;
        let newIntervalType = store.getState().watcherFormReducer.intervalType;

        if (previousIntervalType != newIntervalType) {
          this.setState({
            intervalType: newIntervalType,
          });
        }
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";

    return (
      <div className="mw-interval-type field">
        <label className="tw-label">Interval</label>
        <Select
          value={this.state.intervalType}
          tabIndex={tabIndex}
          label="Interval"
          options={this.state.options}
          onChange={this.onChange}
        />
      </div>
    );
  },
});

export default IntervalType;
