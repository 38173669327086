import React from "react";
var createReactClass = require("create-react-class");
import watcherFormValidation from "../helpers/watcher_form_validation";
import $ from "jquery";
import _ from "lodash";
import APIError from "../helpers/api_error";
import ButtonWithIcon from "./generic/button_with_icon.js";
import WatcherFilterFormatter from "../helpers/watcher_filter_formatter";

const WatcherSaveButtons = createReactClass({
  apiURL: "/api/v1/alerts",

  saveClicked: function (event) {
    if (event) {
      event.preventDefault();
    }

    this.save();
  },

  saveAndTestClicked: function (event) {
    event.preventDefault();
    this.save(true);
  },

  stateToAPIParams: function (formState) {
    var filters = WatcherFilterFormatter.prepareToAPIFormat(formState.googleAnalyticsFilters);

    return {
      name: formState.watcherName,
      note: formState.watcherNote,
      metric_name: formState.metricName,
      interval_type: formState.intervalType,
      comparison_type: formState.comparisonType,
      threshold: formState.threshold,
      start_minutes_ago: formState.startMinutesAgo,
      end_minutes_ago: formState.endMinutesAgo,
      ga_service_level: formState.gaServiceLevel,
      schedule: formState.enabledTimes,
      email_recipients: formState.emailRecipients,
      mobile_recipients: formState.smsRecipients,
      slack_recipients: formState.slackRecipients,
      google_analytics_profile_id: formState.google_analytics_profile_id,
      google_analytics_filters: filters,
      send_test_email: formState.sendTest,
    };
  },

  save: function (sendTest) {
    let formState = _.clone(store.getState().watcherFormReducer);
    formState.sendTest = sendTest;
    let v = watcherFormValidation.validate(formState, this.props.ga_version);
    if (v.errors.length > 0) {
      return;
    }

    if (formState.watcherID) {
      this.updateAlert(formState);
    } else {
      this.createAlert(formState);
    }
  },

  updateAlert: function (formState) {
    $.ajax({
      url: this.apiURL + "/" + formState.watcherID,
      dataType: "json",
      method: "PUT",
      data: this.stateToAPIParams(formState),
      success: function (data) {
        this.slowScrollToTop();
        store.dispatch(setFlashMessage("Alert successfully updated!", []));
      }.bind(this),
      error: function (xhr, status, err) {
        store.dispatch(setFlashMessage("Alert could not be saved.", ["Unknown error"]));
        new APIError("WatcherUpdate", xhr, status, err);
      }.bind(this),
    });
  },

  createAlert: function (formState) {
    $.ajax({
      url: this.apiURL,
      dataType: "json",
      method: "POST",
      data: this.stateToAPIParams(formState),
      success: function (data) {
        this.slowScrollToTop();
        store.dispatch(setFlashMessage("Alert successfully created.", []));
        this.redirectsToWatcher(data.data.id);
      }.bind(this),
      error: function (xhr, status, err) {
        store.dispatch(setFlashMessage("Alert could not be saved.", ["Unknown error"]));
        new APIError("WatcherCreate", xhr, status, err);
      }.bind(this),
    });
  },

  slowScrollToTop: function () {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    $("main").animate({ scrollTop: 0 }, "slow");
  },

  redirectsToWatcher: function (id) {
    if (this.props.ga_version == "ga4") {
      document.location.href = "/watchers/" + id + "/edit-ga4";
    } else {
      document.location.href = "/watchers/" + id + "/edit";
    }
  },

  resetForm: function () {
    store.dispatch(setWatcherName(undefined));
    store.dispatch(setWatcherNote(""));
    store.dispatch(selectGoogleAnalyticsProfile(undefined));
    store.dispatch(selectMetricName(undefined));
    store.dispatch(selectIntervalType(undefined));
    store.dispatch(selectComparisonType(undefined));
    store.dispatch(setThreshold(undefined));
    store.dispatch(setEmailRecipients([]));
    store.dispatch(setGoogleAnalyticsFilters([]));
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    return (
      <div className="actions mt-4">
        <ButtonWithIcon onClick={this.saveClicked} icon="fa-check" text="Save" extraClasses="tw-submit-button" />
        &nbsp;
        <input
          tabIndex={tabIndex}
          className="tw-button"
          type="submit"
          value="Save &amp; test"
          onClick={this.saveAndTestClicked}
        />
      </div>
    );
  },
});

export default WatcherSaveButtons;
