const reportFormValidation = {
  // converts state for reportCanvas component widgets state to json
  formattedWidgetsJSON: function (widgetLayouts, widgetConfigs) {
    var widgets = [];

    widgetLayouts
      .filter(function (widgetLayout) {
        // When this is a new widget being placed, but not fully placed yet (that has no config), we just remove it from the layout to format into JSON
        return !(widgetConfigs[widgetLayout.i] === undefined);
      })
      .map(function (widgetLayout) {
        var widget = widgetConfigs[widgetLayout.i];
        widget.widgetLayout = widgetLayout;

        // Is it the integration store? Otherwise, it's legacy, and the next switch/case will handle it.
        var widgetFromStore = window.integrationStore.getWidgetByType(widget.widgetType);

        var widgetFromLayoutStore = window.layoutWidgetStore.getWidgetByType(widget.widgetType);

        if (widgetFromLayoutStore) {
          widget.name = widgetFromLayoutStore.name();
        }
        widgets.push(widget);
      });
    return JSON.stringify(widgets);
  },

  getIntegrationByWidgetName: function (widgetName) {
    const integrations = store.getState().widgetReducer.integrations;

    for (const key in integrations) {
      for (let i = 0; i < integrations[key].widget_names.length; i++) {
        if (widgetName == integrations[key].widget_names[i]) {
          return key;
        }
      }
    }

    if (widgetName.startsWith("Layout --")) {
      return undefined;
    } else {
      // If all integrations are not loaded, this would throw and break the report screen.
      // Just commenting out for now.
      // throw `[reportFormValidation.getIntegrationByWidgetName] Unknown integration for: ${widgetName}`
    }
  },

  // List the integratins required for the widgets on canvas
  requiredIntegrations: function () {
    let requiredIntegration = [];

    let widgets = JSON.parse(
      this.formattedWidgetsJSON(
        store.getState().widgetReducer.widgetLayouts,
        store.getState().widgetReducer.widgetConfigs
      )
    );

    for (const widget of widgets) {
      requiredIntegration.push(this.getIntegrationByWidgetName(widget.name));
    }

    requiredIntegration = requiredIntegration.filter((integration) => integration !== undefined);

    return requiredIntegration;
  },
   missingAccounts: function (reportData) {
    const requiredIntegrations = this.requiredIntegrations();
    let warnings = [];
    let missingAccounts = false
    requiredIntegrations.forEach(function (integrationName) {
      let integration = integrationStore.findIntegrationByOAuthProviderName(integrationName);
      if (integrationName == "googlesheets") {
        return;
      }
       
      missingAccounts = false
      if (integration.integrationConfig().metricsWatchIntegrationProtocolVersion === "3.0.0") {
        if (!integration.accountList.length > 0 && !reportData.integration_account_ids[integrationName]) {
          missingAccounts = true
        }
      } else if (!reportData[integration.reducerAccountIDVariableName()]) {
        missingAccounts = true
      }

      if (missingAccounts) {
        warnings.push(integration.name());
        missingAccounts = warnings.length > 0 ? true : false
      }
      warnings = [...new Set(warnings)];
      
    });
    return {missingAccounts, warnings};
  },
  // Validate the Report Form
  validate: function (reportData) {
    const requiredIntegrations = this.requiredIntegrations();
    var state = { text: undefined, errors: [] };

    if (requiredIntegrations.length === 0) {
      state.errors.push("[Widgets] There are no widgets. You need at one least one widget in a report.");
    }

    requiredIntegrations.forEach(function (integrationName) {
      let integration = integrationStore.findIntegrationByOAuthProviderName(integrationName);

      // Google Sheets integration set the provider in the widget; no account validation required
      if (integrationName == "googlesheets") {
        return;
      }

      let accountMissing = false;

      if (integration.integrationConfig().metricsWatchIntegrationProtocolVersion === "3.0.0") {
        if (!reportData.integration_account_ids[integrationName]) {
          accountMissing = true;
        }
      } else if (!reportData[integration.reducerAccountIDVariableName()]) {
        accountMissing = true;
      }

      if (accountMissing) {
        let messageToAdd = `[Integration] You need to configure the ${integration.name()} integration for this report in the "Integration" tab of this report.`;

        if (!state.errors.includes(messageToAdd)) {
          state.errors.push(messageToAdd);
        }
      }
    });

    if (!reportData.day_of_week && reportData.interval_weekly) {
      state.errors.push("[Schedule] Day of the week for the report is required");
    }

    if (!reportData.language) {
      state.errors.push("[Settings] Report language is required");
    }

    if (!reportData.name) {
      state.errors.push("[Settings] Site name is required");
    }

    if (reportData.interval_daily && (!reportData.emails_daily || reportData.emails_daily.length === 0)) {
      state.errors.push(
        "[Schedule] You need to set at least one email recipient for the daily schedule. You can also disable the daily schedule if you prefer."
      );
    }

    if (reportData.interval_weekly && (!reportData.emails_weekly || reportData.emails_weekly.length === 0)) {
      state.errors.push(
        "[Schedule] You need to set at least one email recipient for the weekly schedule. You can also disable the weekly schedule if you prefer."
      );
    }

    if (reportData.interval_monthly && (!reportData.emails_monthly || reportData.emails_monthly.length === 0)) {
      state.errors.push(
        "[Schedule] You need to set at least one email recipient for the monthly schedule. You can also disable the monthly schedule if you prefer."
      );
    }

    switch (state.errors.length) {
      case 0:
        // all good!
        break;
      case 1:
        state.text = "We could not save the report due to this error:";
        break;
      default:
        state.text = "We could not save the report due to these errors:";
    }

    store.dispatch(setFlashMessage(state.text, state.errors));
    return state;
  },

  widgetHasNoAccountConfigured: function (widgetName) {
    const configuredIntegrations = store.getState().integrationsReducer.integrationsAccountLists;
    const integrationCode = reportFormValidation.getIntegrationByWidgetName(widgetName);

    // This is the case for layout widgets
    if (integrationCode == undefined) {
      return false;
    }

    // In some cases, the cache is enpty. It should not happen, in theory, but it should not crash when it happens.
    if (configuredIntegrations[integrationCode] == undefined) {
      return true;
    }

    return configuredIntegrations[integrationCode].length === 0;
  },
};

export { reportFormValidation };
