import React from "react";
var createReactClass = require("create-react-class");
import Template from "./template.js";

var TemplateList = createReactClass({
  getInitialState: function () {
    return {
      templates: [
        {
          refID: "rt-traffic-spike",
          title: "Traffic Spike (real-time)",
          question:
            "Receive an alert when there is more than X currently active users. We use the same data you see in the real-time section of Google Analytics.",
          intervalType: "real_time",
          metricName: "rt:activeUsers",
          comparisonType: "goes_above",
        },
        {
          refID: "low-revenue-last-hour",
          title: "Low ecommerce revenue in last hour",
          question:
            "Receive an alert when there is less than X in sales in the last full hour. If you have a LOT of traffic, you might have to tweak this to be two hours ago, for example, to let Google Analytics process the data and make it available.",
          intervalType: "last_hour",
          metricName: "ga:transactionRevenue",
          comparisonType: "falls_below",
        },
        {
          refID: "traffic-drop",
          title: "Traffic drop",
          question:
            "What if you had very little visits yesterday? Maybe something is broken? How many sessions are you having at a minimum per day?",
          intervalType: "yesterday",
          metricName: "ga:sessions",
          comparisonType: "falls_below",
          defaultValue: 100,
        },
        {
          refID: "low-goal-completion-real-time",
          title: "Low conversions (real-time)",
          question:
            "If you have less than X conversions happening, there might be an issue. You can also go and edit this alert once created, to be for a specific goal instead of all goals.",
          intervalType: "real_time",
          metricName: "rt:goalCompletionsAll",
          comparisonType: "falls_below",
        },
        {
          refID: "goal-completion-drop",
          title: "Goal completion drop",
          question:
            "What if you had no goal completed, or very few? You want to know that. What is the a low number that would be worrying? Use that as a value here.",
          intervalType: "yesterday",
          metricName: "ga:goalCompletionsAll",
          comparisonType: "falls_below",
        },
        {
          refID: "goal-completion-spike",
          title: "Goal completion spike (so far today)",
          question:
            "What is the total number of goal completions (or conversions) that would be worth knowing in as soon as possible? This is accross all your goals that you have setup.",
          intervalType: "today",
          metricName: "ga:goalCompletionsAll",
          comparisonType: "goes_above",
        },
        {
          refID: "adwords-click-spike",
          title: "Adwords click spike (so far today)",
          question:
            "People are clicking like crazy on your Adwords ad, cool! Probably, but who knows? Know it as soon as possible, using the metrics avaialble so far today. After how many clicks in a day do you want us to email you?",
          intervalType: "today",
          metricName: "ga:adClicks",
          comparisonType: "goes_above",
        },
        {
          refID: "adwords-cpc-spike",
          title: "Adwords cost per click (CPC) spike (so far today)",
          question: "What CPC is the maximum you want your campaigns to cost you?",
          intervalType: "today",
          metricName: "ga:adClicks",
          comparisonType: "goes_above",
        },
        {
          refID: "bouncerate-is-too-high",
          title: "Bounce rate is too high",
          question: "What is a typical bounce rate? What if it's X% higher? Worth knowing right? Put that number here.",
          intervalType: "yesterday",
          metricName: "ga:bounceRate",
          comparisonType: "goes_above",
        },
        {
          refID: "low-session-duration",
          title: "Low session duration",
          question:
            'You know on average your users are on your site for X seconds/minutes, a massively different number could mean something changed, for the better or the worse. If it falls below which number do you want to receive an alert? Set it in seconds here (i.e., if you want 2 minutes, use "120" for the value).',
          intervalType: "yesterday",
          metricName: "ga:sessionDuration",
          comparisonType: "falls_below",
        },
        {
          refID: "slow-average-load",
          title: "Very slow average page load time?",
          question:
            "This can be very very bad. What if the average page load was 5 seconds? A catastrophy if you are trying to convince someone to buy from you! What is the maximum time you can deal with, or that your users will wait?",
          intervalType: "yesterday",
          metricName: "ga:avgPageLoadTime",
          comparisonType: "goes_above",
        },
      ],
    };
  },

  render: function () {
    return (
      <div>
        {this.state.templates.map(
          function (template) {
            return (
              <Template
                title={template.title}
                question={template.question}
                metricName={template.metricName}
                comparisonType={template.comparisonType}
                intervalType={template.intervalType}
                defaultValue={template.defaultValue}
                refID={template.refID}
                key={template.refID}
              />
            );
          }.bind(this)
        )}
      </div>
    );
  },
});

export default TemplateList;
