// AppSignal
import Appsignal from "@appsignal/javascript";
import { plugin as consolePlugin } from "@appsignal/plugin-breadcrumbs-console";
import { plugin as networkPlugin } from "@appsignal/plugin-breadcrumbs-network";
import { plugin as windowEventsPlugin } from "@appsignal/plugin-window-events";
import { plugin as pathPlugin } from "@appsignal/plugin-path-decorator";


const appsignal = new Appsignal({
  key: window.appsignalPushKey,
  revision: window.appsignalRevision,
  active: process.env.NODE_ENV !== "development",
});

appsignal.addDecorator((span) => {
  return span.setTags({ user_id: MetricsWatch.currentUser.id });
});

// By default, AppSignal don't catch uncaught exceptions. We need to add it with those few lines
// Doc -> https://docs.appsignal.com/front-end/plugins/plugin-window-events.html
appsignal.use(windowEventsPlugin());
appsignal.use(networkPlugin());
appsignal.use(consolePlugin());
appsignal.use(pathPlugin());


export { appsignal };
