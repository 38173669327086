import React from "react";
import _ from "lodash";

import VerticalBarGraph from "./vertical-bar-graph";

const SingleMetricUpper = ({ number, arrowUrl, comparisonEnabled }) => (
  <p className="text-3xl font-extrabold" style={{ paddingTop: comparisonEnabled === true ? "0px" : "25px" }}>
    {arrowUrl && (
      <span className="inline-block h-6 w-6 mr-2 text-center">
        <img src={arrowUrl}></img>
      </span>
    )}
    {number}
  </p>
);

const SingleMetricLower = ({ number = 0, percentage = "0%" }) => (
  <>
    <p className="text-xs">
      Change: <strong>{percentage}</strong>
    </p>
    <p className="text-xs">
      Previous period: <strong>{number}</strong>
    </p>
  </>
);

const ListPreview = ({
  color,
  metricName = "Count",
  dimensionName = "Publication",
  secondary_metric_label = false,
  third_metric_label,
  secondary_column_is_previous_period_comparison,
  columnHeaders,
  data_type,
  data,
  widgetIndex,
}) => {
  const rowStyle = { borderColor: color };
  let body = [];

  let ad_preview_supported = false;

  let ads = [];
  // If the list is a preview of Facebook Ads, we need to structure and display the ad content in <td> on this loop.  if (data_type === "[facebook-ads] ad-preview") {
  if (data_type === "[facebook-ads] ad-preview") {
    for (let i = 0; i < data.length; i++) {
      let ad_preview = [];
      if (data[i][0].title) {
        ad_preview.push(
          <span style={{ color: "#3b5998" }} className="font-bold" key={"title" + i + widgetIndex}>
            {" "}
            {data[i][0].title}
          </span>
        );
        ad_preview_supported = true;
      }

      if (data[i][0].link_url) {
        ad_preview.push(
          <span key={"url" + i + widgetIndex}>
            <br />
            <a href={data[i][0].link_url} style={{ color: "#3b5998" }}>
              {data[i][0].link_url.split("https://").pop()}
            </a>
          </span>
        );
        ad_preview_supported = true;
      }

      if (data[i][0].description) {
        ad_preview.push(
          <span key={"desc" + i + widgetIndex}>
            <br />
            {data[i][0].description}
          </span>
        );
        ad_preview_supported = true;
      }

      if (data[i][0].image_url) {
        ad_preview.push(<img src={data[i][0].image_url} style={{ maxWidth: "200px", maxHeight: "200px" }} />);
        ad_preview_supported = true;
      }

      if (!ad_preview_supported) {
        ad_preview.push(<em>Ad type unsupported for preview.</em>);
      }

      ads.push(ad_preview);
    }
  }

  // Construction of the body of the table (variable body displayed in the return)
  for (let i = 0; i < data.length; i++) {
    let dimension_content;
    if (data_type === "[facebook-ads] ad-preview") {
      dimension_content = ads[i];
    } else {
      let item = data[i];

      // metadata is the last item of the array, but we don't know yet if we have metadata (only some integrations have metadata in lists)
      let lastItemId = item.length - 1;
      let lastItem = item[lastItemId];
      let hasMetadata = _.isObject(lastItem); // if it has metadata (like an image and/or URL), it should be an object, otherwise it's not metadata

      if (hasMetadata) {
        const metadata = lastItem;
        dimension_content = data[i][0];
        if (metadata.image_url) {
          dimension_content = (
            <>
              <img
                src={metadata.image_url}
                style={{ maxWidth: "50px", maxHeight: "50px", float: "left", margin: "4px 10px 4px 0" }}
              />
              {dimension_content}
            </>
          );
        }
        if (metadata.permalink_url) {
          dimension_content = <a href={metadata.permalink_url}>{dimension_content}</a>;
        }
      } else {
        dimension_content = data[i][0];
      }
    }

    body.push(
      <tr className="border-b" style={rowStyle} key={"ListPrevierTr" + i.toString() + widgetIndex}>
        <td>{dimension_content}</td>
        <td className="text-right pl-3">{data[i][1]}</td>
        {secondary_column_is_previous_period_comparison === true && <td className="text-right">{data[i][2]}</td>}
        {secondary_column_is_previous_period_comparison === true && (
          <td
            className="text-right"
            style={{
              whiteSpace: "nowrap",
              color:
                store.getState().reportFormReducer.agencySettings.visual.widgets.singleMetrics[
                  `${data[i][4]}TextColor`
                ],
              paddingLeft: "10px",
            }}
          >
            {data[i][3]}
          </td>
        )}
        {secondary_column_is_previous_period_comparison !== true &&
          data[i][2] !== undefined &&
          !_.isObject(data[i][2]) && <td className="text-right pl-3">{data[i][2]}</td>}
        {secondary_column_is_previous_period_comparison !== true &&
          data[i][3] !== undefined &&
          !_.isObject(data[i][3]) && <td className="text-right pl-3">{data[i][3]}</td>}
      </tr>
    );
  }

  return (
    <table className="table-auto w-full" key={"listPreview" + widgetIndex}>
      <colgroup>
        <col style={{ width: "65%", paddingRight: 8 }} />
        <col />
        {columnHeaders && columnHeaders[2] && <col style={{ paddingLeft: 8 }} />}
        {columnHeaders && columnHeaders[2] && <col style={{ paddingLeft: 8 }} />}
        {secondary_column_is_previous_period_comparison === true && <col style={{ paddingLeft: 8 }} />}
        {secondary_column_is_previous_period_comparison !== true && secondary_metric_label && (
          <col style={{ paddingLeft: 8 }} />
        )}
        {secondary_column_is_previous_period_comparison !== true && third_metric_label && (
          <col style={{ paddingLeft: 8 }} />
        )}
      </colgroup>

      <thead>
        <tr className="border-b-2" style={rowStyle}>
          <th className="text-left">{dimensionName}</th>
          <th className="text-right">{metricName}</th>
          {columnHeaders && columnHeaders[2] && <th className="text-right pl-2">{columnHeaders[2]}</th>}
          {columnHeaders && columnHeaders[3] && <th className="text-right pl-2">{columnHeaders[3]}</th>}
        </tr>
      </thead>
      <tbody>{body}</tbody>
    </table>
  );
};

const ListAdsPreview = ({
  color,
  metricName = "Count",
  dimensionName = "Publication",
  secondary_metric_label = false,
  third_metric_label,
  secondary_column_is_previous_period_comparison,
  columnHeaders,
  data,
  all_ads_metadata,
  widgetIndex,
}) => {
  const rowStyle = { borderColor: color };
  let body = [];

  for (let i = 0; i < data.length; i++) {
    let ads_metadata = null;
    for (let j = 0; j < all_ads_metadata.length; j++) {
      if (all_ads_metadata[j].resource_name == data[i][0]) {
        ads_metadata = all_ads_metadata[i];
        break;
      }
    }
    // Construction of the body of the table (variable body displayed in the return)
    if (ads_metadata) {
      body.push(
        <tr className="border-b" style={rowStyle} key={"ListAdPrevierTr" + i.toString() + widgetIndex}>
          <td>
            {ads_metadata.url_clickable && (
              <span style={{ color: "#006621" }}>
                <a href={ads_metadata.url} style={{ color: "#006621", textDecoration: "none" }}>
                  {ads_metadata.url}
                </a>
              </span>
            )}
            {!ads_metadata.url_clickable && <span style={{ color: "#006621" }}>{ads_metadata.url}</span>}
            <span style={{ fontSize: "14px", color: "#1558D6" }}>
              <br />
              {ads_metadata.headline}
            </span>

            {ads_metadata.description && (
              <span>
                <br />
                {ads_metadata.description}
              </span>
            )}

            {ads_metadata.image_url && (
              <span>
                <br />
                <img src={ads_metadata.image_url} style={{ maxWidth: "200px", maxHeight: "200px" }} />
                {ads_metadata.image_additionnal_text && <br /> && ads_metadata.image_additionnal_text}
              </span>
            )}
            {ads_metadata.videos.length > 0 && (
              <span>
                <br />
                YouTube video: <a href={ads_metadata.video_url}>{ads_metadata.video_title}</a>
                {ads_metadata.video_additionnal_text}
              </span>
            )}
          </td>
          <td className="text-right pl-3">{data[i][1]}</td>
          {secondary_column_is_previous_period_comparison === true && <td className="text-right">{data[i][2]}</td>}
          {secondary_column_is_previous_period_comparison === true && (
            <td
              className="text-right"
              style={{
                whiteSpace: "nowrap",
                color:
                  store.getState().reportFormReducer.agencySettings.visual.widgets.singleMetrics[
                    `${data[i][4]}TextColor`
                  ],
                paddingLeft: "10px",
              }}
            >
              {data[i][3]}
            </td>
          )}
          {secondary_column_is_previous_period_comparison !== true && data[i][2] !== undefined && (
            <td className="text-right pl-3">{data[i][2]}</td>
          )}
          {secondary_column_is_previous_period_comparison !== true && data[i][3] !== undefined && (
            <td className="text-right pl-3">{data[i][3]}</td>
          )}
        </tr>
      );
    }
  }

  return (
    <table className="table-auto w-full">
      <colgroup>
        <col style={{ width: "65%", paddingRight: 8 }} />
        <col />
        {columnHeaders && columnHeaders[2] && <col style={{ paddingLeft: 8 }} />}
        {columnHeaders && columnHeaders[2] && <col style={{ paddingLeft: 8 }} />}
        {secondary_column_is_previous_period_comparison === true && <col style={{ paddingLeft: 8 }} />}
        {secondary_column_is_previous_period_comparison !== true && secondary_metric_label && (
          <col style={{ paddingLeft: 8 }} />
        )}
        {secondary_column_is_previous_period_comparison !== true && third_metric_label && (
          <col style={{ paddingLeft: 8 }} />
        )}
      </colgroup>

      <thead>
        <tr className="border-b-2" style={rowStyle}>
          <th className="text-left">{dimensionName}</th>
          <th className="text-right">{metricName}</th>
          {columnHeaders && columnHeaders[2] && <th className="text-right pl-2">{columnHeaders[2]}</th>}
          {columnHeaders && columnHeaders[3] && <th className="text-right pl-2">{columnHeaders[3]}</th>}
        </tr>
      </thead>
      <tbody>{body}</tbody>
    </table>
  );
};

const AllGoals = () => {
  return (
    <>
      <h3 className="label text-xl font-bold">All goals with data (Absolute)</h3>
      <p className="text-sm">This will generate one widget per goal that had conversions recently.</p>
    </>
  );
};

export default {
  SingleMetricUpper,
  SingleMetricLower,
  ListPreview,
  ListAdsPreview,
  VerticalBarGraph,
  AllGoals,
};
