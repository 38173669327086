import React from "react";
import { connect } from "react-redux";
import FilterValidation from "../../../../helpers/filter_validation";
import GoogleSheetsListEditor from "./gs_list_editor";
import GoogleSheetsApi from "../../api";
import LoadSpinner from "../../../../components/utility/load_spinner";
import WidgetPreviewFetcher from "../../../../helpers/widget_preview/widget_preview_fetcher";
import GoogleSheetsActions from "../../google_sheets_actions";

let gsActions;
let providerAccount;
let sheetId;
let currentlyRendering = false;
let isCurrency = false;

// thinking to optimize this part for more flexibility to add more metrics.
let metrics1IsCurrency = false;
let metrics2IsCurrency = false;
let metrics3IsCurrency = false;

let dataTypeList = [];

class ListEditor extends React.Component {
  constructor(props) {
    super(props);
    this.props.secondary_column_is_previous_period_comparison || false;
    this.api = new GoogleSheetsApi();
    gsActions = new GoogleSheetsActions();

    if (typeof this.props.gsDataType !== "undefined") {
      dataTypeList = this.props.gsDataType;
    }
    this.flagAccountSaved = false;
    if (typeof this.props.googleSheetsAccount !== "undefined") {
      this.flagAccountSaved = true;
    }
  }

  saveAndClose() {
    let errors = [];
    if (
      !this.props.gsFileName ||
      !this.props.gsSheetId ||
      !this.props.googleSheetsAccount ||
      !this.props.selectedSheet
    ) {
      errors.push("All fields are required. Please complete the form before saving and closing.");
    }

    if (this.props.metric_name && !this.props.valueHandlingMetricOne) {
      errors.push("Metric details are missing");
    }

    if (this.props.secondary_metric_name && !this.props.valueHandlingMetricTwo) {
      errors.push("Secondary metric details are missing");
    }

    if (this.props.third_metric_name && !this.props.valueHandlingMetricThree) {
      errors.push("Third metric details are missing");
    }

    if (!FilterValidation.validate(this.props.filters)) {
      errors.push("At least one filter has an unspecified value");
    }

    if (errors.length > 0) {
      store.dispatch(setFlashMessage("We could not save the widget:", errors));
      return;
    }

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    store.dispatch(saveWidgetConfigFromEditor(this.props.widgetIndex));

    new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

    this.props.closeWidgetEditModal();
  }

  close() {
    if (!this.flagAccountSaved) {
      store.dispatch(
        setFlashMessage("All field are required", [
          "Please complete the form and save before closing, or delete the widget. You can't close without saving a new widget.",
        ])
      );
    } else {
      store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
      this.props.closeWidgetEditModal();
    }
  }

  render() {
    let googleSheetsEditorsInfo = {};
    var operators = [
      { value: "CONTAIN", label: "contains" },
      { value: "NOT_CONTAIN", label: "does NOT contain" },
    ];

    providerAccount = this.props.googleSheetsAccount;
    sheetId = this.props.gsSheetId;

    googleSheetsEditorsInfo = {
      provider: "googlesheets",
      providerLabel: "Google Sheets Account",
      providerAccountId: this.props.googleSheetsAccount,
      fileName: { label: "File Name", value: this.props.gsFileName },
      valueHanding: this.props.valueHandling,
      sheetsList: this.props.gsSheetsList,
    };

    if (metrics1IsCurrency || metrics2IsCurrency || metrics3IsCurrency) {
      isCurrency = true;
    } else {
      isCurrency = false;
    }

    let filtersList = this.props.dimensions;
    if (filtersList !== undefined) {
      filtersList = filtersList.filter((filter) => filter.value.includes(this.props.dimension));
    }
    return (
      <div>
        {currentlyRendering === true && <LoadSpinner extraClasses="absolute" />}
        <GoogleSheetsListEditor
          {...this.props}
          name="Google Sheets List"
          dimensions={this.props.dimensions}
          metricsList={this.props.gsMetricsList}
          filterOperators={[
            { value: "LIKE", label: "contains" },
            { value: "NOT LIKE", label: "does NOT contain" },
          ]}
          filterDimensionList={filtersList}
          filterHasORenabled={false}
          saveAndClose={this.saveAndClose.bind(this)}
          deleteWidget={this.props.deleteWidget.bind(this)}
          closeWidgetEditModal={this.close.bind(this)}
          offerPreviousPeriodComparison
          isCurrency={isCurrency}
          googleSheetsEditorsInfo={googleSheetsEditorsInfo}
        />

        <p className="text-sm text-gray-500 mt-4">
          Please refer to{" "}
          <a
            href="https://help.metricswatch.com/article/5-how-to-use-the-google-sheets-integration"
            target="_blank"
            className="underline text-blue-500"
          >
            the Google Sheets integration documentation to learn more about how to format your data for Metrics Watch
          </a>
          .
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateComparisonColumnName: (event) => {
      dispatch(
        updateEditorWidgetConfig(
          "secondary_column_is_previous_period_comparison",
          event.target.checked,
          ownProps.widgetIndex
        )
      );
    },
    updateComparisonColumnLabel: (event) => {
      dispatch(updateEditorWidgetConfig("comparison_column_label", event.target.value, ownProps.widgetIndex));
    },
    updateLabel: (event) => {
      dispatch(updateEditorWidgetConfig("label", event.target.value, ownProps.widgetIndex));
    },
    updateDimension: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label;
      }

      dispatch(updateEditorWidgetConfig("dimension", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("dimension_label", label, ownProps.widgetIndex));
    },
    updateDimensionLabel: (event) => {
      dispatch(updateEditorWidgetConfig("dimension_label", event.target.value, ownProps.widgetIndex));
    },
    updateMetricLabel: (event) => {
      dispatch(updateEditorWidgetConfig("metric_label", event.target.value, ownProps.widgetIndex));
    },
    updateMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label;
      }
      let dataTypeSelected = gsActions.return_data_type_by_metric(value, dataTypeList);

      metrics1IsCurrency = false;
      if (typeof dataTypeSelected !== "undefined") {
        if (dataTypeSelected.dataType === "CURRENCY") {
          metrics1IsCurrency = true;
        }
      }

      dispatch(updateEditorWidgetConfig("metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("metric_label", label, ownProps.widgetIndex));
    },
    updateCurrency: (selected) => {
      dispatch(updateEditorWidgetConfig("currency", selected.value, ownProps.widgetIndex));
    },
    updateSecondaryLabel: (event) => {
      dispatch(updateEditorWidgetConfig("secondary_metric_label", event.target.value, ownProps.widgetIndex));
    },
    updateSecondaryMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label;
      }
      let dataTypeSelected = gsActions.return_data_type_by_metric(value, dataTypeList);
      metrics2IsCurrency = false;
      if (typeof dataTypeSelected !== "undefined") {
        if (dataTypeSelected.dataType === "CURRENCY") {
          metrics2IsCurrency = true;
        }
      }
      dispatch(updateEditorWidgetConfig("secondary_metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("secondary_metric_label", label, ownProps.widgetIndex));
    },
    updateThirdLabel: (event) => {
      dispatch(updateEditorWidgetConfig("third_metric_label", event.target.value, ownProps.widgetIndex));
    },
    updateThirdMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label;
      }
      let dataTypeSelected = gsActions.return_data_type_by_metric(value, dataTypeList);
      metrics3IsCurrency = false;
      if (typeof dataTypeSelected !== "undefined") {
        if (dataTypeSelected.dataType === "CURRENCY") {
          metrics3IsCurrency = true;
        }
      }
      dispatch(updateEditorWidgetConfig("third_metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("third_metric_label", label, ownProps.widgetIndex));
    },
    updateLimit: (event) => {
      dispatch(updateEditorWidgetConfig("limit", event.target.value, ownProps.widgetIndex));
    },
    save: () => {
      dispatch(saveWidgetConfigFromEditor(ownProps.widgetIndex));
    },
    selectFile: () => {
      currentlyRendering = true;
      dispatch(updateEditorWidgetConfig("selectedSheet", "", ownProps.widgetIndex));
      if (!providerAccount) {
        store.dispatch(setFlashMessage("Please select an account.", ["Google sheets account"]));
        currentlyRendering = false;
        return;
      }
      gsActions.pick_file(
        dispatch,
        ownProps,
        providerAccount,
        () => {
          currentlyRendering = false;
          store.dispatch(setFlashMessage(undefined, []));
          dispatch(updateEditorWidgetConfig("selectedSheet", "", ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("metric_name", "", ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("label", "", ownProps.widgetIndex));
        },
        () => {
          currentlyRendering = false;
          dispatch(updateEditorWidgetConfig("googleSheetsAccount", providerAccount, ownProps.widgetIndex));
        }
      );
    },
    // the name onChangeProviderSelect is standard for reusable single metrics widget
    onChangeProviderSelector: (selected) => {
      currentlyRendering = true;
      providerAccount = selected.value;
      dispatch(updateEditorWidgetConfig("googleSheetsAccount", selected.value, ownProps.widgetIndex));
      gsActions.pick_file(
        dispatch,
        ownProps,
        providerAccount,
        () => {
          currentlyRendering = false;
          store.dispatch(setFlashMessage(undefined, []));
          dispatch(updateEditorWidgetConfig("selectedSheet", "", ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("metric_name", "", ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("label", "", ownProps.widgetIndex));
        },
        () => {
          currentlyRendering = false;
          dispatch(updateEditorWidgetConfig("googleSheetsAccount", providerAccount, ownProps.widgetIndex));
        }
      );
    },
    onChangeValueHandlingSelector: (selected, metricNumber) => {
      dispatch(updateEditorWidgetConfig("valueHandlingMetric" + metricNumber, selected.value, ownProps.widgetIndex));
    },
    onChangeSheetsSelector: (selected) => {
      currentlyRendering = true;
      dispatch(updateEditorWidgetConfig("selectedSheet", selected.value, ownProps.widgetIndex));
      gsActions.setSheetValues(selected, dispatch, ownProps, sheetId, providerAccount, () => {
        currentlyRendering = false;
        dispatch(updateEditorWidgetConfig("metric_name", "", ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("label", "", ownProps.widgetIndex));
      });
    },
    updateDateFilter: (ranges, isEnabledDateFilter) => {
      if(isEnabledDateFilter){
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, ownProps.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, ownProps.widgetIndex));
      }
      
    },
    updateFiltersEnabled: (checked) => {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, ownProps.widgetIndex));
    },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListEditor);
