import React from "react";
var createReactClass = require("create-react-class");
import { connect, Provider } from "react-redux";
import { getBoolean } from "../helpers/get_boolean";
import ReactTimeInput from "react-time-input";

const WatcherDisabledTimingsComponent = createReactClass({
  onTimeChanged: function (value, day, field) {
    store.dispatch(setWatcherEnabledTimings(value, day, field));
  },

  render: function () {
    var daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    var enabledTimes = this.props.enabledTimes;
    return (
      <div className="field mt-4 mb-2">
        <label className="tw-label">Limit Alert Emails to These Times</label>
        <p className="text-xs mt-1 text-gray-500">We will only alert you during those times and days.</p>
        <br />
        <div className="days-container">
          {daysOfWeek.map(
            function (day, index) {
              var dayKey = day.toLowerCase();
              var enabled = getBoolean(enabledTimes[dayKey]["enabled"]);
              return (
                <div
                  key={index}
                  className={dayKey + "-time-enabled" + " tw-label day-container flex items-baseline mb-4"}
                >
                  <label className={"tw-label inline-block mr-auto w-36" + (enabled ? "" : " font-normal")}>
                    <input
                      className={dayKey + "-time-enabled-checkbox" + " mr-2 tw-checkbox"}
                      type="checkbox"
                      checked={enabled}
                      onChange={function (event) {
                        this.onTimeChanged(event.target.checked, dayKey, "enabled");
                      }.bind(this)}
                    />{" "}
                    {day}
                  </label>
                  <span
                    className={
                      dayKey +
                      "-time-from" +
                      (enabled ? "" : " opacity-50") +
                      " time-container inline-flex align-baseline font-normal"
                    }
                  >
                    from&nbsp;
                    <ReactTimeInput
                      className={
                        dayKey +
                        "-time-from-input" +
                        " tw-field inline-block mx-1 w-16 h-6 px-2 text-center text-sm font-medium"
                      }
                      initTime={enabledTimes[dayKey]["from"]}
                      disabled={!enabled}
                      onTimeChange={function (val) {
                        this.onTimeChanged(val, dayKey, "from");
                      }.bind(this)}
                    />
                  </span>
                  <span
                    className={
                      dayKey +
                      "-time-to" +
                      (enabled ? "" : " opacity-50 ") +
                      " time-container inline-flex align-baseline font-normal"
                    }
                  >
                    &nbsp; to &nbsp;
                    <ReactTimeInput
                      className={
                        dayKey +
                        "-time-to-input" +
                        " tw-field inline-block mx-1 w-16 h-6 px-2 text-center text-sm font-medium"
                      }
                      initTime={enabledTimes[dayKey]["to"]}
                      disabled={!enabled}
                      onTimeChange={function (val) {
                        this.onTimeChanged(val, dayKey, "to");
                      }.bind(this)}
                    />
                  </span>
                </div>
              );
            }.bind(this)
          )}
        </div>
      </div>
    );
  },
});

var WatcherDisabledTimingsConnect = connect(
  function (state, ownProps) {
    return { enabledTimes: state.watcherFormReducer.enabledTimes };
  },
  null,
  null
)(WatcherDisabledTimingsComponent);

var WatcherDisabledTimings = createReactClass({
  render: function () {
    return (
      <Provider store={store}>
        <WatcherDisabledTimingsConnect editingReportCanvas={this.props.editingReportCanvas} />
      </Provider>
    );
  },
});

export default WatcherDisabledTimings;
