import React from "react";
import { connect } from "react-redux";

class DailyOptionsMonthToDate extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="field">
        <div>
          <label className="tw-label">
            <p>
              <input
                type="checkbox"
                className="mr-2 tw-checkbox"
                checked={this.props.month_to_date}
                onChange={this.props.updateMonthToDate}
              />
              Month to date: use the data for the full month to date instead of yesterday's data. Comparison is vs
              previous month until same day of last month.
            </p>
          </label>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let month_to_date = false;
  if (state.reportFormReducer.compare_daily_options) {
    month_to_date = state.reportFormReducer.compare_daily_options.month_to_date;
  }

  return {
    month_to_date: month_to_date,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateMonthToDate: (event) => {
      dispatch(setCompareDailyOptionsMonthToDate(event.target.checked));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyOptionsMonthToDate);
