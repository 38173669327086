import React from "react";
class EmailConfirmationResend extends React.Component {
  constructor(props) {
    super(props);
    this.state = { enabled: true, message: "" };
  }

  resend() {
    this.setState({ enabled: false });
    fetch("/email_confirmation_resend", {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.setState({
            message:
              "Email sent! Although the email should not land in spam, please check to ensure it has been received. The next resend will be available in 1 minute.",
          });
        } else {
          this.setState({
            message:
              "The email has been sent, and you will not be able to resend it for the next 1 minute. Please check your spam folder to ensure it has not been classified as spam.",
          });
        }
      })
      .catch((error) => console.error(error));

    setTimeout(() => {
      this.setState({ enabled: true });
    }, 1 * 60 * 1000); // 1 minute in milliseconds
  }

  render() {
    return (
      <div className="text-center mt-8">
        <button
          onClick={this.resend.bind(this)}
          disabled={!this.state.enabled}
          className={`tw-button ${this.state.enabled === false && "disabled"}`}
        >
          Re-send email confirmation
        </button>
        <p className="text-sm text-gray-600 mt-2">{this.state.enabled === false && this.state.message}</p>
      </div>
    );
  }
}

export default EmailConfirmationResend;
