import React from "react";

import SingleMetricWidget from "./widget.jsx";
import SingleMetricEditor from "./editor.jsx";
import WidgetBase from "../../../widgets/widget_base";

export default class SingleMetric extends WidgetBase {
  constructor(widget, integration) {
    super(widget, integration);
  }

  name() {
    return this.widget.widgetName;
  }

  type() {
    return this.widget.widgetType;
  }

  width() {
    return "small";
  }

  config() {
    return {
      widgetType: this.type(),
      name: this.name(),
      label: this.widget.defaultLabel,
      metric_name: this.widget.defaultMetricName,
      filters: [],
    };
  }

  widgetComponent(widgetIndex) {
    return React.createElement(
      SingleMetricWidget,
      {
        widgetIndex: widgetIndex,
        key: widgetIndex,
        providerName: this.integration.name(),
      },
      null
    );
  }

  editorComponent(widgetIndex, closeAction, deleteWidget) {
    return React.createElement(
      SingleMetricEditor,
      {
        widgetIndex: widgetIndex,
        key: widgetIndex,
        closeWidgetEditModal: closeAction,
        deleteWidget: deleteWidget,
        metrics: this.integration.config.metrics(),
        filterOperators: this.integration.config.filterOperators,
        filterDimensionList: this.integration.config.dimensions(),
        filterHasORenabled: this.integration.config.filterHasORenabled,
        widgetName: this.name(),
        providerName: this.integration.name(),
        oAuthProviderName: this.integration.oAuthProviderName(),
      },
      null
    );
  }
}
