import React, { useState } from "react";

const drawerStyles = {};

const Drawer = ({
  children,
  extraClasses = "",
  title,
  textSizeClass = "text-base",
  headerColorClass = "text-gray-700 hover:text-gray-500",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={"drawer-container " + extraClasses}>
      <div className="" onClick={() => setIsOpen(!isOpen)}>
        <h4 className={"drawer-box-header " + textSizeClass}>
          <a className={"flex items-center cursor-default " + headerColorClass + " " + textSizeClass}>
            {title}
            <span className="ml-auto">
              <i className={"fas fa-angle-down ml-2 text-blue-mw ml-4" + (isOpen ? " is-flipped" : "")} />
            </span>
          </a>
        </h4>
      </div>
      <div className={"add-button-container my-2 flex flex-col" + (isOpen ? "" : " hidden ")}>{children}</div>
    </div>
  );
};

export default Drawer;
