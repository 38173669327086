import React from "react";
var createReactClass = require("create-react-class");

const Button = createReactClass({
  render: function () {
    var className = "tw-button " + (this.props.extraClasses || "");
    return (
      <a href={this.props.href} className={className} onClick={this.props.onClick}>
        <span className="m-auto">{this.props.text}</span>
      </a>
    );
  },
});

export default Button;
