import IntegrationBase from "../integration_base";
import SingleMetric from "./widgets/single_metric/single_metric.js";
import List from "./widgets/list/list.js";
import BarGraph from "./widgets/bar_graph/bar_graph";
import PieChart from "./widgets/pie_chart/pie_chart";
import IntegrationIcon from "./logo/logo.png";

export default class LinkedInPages extends IntegrationBase {
  constructor() {
    super();
    this.registerWidget(new SingleMetric());
    this.registerWidget(new List());
    this.registerWidget(new BarGraph());
    this.registerWidget(new PieChart());
  }

  name() {
    return "LinkedIn Pages";
  }

  iconURL() {
    return IntegrationIcon;
  }

  oAuthProviderName() {
    return "linkedin-pages";
  }

  reducerAccountIDVariableName() {
    return "linkedin_pages_account_id";
  }

  reportStoreDispatchFunction() {
    return setLinkedInPagesAccount;
  }
}
