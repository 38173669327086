import _ from "lodash";

const addCustomMetricsToBaseMetrics = (integrationProvider, metrics, customAccount) => {
  metrics = _.clone(metrics) || [];
  const accountID = getCurrentlyApplicableAccountId(customAccount, integrationProvider);
  const customMetrics = getCustomMetricsFromAccount(accountID, integrationProvider);

  for (var i = 0; i < customMetrics.length; i++) {
    metrics.push({
      value: customMetrics[i].value,
      label: customMetrics[i].label,
    });
  }

  return metrics.sort((a, b) => a.label.localeCompare(b.label));
};

const getCustomMetricsFromAccount = (accountID, integrationProvider) => {
  if (accountID == undefined) {
    return [];
  }

  const integrationAccounts = store.getState().integrationsReducer.integrationsAccountLists[integrationProvider];
  if (integrationAccounts == undefined) {
    return [];
  }

  for (let index = 0; index < integrationAccounts.length; index++) {
    const account = integrationAccounts[index];
    if (account.value === accountID) {
      return account.customMetrics || [];
    }
  }

  return [];
};

const addCustomDimensionsToBaseDimensions = (integrationProvider, dimensions, customAccount) => {
  dimensions = _.clone(dimensions) || [];
  const accountID = getCurrentlyApplicableAccountId(customAccount, integrationProvider);
  const customDimensions = getCustomDimensionsFromAccount(accountID, integrationProvider);

  for (var i = 0; i < customDimensions.length; i++) {
    dimensions.push({
      value: customDimensions[i].value,
      label: customDimensions[i].label,
    });
  }

  return dimensions.sort((a, b) => a.label.localeCompare(b.label));
};

const getCustomDimensionsFromAccount = (accountID, integrationProvider) => {
  if (accountID == undefined) {
    return [];
  }

  const integrationAccounts = store.getState().integrationsReducer.integrationsAccountLists[integrationProvider];
  if (integrationAccounts == undefined) {
    return [];
  }

  for (let index = 0; index < integrationAccounts.length; index++) {
    const account = integrationAccounts[index];
    if (account.value === accountID) {
      return account.customDimensions || [];
    }
  }

  return [];
};

const getCurrentlyApplicableAccountId = (customAccount, integrationProvider) => {
  if (customAccount) {
    return customAccount;
  } else {
    return store.getState().reportFormReducer.integration_account_ids[integrationProvider];
  }
};

export { addCustomDimensionsToBaseDimensions, addCustomMetricsToBaseMetrics };
