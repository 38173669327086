import React from "react";
import Select from "react-select";
import Button from "../../legacy-components/generic/button.js.jsx";
import ButtonWithIcon from "../../legacy-components/generic/button_with_icon.js";
import WidgetFilterEditor from "../../legacy-components/canvas-widgets/widget_filter_editor";
import FlashMessage from "../../legacy-components/flash_message.js";
import {
  addCustomDimensionsToBaseDimensions,
  addCustomMetricsToBaseMetrics,
} from "./../../integrations/custom_definitions.js";
import DatePickerComponent from "../../legacy-components/DateRangePicker.jsx";

const ListEditor = ({
  name,
  dimensions,
  sortByOptions,
  metricsList,
  noSecondaryMetric = [],
  offerPreviousPeriodComparison,
  shortFilterVariable = false,
  multilineFilter = false,
  ...props // Generic props passed from the parent editor component
}) => {
  const offerSecondaryMetric =
    !noSecondaryMetric.some((x) => props.dimension == x) && !props.secondary_column_is_previous_period_comparison;
  // For readability. The secondary metric is only offered if the selected
  // dimension isn't a match in the noSecondaryMetric list.

  metricsList = addCustomMetricsToBaseMetrics(
    props.oAuthProviderName,
    metricsList,
    store.getState().widgetReducer.editorConfig.custom_account
  );

  dimensions = addCustomDimensionsToBaseDimensions(
    props.oAuthProviderName,
    dimensions,
    store.getState().widgetReducer.editorConfig.custom_account
  );

  const filterDimensionList = addCustomDimensionsToBaseDimensions(
    props.oAuthProviderName,
    props.filterDimensionList,
    store.getState().widgetReducer.editorConfig.custom_account
  );

  let sortBy = props.sort_by || "metric[0]";
  let sortByAscDesc = props.sort_by_asc_desc || "DESC";

  const enbaleWidgetDateFeature = store.getState().reportFormReducer.enbale_widget_date_feature;
  
  return (
    <div className="widget widget-editor">
      <h1 className="tw-large-header mb-4">{name}</h1>
      <div className="my-4">
        <label className="tw-label">Widget Label</label>
        <input className="tw-field mb-4 mw-key-label" value={props.label} onChange={props.updateLabel} />
      </div>
      <div className="md:flex flex-row">
        <div className="flex-1 md:mr-2">
          <div className="mb-4">
            <label className="tw-label">What do you want to list? (i.e., dimension)</label>
            <Select
              name="dimension"
              className="mw-key-dimension"
              value={props.dimension}
              options={dimensions}
              onChange={props.updateDimension}
            />
          </div>
          <div className="field">
            <label className="tw-label">Dimension Label</label>
            <input
              className="tw-field mb-4 mw-key-dimension_label"
              value={props.dimension_label || ""}
              onChange={props.updateDimensionLabel}
            />
          </div>

          <div className="field">
            <label className="tw-label">Limit</label>
            <input className="tw-field mb-4 mw-key-limit" value={props.limit} onChange={props.updateLimit} />
          </div>

          {Array.isArray(sortByOptions) && sortByOptions.length > 0 && (
            <div className="field">
              <label className="tw-label">Sort by</label>
              <div className="flex">
                <Select
                  name="sort_by"
                  className="w-1/2"
                  value={sortBy}
                  options={sortByOptions}
                  onChange={props.updateSortBy}
                />
                <Select
                  name="sort_by_asc_desc"
                  className="ml-4 w-2/5"
                  value={sortByAscDesc}
                  options={[
                    { label: "Ascending", value: "ASC" },
                    { label: "Descending", value: "DESC" },
                  ]}
                  onChange={props.updateSortByAscDesc}
                />
              </div>
            </div>
          )}
        </div>

        <div className="mt-8 md:mt-0 md:ml-2 flex-1">
          <div>
            <div>
              <div className="mb-4">
                <label className="tw-label">Metric</label>
                <p className="text-sm italic">This first metric will determine the order of the list.</p>
                <Select
                  name="metric_name"
                  className="mw-key-metric_name"
                  value={props.metric_name}
                  options={metricsList}
                  onChange={props.updateMetric}
                />
              </div>

              <div className="field">
                <label className="tw-label">Metric Label</label>
                <input
                  className="tw-field mb-4 mw-key-metric_label"
                  value={props.metric_label || ""}
                  onChange={props.updateMetricLabel}
                />
              </div>
              {offerPreviousPeriodComparison && (
                <>
                  <label className="tw-label mb-4">
                    <input
                      type="checkbox"
                      className="mr-2 tw-checkbox mw-key-secondary_column_is_previous_period_comparison"
                      checked={props.secondary_column_is_previous_period_comparison || false}
                      onChange={props.updateComparisonColumnName}
                    />
                    Add comparison to previous period
                  </label>
                  {props.secondary_column_is_previous_period_comparison && (
                    <div className="field">
                      <label className="tw-label">Comparison column name</label>
                      <input
                        className="tw-field mb-2 mw-key-comparison_column_label"
                        value={props.comparison_column_label || ""}
                        onChange={props.updateComparisonColumnLabel}
                      />
                      <p className="text-xs text-gray-700 mb-4">
                        Defaults to "Previous Period" in English, and "Période Précédente" in French. Leave blank to
                        keep the default value.
                      </p>
                    </div>
                  )}
                </>
              )}
              {offerSecondaryMetric && (
                <div>
                  <div className="mb-4">
                    <label className="tw-label">Secondary Metric</label>
                    <Select
                      name="secondary_metric_name"
                      className="mw-key-secondary_metric_name"
                      value={props.secondary_metric_name}
                      options={metricsList}
                      onChange={props.updateSecondaryMetric}
                    />
                  </div>
                  <div className="field">
                    <label className="tw-label">Secondary Metric Label</label>
                    <input
                      className="tw-field mb-4 mw-key-secondary_metric_label"
                      value={props.secondary_metric_label || ""}
                      onChange={props.updateSecondaryLabel}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="tw-label">Third Metric</label>
                    <Select
                      name="third_metric_name"
                      className="mw-key-third_metric_name"
                      value={props.third_metric_name}
                      options={metricsList}
                      onChange={props.updateThirdMetric}
                    />
                  </div>
                  <div className="field">
                    <label className="tw-label">Third Metric Label</label>
                    <input
                      className="tw-field mb-4 mw-key-third_metric_label"
                      value={props.third_metric_label || ""}
                      onChange={props.updateThirdLabel}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {
        enbaleWidgetDateFeature &&
        <DatePickerComponent
            updateFiltersEnabled={props.updateFiltersEnabled}
            updateDateFilter={props.updateDateFilter}
            selectedDate={store.getState().widgetReducer.editorConfig.date_filter_by_widget}
            lang={props.language}  
        />
      }

      {
        // `props.filtersEnabled` is undefined for older integrations, so we need to check if it's undefined or true
        // And we need to also make sure we have operators and dimensions to filter on. Then and only then we
        // enable the filter stuff.
        (props.filtersEnabled == undefined || props.filtersEnabled === true) &&
          Array.isArray(props.filterOperators) &&
          Array.isArray(filterDimensionList) && (
            <WidgetFilterEditor
              operators={props.filterOperators}
              dimensionList={filterDimensionList}
              hasORenabled={props.filterHasORenabled}
              multilineFilter={multilineFilter}
              shortFilterVariable={shortFilterVariable}
            />
          )
      }

      {props.customAccountSelector}

      <FlashMessage />

      <div className="flex flex-row mt-4">
        <ButtonWithIcon
          onClick={props.saveAndClose}
          text="Save &amp; close"
          icon="fa-check"
          extraClasses="tw-submit-button"
        />
        &nbsp;
        <Button onClick={props.closeWidgetEditModal} text="Close without saving" />
        &nbsp;
        <ButtonWithIcon
          onClick={props.deleteWidget}
          icon="fa-times"
          extraClasses="bg-red-100 text-red-700 hover:bg-red-200 ml-auto"
          text="Delete this widget"
        />
      </div>
    </div>
  );
};

export default ListEditor;
