import React from 'react'
import AccountSelector from "../integrations/account_selector"
import FacebookPagesAccountSelector from "./account_selectors/facebook_pages_account_selector"
import GoogleAnalyticsProfiles from "./google_analytics_profiles.js"
import LinkedInAdsAccountSelector from '../integrations/linked_in_ads/account_selector.jsx'
import LinkedInPagesAccountSelector from '../integrations/linked_in_pages/account_selector.jsx'
import MailchimpAccountSelector from './account_selectors/mailchimp_account_selector.jsx'

const AccountSelectorsList = () => {
  return (
      <>
        <AccountSelector
            integrationProviderName="facebookads"
            accountSelectorLabel="Facebook & Instagram Ads account"
            accountListFunction={integrationStore.findIntegrationByOAuthProviderName("facebookads").accountList}
        />
        <FacebookPagesAccountSelector tabIndex="3" />
        <AccountSelector
            integrationProviderName="googleads"
            accountSelectorLabel="Google Ads account"
            accountListFunction={integrationStore.findIntegrationByOAuthProviderName("googleads").accountList}
        />
        <GoogleAnalyticsProfiles tabIndex="2" form="report" />

        <AccountSelector
            integrationProviderName="ga4"
            accountSelectorLabel="Google Analytics 4 property"
            accountListFunction={integrationStore.findIntegrationByOAuthProviderName("ga4").accountList}
        />

        <AccountSelector
            integrationProviderName="google-business-profile"
            accountSelectorLabel="Google Business Profile location"
            accountListFunction={
                integrationStore.findIntegrationByOAuthProviderName("google-business-profile").accountList
            }
        />

        <AccountSelector
            integrationProviderName="google-search-console"
            accountSelectorLabel="Google Search Console site"
            accountListFunction={
                integrationStore.findIntegrationByOAuthProviderName("google-search-console").accountList
            }
        />

        <AccountSelector
            integrationProviderName="instagram"
            accountSelectorLabel="Instagram business account"
            accountListFunction={integrationStore.findIntegrationByOAuthProviderName("instagram").accountList}
        />
        <LinkedInAdsAccountSelector tabIndex="3" />
        <LinkedInPagesAccountSelector tabIndex="3" />
        <MailchimpAccountSelector tabIndex="3" />

        <AccountSelector
            integrationProviderName="ms-ads"
            accountSelectorLabel="Microsoft Advertising account"
            accountListFunction={integrationStore.findIntegrationByOAuthProviderName("ms-ads").accountList}
        />
      </>
  )
}

export default AccountSelectorsList