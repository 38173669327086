import React from "react";
import $ from "jquery";
import APIError from "../../helpers/api_error";
import LoadSpinner from "../utility/load_spinner";

class ReportPreviewRenderer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      apiURL: "/api/v1/report_previews",
      currentlyRendering: false,
    };

    // Internal queue stuff
    this.previewRenderQueue = this.previewRenderQueue.bind(this);
    this.previewRenderQueueItems = [];
    this.previewItemsAlreadyRendered = [];
    this.queueInterval = undefined;
  }

  componentDidMount() {
    this.previewRenderQueue().start();

    this.previewRenderQueue().enqueue(
      this.props.renderRequestedAt,
      this.props.businessSettings,
      this.props.settingsAreValid
    );
  }

  componentDidUpdate() {
    this.previewRenderQueue().enqueue(
      this.props.renderRequestedAt,
      this.props.businessSettings,
      this.props.settingsAreValid
    );
  }

  // This manages the whole queuing mechanism, which is based on setInterval looking
  // into a queue (an array) to see if there is a preview to render. We only
  // keep the latest two, to avoid doing tons of calls.
  // We also don't want to preview if there were no changes...changes that are tracked through the
  // React `componentDidUpdate` that enqueues the preview renderings we need to do.
  previewRenderQueue() {
    return {
      start: () => {
        this.queueInterval = setInterval(() => {
          this.previewRenderQueue().executeNext();
        }, 500);
      },
      // The stop is basically just for tests, so we don't have a bunch of setInterval in action.
      // I know. I know, it's sad.
      stop: () => {
        clearInterval(this.queueInterval);
      },
      enqueue: (renderRequestedAt, businessSettings, settingsAreValid) => {
        // If settings are not valid, we just skip this render in the first place.
        if (!settingsAreValid) {
          return;
        }

        const newItem = {
          renderRequestedAt: renderRequestedAt,
          businessSettings: businessSettings,
        };

        // if that request was already rendered
        if (this.previewItemsAlreadyRendered.includes(renderRequestedAt)) {
          return;
        }

        // if that request is already in the queue, we don't want to enqueue it again
        if (
          this.previewRenderQueue()
            .items()
            .some((item) => item.renderRequestedAt === renderRequestedAt)
        ) {
          return;
        }

        this.previewRenderQueueItems.push(newItem);

        if (this.previewRenderQueue().size() > 2) {
          // We never want more than two render in the queue, and we want to keep the latest,
          // so we remove the oldest before adding to the queue
          this.previewRenderQueue().dequeue();
        }
      },
      dequeue: () => {
        this.previewRenderQueueItems.shift();
      },
      executeNext: () => {
        // we first want to make sure no other rendering is currently active.
        if (this.state.currentlyRendering === false) {
          // And that there is something in the queue (i.e., that a preview is needed)
          if (this.previewRenderQueue().size() > 0) {
            // We then block the rendering process just for the next item
            this.setState({ currentlyRendering: true });

            let nextItem = this.previewRenderQueue().items()[0];
            if (this.previewItemsAlreadyRendered.includes(nextItem.renderRequestedAt)) {
              this.setState({ currentlyRendering: false });
              return;
            }

            // We want to avoid doing the same previews...the renderRequestedAt is good enough
            // to know if we need a new preview...it's a preview, not a bank transaction, after all.
            this.previewItemsAlreadyRendered.push(nextItem.renderRequestedAt);
            let businessSettings = nextItem.businessSettings;

            // We remove it from the queue, then enqueue it.
            this.previewRenderQueue().dequeue();
            this.renderPreview(businessSettings);
          }
        }
      },
      doneRendering: () => {
        this.setState({ currentlyRendering: false });
      },
      size: () => {
        return this.previewRenderQueue().items().length;
      },
      items: () => {
        return this.previewRenderQueueItems;
      },
    };
  }

  renderPreview(businessSettings) {
    $.ajax({
      url: this.state.apiURL,
      dataType: "json",
      method: "POST",
      data: businessSettings,
      cache: false,
      success: function (data) {
        this.setIframeContent(data.preview, data.image_parts);
        this.previewRenderQueue().doneRendering();
      }.bind(this),
      error: function (xhr, status, err) {
        this.previewRenderQueue().doneRendering();

        this.setIframeContent(
          '<html><body><p style="text-align: center;">There was an error while loading the preview.</p></body></html>',
          []
        );

        new APIError("SampleReport", xhr, status, err);
      }.bind(this),
    });
  }

  setIframeContent(htmlContent, imageParts) {
    for (let index = 0; index < imageParts.length; index++) {
      const image = imageParts[index];

      let originalSrc = "cid:" + image["cid"];
      let binary = "data:image/png;base64," + image["binary"];
      htmlContent = htmlContent.replace(originalSrc, binary);
    }

    this.refs.iframe.srcdoc = htmlContent;
  }

  render() {
    return (
      <div id="previewContainer" className="w-full relative flex flex-col flex-1">
        <h3 className="tw-medium-header my-4">Live preview of a sample report email.</h3>
        {this.state.currentlyRendering === true && <LoadSpinner extraClasses="absolute" />}

        <iframe
          src="about:blank"
          className="w-full flex-1 border border-gray-400 rounded-lg"
          ref="iframe"
          style={{ minHeight: "75vh" }}
        />
      </div>
    );
  }
}

export default ReportPreviewRenderer;
