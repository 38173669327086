import axios from "axios";
import { appsignal } from "../../appsignal";

export default class GoogleSheetsApi {
  constructor() {
    this.pickerApiLoaded = false;
    this.oauthToken = undefined;
    this.developerKey = undefined;
    this.clientID = undefined;
    this.appID = undefined;
    this.callback = undefined;
  }

  fetchTokens(googleSheetsProviderId) {
    const promise = axios.get("/api/v1/tokens/google-sheets");
    return promise
      .then(
        function (response) {
          if (response.data.error == "token_revoked") {
            store.dispatch(
              setFlashMessage("There was an error:", [
                "Your Google Sheets account is disconnected. Please reconnect it from the Integrations page (in the left menu).",
              ])
            );
            return false;
          }

          let token = "";
          response.data.map((resp) => {
            if (resp.id == googleSheetsProviderId) {
              token = resp.access_token;
            }
          });
          return token;
        }.bind(this)
      )
      .catch(function (error) {
        store.dispatch(
          setFlashMessage("There was an error:", ["Unknown error while trying to access your Google Sheet account."])
        );

        const e = { name: "GSheet Picker - Fetching token", message: error.toJSON().message };
        Bugsnag.notify(e);
        appsignal.sendError(e);
        return false;
      });
  }

  getListSheets(spreadsheetId, oauthToken) {
    const promise = axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`, {
      headers: { Authorization: `Bearer ${oauthToken}` },
    });
    return promise.then((response) => response.data);
  }

  // It's returning all headers, no matter what the content for those is (date range, dimension or metric)
  getlistColumns(spreadsheetId, sheetTitle, googleSheetsProviderId) {
    const promise = this.fetchTokens(googleSheetsProviderId).then((token) => {
      return axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`, {
        params: {
          // only line 1 and 2 for header and data type information
          ranges: `'${sheetTitle}'!A1:ZZ2`,

          fields: "sheets(data(rowData(values(userEnteredFormat/numberFormat,userEnteredValue)),startColumn,startRow))",
        },
        headers: { Authorization: `Bearer ${token}` },
      });
    });
    return promise.then(function (response) {
      const dataBasic = response.data.sheets[0].data[0].rowData;
      if (dataBasic[0] !== undefined && dataBasic[1] !== undefined) {
        return [dataBasic[0].values, dataBasic[1].values];
      } else {
        return [];
      }
    });
  }
}
