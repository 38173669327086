import _ from "lodash";
import { getRandomInt } from "../helpers/utils";
import { isElementInViewport } from "../helpers/is_element_in_viewport";
import WidgetPreviewFetcher from "../helpers/widget_preview/widget_preview_fetcher";

var widgetReducerInitialState = {
  name: "Canvas",
  gaMetricsForCanvas: [],
  mailchimpMetricsForCanvas: [],
  fbPagesMetricsForCanvas: [],
  instagramMetricsForCanvas: [],
  googleAdsMetricsForCanvas: [],
  linkedInMetricsForCanvas: [],
  linkedInPagesMetricsForCanvas: [],
  fbAdsMetricsForCanvas: [],
  fbAdsDimensionsForCanvas: [],
  integrations: [],
  widgetLayouts: [],
  widgetConfigs: {},
  editorConfig: {},
  canvasDroppingItem: {},
  editorWidgetID: -1,
};

function arrayWithout(arr, values) {
  var isArray = function (canBeArray) {
    if (Array.isArray) {
      return Array.isArray(canBeArray);
    }
    return Object.prototype.toString.call(canBeArray) === "[object Array]";
  };

  var excludedValues = isArray(values) ? values : [].slice.call(arguments, 1);

  for (var i = arr.length - 1; i >= 0; i--) {
    if (excludedValues.indexOf(arr[i]) > -1) {
      arr.splice(i, 1);
    }
  }

  return arr;
}

var findNextSmallWidthWidgetSpot = function (widgetLayout) {
  widgetLayout = _.clone(widgetLayout);

  var y = 0,
    x = 0,
    addExtraLine = false;
  var lines = {};
  for (var p = 0; p < widgetLayout.length; p++) {
    var lineNoAsKey = widgetLayout[p].y + "";
    if (!lines[lineNoAsKey]) {
      lines[lineNoAsKey] = [0, 1, 2];
      if (widgetLayout[p].w == 3) {
        lines[lineNoAsKey] = [];
      }
    }
    lines[lineNoAsKey] = arrayWithout(lines[lineNoAsKey], widgetLayout[p].x);
    if (widgetLayout[p].y > y) {
      y = widgetLayout[p].y;
      addExtraLine = true;
    }
  }
  var lineKeys = Object.keys(lines);
  for (var j = 0; j < lineKeys.length; j++) {
    var lineSpaces = lines[lineKeys[j]];
    if (lineSpaces.length) {
      x = lineSpaces[0];
      y = lineKeys[j] * 1;
      addExtraLine = false;
      break;
    }
  }
  if (addExtraLine) {
    y++;
  }
  return { x: x, y: y };
};

var findNextLargeWidgetSpot = function (widgetLayout) {
  widgetLayout = _.clone(widgetLayout);

  var y = 0;
  for (var i = 0; i < widgetLayout.length; i++) {
    if (widgetLayout[i].y > y) {
      y = widgetLayout[i].y;
    }
  }

  y++;

  return { x: 0, y: y };
};

function findSpaceInCanvas(widgetName, currentLayouts) {
  var currentWidgetLayouts = _.clone(currentLayouts);

  // Is it the integration store? Otherwise, it's legacy, and the next switch/case will handle it.
  var widget = window.integrationStore.getWidgetByName(widgetName);
  if (widget) {
    switch (widget.width()) {
      case "small":
        return findNextSmallWidthWidgetSpot(currentWidgetLayouts);
      case "large":
        return findNextLargeWidgetSpot(currentWidgetLayouts);
    }
  }

  let layoutWidget = window.layoutWidgetStore.getWidgetByName(widgetName);
  if (layoutWidget) {
    switch (layoutWidget.width()) {
      case "small":
        return findNextSmallWidthWidgetSpot(currentWidgetLayouts);
      case "large":
        return findNextLargeWidgetSpot(currentWidgetLayouts);
    }
  }

  // Legacy widgets
  switch (widgetName) {
    case "Google Search Console Single Metric":
      return findNextSmallWidthWidgetSpot(currentWidgetLayouts);
    case "Google Search Console List":
      return findNextLargeWidgetSpot(currentWidgetLayouts);
    default:
      break;
  }
}

var smallLayout = function (id, nextAvailableSpace, height) {
  if (height === undefined) {
    height = 2;
  }

  return {
    i: id,
    x: nextAvailableSpace.x,
    y: nextAvailableSpace.y,
    w: 1,
    h: height,
    minW: 1,
    maxW: 1,
    minH: height,
    maxH: height,
  };
};

var largeLayout = function (id, nextAvailableSpace, height) {
  if (height === undefined) {
    height = 2;
  }

  return {
    i: id,
    x: nextAvailableSpace.x,
    y: nextAvailableSpace.y,
    w: 3,
    h: height,
    minW: 3,
    maxW: 3,
    minH: height,
    maxH: height,
  };
};

function getNewWidgetLayoutFor(widgetName, id, currentLayouts) {
  currentLayouts = _.clone(currentLayouts);

  var nextAvailableSpace = findSpaceInCanvas(widgetName, currentLayouts);
  // Is it the integration store? Otherwise, it's legacy, and the next switch/case will handle it.
  var widget = window.integrationStore.getWidgetByName(widgetName);
  if (widget) {
    switch (widget.width()) {
      case "small":
        return smallLayout(id, nextAvailableSpace);
      case "large":
        return largeLayout(id, nextAvailableSpace);
    }
  }

  let layoutWidget = window.layoutWidgetStore.getWidgetByName(widgetName);
  if (layoutWidget) {
    switch (layoutWidget.width()) {
      case "small":
        return smallLayout(id, nextAvailableSpace, layoutWidget.height());
      case "large":
        return largeLayout(id, nextAvailableSpace, layoutWidget.height());
    }
  }
}

function getNewWidgetConfigFor(widgetName, chartType) {
  let newConfig;

  // Is it the integration store? Otherwise, it's legacy, and the next switch/case will handle it.
  let widget = window.integrationStore.getWidgetByName(widgetName);
  if (widget) {
    return widget.config(chartType);
  }

  let layoutWidget = window.layoutWidgetStore.getWidgetByName(widgetName);
  if (layoutWidget) {
    return layoutWidget.config();
  }
  return newConfig;
}

function formatMetricLabel(action, key) {
  return [action.metrics[key].label, action.metrics[key].description]
    .filter((item) => {
      return !!item;
    })
    .join(" -- ");
}

const widgetReducer = function (state, action) {
  state = state || _.clone(widgetReducerInitialState, true);

  switch (action.type) {
    case "GOT_REPORT_CONFIG_DATA":
      return _.assign({}, state, { name: action.name }, action.data);
    case "UPDATE_CANVAS_NAME":
      return _.assign({}, state, { name: action.name });
    case "ADD_WIDGET":
      var stateWithNewWidget = {
        widgetLayouts: _.clone(state.widgetLayouts),
        widgetConfigs: _.assign({}, state.widgetConfigs),
      };
      var newWidgetReferenceId = getRandomInt(400, 999999) + "";
      stateWithNewWidget.widgetLayouts.push(
        getNewWidgetLayoutFor(action.widgetType, newWidgetReferenceId, _.clone(state.widgetLayouts))
      );
      stateWithNewWidget.widgetConfigs[newWidgetReferenceId] = getNewWidgetConfigFor(
        action.widgetType,
        action.chartType
      );

      setTimeout(function () {
        var elmnt = document.getElementById(newWidgetReferenceId);
        if (!isElementInViewport(elmnt)) {
          elmnt.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);

      if (
        !stateWithNewWidget.widgetConfigs[newWidgetReferenceId].widgetType.includes("layout") &&
        !stateWithNewWidget.widgetConfigs[newWidgetReferenceId].widgetType.includes("all-goals-widget")
      ) {
        stateWithNewWidget.editorWidgetID = newWidgetReferenceId;
        stateWithNewWidget.editorConfig = stateWithNewWidget.widgetConfigs[newWidgetReferenceId];
      }
      return _.assign({}, state, stateWithNewWidget);

    case "SET_CANVAS_DROPPING_ITEM":
      var stateWithNewWidget = {
        widgetLayouts: _.clone(action.newFullLayout),
        widgetConfigs: _.clone(state.widgetConfigs),
      };

      // Remove the temporary widget from the drag & drop logic
      stateWithNewWidget.widgetLayouts = stateWithNewWidget.widgetLayouts.filter(
        (layout) => layout.i !== "__dropping-elem__"
      );

      // This is to ensure that it is placed at the right place, otherwise it is always on the line after
      // the line we intended to place it. This is definitely a pretty bad hack, but it works.
      action.layout.y--;

      // Add the new widget
      stateWithNewWidget.widgetLayouts.push(action.layout);
      stateWithNewWidget.widgetLayouts = stateWithNewWidget.widgetLayouts.map((layout) => {
        // This is related to the the other hack above, so that the new widget is properly placed.
        layout.y++;
        return layout;
      });
      stateWithNewWidget.widgetConfigs[action.layout.i] = action.config;

      if (
        !stateWithNewWidget.widgetConfigs[action.layout.i].widgetType.includes("layout") &&
        !stateWithNewWidget.widgetConfigs[action.layout.i].widgetType.includes("all-goals-widget")
      ) {
        stateWithNewWidget.editorWidgetID = action.layout.i;
        stateWithNewWidget.editorConfig = stateWithNewWidget.widgetConfigs[action.layout.i];
      }
      return _.assign({}, state, stateWithNewWidget);

    case "SET_LAYOUT":
      var stateWithNewLayout = {
        widgetLayouts: _.clone(action.newFullLayout),
        widgetConfigs: _.clone(state.widgetConfigs),
      };

      stateWithNewLayout.widgetLayouts = action.newLayout;

      return _.assign({}, state, stateWithNewLayout);

    case "DUPLICATE_WIDGET":
      // Start state
      var stateWithNewWidget = {
        widgetLayouts: _.clone(state.widgetLayouts),
        widgetConfigs: _.assign({}, state.widgetConfigs),
      };

      // Building the new duplicated widget
      var newWidgetConfig = _.cloneDeep(state.widgetConfigs[action.widgetId]);
      var newWidgetLayout = _.cloneDeep(
        _.find(state.widgetLayouts, function (o) {
          return o.i == action.widgetId;
        })
      );

      // Generate its ID
      var newWidgetReferenceId = getRandomInt(400, 999999) + "";

      // Find & set his position (x & y)
      newWidgetLayout.i = newWidgetReferenceId;
      let nextSpot = {};
      switch (newWidgetLayout.w) {
        case 1:
          nextSpot = findNextSmallWidthWidgetSpot(_.clone(state.widgetLayouts));
          break;
        case 3:
          nextSpot = findNextLargeWidgetSpot(_.clone(state.widgetLayouts));
          break;
      }

      newWidgetLayout.x = nextSpot.x;
      newWidgetLayout.y = nextSpot.y;

      // Add it to state
      stateWithNewWidget.widgetLayouts.push(newWidgetLayout);
      stateWithNewWidget.widgetConfigs[newWidgetReferenceId] = newWidgetConfig;

      setTimeout(function () {
        var elmnt = document.getElementById(newWidgetReferenceId);
        if (!isElementInViewport(elmnt)) {
          elmnt.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
      new WidgetPreviewFetcher().fetch(newWidgetReferenceId, stateWithNewWidget.widgetConfigs);
      return _.assign({}, state, stateWithNewWidget);

    case "UPDATE_WIDGET_CONFIG":
      var newStateSelectedWidgets = {};
      newStateSelectedWidgets[action.widgetIndex] = {};
      newStateSelectedWidgets[action.widgetIndex][action.configKey] = action.configValue;
      return _.merge({}, state, {
        widgetConfigs: newStateSelectedWidgets,
      });
    case "UPDATE_EDITOR_WIDGET_CONFIG":
      var newEditorConfigState = _.clone(state.editorConfig);
      newEditorConfigState[action.configKey] = action.configValue;

      return _.assign({}, state, {
        editorConfig: newEditorConfigState,
      });
    case "SAVE_WIDGET_CONFIG_BEFORE_EDIT":
      var editorConfigState = _.clone(state.widgetConfigs[action.widgetIndex]);

      return _.assign({}, state, {
        editorConfig: editorConfigState,
        editorWidgetID: action.widgetIndex,
      });
    case "SAVE_WIDGET_CONFIG_FROM_EDITOR":
      var widgetConfigs = _.clone(state.widgetConfigs);
      var filters = [];
      if (state.editorConfig.filters) {
        filters = state.editorConfig.filters.filter(function (f) {
          return f.selectedDimension !== "" && f.selectedOperator !== "" && f.specifiedValue !== "";
        });
      }
      widgetConfigs[action.widgetIndex] = state.editorConfig;
      widgetConfigs[action.widgetIndex].filters = filters;
      return _.assign({}, state, {
        widgetConfigs: widgetConfigs,
        editorConfig: {},
      });
    case "CLOSE_WIDGET_EDITOR":
      return _.assign({}, state, { editorWidgetID: -1 });
    case "UPDATE_WIDGET_LAYOUT":
      return Object.assign({}, state, {
        widgetLayouts: action.newLayout,
      });
    case "SET_WIDGET_FILTERS":
      return _.assign({}, state, {
        widgetFilters: action.widgetFilters,
      });
    case "SET_MAILCHIMP_METRICS_FOR_CANVAS_CACHE":
      return _.assign({}, state, {
        mailchimpMetricsForCanvas: Object.keys(action.metrics).map(function (key) {
          return {
            value: key,
            label: action.metrics[key].label,
            valid_dimensions: action.metrics[key].valid_dimensions,
          };
        }),
      });
    case "SET_GA_METRICS_FOR_CANVAS_CACHE":
      return _.assign({}, state, {
        gaMetricsForCanvas: Object.keys(action.metrics).map(function (key) {
          return { value: key, label: action.metrics[key] };
        }),
      });
    case "SET_FB_PAGES_METRICS_FOR_CANVAS_CACHE":
      return _.assign({}, state, {
        fbPagesMetricsForCanvas: Object.keys(action.metrics).map(function (key) {
          return {
            value: key,
            label: formatMetricLabel(action, key),
            metricLevel: action.metrics[key].metric_level,
            description: action.metrics[key].description,
          };
        }),
      });
    case "SET_INSTAGRAM_METRICS_FOR_CANVAS_CACHE":
      return _.assign({}, state, {
        instagramMetricsForCanvas: Object.keys(action.metrics).map(function (key) {
          return {
            value: key,
            label: formatMetricLabel(action, key),
            metricLevel: action.metrics[key].metric_level,
            description: action.metrics[key].description,
          };
        }),
      });
    case "SET_GOOGLE_ADS_METRICS_FOR_CANVAS_CACHE":
      return _.assign({}, state, {
        googleAdsMetricsForCanvas: Object.keys(action.metrics).map(function (key) {
          return {
            value: key,
            label: formatMetricLabel(action, key),
            metricLevel: action.metrics[key].metric_level,
            description: action.metrics[key].description,
          };
        }),
      });
    case "SET_LINKEDIN_METRICS_FOR_CANVAS_CACHE":
      return _.assign({}, state, {
        linkedInMetricsForCanvas: Object.keys(action.metrics).map(function (key) {
          return {
            value: key,
            label: formatMetricLabel(action, key),
            metricLevel: action.metrics[key].metric_level,
            description: action.metrics[key].description,
          };
        }),
      });
    case "SET_LINKEDIN_PAGES_METRICS_FOR_CANVAS_CACHE":
      return _.assign({}, state, {
        linkedInPagesMetricsForCanvas: Object.keys(action.metrics).map(function (key) {
          return {
            value: key,
            label: formatMetricLabel(action, key),
            metricLevel: action.metrics[key].metric_level,
            description: action.metrics[key].description,
          };
        }),
      });
    case "SET_FB_ADS_METRICS_FOR_CANVAS_CACHE":
      return _.assign({}, state, {
        fbAdsMetricsForCanvas: Object.keys(action.metrics).map(function (key) {
          return { value: key, label: formatMetricLabel(action, key), description: action.metrics[key].description };
        }),
      });
    case "SET_FB_ADS_DIMENSIONS_FOR_CANVAS_CACHE":
      return _.assign({}, state, {
        fbAdsDimensionsForCanvas: Object.keys(action.dimensions).map(function (key) {
          return { value: key, label: action.dimensions[key].label, description: action.dimensions[key].description };
        }),
      });
    case "SET_INTEGRATIONS":
      return _.assign({}, state, {
        integrations: action.integrations,
      });
    case "REMOVE_WIDGET":
      var newState = { widgetLayouts: [], widgetConfigs: {} };
      newState.widgetLayouts = state.widgetLayouts.filter(function (widgetLayout) {
        return action.id != widgetLayout.i;
      });
      newState.widgetConfigs = _.clone(state.widgetConfigs);
      delete newState.widgetConfigs[action.id];

      return _.assign({}, state, newState);
    default:
      return state;
  }
};

export { widgetReducer, findSpaceInCanvas, getNewWidgetConfigFor, getNewWidgetLayoutFor, widgetReducerInitialState };
