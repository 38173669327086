import React from "react";
import Drawer from "../../utility/drawer";
import WidgetButton from "../../utility/widget_button";
import IntegrationWidgetDrawer from "../../../integrations/integration_widget_drawer";
import _ from "lodash";

export default class AllIntegrationsDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { integrationList: [] };
  }

  componentDidMount() {
    this.setState({ integrationList: store.getState().integrationsReducer.integrationsAccountLists });

    this.storeSubscription = store.subscribe(() => {
      let newIntegrationList = store.getState().integrationsReducer.integrationsAccountLists;
      if (_.isEqual(this.state.integrationList, newIntegrationList)) {
        this.setState({ integrationList: newIntegrationList });
      }
    });
  }

  componentWillUnmount() {
    this.storeSubscription();
  }

  isConnected(integration) {
    const accounts = this.state.integrationList[integration];
    if (accounts === undefined || (Array.isArray(accounts) && accounts.length === 0)) {
      return false;
    }

    return true;
  }

  render() {
    // When the "connected" prop is true, it means we want to list the integrations that are connected.
    // When it's false, we want those that are NOT connected.
    const connectionStateWanted = this.props.connected === true;

    return (
      <>
        {this.isConnected("facebookads") === connectionStateWanted && (
          <IntegrationWidgetDrawer
            oAuthProviderName={"facebookads"}
            gridElement={this.props.gridElement}
            addWidget={this.props.addWidget}
            storeState={this.props.storeState}
          />
        )}

        {this.isConnected("facebook") === connectionStateWanted && (
          <Drawer title="Facebook Pages">
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Single Metric"
              widgetName="Facebook Pages Single Metric"
              icon="Single Metric"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="List"
              widgetName="Facebook Pages List"
              icon="List"
            />

            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Pie"
              widgetName="Facebook Pages Pie Chart"
              icon="Pie Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Doughnut"
              widgetName="Facebook Pages Pie Chart"
              chartType="doughnut"
              icon="Doughnut Chart"
            />

            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Bar"
              widgetName="Facebook Pages Bar Graph"
              icon="Bar Graph"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Line"
              widgetName="Facebook Pages Bar Graph"
              chartType="line"
              icon="Line Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Area"
              widgetName="Facebook Pages Bar Graph"
              chartType="area"
              icon="Area Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Mixed Chart"
              widgetName="Facebook Pages Bar Graph"
              icon="Mixed Chart"
            />
          </Drawer>
        )}
        {this.isConnected("googleads") === connectionStateWanted && (
          <Drawer title="Google Ads">
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Single Metric"
              widgetName="Google Ads Single Metric"
              icon="Single Metric"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="List"
              widgetName="Google Ads List"
              icon="List"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Pie"
              widgetName="Google Ads Pie Chart"
              icon="Pie Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Doughnut"
              widgetName="Google Ads Pie Chart"
              chartType="doughnut"
              icon="Doughnut Chart"
            />

            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Bar"
              widgetName="Google Ads Bar Graph"
              icon="Bar Graph"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Line"
              widgetName="Google Ads Bar Graph"
              chartType="line"
              icon="Line Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Area"
              widgetName="Google Ads Bar Graph"
              chartType="area"
              icon="Area Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Mixed Chart"
              widgetName="Google Ads Bar Graph"
              icon="Mixed Chart"
            />
          </Drawer>
        )}

        {this.isConnected("ga4") === connectionStateWanted && (
          <IntegrationWidgetDrawer
            oAuthProviderName={"ga4"}
            gridElement={this.props.gridElement}
            addWidget={this.props.addWidget}
            storeState={this.props.storeState}
          />
        )}

        {this.isConnected("google") === connectionStateWanted && (
          <Drawer title="Google Analytics (UA 360)">
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Single Metric"
              widgetName="Formatted Metric"
              icon="Single Metric"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Single Metric for AdWord"
              widgetName="Formatted Metric only for Adwords"
              icon="Single Metric"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="List"
              widgetName="Top metric by dimension"
              icon="List"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Pie"
              widgetName="GA Pie Chart"
              chartType="pie"
              icon="Pie Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Doughnut"
              widgetName="GA Pie Chart"
              chartType="doughnut"
              icon="Doughnut Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Bar"
              widgetName="GA Bar Graph"
              icon="Bar Graph"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Line"
              widgetName="GA Bar Graph"
              chartType="line"
              icon="Line Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Area"
              widgetName="GA Bar Graph"
              chartType="area"
              icon="Area Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Mixed Chart"
              widgetName="GA Bar Graph"
              icon="Mixed Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="All Goals With Data (Absolute)"
              widgetName="All Goals With Data (Absolute)"
              icon="Single Metric"
            />
          </Drawer>
        )}

        {this.isConnected("google-business-profile") === connectionStateWanted && (
          <IntegrationWidgetDrawer
            oAuthProviderName={"google-business-profile"}
            gridElement={this.props.gridElement}
            addWidget={this.props.addWidget}
            storeState={this.props.storeState}
          />
        )}

        {this.isConnected("google-search-console") === connectionStateWanted && (
          <Drawer title="Google Search Console">
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Single Metric"
              widgetName="Google Search Console Single Metric"
              icon="Single Metric"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="List"
              widgetName="Google Search Console List"
              icon="List"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Pie"
              widgetName="Google Search Console Pie Chart"
              chartType="pie"
              icon="Pie Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Doughnut"
              widgetName="Google Search Console Pie Chart"
              chartType="doughnut"
              icon="Doughnut Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Bar"
              widgetName="Google Search Console Bar Graph"
              icon="Bar Graph"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Line"
              widgetName="Google Search Console Bar Graph"
              chartType="line"
              icon="Line Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Area"
              widgetName="Google Search Console Bar Graph"
              chartType="area"
              icon="Area Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Mixed Chart"
              widgetName="Google Search Console Bar Graph"
              icon="Mixed Chart"
            />
          </Drawer>
        )}

        {this.isConnected("googlesheets") === connectionStateWanted && (
          <Drawer title="Google Sheets">
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Single Metric"
              widgetName="Google Sheets Single Metric"
              icon="Single Metric"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="List"
              widgetName="Google Sheets List"
              icon="List"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Pie"
              widgetName="Google Sheets Pie Chart"
              chartType="pie"
              icon="Pie Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Doughnut"
              widgetName="Google Sheets Pie Chart"
              chartType="doughnut"
              icon="Doughnut Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Bar"
              widgetName="Google Sheets Bar Graph"
              icon="Bar Graph"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Line"
              widgetName="Google Sheets Bar Graph"
              chartType="line"
              icon="Line Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Area"
              widgetName="Google Sheets Bar Graph"
              chartType="area"
              icon="Area Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Mixed Chart"
              widgetName="Google Sheets Bar Graph"
              icon="Mixed Chart"
            />
          </Drawer>
        )}

        {this.isConnected("instagram") === connectionStateWanted && (
          <IntegrationWidgetDrawer
            oAuthProviderName={"instagram"}
            gridElement={this.props.gridElement}
            addWidget={this.props.addWidget}
            storeState={this.props.storeState}
          />
        )}

        {this.isConnected("linkedin-ads") === connectionStateWanted && (
          <Drawer title="LinkedIn Ads">
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Single Metric"
              widgetName="LinkedIn Ads Single Metric"
              icon="Single Metric"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="List"
              widgetName="LinkedIn Ads List"
              icon="List"
            />

            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Pie"
              widgetName="LinkedIn Ads Pie Chart"
              icon="Pie Chart"
            />

            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Doughnut"
              widgetName="LinkedIn Ads Pie Chart"
              chartType="doughnut"
              icon="Doughnut Chart"
            />

            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Bar"
              widgetName="LinkedIn Ads Bar Graph"
              icon="Bar Graph"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Line"
              widgetName="LinkedIn Ads Bar Graph"
              chartType="line"
              icon="Line Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Area"
              widgetName="LinkedIn Ads Bar Graph"
              chartType="area"
              icon="Area Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Mixed Chart"
              widgetName="LinkedIn Ads Bar Graph"
              icon="Mixed Chart"
            />
          </Drawer>
        )}

        {this.isConnected("linkedin-pages") === connectionStateWanted && (
          <Drawer title="LinkedIn Pages">
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Single Metric"
              widgetName="LinkedIn Pages Single Metric"
              icon="Single Metric"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="List"
              widgetName="LinkedIn Pages List"
              icon="List"
            />

            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Pie"
              widgetName="LinkedIn Pages Pie Chart"
              icon="Pie Chart"
            />

            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Doughnut"
              widgetName="LinkedIn Pages Pie Chart"
              chartType="doughnut"
              icon="Doughnut Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Bar"
              widgetName="LinkedIn Pages Bar Graph"
              icon="Bar Graph"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Line"
              widgetName="LinkedIn Pages Bar Graph"
              chartType="line"
              icon="Line Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Area"
              widgetName="LinkedIn Pages Bar Graph"
              chartType="area"
              icon="Area Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Mixed Chart"
              widgetName="LinkedIn Pages Bar Graph"
              icon="Mixed Chart"
            />
          </Drawer>
        )}

        {this.isConnected("mailchimp") === connectionStateWanted && (
          <Drawer title="Mailchimp">
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Single Metric"
              widgetName="Mailchimp Single Metric"
              icon="Single Metric"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="List"
              widgetName="Mailchimp List"
              icon="List"
            />

            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Pie"
              widgetName="Mailchimp Pie Chart"
              icon="Pie Chart"
            />

            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Doughnut"
              widgetName="Mailchimp Pie Chart"
              chartType="doughnut"
              icon="Doughnut Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Bar"
              widgetName="Mailchimp Bar Graph"
              icon="Bar Graph"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Line"
              widgetName="Mailchimp Bar Graph"
              chartType="line"
              icon="Line Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Area"
              widgetName="Mailchimp Bar Graph"
              chartType="area"
              icon="Area Chart"
            />
            <WidgetButton
              gridElement={this.props.gridElement}
              addWidget={this.props.addWidget}
              storeState={this.props.storeState}
              label="Mixed Chart"
              widgetName="Mailchimp Bar Graph"
              icon="Mixed Chart"
            />
          </Drawer>
        )}

        {this.isConnected("ms-ads") === connectionStateWanted && (
          <IntegrationWidgetDrawer
            oAuthProviderName={"ms-ads"}
            gridElement={this.props.gridElement}
            addWidget={this.props.addWidget}
            storeState={this.props.storeState}
          />
        )}
      </>
    );
  }
}
