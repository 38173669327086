import IntegrationBase from "../integration_base";
import SingleMetric from "./widgets/single_metric/single_metric.js";
import List from "./widgets/list/list.js";
import IntegrationIcon from "./logo/logo.png";
import BarGraph from "./widgets/bar_graph/bar_graph";
import PieChart from "./widgets/pie_chart/pie_chart";

export default class LinkedInAds extends IntegrationBase {
  constructor() {
    super();
    this.registerWidget(new SingleMetric());
    this.registerWidget(new List());
    this.registerWidget(new BarGraph());
    this.registerWidget(new PieChart());
  }

  name() {
    return "LinkedIn Ads";
  }

  iconURL() {
    return IntegrationIcon;
  }

  oAuthProviderName() {
    return "linkedin-ads";
  }

  reducerAccountIDVariableName() {
    return "linkedin_ads_account_id";
  }

  reportStoreDispatchFunction() {
    return setLinkedInAdsAccount;
  }
}
