import React from "react";
import ReactDOM from "react-dom";
import Modal from "./components/modal";
import Cookies from "js-cookie";

class BlockerDetectorModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };
  }

  close() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <Modal
        widthCanBeLarge
        visible={this.state.visible}
        title="Ad Blocker Detected"
        htmlMessage={
          <span>
            <p className="mt-2">
              Just like all analytics and advertising platforms, ad blockers might break things in Metrics Watch.
            </p>

            <p className="mt-2">
              We highly encourage you to disable your ad blocker or white-list <strong>app.metrics.watch</strong> so
              that nothing breaks.
            </p>

            <p className="mt-2">
              <strong>Why?</strong>
            </p>

            <p className="mt-2">
              Ad blockers detect various keywords in URLs, among other things, and will prevent them from working. As we
              use the words "ads" and "analytics" in many areas of our application, things tend to be a bit rocky with
              an ad blocker.
            </p>

            <p className="mt-2">This is why we encourage you to disable it when using Metrics Watch</p>

            <p className="mt-2">
              <strong>How?</strong>
            </p>

            <p className="mt-2">
              Most ad blockers let you disable them just for one domain. You need to disable it for{" "}
              <strong>app.metrics.watch</strong> to ensure that you do not encounter issues due to your ad blockers.
            </p>

            <p className="mt-2">Thanks for understanding!</p>
          </span>
        }
        closeCallback={() => this.close()}
      />
    );
  }
}

class Hit {
  constructor(hasAdBlockerEnabled) {
    // Serializing the navigator object so it can be sent via the API
    let _navigator = {};
    for (let i in navigator) _navigator[i] = navigator[i];

    // Prepping the metadata
    let hitMetadata = {
      url: window.location,
      hasAdBlockerEnabled: hasAdBlockerEnabled,
      navigator: _navigator,
    };

    // sending the HIT
    var xhr = new XMLHttpRequest();
    var url = "/api/v1/hit";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    var data = JSON.stringify({ metadata: hitMetadata });
    xhr.send(data);
  }
}

export default class BlockerDetector {
  constructor() {
    document.addEventListener("DOMContentLoaded", () => {
      try {
        return fetch(
          new Request("https://cdn.segment.com/analytics.js/v1/lla1I8oi9N0GpLUPs5ecgilLHefVoSJ6/analytics.min.js", {
            method: "HEAD",
          })
        )
          .then(
            function (response) {
              this.detectionDone(false);
            }.bind(this)
          )
          .catch(
            function (e) {
              this.detectionDone(true);
            }.bind(this)
          );
      } catch (error) {
        hasAdBlockerEnabled = true;
        this.detectionDone(true);
      }
    });
  }

  detectionDone(hasAdBlockerEnabled) {
    const modalContainer = document.getElementById("blocker-detector-modal");
    if (modalContainer && hasAdBlockerEnabled && Cookies.get("ad-blocker-detected") !== "true") {
      Cookies.set("ad-blocker-detected", "true", { expires: 1 });
      ReactDOM.render(<BlockerDetectorModal />, modalContainer);
    }

    new Hit(hasAdBlockerEnabled);
  }
}
