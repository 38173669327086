import React from "react";
var createReactClass = require("create-react-class");
import $ from "jquery";
import _ from "lodash";
import { reportFormValidation } from "../helpers/report_form_validation";
import APIError from "../helpers/api_error";
import ButtonWithIcon from "./generic/button_with_icon.js";
import FindAndReplaceModal from "./find_replace_modal.js";
import ReportSenderModal from "../components/reports/report_sender_modal";
import ReportSettingList from "../helpers/report_setting_list.js";

const ReportActionButtons = createReactClass({
  getInitialState: function () {
    return {
      showModalSendTest: false,
      showReportSenderModal: false,
      showFindAndReplaceModal: false,
      email: window.MetricsWatch.currentUser.email,
      showGenericAPIError: false,
      modalError: undefined,
    };
  },

  componentDidUpdate(prevProps) {
    if (this.props.showAdHocModal !== prevProps.showAdHocModal) {
      this.setState({ showReportSenderModal: this.props.showAdHocModal });
    }
  },

  apiURL: function () {
    return "/api/v1/reports";
  },

  getRequiredData: function (currentState) {
    var reportData = {
      name: currentState.name,
      interval_monthly: currentState.interval_monthly,
      interval_weekly: currentState.interval_weekly,
      interval_daily: currentState.interval_daily,
      emails_monthly: currentState.emails_monthly,
      emails_weekly: currentState.emails_weekly,
      emails_daily: currentState.emails_daily,
      compare_year_to_year: currentState.monthly_compare_year_to_year,
      weekly_compare_year_to_year: currentState.weekly_compare_year_to_year,
      compare_daily_options: currentState.compare_daily_options,
      day_of_week: currentState.day_of_week,
      language: currentState.language,
      always_send_preview_first: currentState.always_send_preview_first,
      integration_account_ids: store.getState().reportFormReducer.integration_account_ids,
      google_analytics_profile_id: store.getState().watcherFormReducer.google_analytics_profile_id,
      facebook_pages_id: currentState.facebook_pages_id,
      instagram_account_id: currentState.instagram_account_id,
      mailchimp_list_id: currentState.mailchimp_list_id,
      linkedin_ads_account_id: currentState.linkedin_ads_account_id,
      linkedin_pages_account_id: currentState.linkedin_pages_account_id,
      widgets: currentState.widgets,
      custom_variables: currentState.custom_variables,
    };

    return reportData;
  },

  saveClicked: function (event) {
    // This is being tested in the watcher/alert save buttons too.
    if (event) {
      event.preventDefault();
    }
    this.save();
  },

  getCurrentState: function () {
    var currentState = _.clone(store.getState().reportFormReducer);
    return currentState;
  },

  isValid: function () {
    let currentState = this.getCurrentState();
    let v = reportFormValidation.validate(this.getRequiredData(currentState));
    if (v.errors.length > 0) {
      return false;
    }

    return true;
  },

  fetchReportAlerts: async function (reportID) {
    const url = `/api/v1/reports/${reportID}/report_alerts`;
    try {
      const state = {warningText:"",warnings: []}
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        cache: 'no-cache',
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const payload = await response.json();
      
      state.warnings = payload.warnings || [];
      state.warningText = payload.warningText || ""
      store.dispatch(setFlashMessageWarning ( state.warningText, state.warnings, "Schedule"));
    } catch (err) {
      console.log(err)
    }
  },

  save: async function () {
    let currentState = this.getCurrentState();
    let reportID = store.getState().reportFormReducer.reportID
    if (!this.isValid()) {
      this.slowScrollToTop();
      return; // do not save if invalid
    }

    if (reportID) {
      store.dispatch(setReportDraft(false));
      await this.updateToAPI(currentState);
     
    } else {
      store.dispatch(setReportDraft(false));
      await this.sendToAPI(currentState);
    }
  },

  stateToAPIParams: function (state) {
    var widgetLayouts = store.getState().widgetReducer.widgetLayouts;
    var widgetConfigs = store.getState().widgetReducer.widgetConfigs;
    let widgets = reportFormValidation.formattedWidgetsJSON(widgetLayouts, widgetConfigs);

    let dataForAPI = {
      widgets: widgets,
      integration_account_ids: store.getState().reportFormReducer.integration_account_ids,
      google_analytics_profile_id: store.getState().watcherFormReducer.google_analytics_profile_id,
      facebook_pages_id: state.facebook_pages_id,
      mailchimp_list_id: state.mailchimp_list_id,
      linkedin_ads_account_id: state.linkedin_ads_account_id,
      linkedin_pages_account_id: state.linkedin_pages_account_id,
      name: state.name,
      message_monthly: state.message_monthly,
      message_weekly: state.message_weekly,
      message_daily: state.message_daily,
      emails_monthly: state.emails_monthly,
      emails_weekly: state.emails_weekly,
      emails_daily: state.emails_daily,
      subject_monthly: state.subject_monthly,
      subject_weekly: state.subject_weekly,
      subject_daily: state.subject_daily,
      logo_url: state.logo_url,
      preview_recipient: state.preview_recipient,
      interval_monthly: state.interval_monthly,
      interval_weekly: state.interval_weekly,
      interval_daily: state.interval_daily,
      compare_year_to_year: state.monthly_compare_year_to_year,
      weekly_compare_year_to_year: state.weekly_compare_year_to_year,
      compare_daily_options: state.compare_daily_options,
      day_of_week: state.day_of_week,
      lang: state.language,
      always_send_preview_first: state.always_send_preview_first,
      custom_variables: state.custom_variables,
      ppc_markup_enabled: store.getState().ppcMarkupReducer.ppcMarkupEnabled,
      ppc_markup: {
        facebook_ads: store.getState().ppcMarkupReducer.facebookAds,
        google_ads: store.getState().ppcMarkupReducer.googleAds,
        linkedin_ads: store.getState().ppcMarkupReducer.linkedInAds,
      },
      single_metric_comparison_disabled: state.single_metric_comparison_disabled,
      shared: state.shared,
      include_public_link_in_email: state.include_public_link_in_email,
    };

    for (let index = 0; index < ReportSettingList.length; index++) {
      const settingName = ReportSettingList[index];
      dataForAPI[settingName] = state[settingName];
    }

    return dataForAPI;
  },

  sendToAPI: function (state) {
    var destination = this.apiURL();
    var apiParams = this.stateToAPIParams(state);
    $.ajax({
      url: destination,
      dataType: "json",
      method: "POST",
      data: apiParams,
      success: function (data) {
        store.dispatch(setFlashMessage("Report successfully created!", []));
        document.location.href = "/reports/" + data.data.id + "/edit";
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("ReportCreate", xhr, status, err);
      }.bind(this),
    });
  },

  slowScrollToTop: function () {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    $("main").animate({ scrollTop: 0 }, "slow");
  },

  updateToAPI: function (state) {
    var destination = this.apiURL();
    var apiParams = this.stateToAPIParams(state);

    $.ajax({
      url: destination + "/" + state.reportID,
      dataType: "json",
      method: "PUT",
      data: this.stateToAPIParams(state),
      success: async function (data) {
        
        this.slowScrollToTop();
        let reportData = this.getCurrentState();
        if (!reportData.interval_monthly && !reportData.interval_weekly && !reportData.interval_daily) {
          store.dispatch(
            setFlashMessage(
              "IMPORTANT: your report has no schedule configured. The report was successfully updated, but it will NOT get sent automatically!",
              []
            )
          );

        } else {
          store.dispatch(setFlashMessage("Report successfully updated!", []));
        }
        await this.fetchReportAlerts(state.reportID)
      }.bind(this),
      error: function (xhr, status, err) {
        this.slowScrollToTop();
        store.dispatch(setFlashMessage("Report could not be saved.", ["Unknown error"]));
        this.setState({
          modalError: "Report could not be saved due to an unknown error.",
        });

        new APIError("ReportUpdate", xhr, status, err);
      }.bind(this),
    });
  },

  resetForm: function () {
    store.dispatch(setReportName(""));
    store.dispatch(setReportMessageMonthly(""));
    store.dispatch(setReportMessageWeekly(""));
    store.dispatch(setReportMessageDaily(""));
    store.dispatch(setReportSubjectMonthly(""));
    store.dispatch(setReportSubjectWeekly(""));
    store.dispatch(setReportSubjectDaily(""));
    store.dispatch(setReportCanvaId(""));
    store.dispatch(setLogoUrl(""));
    store.dispatch(setReportIntervalMonthly(false));
    store.dispatch(setReportIntervalWeekly(false));
    store.dispatch(setReportIntervalDaily(false));
    store.dispatch(setReportDOW(""));
    store.dispatch(setReportLanguage("en"));
    store.dispatch(selectGoogleAnalyticsProfile(undefined));
  },

  render: function () {
    return (
      <div className="actions" id="reportActions">
        <div className="bottom-action-bar">
          <ButtonWithIcon
            text="Save"
            icon="fa-check"
            extraClasses="tw-submit-button mr-2 mt-4 xl:mt-0"
            onClick={this.saveClicked}
          />
          <ButtonWithIcon
            text="Save &amp; send ad-hoc report"
            icon="fa-envelope"
            extraClasses="mr-2 mt-4 xl:mt-0"
            onClick={this.saveAndShowReportSenderModal}
          />
          <ButtonWithIcon
            text="Find &amp; replace in filters"
            icon="fa-search"
            extraClasses={"mt-4 xl:mt-0"}
            onClick={this.openFindAndReplaceModal}
          />
          <FindAndReplaceModal modalOpen={this.state.showFindAndReplaceModal} closeCallback={this.closeModal} />

          <ReportSenderModal
            reportID={store.getState().reportFormReducer.reportID}
            modalOpen={this.state.showReportSenderModal}
            closeCallback={this.closeModal}
          />
        </div>
      </div>
    );
  },

  // SEND WITH CUSTOM DATES
  showReportSenderModal: function () {
    this.setState({ showReportSenderModal: true });
  },

  saveAndShowReportSenderModal: function () {
    if (!this.isValid(true)) {
      this.slowScrollToTop();
      return; // do not show modal if invalid
    }

    this.save();

    this.showReportSenderModal();
  },

  closeModal: function () {
    this.setState({
      showGenericAPIError: false,
      showModalSendTest: false,
      showFindAndReplaceModal: false,
      showReportSenderModal: false,
    });
  },

  openFindAndReplaceModal: function () {
    this.setState({ showFindAndReplaceModal: true });
  },
});

export default ReportActionButtons;
