// It is only called if the live builder is disabled

import React, { memo, useState } from "react";
import OldPreviewParts from "./preview-widget-parts/old-preview-widget-part";
import Chart from "./preview-widget-parts/chart";
import "./../../helpers/string/capitalize";

import {
  coloredMixedData,
  coloredRatioData,
  coloredTimeData,
  coloredAreaData,
} from "./preview-widget-parts/sample-graph-datasets";

// Random preview utilities
const sometimes = () => Math.random() < 0.8;
const randomNumberString = () => {
  const numberString = "123456789";
  const length = Math.floor(3 + Math.random() * 2);
  const start = Math.floor(Math.random() * numberString.length);
  return numberString.slice(start, start + length);
};

const defaultTheme = {
  positive: "#078A5C",
  negative: "#AD2F1E",
  neutral: "#122540",
  graph1: "#9FCCDB",
  graph2: "#69B0C0",
  graph3: "#3F7EAD",
  graph4: "#2A4F96",
  graph5: "#2E1140",
  graph6: "#825562",
};

// Props for chart previews
const chartProps = {
  className: "mx-auto max-w-full h-auto",
  width: 300,
  height: 340,
};

const chartOptions = {
  responsive: false,
  interaction: { mode: false },
  animation: { duration: 0 },
};

const OldPreviewWidget = ({
  label,
  providerName,
  metricType,
  onLabelChange,
  fullWidth,
  dimension_label,
  secondary_metric_label,
  third_metric_label,
  secondary_column_is_previous_period_comparison,
  comparison_column_label,
  metric_label,
  indexAxis,
  chartType,
  secondaryMetricEnabled,
  secondaryChartType,
  theme = defaultTheme,
}) => {
  // Full set of random preview data
  // We're keeping this as state to minimize
  // re-computing on render
  const [randomData] = useState({
    up: sometimes(),
    number: randomNumberString(),
    ratio: coloredRatioData(theme),
    time: coloredTimeData(theme),
    area: coloredAreaData(theme),
    mixed: coloredMixedData(theme, chartType, secondaryChartType),
  });

  const color = metricType == "Single Metric" ? (randomData.up ? theme.positive : theme.negative) : theme.neutral;

  let UpperPreview = false;
  let LowerPreview = false;

  let previewType = chartType || metricType;
  if (secondaryMetricEnabled) {
    previewType = "mixed";
  }

  switch (previewType) {
    case "Single Metric":
      UpperPreview = OldPreviewParts.SingleMetricUpper;
      LowerPreview = OldPreviewParts.SingleMetricLower;
      break;

    case "List":
      LowerPreview = OldPreviewParts.ListPreview;
      break;

    case "Pie Chart":
    case "pie":
      LowerPreview = () => <Chart type="pie" data={randomData.ratio} options={chartOptions} {...chartProps} />;
      break;

    case "Doughnut Chart":
    case "doughnut":
      LowerPreview = () => <Chart type="doughnut" data={randomData.ratio} options={chartOptions} {...chartProps} />;
      break;

    case "Bar Graph":
    case "bar":
      LowerPreview = () => (
        <Chart
          type="bar"
          data={randomData.time}
          options={{
            ...chartOptions,
            indexAxis,
            plugins: { legend: false },
            scales: { x: { grid: { display: false } } },
          }}
          {...chartProps}
        />
      );
      break;

    case "line":
      LowerPreview = () => (
        <Chart
          type="line"
          data={randomData.time}
          options={{
            ...chartOptions,
            indexAxis,
            plugins: { legend: false },
            scales: { x: { grid: { display: false } } },
          }}
          {...chartProps}
        />
      );
      break;
    case "area":
      LowerPreview = () => (
        <Chart
          type="line"
          data={randomData.area}
          options={{
            ...chartOptions,
            indexAxis,
            plugins: { legend: false },
            scales: { x: { grid: { display: false } } },
          }}
          {...chartProps}
        />
      );
      break;

    case "mixed":
      LowerPreview = () => (
        <Chart
          type="line"
          data={randomData.mixed}
          options={{
            ...chartOptions,
            indexAxis,
            plugins: { legend: false },
            scales: { x: { grid: { display: false } } },
          }}
          {...chartProps}
        />
      );
      break;

    case "All Goals":
      LowerPreview = OldPreviewParts.AllGoals;
      break;
  }

  const singleLineLabelChange = (event) => {
    event.target.value = event.target.value.replace(/\n/g, "");
    onLabelChange(event);
  };

  return (
    <div
      className={"widget " + (fullWidth ? "full-width-widget" : "single-metric-widget")}
      style={{
        backgroundColor: `${color}11`,
        color: color,
      }}
    >
      <div>
        <div className="widget-classification flex" style={{ backgroundColor: "#fff", color: color }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="8 0 16 24" stroke="currentColor" width="10">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            />
          </svg>

          <div>
            <span className="provider">{providerName}</span>
            {previewType.capitalize()}
          </div>
        </div>
        {UpperPreview && <UpperPreview up={randomData.up} color={color} number={randomData.number} />}
        {label !== false && (
          <textarea className="label text-xl font-bold" value={label} onChange={singleLineLabelChange} />
        )}
        {LowerPreview && (
          <LowerPreview
            color={color}
            dimensionName={dimension_label}
            metricName={metric_label}
            theme={theme}
            secondary_metric_label={secondary_metric_label}
            third_metric_label={third_metric_label}
            secondary_column_is_previous_period_comparison={secondary_column_is_previous_period_comparison}
            comparison_column_label={comparison_column_label}
          />
        )}
      </div>
    </div>
  );
};

const OldsameRender = (prevProps, nextProps) =>
  prevProps.label == nextProps.label &&
  prevProps.dimension_label == nextProps.dimension_label &&
  prevProps.secondary_metric_label == nextProps.secondary_metric_label &&
  prevProps.third_metric_label == nextProps.third_metric_label &&
  prevProps.secondary_column_is_previous_period_comparison ==
    nextProps.secondary_column_is_previous_period_comparison &&
  prevProps.indexAxis == nextProps.indexAxis;

export default memo(OldPreviewWidget, OldsameRender);
