import React from "react";
import WidgetBase from "../widget_base";
import TitleWidget from "./title_widget.jsx";
import TitleEditor from "./title_editor.jsx";

export default class Title extends WidgetBase {
  constructor() {
    super();
  }

  name() {
    return "Layout -- Title";
  }

  type() {
    return "layout-title";
  }

  width() {
    return "large";
  }

  height() {
    return 1;
  }

  config(chartType) {
    return {
      widgetType: this.type(),
      widgetName: this.name(),
      title: "Title here",
    };
  }

  duplicateWidget(widgetId) {
    store.dispatch(duplicateWidgetOnCanvas(widgetId));
  }

  editorComponent(widgetIndex, closeAction, deleteWidget) {
    return React.createElement(
      TitleEditor,
      {
        widgetIndex: widgetIndex,
        key: widgetIndex,
        closeWidgetEditModal: closeAction,
        deleteWidget: deleteWidget,
      },
      null
    );
  }

  renderInGrid(widgetLayout, showWidgetEditModal, deleteSelectedWidget) {
    return (
      <div
        className="canvas-grid-item layout-title border border-dashed border-gray-300 hover:border-gray-500 rounded"
        id={widgetLayout.i}
        key={widgetLayout.i}
        data-grid={widgetLayout}
      >
        <div className="canvas-widget-edit-save">
          <a
            onClick={function () {
              return this.duplicateWidget(widgetLayout.i);
            }.bind(this)}
            className=""
          >
            <i className="fas fa-copy" />
          </a>

          <a
            onClick={function () {
              return showWidgetEditModal(widgetLayout.i);
            }.bind(this)}
          >
            <i className="fas fa-edit" />
          </a>
          <a
            onClick={function () {
              return deleteSelectedWidget(widgetLayout.i);
            }.bind(this)}
          >
            <i className="fas fa-times" />
          </a>
        </div>
        <TitleWidget widgetIndex={widgetLayout.i} key={widgetLayout.i} />
      </div>
    );
  }
}
