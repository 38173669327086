import React from "react";
import Select from "react-select";
import Button from "../../legacy-components/generic/button.js.jsx";
import ButtonWithIcon from "../../legacy-components/generic/button_with_icon.js";
import WidgetFilterEditor from "../../legacy-components/canvas-widgets/widget_filter_editor";
import FlashMessage from "../../legacy-components/flash_message.js";
import {
  addCustomDimensionsToBaseDimensions,
  addCustomMetricsToBaseMetrics,
} from "./../../integrations/custom_definitions.js";
import DatePickerComponent from "../../legacy-components/DateRangePicker.jsx";

const PieChartEditor = ({
  name,
  dimensions,
  metricsList,
  chartType,
  multilineFilter = false,
  shortFilterVariable = false,
  ...props // Generic props passed from the parent editor component
}) => {
  metricsList = addCustomMetricsToBaseMetrics(
    props.oAuthProviderName,
    metricsList,
    store.getState().widgetReducer.editorConfig.custom_account
  );

  const dimensionList = addCustomDimensionsToBaseDimensions(
    props.oAuthProviderName,
    dimensions,
    store.getState().widgetReducer.editorConfig.custom_account
  );

  const filterDimensionList = addCustomDimensionsToBaseDimensions(
    props.oAuthProviderName,
    props.filterDimensionList,
    store.getState().widgetReducer.editorConfig.custom_account
  );
  const enbaleWidgetDateFeature = store.getState().reportFormReducer.enbale_widget_date_feature;

  return (
    <div className="widget widget-editor">
      <h3 className="tw-large-header mb-4">{name}</h3>

      <div className="mb-4">
        <label className="tw-label">Label</label>
        <input className="tw-field mw-key-label" value={props.label} onChange={props.updateLabel} />
      </div>

      <div className="mb-4">
        <label className="tw-label">Dimension</label>
        <Select
          name="dimension"
          className="mw-key-dimension"
          value={props.dimension}
          options={dimensionList}
          onChange={props.updateDimension}
        />
      </div>

      <div className="mb-4">
        <label className="tw-label">Metric</label>
        <Select
          name="metric_name"
          className="mw-key-metric_name"
          value={props.metric_name}
          options={metricsList}
          onChange={props.updateMetric}
        />
      </div>

      <div className="mb-4">
        <label className="tw-label">Limit</label>
        <input className="tw-field mw-key-limit" value={props.limit} onChange={props.updateLimit} />
        <p className="text-xs text-gray-700 mb-4 mt-2">
          This is the maximum number of items in the graph, and we will add an extra one called "Other" which will be
          the sum of all other results.
        </p>
      </div>

      <div className="mb-4">
        <label className="tw-label">Chart Type</label>
        <Select
          name="chart-type"
          value={chartType || "pie"}
          options={[
            { value: "pie", label: "Pie" },
            { value: "doughnut", label: "Doughnut" },
          ]}
          onChange={props.updateChartType}
        />
      </div>

      {enbaleWidgetDateFeature &&
          <DatePickerComponent
            updateFiltersEnabled={props.updateFiltersEnabled}
            updateDateFilter={props.updateDateFilter}
            selectedDate={store.getState().widgetReducer.editorConfig.date_filter_by_widget}
            lang={props.language}  
        />
      }

      {
        // `props.filtersEnabled` is undefined for older integrations, so we need to check if it's undefined or true
        // And we need to also make sure we have operators and dimensions to filter on. Then and only then we
        // enable the filter stuff.
        (props.filtersEnabled == undefined || props.filtersEnabled === true) &&
          Array.isArray(props.filterOperators) &&
          Array.isArray(filterDimensionList) && (
            <WidgetFilterEditor
              operators={props.filterOperators}
              dimensionList={filterDimensionList}
              hasORenabled={props.filterHasORenabled}
              multilineFilter={multilineFilter}
              shortFilterVariable={shortFilterVariable}
            />
          )
      }

      {props.customAccountSelector}

      <FlashMessage />

      <div className="flex flex-row mt-4">
        <ButtonWithIcon
          onClick={props.saveAndClose}
          text="Save &amp; close"
          icon="fa-check"
          extraClasses="tw-submit-button"
        />
        &nbsp;
        <Button onClick={props.closeWidgetEditModal} text="Close without saving" />
        &nbsp;
        <ButtonWithIcon
          onClick={props.deleteWidget}
          icon="fa-times"
          extraClasses="bg-red-100 text-red-700 hover:bg-red-200 ml-auto"
          text="Delete this widget"
        />
      </div>
    </div>
  );
};

export default PieChartEditor;
