import React from "react";
var createReactClass = require("create-react-class");

const ReportAlwaysSendPreviewFirst = createReactClass({
  getInitialState: function () {
    return { checked: false };
  },

  onChange: function (event) {
    store.dispatch(setReportAlwaysSendPreviewFirst(!this.state.checked));
  },

  componentDidMount: function () {
    if (store.getState().reportFormReducer.always_send_preview_first) {
      this.setState({
        checked: store.getState().reportFormReducer.always_send_preview_first,
      });
    }

    this.storeSubscription = store.subscribe(
      function () {
        this.setState({
          checked: store.getState().reportFormReducer.always_send_preview_first,
        });
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    return (
      <div className="mw-report-always-send-preview-first mb-4">
        <label className="tw-label">
          <input
            className="tw-checkbox mr-2"
            type="checkbox"
            ref="sendPreview"
            tabIndex={tabIndex}
            checked={this.state.checked}
            onChange={this.onChange}
          />
          Enable preview
        </label>
        <p className="text-sm">
          <em>
            When enabled, this sends you a preview of the report before it gets to its recipients. This allows you to
            review the report, and if you wish to do so, add a message for your clients before sending it.
          </em>
        </p>
      </div>
    );
  },
});

export default ReportAlwaysSendPreviewFirst;
