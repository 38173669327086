import React from "react";
import { connect } from "react-redux";
import GenericPieChartEditor from "../../../../widgets/data/pie_chart_editor";
import FilterValidation from "../../../../helpers/filter_validation";
import WidgetPreviewFetcher from "../../../../helpers/widget_preview/widget_preview_fetcher";
import AccountSelector from "../../../../legacy-components/account_selectors/mailchimp_account_selector";

const basicFilterOperators = [
  { value: "LIKE", label: "contains" },
  { value: "NOT LIKE", label: "does NOT contain" },
];

const filters = [
  { label: "List", value: "list" },
  { label: "Campaigns", value: "campaigns" },
  { label: "Email clients", value: "email_clients" },
  { label: "Country", value: "country" },
];

const dimensions = [
  { label: "List", value: "list" },
  { label: "Campaigns", value: "campaigns" },
  { label: "Email clients", value: "email_clients" },
  { label: "Country", value: "country" },
];

let allMetrics = [];

class MailchimpPieChartEditor extends React.Component {
  constructor(props) {
    super(props);
    allMetrics = store.getState().widgetReducer.mailchimpMetricsForCanvas;
  }

  saveAndClose() {
    let errors = [];

    if (!this.props.metric_name) {
      errors.push("Select the metric you want");
    }

    if (!this.props.dimension) {
      errors.push("Select the dimension you want");
    }

    if (!FilterValidation.validate(this.props.filters)) {
      errors.push("At least one filter has an unspecified value");
    }

    if (errors.length > 0) {
      store.dispatch(setFlashMessage("We could not save the widget:", errors));
      return;
    }

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    store.dispatch(saveWidgetConfigFromEditor(this.props.widgetIndex));

    this.close();
  }

  close() {
    new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    this.props.closeWidgetEditModal();
  }

  render() {
    let metricsList = [];

    metricsList = allMetrics.filter((metric) => metric.valid_dimensions.includes(this.props.dimension));
    let filtersList = filters.filter((filter) => filter.value.includes(this.props.dimension));
    return (
      <GenericPieChartEditor
        {...this.props}
        name="Mailchimp Pie &amp; Doughnut"
        dimensions={dimensions}
        metricsList={metricsList}
        filterOperators={basicFilterOperators}
        filterDimensionList={filtersList}
        filterHasORenabled={true}
        saveAndClose={this.saveAndClose.bind(this)}
        closeWidgetEditModal={this.close.bind(this)}
        deleteWidget={this.props.deleteWidget.bind(this)}
        customAccountSelector={
          <AccountSelector
            disconnectStore
            onChange={this.props.updateCustomAccount}
            value={this.props.custom_account}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateCustomAccount: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("custom_account", value, ownProps.widgetIndex));
    },
    updateLabel: (e) => {
      dispatch(updateEditorWidgetConfig("label", e.target.value, ownProps.widgetIndex));
    },
    updateDimension: (e) => {
      let value;
      if (e) {
        value = e.value;
      }

      dispatch(updateEditorWidgetConfig("dimension", value, ownProps.widgetIndex));
    },
    updateMetric: (e) => {
      dispatch(updateEditorWidgetConfig("metric_name", e.value, ownProps.widgetIndex));
    },
    updateLimit: (e) => {
      dispatch(updateEditorWidgetConfig("limit", e.target.value, ownProps.widgetIndex));
    },
    updateChartType: (e) => {
      dispatch(updateEditorWidgetConfig("chartType", e.value, ownProps.widgetIndex));
    },
    updateDateFilter: (ranges, isEnabledDateFilter) => {
      if(isEnabledDateFilter){
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, ownProps.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, ownProps.widgetIndex));
      }
      
    },
    updateFiltersEnabled: (checked) => {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, ownProps.widgetIndex));
    },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailchimpPieChartEditor);
