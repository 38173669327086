import React from "react";
import Select from "react-select";
import Button from "../../../../../javascript/legacy-components/generic/button.js.jsx";
import ButtonWithIcon from "../../../../../javascript/legacy-components/generic/button_with_icon.js";
import WidgetFilterEditor from "../../../../../javascript/legacy-components/canvas-widgets/widget_filter_editor";
import FlashMessage from "../../../../../javascript/legacy-components/flash_message.js";
import GoogleSheetsAccountSelector from "../../account_selector";
import { currenciesList } from "../../../../../javascript/helpers/currencies";
import DatePickerComponent from "../../../../legacy-components/DateRangePicker.jsx";

const GoogleSheetsListEditor = ({
  name,
  dimensions,
  metricsList,
  noSecondaryMetric = [],
  offerPreviousPeriodComparison,
  shortFilterVariable = false,
  multilineFilter = false,
  isCurrency = false,
  googleSheetsEditorsInfo,
  ...props // Generic props passed from the parent editor component
}) => {
  const offerSecondaryMetric =
    !noSecondaryMetric.some((x) => props.dimension == x) && !props.secondary_column_is_previous_period_comparison;
  // For readability. The secondary metric is only offered if the selected
  // dimension isn't a match in the noSecondaryMetric list.
  const valueHandlingOptions = [
    { value: "sum", label: "Sum" },
    { value: "avg", label: "Average" },
  ];
  const enbaleWidgetDateFeature = store.getState().reportFormReducer.enbale_widget_date_feature;
  
  return (
    <div className="widget widget-editor">
      <h1 className="tw-large-header mb-4">{name}</h1>
      <div className="my-4">
        <label className="tw-label">Widget Label</label>
        <input className="tw-field mb-4 mw-key-label" value={props.label} onChange={props.updateLabel} />
      </div>
      {enbaleWidgetDateFeature && 
        <DatePickerComponent
            updateFiltersEnabled={props.updateFiltersEnabled}
            updateDateFilter={props.updateDateFilter}
            selectedDate={store.getState().widgetReducer.editorConfig.date_filter_by_widget}
            lang={props.language} 
        />
      }
      <div className="md:flex flex-row">
        <div className="flex-1 md:mr-2">
          <div className="widget widget-editor">
            <GoogleSheetsAccountSelector {...props} />

            <div>
              <label className="tw-label">{googleSheetsEditorsInfo.fileName.label}</label>
              <p>{googleSheetsEditorsInfo.fileName.value}</p>
            </div>
            <div>
              <label className="tw-label">Sheets Names</label>
              <Select
                name="sheets_names"
                className="mw-key-metric_name w-full"
                value={props.selectedSheet}
                options={googleSheetsEditorsInfo.sheetsList}
                onChange={(e) => {
                  // always use this name in map props for function in this case
                  props.onChangeSheetsSelector(e);
                }}
              />
            </div>
          </div>

          <div>
            <div className="mb-8">
              <label className="tw-label">What do you want to list? (i.e., dimension)</label>
              <Select
                name="dimension"
                className="mw-key-dimension"
                value={props.dimension}
                options={dimensions}
                onChange={props.updateDimension}
              />
              <div className="field">
                <label className="tw-label">Dimension Label</label>
                <input
                  className="tw-field mb-4 mw-key-dimension_label"
                  value={props.dimension_label || ""}
                  onChange={props.updateDimensionLabel}
                />
              </div>
            </div>

            <div className="field">
              <label className="tw-label">Limit</label>
              <input className="tw-field mb-4 mw-key-limit" value={props.limit} onChange={props.updateLimit} />
            </div>
          </div>
        </div>
        <div className="mt-8 md:mt-0 md:ml-2 flex-1">
          <div className="md:flex flex-row">
            <div className="flex-1 md:mr-2">
              <label className="tw-label">Metric</label>
              <Select
                name="metric_name"
                className="mw-key-metric_name"
                value={props.metric_name}
                options={metricsList}
                onChange={props.updateMetric}
              />
            </div>
            <div className="mt-8 md:mt-0 md:ml-2 flex-1">
              <label className="tw-label">Value handling</label>
              <Select
                name="value_handling"
                className="mw-key-metric_name w-full"
                value={props.valueHandlingMetricOne}
                options={valueHandlingOptions}
                onChange={(e) => {
                  // always use this name in map props for function in this case
                  props.onChangeValueHandlingSelector(e, "One");
                }}
              />
            </div>
          </div>
          <div className="field">
            <label className="tw-label">Metric Label</label>
            <input
              className="tw-field mb-4 mw-key-metric_label"
              value={props.metric_label || ""}
              onChange={props.updateMetricLabel}
            />
          </div>

          <label className="tw-label mb-4">
            <input
              type="checkbox"
              className="mr-2 tw-checkbox mw-key-secondary_column_is_previous_period_comparison"
              checked={props.secondary_column_is_previous_period_comparison || false}
              onChange={props.updateComparisonColumnName}
            />
            Add comparison to previous period
          </label>
          {props.secondary_column_is_previous_period_comparison && (
            <div className="field">
              <label className="tw-label">Comparison column name</label>
              <input
                className="tw-field mb-2 mw-key-comparison_column_label"
                value={props.comparison_column_label || ""}
                onChange={props.updateComparisonColumnLabel}
              />
              <p className="text-xs text-gray-700 mb-4">
                Defaults to "Previous Period" in English, and "Période Précédente" in French. Leave blank to keep the
                default value.
              </p>
            </div>
          )}

          {offerSecondaryMetric && !props.secondary_column_is_previous_period_comparison && (
            <div>
              <div className="md:flex flex-row">
                <div className="flex-1 md:mr-2">
                  <label className="tw-label">Secondary Metric</label>
                  <Select
                    name="secondary_metric_name"
                    className="mw-key-secondary_metric_name"
                    value={props.secondary_metric_name}
                    options={metricsList}
                    onChange={props.updateSecondaryMetric}
                  />
                </div>
                <div className="mt-8 md:mt-0 md:ml-2 flex-1">
                  <label className="tw-label">Secondary Metric Value handling</label>
                  <Select
                    name="value_handling"
                    className="mw-key-metric_name w-full"
                    value={props.valueHandlingMetricTwo}
                    options={valueHandlingOptions}
                    onChange={(e) => {
                      // always use this name in map props for function in this case
                      props.onChangeValueHandlingSelector(e, "Two");
                    }}
                  />
                </div>
              </div>
              <div>
                <label className="tw-label">Secondary Metric Label</label>
                <input
                  className="tw-field mb-4 mw-key-secondary_metric_label"
                  value={props.secondary_metric_label || ""}
                  onChange={props.updateSecondaryLabel}
                />
              </div>
              <div className="md:flex flex-row">
                <div className="flex-1 md:mr-2">
                  <label className="tw-label">Third Metric</label>
                  <Select
                    name="third_metric_name"
                    className="mw-key-third_metric_name"
                    value={props.third_metric_name}
                    options={metricsList}
                    onChange={props.updateThirdMetric}
                  />
                </div>

                <div className="mt-8 md:mt-0 md:ml-2 flex-1">
                  <label className="tw-label">Third Metric Value handling</label>
                  <Select
                    name="value_handling"
                    className="mw-key-metric_name w-full"
                    value={props.valueHandlingMetricThree}
                    options={valueHandlingOptions}
                    onChange={(e) => {
                      // always use this name in map props for function in this case
                      props.onChangeValueHandlingSelector(e, "Three");
                    }}
                  />
                </div>
              </div>
              <div className="field">
                <label className="tw-label">Third Metric Label</label>
                <input
                  className="tw-field mb-4 mw-key-third_metric_label"
                  value={props.third_metric_label || ""}
                  onChange={props.updateThirdLabel}
                />
              </div>
            </div>
          )}
          {isCurrency && (
            <div className="mb-4">
              <label className="tw-label">Currency</label>
              <Select
                name="currencies"
                className="mw-key-metric_name"
                value={props.currency}
                options={currenciesList}
                onChange={props.updateCurrency}
              />
            </div>
          )}
        </div>
      </div>

      {Array.isArray(props.filterOperators) && Array.isArray(props.filterDimensionList) && (
        <WidgetFilterEditor
          operators={props.filterOperators}
          dimensionList={props.filterDimensionList}
          hasORenabled={props.filterHasORenabled}
          multilineFilter={props.multilineFilter}
          shortFilterVariable={props.shortFilterVariable}
        />
      )}

      <FlashMessage />

      <div className="flex flex-row mt-4">
        <ButtonWithIcon
          onClick={props.saveAndClose}
          text="Save &amp; close"
          icon="fa-check"
          extraClasses="tw-submit-button"
        />
        &nbsp;
        <Button onClick={props.closeWidgetEditModal} text="Close without saving" />
        &nbsp;
        <ButtonWithIcon
          onClick={props.deleteWidget}
          icon="fa-times"
          extraClasses="bg-red-100 text-red-700 hover:bg-red-200 ml-auto"
          text="Delete this widget"
        />
      </div>
    </div>
  );
};

export default GoogleSheetsListEditor;
