import React from "react";
import { connect } from "react-redux";
import { googleAnalyticsDimensionList, googleAnalyticsDimensionFilterOperators } from "../../../../helpers/dimensions";
import FilterValidation from "../../../../helpers/filter_validation";
import GenericPieChartEditor from "../../../../widgets/data/pie_chart_editor";
import WidgetPreviewFetcher from "../../../../helpers/widget_preview/widget_preview_fetcher";
import AccountSelector from "../../../../legacy-components/google_analytics_profiles.js";

class GoogleAnalyticsPieChartEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: this.props.label,
      dimension_label: this.props.dimension_label,
      metric_label: this.props.metric_label,
      limit: this.props.limit,
    };
  }

  saveAndClose() {
    let errors = [];

    if (!FilterValidation.validate(this.props.filters)) {
      errors.push("At least one filter has an unspecified value");
    }

    if (errors.length > 0) {
      store.dispatch(setFlashMessage("We could not save the widget:", errors));
      return;
    }

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    store.dispatch(saveWidgetConfigFromEditor(this.props.widgetIndex));

    this.close();
  }

  close() {
    new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    this.props.closeWidgetEditModal();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      label: nextProps.label,
      dimension_label: nextProps.dimension_label,
      metric_label: nextProps.metric_label,
      limit: nextProps.limit,
    });
  }
  render() {
    var gaMetricsForCanvas = store.getState().widgetReducer.gaMetricsForCanvas;
    return (
      <GenericPieChartEditor
        {...this.props}
        name="Google Analytics Pie &amp; Doughnut"
        dimensions={googleAnalyticsDimensionList}
        metricsList={gaMetricsForCanvas}
        filterOperators={googleAnalyticsDimensionFilterOperators}
        filterDimensionList={googleAnalyticsDimensionList}
        filterHasORenabled={true}
        saveAndClose={this.saveAndClose.bind(this)}
        closeWidgetEditModal={this.close.bind(this)}
        deleteWidget={this.props.deleteWidget.bind(this)}
        customAccountSelector={
          <AccountSelector
            disconnectStore
            onChange={this.props.updateCustomAccount}
            value={this.props.custom_account}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateCustomAccount: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("custom_account", value, ownProps.widgetIndex));
    },
    updateLabel: (e) => {
      dispatch(updateEditorWidgetConfig("label", e.target.value, ownProps.widgetIndex));
    },
    updateDimension: (e) => {
      let value;
      if (e) {
        value = e.value;
      }

      dispatch(updateEditorWidgetConfig("dimension", value, ownProps.widgetIndex));
    },
    updateMetric: (e) => {
      dispatch(updateEditorWidgetConfig("metric_name", e.value, ownProps.widgetIndex));
    },
    updateLimit: (e) => {
      dispatch(updateEditorWidgetConfig("limit", e.target.value, ownProps.widgetIndex));
    },
    updateChartType: (e) => {
      dispatch(updateEditorWidgetConfig("chartType", e.value, ownProps.widgetIndex));
    },
    updateDateFilter: (ranges, isEnabledDateFilter) => {
      if(isEnabledDateFilter){
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, ownProps.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, ownProps.widgetIndex));
      }
      
    },
    updateFiltersEnabled: (checked) => {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, ownProps.widgetIndex));
    },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAnalyticsPieChartEditor);
