import _ from "lodash";

const defaultFilterParamNames = {
  dimension: "selectedDimension",
  operator: "selectedOperator",
  value: "specifiedValue",
};

const FilterValidation = {
  validate(filters, filterParamNames = defaultFilterParamNames) {
    for (let index = 0; index < filters.length; index++) {
      const f = filters[index];

      if (this.isThatFilterValid(f, filterParamNames) === false) {
        return false;
      }
    }

    return true;
  },

  // This is specifically for GA4
  filterAndIntervalAreCompatible(filters, intervalIsRT) {
    // This turns an array to an object of dimensions
    const allDimensions = Object.fromEntries(
      store.getState().watcherFormReducer.allDimensions.map((item) => [item.value, item])
    );

    for (let index = 0; index < filters.length; index++) {
      const f = filters[index];

      if (f.selectedDimension !== undefined) {
        if (intervalIsRT && allDimensions[f.selectedDimension].available_real_time === false) {
          return false;
        }

        if (!intervalIsRT && allDimensions[f.selectedDimension].available_non_real_time === false) {
          return false;
        }
      }
    }

    return true;
  },

  isThatFilterValid(filter, filterParamNames) {
    if (Array.isArray(filter)) {
      for (let index = 0; index < filter.length; index++) {
        const f = filter[index];
        if (this.isThatFilterValid(f, filterParamNames) === false) {
          return false;
        }
      }

      return true;
    }

    if (
      _.trim(filter[filterParamNames["dimension"]]) === "" ||
      _.trim(filter[filterParamNames["operator"]]) === "" ||
      _.trim(filter[filterParamNames["value"]]) === ""
    ) {
      return false;
    }

    return true;
  },
};

export default FilterValidation;
