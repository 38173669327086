import React, { useState } from "react";
import Modal from "../components/modal";
import Button from "./generic/button.js";

const FindAndReplaceModal = ({ modalOpen, closeCallback = () => {} }) => {
  const { updateWidgetConfig, store } = window;
  const widgetConfigs = store.getState().widgetReducer.widgetConfigs;

  const [includeSingleOccurences, setIncludeSingleOccurences] = useState(false);
  const [findValue, setFindValue] = useState("");
  const [replaceValue, setReplaceValue] = useState("");

  // Computes filter settings across the configs tree into a single array
  // with index/ID references. The objects will have a specifiedValue *or*
  // a value property, depending on what the filter has.

  let filterSettings = [];

  const addFilterToList = (filter, index, widgetIndex, additionalProps = {}) => {
    const { value, specifiedValue } = filter;
    if (Array.isArray(filter)) {
      // Filters that are in an "OR" configuration
      // are an array of filters instead
      filter.forEach((subFilter, subIndex) =>
        addFilterToList(subFilter, index, widgetIndex, {
          ...additionalProps,
          subIndex,
        })
      );
    } else {
      filterSettings.push({
        widgetIndex,
        index,
        ...additionalProps,
        ...(value && { value }),
        ...(specifiedValue && { specifiedValue }),
      });
    }
  };

  for (const widgetIndex in widgetConfigs) {
    const { filters = false, steps = false } = widgetConfigs[widgetIndex];
    if (filters) {
      filters.forEach((filter, index) => addFilterToList(filter, index, widgetIndex));
    }

    // Funnel widgets have additional filters under the "steps" key.
    // When a filter reference has a "stepIndex" key, we know it's one of them.
    if (steps) {
      steps.forEach(({ filters }, stepIndex) => {
        filters.forEach((filter, index) => addFilterToList(filter, index, widgetIndex, { stepIndex }));
      });
    }
  }

  const occurencesCount = filterSettings
    .map(({ specifiedValue, value }) => specifiedValue || value)
    .reduce((occurences, value) => {
      const occurenceIndex = occurences.findIndex((x) => x.value == value);
      if (occurenceIndex !== -1) {
        occurences[occurenceIndex].count += 1;
        return occurences;
      } else {
        occurences.push({ value, count: 1 });
        return occurences;
      }
    }, []);

  const occurenceMatch = occurencesCount.find((o) => o.value == findValue);
  const canReplace = occurenceMatch && replaceValue !== "";

  const replaceOccurences = () => {
    // Filter down to only filters affected
    const relevantFilters = filterSettings.filter((f) => f.specifiedValue == findValue || f.value == findValue);

    // ... then only the widgets affected by these filters
    const widgetsToUpdate = relevantFilters.reduce(
      (indexes, { widgetIndex }) => (indexes.indexOf(widgetIndex) !== -1 ? indexes : indexes.concat([widgetIndex])),
      []
    );

    widgetsToUpdate.forEach((widgetIndex) => {
      const widgetConfig = widgetConfigs[widgetIndex];

      // ... then only the relevant changes in a single widget
      const applicableChanges = relevantFilters.filter((f) => f.widgetIndex == widgetIndex);

      applicableChanges.forEach(
        // This moves the reference down to the object
        //  whose properties we want to update
        ({ index, stepIndex = -1, subIndex = -1, value, specifiedValue }) => {
          let filterReference =
            stepIndex > -1 ? widgetConfig.steps[stepIndex].filters[index] : widgetConfig.filters[index];

          // OR filters are in an array, `subIndex` tells us to target this
          if (subIndex > -1) {
            filterReference = filterReference[subIndex];
          }

          // About this: Some filters on the back-end use
          // `specifiedValue` instead of `value`
          if (value) filterReference.value = replaceValue;
          if (specifiedValue) filterReference.specifiedValue = replaceValue;
        }
      );

      // Funnel widgets can have a `filters` property as well,
      // so this is an inclusive if, just in case
      if (widgetConfig.steps) {
        store.dispatch(updateWidgetConfig("steps", widgetConfig.steps, widgetIndex));
      }
      if (widgetConfig.filters) {
        store.dispatch(updateWidgetConfig("filters", widgetConfig.filters, widgetIndex));
      }
    });

    setFindValue("");
    setReplaceValue("");

    return;
  };

  return (
    <Modal visible={modalOpen} closeCallback={closeCallback} noDefaultButtons>
      <div className={"send-test-report-modal modal is-active"}>
        <h4 className="font-bold mb-4">Replace across all filter values</h4>

        <p>
          This will allow you to change a specific value across all filters in this specific report. You can use the
          shortcuts below to select frequently used values.
        </p>

        <div className="mb-1">
          <label className="tw-label">Find this value:</label>
          <input onChange={({ target }) => setFindValue(target.value)} value={findValue} className="tw-field" />
        </div>

        <p>
          {occurencesCount
            .filter(({ count }) => (includeSingleOccurences ? true : count > 1))
            .sort((a, b) => b.count - a.count)
            .map(({ value, count }) => {
              const active = value == findValue;
              return (
                <button
                  key={value}
                  onClick={() => setFindValue(value)}
                  className={
                    "inline-flex items-center pl-0.5 pr-1 py-0.5 mr-2 border shadow-sm text-xs font-medium rounded bg-white hover:bg-gray-100" +
                    (active ? " border-blue-mw text-blue-mw" : " border-gray-300 text-gray-700")
                  }
                >
                  <span
                    className={
                      "inline-flex items-center px-1 mr-1 rounded-sm text-xs font-medium bg-gray-300" +
                      (active ? " bg-blue-mw text-white" : " text-gray-800")
                    }
                  >
                    {count}x
                  </span>
                  {value}
                </button>
              );
            })}
          <label className="tw-label mt-0.5 mb-2 font-medium text-xs text-gray-600" htmlFor="show_singles">
            <input
              id="show_singles"
              className="mr-2 tw-checkbox"
              type="checkbox"
              checked={includeSingleOccurences}
              onChange={() => setIncludeSingleOccurences(!includeSingleOccurences)}
            />
            Show values that only occur once
          </label>
        </p>

        <div className="mb-4">
          <label className="tw-label">Replace with:</label>
          <input onChange={({ target }) => setReplaceValue(target.value)} value={replaceValue} className="tw-field" />
        </div>

        <Button
          text={`Replace ${
            occurenceMatch ? "in " + occurenceMatch.count + (occurenceMatch.count > 1 ? " filters" : " filter") : ""
          }`}
          extraClasses={
            canReplace
              ? "tw-submit-button mr-2"
              : "border border-gray-100 bg-gray-100 text-gray-500 mr-2 pointer-events-none"
          }
          onClick={canReplace ? () => replaceOccurences(findValue, replaceValue) : null}
        />
        <Button text="Close" extraClasses="mr-2" onClick={closeCallback} />
      </div>
    </Modal>
  );
};

export default FindAndReplaceModal;
