import React from "react";
var createReactClass = require("create-react-class");
import { connect, Provider } from "react-redux";
import $ from "jquery";
import APIError from "../../helpers/api_error";

class NewReportForm extends React.Component {
  constructor(props) {
    super(props);
  }

  create() {
    let params = { name: this.props.name };

    $.ajax({
      url: "/api/v1/reports",
      dataType: "json",
      method: "POST",
      data: params,
      success: function (data) {
        document.location.href = "/reports/" + data.data.id + "/edit";
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("NewReport", xhr, status, err);
      }.bind(this),
    });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.create();
    }
  }

  render() {
    return (
      <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="text-center text-3xl font-extrabold text-gray-900">Create a report</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div>
              <label for="email" className="tw-label">
                Report name
              </label>
              <div className="mt-1">
                <input
                  autoFocus
                  ref="input"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="My Report"
                  type="text"
                  onChange={this.props.updateReportName}
                  onKeyDown={this.handleKeyDown.bind(this)}
                  value={this.props.name || ""}
                />
              </div>
            </div>

            <div className="mt-6">
              <button
                onClick={function () {
                  this.create();
                }.bind(this)}
                type="submit"
                className="tw-submit-button w-full"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    name: state.reportFormReducer.name,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setReportID: (event) => {
      dispatch(setReportID(reportID));
    },
    updateReportName: (event) => {
      dispatch(setReportName(event.target.value));
    },
  };
};

var NewReportConnect = connect(mapStateToProps, mapDispatchToProps, null)(NewReportForm);
var NewReportFormWithProvider = createReactClass({
  render: function () {
    return (
      <Provider store={store}>
        <NewReportConnect />
      </Provider>
    );
  },
});

export { NewReportForm };
export default NewReportFormWithProvider;
