import React from "react";
var createReactClass = require("create-react-class");

const MinutesAgo = createReactClass({
  getInitialState: function () {
    return { maxMinutesAgo: 29, startMinutesAgo: 29, endMinutesAgo: 0 };
  },

  onChangeStartMinutesAgo: function (event) {
    let value = event.target.value;

    if (value > this.state.maxMinutesAgo) {
      value = this.state.maxMinutesAgo;
    }

    this.setState({ startMinutesAgo: value });
    store.dispatch(setStartMinutesAgo(value));
  },

  onChangeEndMinutesAgo: function (event) {
    let value = event.target.value;

    if (value > this.state.maxMinutesAgo) {
      value = this.state.maxMinutesAgo;
    }

    this.setState({ endMinutesAgo: value });
    store.dispatch(setEndMinutesAgo(value));
  },

  componentDidMount: function () {
    if (store.getState().watcherFormReducer.startMinutesAgo) {
      this.setState({
        startMinutesAgo: store.getState().watcherFormReducer.startMinutesAgo,
        endMinutesAgo: store.getState().watcherFormReducer.endMinutesAgo,
      });
    }

    this.storeSubscription = store.subscribe(
      function () {
        const is360 = store.getState().watcherFormReducer.gaServiceLevel == "GOOGLE_ANALYTICS_360";
        const maxMinutesAgo = is360 ? 59 : 29;
        this.setState({
          maxMinutesAgo: maxMinutesAgo,
          startMinutesAgo: store.getState().watcherFormReducer.startMinutesAgo,
          endMinutesAgo: store.getState().watcherFormReducer.endMinutesAgo,
        });
        if (store.getState().watcherFormReducer.startMinutesAgo > maxMinutesAgo) {
          store.dispatch(setStartMinutesAgo(maxMinutesAgo));
        }
        if (store.getState().watcherFormReducer.endMinutesAgo > maxMinutesAgo) {
          store.dispatch(setEndMinutesAgo(maxMinutesAgo));
        }
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    return (
      <div className="my-6 mw-threshold">
        <label className="tw-label">Minutes ago range</label>
        <div className="mb-2 mt-4  text-sm">
          <span className="inline-block w-12">From </span>
          <input
            ref="input"
            className="tw-field-small-inline-number"
            tabIndex={tabIndex}
            placeholder="Start of minutes ago range"
            min={0}
            max={59}
            type="number"
            onChange={this.onChangeStartMinutesAgo}
            value={this.state.startMinutesAgo || "29"}
          />{" "}
          minutes ago
        </div>
        <div className="text-sm">
          <span className="inline-block w-12">To </span>
          <input
            ref="input"
            className="tw-field-small-inline-number"
            tabIndex={tabIndex}
            placeholder="End of minutes ago range"
            min={0}
            max={59}
            type="number"
            onChange={this.onChangeEndMinutesAgo}
            value={this.state.endMinutesAgo || "0"}
          />{" "}
          minutes ago
        </div>
        <p className="text-xs mt-4 mb-0 text-gray-500">
          This setting lets you granularly decide which data we check for you. We will check your real-time data every 5
          or 10 minutes (based on your plan), but this setting will change which data we look for.
          <br />
          <br />
          Example #1: if you set the "From" to 20 and the "To" to 0, it would include the 20 last minutes of data.
          <br />
          Example #2: if you set the "From" to 20 and the "To" to 10, it would include 10 minutes of data from 20 to 10
          minutes ago.
          <br />
          <br />
          Up to 59 minutes ago for GA4 360, and up to 29 minutes ago for free GA4 accounts. (This is a limitation of the
          GA4, not Metrics Watch.)
        </p>
      </div>
    );
  },
});

export default MinutesAgo;
