import React from "react";
var createReactClass = require("create-react-class");
import Select from "react-select";
import $ from "jquery";
import _ from "lodash";
import APIError from "../helpers/api_error";

const MetricName = createReactClass({
  apiURL: function (profileID) {
    if (this.props.ga_version == "ga4") {
      return "/api/v1/metrics?provider=ga4";
    } else {
      return "/api/v1/ga_profiles/" + profileID + "/metrics";
    }
  },

  getInitialState: function () {
    return {
      options: [],
      metricName: undefined,
      google_analytics_profile_id: undefined,
    };
  },

  onChange: function (data) {
    let val = undefined;
    if (data) {
      val = data.value;
    }
    this.setState({ metricName: val });
    store.dispatch(selectMetricName(val));
  },

  componentDidMount: function () {
    this.storeSubscription = store.subscribe(
      function () {
        let newValue = store.getState().watcherFormReducer.metricName;
        if (newValue !== this.state.metricName) {
          this.setState({ metricName: newValue });
        }

        let currentStoreState = store.getState();
        let newProfileID = currentStoreState.watcherFormReducer.google_analytics_profile_id;
        if (newProfileID == undefined) {
          this.setState({ options: [] });
          return;
        }

        if (newProfileID != this.state.google_analytics_profile_id) {
          let newState = _.assign({}, this.state, {
            google_analytics_profile_id: newProfileID,
          });
          this.setState(newState);

          this.loadMetrics(newProfileID);
        }
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  loadMetrics: function (profileID) {
    $.ajax({
      url: this.apiURL(profileID),
      dataType: "json",
      cache: false,
      success: function (data) {
        this.prepareOptions(data);
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("MetricNames", xhr, status, err);
      }.bind(this),
    });
  },

  prepareOptions: function (data) {
    let options = [];
    for (let metric in data) {
      if (this.props.ga_version == "ga4") {
        let label = data[metric].label;
        if (data[metric].available_real_time == true && data[metric].available_non_real_time == false) {
          label += " (for real-time intervals ONLY)";
        }

        if (data[metric].available_real_time == true && data[metric].available_non_real_time == true) {
          label += " (available for all intervals)";
        }

        if (data[metric].available_real_time == false && data[metric].available_non_real_time == true) {
          label += " (NOT available for real-time intervals)";
        }

        options.push({
          value: metric,
          label: label,
        });
      } else {
        options.push({ value: metric, label: data[metric] });
      }
    }

    let newState = _.assign({}, this.state, { options: options });
    this.setState(newState);

    // Set all metrics to be used for validation purpose (to know if selected metric supports real-time, or not)
    store.dispatch(setAllMetrics(data));

    // If we are editing an alert...
    let metricNameFromStore = store.getState().watcherFormReducer.metricName;
    if (metricNameFromStore) {
      this.setState({ metricName: metricNameFromStore });
    }
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    return (
      <div className="mw-metric-name">
        <label className="tw-label">Metric</label>
        <Select
          value={this.state.metricName}
          tabIndex={tabIndex}
          label="Metric"
          options={this.state.options}
          onChange={this.onChange}
        />
      </div>
    );
  },
});

export default MetricName;
