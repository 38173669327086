import React from "react";
var createReactClass = require("create-react-class");
import { connect, Provider } from "react-redux";
import ButtonWithIcon from "./generic/button_with_icon.js";
import FlashMessage from "./flash_message.js";
import { getParameterByName } from "../helpers/utils";
import $ from "jquery";
import APIError from "../helpers/api_error";
import LoadSpinner from "../components/utility/load_spinner.jsx";

function mapReportNewsListStateToProps(state, ownProps) {
  return state.widgetReducer;
}

const ReportNewsList = createReactClass({
  getInitialState: function () {
    return { allReportNews: [], loading: true };
  },
  componentDidMount: function () {
    this.showSavedFlashMessage();
    this.loadallReportNews();
  },
  showSavedFlashMessage: function () {
    if (getParameterByName("saved") !== null) {
      store.dispatch(setFlashMessage("News item successfully created!", []));
      return true;
    }
    if (getParameterByName("updated") !== null) {
      store.dispatch(setFlashMessage("News item successfully updated!", []));
      return true;
    }
    return false;
  },
  deleteReportNews: function (id) {
    if (confirm("Are you sure you want to delete this news item?")) {
      $.ajax({
        url: "/api/v1/report_news_items/" + id,
        method: "DELETE",
        cache: false,
        success: function (data) {
          document.location.reload();
        }.bind(this),
        error: function (xhr, status, err) {
          new APIError("ReportNewsDelete", xhr, status, err);
        }.bind(this),
      });
    }
    return false;
  },

  loadallReportNews: function () {
    $.ajax({
      url: "/api/v1/report_news_items",
      dataType: "json",
      method: "GET",
      success: function (data) {
        // $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ allReportNews: data.data, loading: false });
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("ReportNewsLoad", xhr, status, err);
      }.bind(this),
    });
  },

  render: function () {
    return (
      <div>
        <FlashMessage />
        <a href="/report_news/new">
          <button className="tw-submit-button">Add a news item</button>
        </a>
        {this.state.loading === true && <LoadSpinner />}
        {this.state.loading === false && this.state.allReportNews.length === 0 && this.renderWithNoReportNews()}
        {this.state.loading === false && this.state.allReportNews.length !== 0 && this.renderList()}
      </div>
    );
  },

  renderList: function () {
    return (
      <div className="flex flex-col mt-4">
        <div className="-my-2 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr className="hidden lg:table-row">
                    <th className="tw-table-header-title">Name</th>

                    <th className="tw-table-header-title">Date published</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.allReportNews.map(
                    function (newsItem) {
                      let newsItemURL = "/report_news/" + newsItem.id + "/edit";

                      return (
                        <tr className="border-b border-gray-200" key={newsItem.id}>
                          <td className="tw-table-cell-padding text-sm leading-5 font-medium text-gray-900 block md:table-cell">
                            <a href={newsItemURL}>{newsItem.attributes.title}</a>
                          </td>
                          <td className="tw-table-cell-padding lg:py-4 text-sm leading-5 text-gray-500 block lg:table-cell">
                            <a href={newsItemURL}>
                              From {newsItem.attributes.start_date}
                              to {newsItem.attributes.end_date}
                            </a>
                          </td>
                          <td className="tw-table-cell-padding whitespace-nowrap text-sm text-right leading-5 font-medium flex md:table-cell">
                            <ButtonWithIcon icon="fa-edit" extraClasses="is-primary" text="Edit" href={newsItemURL} />
                            &nbsp;
                            <ButtonWithIcon
                              icon="fa-times"
                              text="Delete"
                              href="#"
                              onClick={function (event) {
                                return this.deleteReportNews(newsItem.id, event.target);
                              }.bind(this)}
                            />
                          </td>
                        </tr>
                      );
                    }.bind(this)
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  },

  renderLoading: function () {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  },

  renderWithNoReportNews: () => {
    return (
      <div>
        <p className="text-xl my-8 mx-auto">There is currently no news configured to be included in your reports.</p>
        <p className="text-sm mt-20">
          <strong>What is this "news" section about?</strong>
        </p>
        <p className="text-sm mt-2">
          It is a feature to help you share some good news or your latest blog post, for instance, with the recipients
          of the reports. They get added to the bottom of the report. Only one can get added, and you can set them up in
          advance with start and end dates.
        </p>
      </div>
    );
  },
});

const ReportNewsListConnect = connect(mapReportNewsListStateToProps)(ReportNewsList);
const ReportNewsListWithProvider = createReactClass({
  render: function () {
    return (
      <Provider store={store}>
        <ReportNewsListConnect />
      </Provider>
    );
  },
});

export default ReportNewsListWithProvider;
