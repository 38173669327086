import React from "react";
import { connect } from "react-redux";
import ButtonWithIcon from "../../../legacy-components/generic/button_with_icon.js.jsx";

// This component represent a single report setting. It can be any setting that will be on the report-level.
// It is meant to be a super easy way to add a setting to the report form
//
// To fully add a report setting, you need to add things in:
// - app/models/report.rb
// - app/serializers/report_serializer.rb
// - app/jobs/report_runner_job.rb
// - app/controllers/api/v1/reports_controller.rb
// - app/webpacker/src/javascript/helpers/report_setting_list.js
// - spec/controllers/api/v1/reports_controller_spec.rb
// - spec/jobs/report_runner_job_spec.rb
// - spec/mailers/report_mailer_spec.rb
// - spec/webpacker/src/javascript/legacy-components/report_action_buttons.spec.js
class ReportSetting extends React.Component {
  render() {
    return (
      <>
        {this.props.type == "text" && (
          <TextInput label={this.props.label} settingName={this.props.settingName} parentProps={this.props} />
        )}
        {this.props.type == "textarea" && (
          <TextArea label={this.props.label} settingName={this.props.settingName} parentProps={this.props} />
        )}
      </>
    );
  }
}

// When the setting is set with an text type
class TextInput extends React.Component {
  render() {
    return (
      <>
        <div className="field">
          <label className="tw-label">{this.props.label}</label>
          <input
            className="tw-field"
            type="text"
            onChange={(event) => {
              this.props.parentProps.updateReportSetting(this.props.settingName, event.target.value);
            }}
            value={this.props.parentProps[this.props.settingName]}
          />
        </div>
      </>
    );
  }
}

// When the setting is set with a textarea type
class TextArea extends React.Component {
  render() {
    return (
      <>
        <div className="field">
          <label className="tw-label">{this.props.label}</label>
          <textarea
            rows={5}
            className="tw-field"
            onChange={(event) => {
              this.props.parentProps.updateReportSetting(this.props.settingName, event.target.value);
            }}
            value={this.props.parentProps[this.props.settingName]}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return state.reportFormReducer;
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateReportSetting: (settingName, value) => {
      dispatch(setReportSetting(settingName, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetting);
