import IntegrationBase from "../integration_base";
import IntegrationIcon from "./logo/logo.svg";
import { ga4DimensionFilterOperators } from "./../../integrations/google-analytics-4/filter-operators";

export default class GA4 extends IntegrationBase {
  // This configures some autoloading
  integrationConfig() {
    return {
      // Metrics Watch Integration Protcol Version.
      metricsWatchIntegrationProtocolVersion: "3.0.0",

      // This is enabling the use of generic widgets, instead of integration-specific widgets like we used to do.
      autoRegisterWidgets: { enabled: true },

      // This is enabling the autoloading of metrics. When enabled, we need a to set metricLoaderCallback to handle the API response and format the metrics.
      autoloadMetrics: { enabled: true, metricLoaderCallback: this.formatMetrics.bind(this) },

      // This is enabling the autoloading of metrics. When enabled, we need a to set dimensionLoaderCallback to handle the API response and format the metrics.
      autoloadDimensions: { enabled: true, dimensionLoaderCallback: this.formatDimensions.bind(this) },

      // This is enabling the use of the generic account selector, instead of an integration-specific one.
      accountSelector: {
        enabled: true,
        databaseProviderType: "metrics_providers-google_analytics4s",
        accountLoaderCallback: this.formatAccounts.bind(this),
      },

      metrics: () => {
        return this.metrics;
      },
      dimensions: () => {
        return this.dimensions;
      },
      filterDimensionList: () => {
        return this.dimensions;
      },
      filterOperators: ga4DimensionFilterOperators,
      filterHasORenabled: true,
    };
  }

  // The name of this function is important. It's a hardcoded callback name used to autoload metrics.
  // This function is used to properly format the metrics returned for the integration, into the generic format.
  formatMetrics(metrics) {
    let formattedMetrics = [];

    for (let metric in metrics) {
      if (metrics[metric].available_non_real_time == true) {
        let label = metrics[metric].label;

        formattedMetrics.push({
          value: metric,
          label: label,
        });
      }
    }

    this.metrics = formattedMetrics;
  }

  formatDimensions(data) {
    this.dimensions = [];

    for (const key in data) {
      const item = data[key];
      if (item.available_non_real_time == true) {
        this.dimensions.push({
          value: key,
          label: item.label,
        });
      }
    }
  }

  formatAccounts(accounts) {
    let options = [];
    for (let index = 0; index < accounts.length; index++) {
      const integrationAccount = accounts[index];
      let ga4Accounts = integrationAccount.attributes.provider_accounts;
      if (integrationAccount.type == "metrics_providers-google_analytics4s" && ga4Accounts) {
        for (let j = 0; j < ga4Accounts.length; j++) {
          const account = ga4Accounts[j];
          const properties = ga4Accounts[j].properties;

          for (let propertyIndex = 0; propertyIndex < properties.length; propertyIndex++) {
            const property = properties[propertyIndex];
            options.push({
              value: property.name,
              label: `${account.displayName} - ${property.displayName}`,
              customMetrics: property.custom_metrics,
              customDimensions: property.custom_dimensions,
            });
          }
        }
      }
    }

    store.dispatch(setIntegrationAccountList("ga4", options));
  }

  name() {
    return "Google Analytics 4";
  }

  iconURL() {
    return IntegrationIcon;
  }

  oAuthProviderName() {
    return "ga4";
  }

  integrationFor() {
    return ["alert", "report"];
  }

  widgets() {
    return {
      integration: this,
      singleMetric: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        widgetName: "GA4 Single Metric",
        widgetType: "ga4-single-metric",
        defaultLabel: "Sessions",
        defaultMetricName: "sessions",
      },
      list: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        sortEnabled: true, // if we want to to overwrite the sorting optins we need to set `this.sortByOptions` in this class (see how it's down in the `IntegrationBase` class)
        widgetName: "GA4 List",
        widgetType: "ga4-list",
        defaultLabel: "First source / medium",
        defaultDimensionName: "firstUserSourceMedium",
        defaultDimensionLabel: "First source / medium",
        defaultMetricName: "sessions",
        defaultMetricLabel: "Sessions",
        // noSecondaryMetric: [],
      },
      pieAndDoughnut: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        widgetName: "GA4 Pie Chart",
        widgetType: "ga4-pie-chart",
        defaultLabel: "Browsers",
        defaultDimensionName: "browser",
        defaultDimensionLabel: "Browser",
        defaultMetricName: "sessions",
        defaultMetricLabel: "Sessions",
      },
      mixedChart: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        widgetName: "GA4 Mixed Chart",
        widgetType: "ga4-mixed-chart",
        defaultLabel: "Sessions",
        defaultDimensionName: "MW--period",
        defaultMetricName: "sessions",
        defaultMetricLabel: "Sessions",
        isPeriodDimensionFilterable: true,
      },
    };
  }
}
