import React, { useState, useEffect } from "react";
import useReportAlerts from "../helpers/report_alerts_loader.jsx";
import { reportFormValidation } from "../helpers/report_form_validation";
const FlashMessageWarnings = ({ setActiveTab, reportId }) => {
  const { alerts } = useReportAlerts(reportId);

  const [warningMessage, setWarningMessage] = useState({
    notificationClassName: "is-warning",
    text: alerts.warningText,
    warnings: alerts.warnings,
    tabRef: "Schedule",
  });

  const handlePushToLocation = () => {
    setActiveTab(warningMessage.tabRef);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleStoreChange = () => {
      let { flashMessageWarning } = store.getState().watcherFormReducer ?? {};
      if (flashMessageWarning?.warnings.length > 0) {
        setWarningMessage({
          notificationClassName: "is-warning",
          text: flashMessageWarning?.text || alerts.warningMessage,
          warnings: flashMessageWarning?.warnings || alerts.warnings,
          tabRef: flashMessageWarning?.tabRef,
        });
      }
    };

    const unsubscribe = store.subscribe(handleStoreChange);
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div>
      {warningMessage.text && warningMessage.text.length > 0 && (
        <div className="react-flash-message">
          <div className={`notification tw-flash m-2 ${warningMessage.notificationClassName}`}>
            {warningMessage.warnings.length > 0 && (
              <div class="rounded-md bg-yellow-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <h3 class="text-sm font-medium text-yellow-800">{warningMessage.text}</h3>
                    <ul class="list-disc ml-8 mt-1 text-sm md:ml-6 md:mt-0">
                      {warningMessage.warnings.map((warn, index) => (
                        <li key={`warning${index}`}>
                          <p>{warn}</p>
                          <p class="mt-3 text-sm md:ml-6 md:mt-0">
                            <button class="whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600" onClick={handlePushToLocation}>
                              Click here to fix
                              <span aria-hidden="true"> &rarr;</span>
                            </button>
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FlashMessageWarnings;
