var ga4DimensionFilterOperators = [
  { value: "EXACT", label: "match" },
  { value: "NOT_EXACT", label: "does NOT match" },
  { value: "CONTAINS", label: "contains" },
  { value: "NOT_CONTAINS", label: "does NOT contain" },
  { value: "BEGINS_WITH", label: "Begins with" },
  { value: "ENDS_WITH", label: "Ends with" },
  { value: "FULL_REGEXP", label: "Full match for the regular expression" },
  { value: "PARTIAL_REGEXP", label: "Partial match for the regular expression" },
];

export { ga4DimensionFilterOperators };
