import $ from "jquery";
import { setMarkup, setMarkupEnabled } from "../actions/ppc_markup_actions";
import APIError from "../helpers/api_error";
import ReportPreviewFetcher from "../helpers/widget_preview/report_preview_fetcher";
import ReportSettingList from "../helpers/report_setting_list";
import _ from "lodash";

const ReportFormLoader = (function () {
  const initReportForm = function (reportID, callback) {
    if (reportID) {
      loadReport(reportID, callback);
    }
  };

  // converts widgets data to state for reportCanvas component
  const formattedWidgetsState = function (data) {
    var widgetLayouts = [];
    var widgetConfigs = {};

    data.map(function (widget, index) {
      var newWidgetLayout = _.clone(widget.widgetLayout);
      widgetLayouts.push(newWidgetLayout);

      widgetConfigs[widget.widgetLayout.i + ""] = _.clone(widget);
      delete widgetConfigs[widget.widgetLayout.i + ""].widgetLayout;
    });
    return { widgetLayouts, widgetConfigs };
  };

  const loadReport = function (reportID, callback) {
    let url = "/api/v1/reports/" + reportID;
    $.ajax({
      url: url,
      dataType: "json",
      cache: false,
      success: function (payload) {
        store.dispatch(setReportID(reportID));
        let attr = payload.data.attributes;
        store.dispatch(setEmailRecipients(attr.email_recipients));
        store.dispatch(selectGoogleAnalyticsProfile(attr.google_analytics_profile_id));
        store.dispatch(setReportName(attr.name));
        store.dispatch(setReportAlwaysSendPreviewFirst(attr.always_send_preview_first));

        store.dispatch(setReportSubjectMonthly(attr.subject_monthly));
        store.dispatch(setReportSubjectWeekly(attr.subject_weekly));
        store.dispatch(setReportSubjectDaily(attr.subject_daily));

        store.dispatch(setReportMessageMonthly(attr.message_monthly));
        store.dispatch(setReportMessageWeekly(attr.message_weekly));
        store.dispatch(setReportMessageDaily(attr.message_daily));

        store.dispatch(setReportEmailsMonthly(attr.emails_monthly));
        store.dispatch(setReportEmailsWeekly(attr.emails_weekly));
        store.dispatch(setReportEmailsDaily(attr.emails_daily));

        store.dispatch(setReportLanguage(attr.lang));
        store.dispatch(setReportIntervalMonthly(attr.interval_monthly));
        store.dispatch(setReportIntervalWeekly(attr.interval_weekly));
        store.dispatch(setReportIntervalDaily(attr.interval_daily));

        store.dispatch(setCompareYearToYear(attr.compare_year_to_year, "monthly"));
        store.dispatch(setCompareYearToYear(attr.weekly_compare_year_to_year, "weekly"));

        store.dispatch(setCustomVariables(attr.custom_variables));

        if (attr.compare_daily_options) {
          attr.compare_daily_options.compare_to_same_day_of_last_week =
            attr.compare_daily_options.compare_to_same_day_of_last_week === "true";
        }

        store.dispatch(setCompareDailyOptions(attr.compare_daily_options));

        store.dispatch(setReportDOW(attr.day_of_week));

        store.dispatch(gotReportCanvasConfig(formattedWidgetsState(attr.widgets)));

        store.dispatch(setLogoUrl(attr.logo_url));
        store.dispatch(setPreviewRecipient(attr.preview_recipient));
        store.dispatch(setMailchimpList(attr.mailchimp_list_id));
        store.dispatch(setFacebookPagesAccount(attr.facebook_pages_id));

        store.dispatch(setSingleMetricComparisonDisabled(attr.single_metric_comparison_disabled === true));
        store.dispatch(setReportShared(attr.shared === true));
        store.dispatch(setSharedAceessKey(attr.enable_access_password === true));
        store.dispatch(setIsInitialReportShared(attr.is_initial_report_shared === true));
        store.dispatch(setIncludePublicLinkInEmail(attr.include_public_link_in_email === true));
        store.dispatch(setReportPubliclyShareableURL(attr.public_shareable_url));
        store.dispatch(setReportAccessKey(attr.access_password));

        store.dispatch(set_FF_EnableDatePicker(attr.enbale_widget_date_feature === true));
        store.dispatch(set_FF_EnableIntegrationModal(attr.enable_integration_modal_feature === true));
        store.dispatch(setReportDraft(attr.draft === true));
        store.dispatch(setMarkupEnabled(attr.ppc_markup_enabled === "true"));
        store.dispatch(setMarkup("facebookAds", attr.ppc_markup.facebook_ads || 0));
        store.dispatch(setMarkup("googleAds", attr.ppc_markup.google_ads || 0));
        store.dispatch(setMarkup("linkedInAds", attr.ppc_markup.linkedin_ads || 0));
        store.dispatch(setAgencySettings(attr.agency_settings.data.attributes));

        // dispatch the ReportSetting stuff
        for (let index = 0; index < ReportSettingList.length; index++) {
          const settingName = ReportSettingList[index];
          store.dispatch(setReportSetting(settingName, attr[settingName]));
        }

        attr.integration_account_ids.forEach(function (account) {
          let account_key = Object.keys(account)[0];
          let value = account[account_key];

          if (!value) {
            return; // skip this one if there is no account set
          }

          let integration = window.integrationStore.findIntegrationByReducerAccountIDVariableName(account_key, true);
          if (integration === false) {
            integration = window.integrationStore.findIntegrationByOAuthProviderName(account_key);
          }

          if (integration.integrationConfig().metricsWatchIntegrationProtocolVersion == "3.0.0") {
            store.dispatch(setGlobalReportIntegrationAccountID(account_key, value));
          } else {
            store.dispatch(integration.reportStoreDispatchFunction()(value));
          }
        });

        setTimeout(function () {
          new ReportPreviewFetcher().fetch();
        }, 100);

        if (callback) {
          callback();
        }
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("ReportFormLoader", xhr, status, err);
      }.bind(this),
    });
  };

  return {
    init: function (reportID, callback) {
      initReportForm(reportID, callback);
    },
    getCurrentPath: function () {
      return window.location.pathname;
    },
  };
})();

export default ReportFormLoader;
