import GoogleSheetsApi from "./api";

export default class GoogleDrivePicker {
  constructor() {
    this.pickerApiLoaded = false;
    this.oauthToken = undefined;
    this.developerKey = undefined;
    this.clientID = undefined;
    this.appID = undefined;
    this.callback = undefined;

    this.api = new GoogleSheetsApi();

    document.addEventListener("DOMContentLoaded", () => {
      window.addEventListener(
        "open-google-drive-picker",
        (google_sheets_provider) => {
          this.callback = google_sheets_provider.detail.callback;
          this.api.fetchTokens(google_sheets_provider.detail.id).then((token) => {
            if (!token) {
              this.callback(null);
            } else {
              this.loadPicker(token);
            }
          });
        },
        false
      );
    });
  }

  loadPicker(token) {
    this.developerKey = window.googleDriveDeveloperKey;
    this.appID = window.googleDriveAppID;
    this.clientID = window.googleDriveClientID;

    this.oauthToken = token;

    gapi.load("picker", { callback: this.onPickerApiLoad.bind(this) });
  }

  onPickerApiLoad() {
    this.pickerApiLoaded = true;
    this.createPicker();
  }

  createPicker() {
    if (this.pickerApiLoaded && this.oauthToken) {
      var picker = new google.picker.PickerBuilder()
        .setAppId(this.appId)
        .setOAuthToken(this.oauthToken)
        .addView(google.picker.ViewId.SPREADSHEETS)
        .addView(new google.picker.DocsUploadView())
        .setDeveloperKey(this.developerKey)
        .setCallback(this.pickerCallback.bind(this))
        .build();
      picker.setVisible(true);
    }
    return picker;
  }

  pickerCallback(data) {
    if (data.action == google.picker.Action.PICKED) {
      var spreadsheetId = data.docs[0].id;
      this.api.getListSheets(spreadsheetId, this.oauthToken).then((sheets) => {
        this.callback(sheets);
      });
    } else if (data.action == "cancel") {
      this.callback(null);
    }
  }
}
