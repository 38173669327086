import React from "react";
var createReactClass = require("create-react-class");
import $ from "jquery";

const ReportCompareYearToYear = createReactClass({
  getInitialState: function () {
    return { checked: false };
  },

  onChange: function () {
    const newCheckedState = !this.state.checked;
    store.dispatch(setCompareYearToYear(newCheckedState, this.props.interval));
  },
  componentDidMount: function () {
    const reducerKey = `${this.props.interval}_compare_year_to_year`;
    this.storeSubscription = store.subscribe(
      function () {
        const storeState = store.getState();
        this.setState({
          checked: storeState.reportFormReducer[reducerKey],
        });
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  render: function () {
    const intervalWord = this.props.interval == "weekly" ? "week" : "month";

    return (
      <div className="field" id="reportCompareYearToYear">
        <label className="tw-label">
          <p>
            <input type="checkbox" className="tw-checkbox mr-2" checked={this.state.checked} onChange={this.onChange} />
            Year over year comparison.
          </p>
          <p className="font-normal text-sm text-gray-600">
            Instead of comparing to previous {intervalWord}, data will be compared to the same dates of the previous
            year.
          </p>
        </label>
      </div>
    );
  },
});

export default ReportCompareYearToYear;
