import React from "react";
import Select from "react-select";
import { Provider } from "react-redux";
import $ from "jquery";

import ReportPreviewRenderer from "./report_preview_renderer";
import ButtonWithIcon from "../../legacy-components/generic/button_with_icon.js";
import Button from "../../legacy-components/generic/button.js";
import FlashMessage from "../../legacy-components/flash_message.js";
import ReportPreview from "../../legacy-components/report_preview.js";

import ColorInput from "../utility/color_input";
import Drawer from "../utility/drawer";
import APIError from "../../helpers/api_error";
import { hexColorCodeIsValid } from "../../helpers/color_code_validator";
import PPCMarkup from "./ppc_markup";
import { setMarkup } from "../../actions/ppc_markup_actions";
import Themes from "../reports/visual_settings/themes.js";
import ThemeGenerator from "../reports/visual_settings/theme_generator.js";
import { BuildingStorefrontIcon, PaintBrushIcon, WrenchIcon, ReceiptPercentIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class BusinessSettings extends React.Component {
  constructor(props) {
    super(props);

    this.resetApiURL = "api/v1/business_settings/default-visual";

    this.state = {
      activeTab: "business",
      apiLoaded: false,
      errorFromAPI: false,
      colorsAreValid: true,
      previewRenderedRequestedAt: this.now(),
      apiURL: "/api/v1/business_settings",

      business__name: "",
      business__emailReplyAddress: "",
      business__emailFromAddress: "",
      business__logoUrl: "",
      business__physicalAddress: "",

      visualCustomization__global__backgroundColor: "",
      visualCustomization__global__textColor: "",

      visualCustomizations__header__slim: false,
      visualCustomizations__header__hideLogo: false,
      visualCustomizations__header__hideSiteName: false,
      visualCustomizations__header__backgroundColor: "",
      visualCustomizations__header__textColor: "",

      visualCustomization__widgets__single_metrics__positive__textColor: "",
      visualCustomization__widgets__single_metrics__positive__backgroundColor: "",
      visualCustomization__widgets__single_metrics__neutral__textColor: "",
      visualCustomization__widgets__single_metrics__neutral__backgroundColor: "",
      visualCustomization__widgets__single_metrics__negative__textColor: "",
      visualCustomization__widgets__single_metrics__negative__backgroundColor: "",

      visualCustomizations__widgets__list__backgroundColor: "",
      visualCustomizations__widgets__list__textColor: "",
      visualCustomizations__widgets__list__titleColor: "",

      visualCustomizations__widgets__graph__backgroundColor: "",
      visualCustomizations__widgets__graph__titleColor: "",
      visualCustomizations__widgets__graph__theme_colors_1: "",
      visualCustomizations__widgets__graph__theme_colors_2: "",
      visualCustomizations__widgets__graph__theme_colors_3: "",
      visualCustomizations__widgets__graph__theme_colors_4: "",
      visualCustomizations__widgets__graph__theme_colors_5: "",
      visualCustomizations__widgets__graph__theme_colors_6: "",
      visualCustomizations__widgets__graph__theme_colors_7: "",
      visualCustomizations__widgets__graph__markerColor: "",
      visualCustomizations__widgets__graph__pieMarkerColor: "",
      visualCustomizations__widgets__graph__fontColor: "",

      visualCustomization__widgets__global__backgroundColor: "",

      visualCustomizations__widgets__layout__titleColor: "",
      visualCustomizations__widgets__layout__textColor: "",
      visualCustomizations__widgets__layout__separatorColor: "",

      visualCustomizations__news__backgroundColor: "",
      visualCustomizations__news__textColor: "",

      visualCustomizations__footer__backgroundColor: "",
      visualCustomizations__footer__textColor: "",
      visualCustomizations__footer__dividerColor: "",

      advanced__unsubscription_notification: true,
      advanced__delimiter: " ",
      advanced__separator: ".",
      advanced__custom_send_time: undefined,
      advanced__custom_send_timezone: undefined,
    };
  }

  isActiveTab(tabId) {
    return this.state.activeTab === tabId;
  }

  setActiveTab(tabId) {
    this.setState({ activeTab: tabId });
  }

  componentDidMount() {
    this.setActiveTab("business-settings-tab");
    this.fetchFromAPI();
  }

  fetchFromAPI() {
    $.ajax({
      url: this.state.apiURL,
      dataType: "json",
      method: "GET",
      cache: false,
      success: function (data) {
        this.setStateFromAPI(data);
      }.bind(this),
      error: function (xhr, status, err) {
        this.setState({ errorFromAPI: true });

        new APIError("ReportPreview", xhr, status, err);
      }.bind(this),
    });
  }

  setStateFromAPI(data) {
    let attr = data.data.attributes;

    this.setState({
      business__name: attr.name,
      business__emailReplyAddress: attr.email_reply_address,
      business__emailFromAddress: attr.email_from_address,
      business__logoUrl: attr.logo_url,
      business__physicalAddress: attr.address,

      advanced__unsubscription_notification: attr.unsubscription_notification,
      advanced__delimiter: attr.delimiter || "",
      advanced__separator: attr.separator || "",
      advanced__custom_send_time: attr.send_time,
      advanced__custom_send_timezone: attr.send_timezone,
    });

    this.setVisualCustomizations(attr.visual);

    store.dispatch(setMarkup("facebookAds", attr.ppc_markup.facebook_ads));
    store.dispatch(setMarkup("googleAds", attr.ppc_markup.google_ads));
    store.dispatch(setMarkup("linkedInAds", attr.ppc_markup.linkedin_ads));
  }

  setVisualCustomizations(visualAttributes) {
    this.setState(
      {
        apiLoaded: true,
        visualCustomization__global__backgroundColor: visualAttributes.global.backgroundColor,
        visualCustomization__global__textColor: visualAttributes.global.text.color,

        visualCustomizations__header__slim: visualAttributes.header.slim,
        visualCustomizations__header__hideLogo: visualAttributes.header.hideLogo,
        visualCustomizations__header__hideSiteName: visualAttributes.header.hideSiteName,
        visualCustomizations__header__backgroundColor: visualAttributes.header.backgroundColor,
        visualCustomizations__header__textColor: visualAttributes.header.text.color,

        visualCustomization__widgets__single_metrics__positive__textColor:
          visualAttributes.widgets.singleMetrics.positiveTextColor,
        visualCustomization__widgets__single_metrics__positive__backgroundColor:
          visualAttributes.widgets.singleMetrics.positiveBackgroundColor,
        visualCustomization__widgets__single_metrics__neutral__textColor:
          visualAttributes.widgets.singleMetrics.neutralTextColor,
        visualCustomization__widgets__single_metrics__neutral__backgroundColor:
          visualAttributes.widgets.singleMetrics.neutralBackgroundColor,
        visualCustomization__widgets__single_metrics__negative__textColor:
          visualAttributes.widgets.singleMetrics.negativeTextColor,
        visualCustomization__widgets__single_metrics__negative__backgroundColor:
          visualAttributes.widgets.singleMetrics.negativeBackgroundColor,
        visualCustomization__widgets__global__backgroundColor: visualAttributes.widgets.global.backgroundColor,

        visualCustomizations__widgets__list__backgroundColor: visualAttributes.widgets.list.backgroundColor,
        visualCustomizations__widgets__list__textColor: visualAttributes.widgets.list.text.color,
        visualCustomizations__widgets__list__titleColor: visualAttributes.widgets.list.title.color,

        visualCustomizations__widgets__graph__backgroundColor: visualAttributes.widgets.graphBox.backgroundColor,
        visualCustomizations__widgets__graph__titleColor: visualAttributes.widgets.graphBox.title.color,
        visualCustomizations__widgets__graph__theme_colors_1:
          visualAttributes.widgets.graphBox.graphTheme.colors.color_1,
        visualCustomizations__widgets__graph__theme_colors_2:
          visualAttributes.widgets.graphBox.graphTheme.colors.color_2,
        visualCustomizations__widgets__graph__theme_colors_3:
          visualAttributes.widgets.graphBox.graphTheme.colors.color_3,
        visualCustomizations__widgets__graph__theme_colors_4:
          visualAttributes.widgets.graphBox.graphTheme.colors.color_4,
        visualCustomizations__widgets__graph__theme_colors_5:
          visualAttributes.widgets.graphBox.graphTheme.colors.color_5,
        visualCustomizations__widgets__graph__theme_colors_6:
          visualAttributes.widgets.graphBox.graphTheme.colors.color_6,
        visualCustomizations__widgets__graph__theme_colors_7:
          visualAttributes.widgets.graphBox.graphTheme.colors.color_7,

        visualCustomizations__widgets__graph__markerColor: visualAttributes.widgets.graphBox.graphTheme.markerColor,
        visualCustomizations__widgets__graph__pieMarkerColor:
          visualAttributes.widgets.graphBox.graphTheme.pieMarkerColor ||
          visualAttributes.widgets.graphBox.graphTheme.markerColor,
        visualCustomizations__widgets__graph__fontColor: visualAttributes.widgets.graphBox.graphTheme.fontColor,

        visualCustomizations__widgets__layout__titleColor: visualAttributes.widgets.layout.title.color,
        visualCustomizations__widgets__layout__textColor: visualAttributes.widgets.layout.text.color,
        visualCustomizations__widgets__layout__separatorColor: visualAttributes.widgets.layout.separator.color,

        visualCustomizations__news__backgroundColor: visualAttributes.news.backgroundColor,
        visualCustomizations__news__textColor: visualAttributes.news.text.color,

        visualCustomizations__footer__backgroundColor: visualAttributes.footer.backgroundColor,
        visualCustomizations__footer__textColor: visualAttributes.footer.text.color,
        visualCustomizations__footer__dividerColor: visualAttributes.footer.divider.color,
      },
      () => {
        this.validateColors();
      }
    );
  }

  now() {
    let now = new Date();

    return now.getTime();
  }

  businessSettingsForAPI() {
    return {
      business: {
        name: this.state.business__name,
        email_reply_address: this.state.business__emailReplyAddress,

        /* It's not possible to update that for clients at the moment. */
        // email_from_address: '',

        logo_url: this.state.business__logoUrl,
        physical_address: this.state.business__physicalAddress,
      },

      visualCustomization: {
        global: {
          backgroundColor: this.state.visualCustomization__global__backgroundColor,
          text: {
            color: this.state.visualCustomization__global__textColor,
          },
        },
        header: {
          slim: this.state.visualCustomizations__header__slim,
          hideLogo: this.state.visualCustomizations__header__hideLogo,
          hideSiteName: this.state.visualCustomizations__header__hideSiteName,
          backgroundColor: this.state.visualCustomizations__header__backgroundColor,
          textColor: this.state.visualCustomizations__header__textColor,
        },
        widgets: {
          global: {
            backgroundColor: this.state.visualCustomization__widgets__global__backgroundColor,
          },
          singleMetrics: {
            positiveTextColor: this.state.visualCustomization__widgets__single_metrics__positive__textColor,
            positiveBackgroundColor: this.state.visualCustomization__widgets__single_metrics__positive__backgroundColor,
            neutralTextColor: this.state.visualCustomization__widgets__single_metrics__neutral__textColor,
            neutralBackgroundColor: this.state.visualCustomization__widgets__single_metrics__neutral__backgroundColor,
            negativeTextColor: this.state.visualCustomization__widgets__single_metrics__negative__textColor,
            negativeBackgroundColor: this.state.visualCustomization__widgets__single_metrics__negative__backgroundColor,
          },
          list: {
            backgroundColor: this.state.visualCustomizations__widgets__list__backgroundColor,
            title: {
              color: this.state.visualCustomizations__widgets__list__titleColor,
            },
            text: {
              color: this.state.visualCustomizations__widgets__list__textColor,
            },
          },
          graphBox: {
            backgroundColor: this.state.visualCustomizations__widgets__graph__backgroundColor,
            title: {
              color: this.state.visualCustomizations__widgets__graph__titleColor,
            },
            graphTheme: {
              colors: {
                color_1: this.state.visualCustomizations__widgets__graph__theme_colors_1,
                color_2: this.state.visualCustomizations__widgets__graph__theme_colors_2,
                color_3: this.state.visualCustomizations__widgets__graph__theme_colors_3,
                color_4: this.state.visualCustomizations__widgets__graph__theme_colors_4,
                color_5: this.state.visualCustomizations__widgets__graph__theme_colors_5,
                color_6: this.state.visualCustomizations__widgets__graph__theme_colors_6,
                color_7: this.state.visualCustomizations__widgets__graph__theme_colors_7,
              },
              markerColor: this.state.visualCustomizations__widgets__graph__markerColor,
              pieMarkerColor: this.state.visualCustomizations__widgets__graph__pieMarkerColor,
              fontColor: this.state.visualCustomizations__widgets__graph__fontColor,
            },
          },
          layout: {
            title: {
              color: this.state.visualCustomizations__widgets__layout__titleColor,
            },
            text: {
              color: this.state.visualCustomizations__widgets__layout__textColor,
            },
            separator: {
              color: this.state.visualCustomizations__widgets__layout__separatorColor,
            },
          },
        },
        footer: {
          backgroundColor: this.state.visualCustomizations__footer__backgroundColor,
          text: {
            color: this.state.visualCustomizations__footer__textColor,
          },
          divider: {
            color: this.state.visualCustomizations__footer__dividerColor,
          },
        },
        news: {
          backgroundColor: this.state.visualCustomizations__news__backgroundColor,
          text: {
            color: this.state.visualCustomizations__news__textColor,
          },
        },
      },

      ppc_markup: {
        facebook_ads: store.getState().ppcMarkupReducer.facebookAds,
        google_ads: store.getState().ppcMarkupReducer.googleAds,
        linkedin_ads: store.getState().ppcMarkupReducer.linkedInAds,
      },

      advanced: {
        unsubscription_notification: this.state.advanced__unsubscription_notification,
        delimiter: this.state.advanced__delimiter,
        separator: this.state.advanced__separator,

        /* It's not possible to update that for clients at the moment. */
        // custom_send_time: this.state.advanced__custom_send_time,
        // custom_send_timezone: this.state.advanced__custom_send_timezone,
      },
    };
  }

  componentDidUpdate() {
    this.validateColors();
  }

  validateColors() {
    const colorsAreValid = this.allColorsAreValid();

    if (colorsAreValid != this.state.colorsAreValid) {
      this.setState({ colorsAreValid: colorsAreValid });
    }

    if (colorsAreValid) {
      store.dispatch(setFlashMessage("", []));
    } else {
      store.dispatch(
        setFlashMessage("Previews and saving are disabled:", [
          'At least one color is invalid. Look int he "Visual" tab.',
        ])
      );
    }
  }

  allColorsAreValid() {
    for (let [stateVariableName, value] of Object.entries(this.state)) {
      if (
        stateVariableName != "colorsAreValid" && // This state variable is not a color code, so we don't care about that one and just ignores it (do not test its validity)
        (stateVariableName.includes("color") || stateVariableName.includes("Color")) &&
        !stateVariableName.includes("themeGeneratorColor") // excludes the theme generator colors from validation
      ) {
        if (hexColorCodeIsValid(value) == false) {
          return false;
        }
      }
    }

    return true;
  }

  renderPreview() {
    if (!this.state.colorsAreValid) {
      return;
    }

    this.setState({ previewRenderedRequestedAt: this.now() });
  }

  save() {
    if (!this.state.colorsAreValid) {
      return;
    }

    $.ajax({
      url: this.state.apiURL,
      dataType: "json",
      method: "PUT",
      data: this.businessSettingsForAPI(),
      cache: false,
      success: function (data) {
        store.dispatch(setFlashMessage("The settings were succesfully saved.", []));
      }.bind(this),
      error: function (xhr, status, err) {
        store.dispatch(
          setFlashMessage("We encountered an error while trying to save the settings:", ["Unknown error."])
        );

        new APIError("BusinessSettingsUpdate", xhr, status, err);
      }.bind(this),
    });
  }

  setVisualTheme(el) {
    let value;
    if (el) {
      value = el.value;
    }

    this.setState({ visualCustomization__theme: value });
  }

  applyVisualTheme() {
    const whiteLogo = "https://report-assets.s3.amazonaws.com/logo/Logo+-+1C+White.png";
    const selectedTheme = Themes[this.state.visualCustomization__theme];

    if (selectedTheme) {
      this.setVisualCustomizations(selectedTheme.theme);

      if (selectedTheme.theme.baseColors) {
        this.setState({
          themeGeneratorColor1: selectedTheme.theme.baseColors.color1,
          themeGeneratorColor2: selectedTheme.theme.baseColors.color2,
          themeGeneratorColor3: selectedTheme.theme.baseColors.color3,
        });
      }

      if (this.state.business__logoUrl == whiteLogo && selectedTheme.logo == "default") {
        this.setState({ business__logoUrl: undefined });
      }

      if (!this.state.business__logoUrl || String(this.state.business__logoUrl).trim() == "") {
        if (selectedTheme.logo == "white") {
          this.setState({ business__logoUrl: "https://report-assets.s3.amazonaws.com/logo/Logo+-+1C+White.png" });
        }
      }
    }
  }

  generateTheme() {
    let theme = ThemeGenerator(
      this.state.themeGeneratorColor1,
      this.state.themeGeneratorColor2,
      this.state.themeGeneratorColor3
    );

    this.setVisualCustomizations(theme);
  }

  resetVisual() {
    if (
      window.confirm(
        "Are you sure you want to reset all your visual customizations? (It will NOT reset any other customization)"
      )
    ) {
      $.ajax({
        url: this.resetApiURL,
        dataType: "json",
        method: "GET",
        cache: false,
        success: function (visualAttributes) {
          this.setVisualCustomizations(visualAttributes);
        }.bind(this),
        error: function (xhr, status, err) {
          this.setState({ errorFromAPI: true });

          new APIError("ReportVisualDefault", xhr, status, err);
        }.bind(this),
      });
    }
  }

  render() {
    if (this.state.errorFromAPI === true) {
      return <div>There was an unknown error while loading the settings. Try refreshing the page.</div>;
    }

    let settingsTabHeight = window.innerHeight - 275;

    const defaultClasses = "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium";
    let tabClasses = defaultClasses + "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700";
    let activeTabClasses = "border-indigo-500 text-indigo-600";

    let visibleTabContentClasses = "lg:w-1/2 lg:pr-4";
    const tabs = [
      { name: "Business", contentId: "business-settings-tab", icon: BuildingStorefrontIcon, current: true },
      { name: "Visual settings", contentId: "visual-settings-tab", icon: PaintBrushIcon, current: false },
      { name: "PPC Markup", contentId: "markup-settings-tab", icon: ReceiptPercentIcon, current: false },
      { name: "Advanced", contentId: "advanced-settings-tab", icon: WrenchIcon, current: false },
    ];

    return (
      <div>
        <div className="">
          <h1 className="tw-page-title mb-4 mt-2">Business &amp; brand settings</h1>
        </div>

        <FlashMessage />

        <div className="mb-4 bg-white">
          <div className="sm:hidden">
            <select
              aria-label="Selected tab"
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              name="tabs"
              id="tabs"
              defaultValue={tabs.find((tab) => tab.current).contentId}
            >
              {tabs.map((tab) => (
                <option key={tab.contentId}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8">
                {tabs.map((tab) => (
                  <a
                    key={tab.contentId}
                    href="#"
                    data-tab-content-id={tab.contentId}
                    onClick={() => this.setActiveTab(tab.contentId)}
                    className={classNames(
                      this.isActiveTab(tab.contentId)
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    <tab.icon
                      className={classNames(
                        this.isActiveTab(tab.contentId) ? "text-indigo-500" : "text-gray-400 group-hover:text-gray-500",
                        "-ml-0.5 mr-2 h-5 w-5"
                      )}
                      aria-hidden="true"
                    />
                    <span>{tab.name}</span>
                  </a>
                ))}
                {/* <a
                  href="#"
                  className={this.isActiveTab("business") ? activeTabClasses : tabClasses}
                  data-tab-content-id="business-settings-tab"
                  onClick={() => this.setActiveTab("business")}
                >
                  <i className="fas fa-building mr-2-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
                  <span>Business</span>
                </a>
                <a
                  href="#"
                  className={this.isActiveTab("visual") ? activeTabClasses : tabClasses}
                  data-tab-content-id="visual-settings-tab"
                  onClick={() => this.setActiveTab("visual")}
                >
                  <i className="fas fa-paint-brush mr-2-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
                  <span>Visual settings</span>
                </a>
                <a
                  href="#"
                  className={this.isActiveTab("markup") ? activeTabClasses : tabClasses}
                  data-tab-content-id="markup-settings-tab"
                  onClick={() => this.setActiveTab("markup")}
                >
                  <i className="fas fa-percent -ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
                  <span>PPC Markup</span>
                </a>
                <a
                  href="#"
                  className={this.isActiveTab("advanced") ? activeTabClasses : tabClasses}
                  data-tab-content-id="advanced-settings-tab"
                  onClick={() => this.setActiveTab("advanced")}
                >
                  <i className="fas fa-cog mr-2-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
                  <span>Advanced</span>
                </a> */}
              </nav>
            </div>
          </div>
        </div>

        <div className="lg:flex lg:flex-row items-stretch mb-8">
          {/* BUSINESS TAB  */}
          <div
            id="business-settings-tab"
            style={{
              display: this.isActiveTab("business-settings-tab") ? "block" : "none",
            }}
            className={visibleTabContentClasses}
          >
            <h3 className="tw-medium-header my-4">Business settings</h3>
            <div className="mb-4">
              <label className="tw-label">Business name</label>
              <p className="text-sm italic mb-2">The email will be sent from your business name if you set this.</p>
              <input
                className="tw-field mb-2"
                onChange={function (e) {
                  this.setState({ business__name: e.target.value });
                }.bind(this)}
                value={this.state.business__name}
                type="text"
              />
            </div>
            <div className="mb-4">
              <label className="tw-label">Email reply address</label>
              <p className="text-sm italic mb-2">The replies to the email reports will go to this email.</p>
              <input
                className="tw-field mb-2"
                onChange={function (e) {
                  this.setState({
                    business__emailReplyAddress: e.target.value,
                  });
                }.bind(this)}
                value={this.state.business__emailReplyAddress}
                type="text"
              />
            </div>
            <div className="mb-4">
              <label className="tw-label">Email from address</label>
              <p className="text-sm italic mb-2">
                By default, we will send the reports from our own email address. But if you want, you can go for the
                ultimate white label option: we can send the email reports from your email &amp; domain. This will
                require manual configuration on your end (DNS) and our end so that it's not considered as spam by spam
                softwares. Reach out to us to set or change this.
              </p>
              <input
                className="tw-field mb-2"
                disabled="disabled"
                value={this.state.business__emailFromAddress || ""}
                type="text"
              />
            </div>
            <div className="mb-4">
              <label className="tw-label">Logo URL</label>
              <p className="text-sm italic mb-2">
                The URL to your business logo. It will be included at the top of the email reports.
              </p>
              <input
                className="tw-field mb-2"
                onChange={function (e) {
                  this.setState({ business__logoUrl: e.target.value });
                }.bind(this)}
                value={this.state.business__logoUrl}
                type="text"
              />
            </div>
            <div className="mb-4">
              <label className="tw-label">Physical address</label>
              <p className="text-sm italic mb-2">
                This will be used in the footer of the email to comply to anti-spam laws. If it's not filled, our
                address and name will be used in the footer instead. Do NOT include your agency name, we will add it for
                you in the email. It requires you to set your business name in the "Business Name" section above in
                order to be used.
              </p>
              <textarea
                rows={5}
                onChange={function (e) {
                  this.setState({
                    business__physicalAddress: e.target.value,
                  });
                }.bind(this)}
                value={this.state.business__physicalAddress}
                className="tw-field mb-2"
              />
            </div>
          </div>

          {/* VISUAL TAB  */}
          <div
            id="visual-settings-tab"
            style={{
              display: this.isActiveTab("visual-settings-tab") ? "block" : "none",
            }}
            className={visibleTabContentClasses}
          >
            <h3 className="tw-medium-header my-4">Visual settings</h3>
            <p className="text-sm italic mb-2">
              Note: themes and custom colors will not be applied to the report builder interface but only to the reports
              sent by email, shared via the web or PDF exports.
            </p>
            <h4 className="tw-small-header my-4">Pre-made Themes</h4>
            <div className="flex">
              <Select
                className="w-96 mr-2"
                value={this.state.visualCustomization__theme}
                label="Theme"
                options={[
                  { value: "default", label: "Original (default)" },
                  { value: "brown_vintage", label: "Brown Vintage" },
                  { value: "dark_blue", label: "Dark Blue" },
                  { value: "deep_purple", label: "Deep Purple" },
                  { value: "fire", label: "Fire" },
                  { value: "neon_blue", label: "Neon Blue" },
                  { value: "pink_pastel", label: "Pink Pastel" },
                  { value: "spring", label: "Spring" },
                  { value: "violet_turquoise", label: "Violets are turquoise" },
                ]}
                onChange={this.setVisualTheme.bind(this)}
              />
              <button className="tw-button my-auto" onClick={this.applyVisualTheme.bind(this)}>
                Apply theme
              </button>
            </div>

            <h4 className="tw-small-header my-4">Theme Generator</h4>
            <p className="text-sm italic mb-2">
              You can generate your custom theme by picking three colors. This is a middle ground between our pre-made
              themes and the advanced theming options. After generating your theme, you can tweak the colors to your
              liking in the advanced settings.
            </p>
            <div className="">
              <div>
                <ColorInput
                  scrollableParentID="visual-settings-tab"
                  label="Color 1 (mainly used for the background)"
                  onChange={function (color) {
                    this.setState({
                      themeGeneratorColor1: color,
                    });
                  }.bind(this)}
                  validWhenEmpty={true}
                  color={this.state.themeGeneratorColor1}
                />

                <ColorInput
                  scrollableParentID="visual-settings-tab"
                  label="Color 2 (mainly used for text outside of widgets and widget background)"
                  onChange={function (color) {
                    this.setState({
                      themeGeneratorColor2: color,
                    });
                  }.bind(this)}
                  validWhenEmpty={true}
                  color={this.state.themeGeneratorColor2}
                />

                <ColorInput
                  scrollableParentID="visual-settings-tab"
                  label="Color 3 (text in widgets, also used to create a color palette for charts and pies)"
                  onChange={function (color) {
                    this.setState({
                      themeGeneratorColor3: color,
                    });
                  }.bind(this)}
                  validWhenEmpty={true}
                  color={this.state.themeGeneratorColor3}
                />
                <button className="tw-button my-auto" onClick={this.generateTheme.bind(this)}>
                  Generate your theme
                </button>
              </div>
            </div>

            <h4 className="tw-small-header my-4">Advanced (custom colors)</h4>

            {/* GLOBAL SECTION */}
            <Drawer title="Global">
              <div className="is-flex">
                <ColorInput
                  scrollableParentID="visual-settings-tab"
                  label="Background color"
                  onChange={function (color) {
                    this.setState({
                      visualCustomization__global__backgroundColor: color,
                    });
                  }.bind(this)}
                  color={this.state.visualCustomization__global__backgroundColor}
                />

                <ColorInput
                  scrollableParentID="visual-settings-tab"
                  label="Text color"
                  onChange={function (color) {
                    this.setState({
                      visualCustomization__global__textColor: color,
                    });
                  }.bind(this)}
                  color={this.state.visualCustomization__global__textColor}
                />
              </div>
            </Drawer>

            {/* HEADER SECTION */}
            <Drawer title="Header">
              <div className="mb-4">
                <label className="tw-label mx-4 my-2" htmlFor="agency_setting_slim_header">
                  <input
                    id="agency_setting_slim_header"
                    className="mr-2 tw-checkbox"
                    type="checkbox"
                    checked={this.state.visualCustomizations__header__slim}
                    onChange={function () {
                      this.setState({
                        visualCustomizations__header__slim: event.target.checked,
                      });
                    }.bind(this)}
                  />
                  Slim header (no logo, no site name, only report dates)
                </label>

                <label className="tw-label mx-4 my-2" htmlFor="agency_setting_no_logo">
                  <input
                    id="agency_setting_no_logo"
                    className="mr-2 tw-checkbox"
                    type="checkbox"
                    checked={this.state.visualCustomizations__header__hideLogo}
                    onChange={function () {
                      this.setState({
                        visualCustomizations__header__hideLogo: event.target.checked,
                      });
                    }.bind(this)}
                  />
                  Do not show logo in header
                </label>

                <label className="tw-label mx-4 my-2" htmlFor="agency_setting_no_site_name">
                  <input
                    id="agency_setting_no_site_name"
                    className="mr-2 tw-checkbox"
                    type="checkbox"
                    checked={this.state.visualCustomizations__header__hideSiteName}
                    onChange={function () {
                      this.setState({
                        visualCustomizations__header__hideSiteName: event.target.checked,
                      });
                    }.bind(this)}
                  />
                  Do not show the site name in header
                </label>
              </div>

              <div className="">
                <ColorInput
                  scrollableParentID="visual-settings-tab"
                  label="Background color"
                  onChange={function (color) {
                    this.setState({
                      visualCustomizations__header__backgroundColor: color,
                    });
                  }.bind(this)}
                  color={this.state.visualCustomizations__header__backgroundColor}
                />

                <ColorInput
                  scrollableParentID="visual-settings-tab"
                  label="Text color"
                  onChange={function (color) {
                    this.setState({
                      visualCustomizations__header__textColor: color,
                    });
                  }.bind(this)}
                  color={this.state.visualCustomizations__header__textColor}
                />
              </div>
            </Drawer>

            {/* WIDGETS SECTION */}
            <Drawer title="Widgets">
              <Drawer title="Global widget settings" textSizeClass="text-sm" headerColorClass="text-gray-600">
                <div className="is-flex">
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Background color of the widgets' section"
                    onChange={function (color) {
                      this.setState({
                        visualCustomization__widgets__global__backgroundColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomization__widgets__global__backgroundColor}
                  />
                </div>
              </Drawer>

              <Drawer title="Single Metric widget" textSizeClass="text-sm" headerColorClass="text-gray-600">
                <div className="is-flex">
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Positive text color"
                    description="Text color of metric boxes when it improved vs the last period."
                    onChange={function (color) {
                      this.setState({
                        visualCustomization__widgets__single_metrics__positive__textColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomization__widgets__single_metrics__positive__textColor}
                  />

                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Positive background color"
                    description="Background color of metric boxes when it improved vs the last period."
                    onChange={function (color) {
                      this.setState({
                        visualCustomization__widgets__single_metrics__positive__backgroundColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomization__widgets__single_metrics__positive__backgroundColor}
                  />
                </div>

                <div className="is-flex">
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Neutral text color"
                    description="Text color of metric boxes when it stayed similar."
                    onChange={function (color) {
                      this.setState({
                        visualCustomization__widgets__single_metrics__neutral__textColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomization__widgets__single_metrics__neutral__textColor}
                  />

                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Neutral background color"
                    description="Background color of metric boxes when it stayed similar."
                    onChange={function (color) {
                      this.setState({
                        visualCustomization__widgets__single_metrics__neutral__backgroundColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomization__widgets__single_metrics__neutral__backgroundColor}
                  />
                </div>

                <div className="is-flex">
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Negative text color"
                    description="Text color of metric boxes when it declined since last period."
                    onChange={function (color) {
                      this.setState({
                        visualCustomization__widgets__single_metrics__negative__textColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomization__widgets__single_metrics__negative__textColor}
                  />

                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Negative background color"
                    description="Background color of metric boxes when it declined since last period."
                    onChange={function (color) {
                      this.setState({
                        visualCustomization__widgets__single_metrics__negative__backgroundColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomization__widgets__single_metrics__negative__backgroundColor}
                  />
                </div>
              </Drawer>

              <Drawer title="List widget" textSizeClass="text-sm" headerColorClass="text-gray-600">
                <div className="is-flex">
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Background color"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__list__backgroundColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__list__backgroundColor}
                  />

                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Text color"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__list__textColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__list__textColor}
                  />

                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Title color"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__list__titleColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__list__titleColor}
                  />
                </div>
              </Drawer>

              <Drawer title="Graph widgets" textSizeClass="text-sm" headerColorClass="text-gray-600">
                <div className="is-flex">
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Background color"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__graph__backgroundColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__graph__backgroundColor}
                  />

                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Title color"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__graph__titleColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__graph__titleColor}
                  />
                </div>
                <div className="is-flex">
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Font color"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__graph__fontColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__graph__fontColor}
                  />

                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Marker color"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__graph__markerColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__graph__markerColor}
                  />

                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Pie Percentage color"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__graph__pieMarkerColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__graph__pieMarkerColor}
                  />
                </div>

                <div className="is-flex">
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Graph color #1"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__graph__theme_colors_1: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__graph__theme_colors_1}
                  />
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Graph color #2"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__graph__theme_colors_2: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__graph__theme_colors_2}
                  />
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Graph color #3"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__graph__theme_colors_3: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__graph__theme_colors_3}
                  />
                </div>
                <div className="is-flex">
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Graph color #4"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__graph__theme_colors_4: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__graph__theme_colors_4}
                  />
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Graph color #5"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__graph__theme_colors_5: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__graph__theme_colors_5}
                  />
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Graph color #6"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__graph__theme_colors_6: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__graph__theme_colors_6}
                  />
                </div>
                <div className="is-flex">
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Graph color #7"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__graph__theme_colors_7: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__graph__theme_colors_7}
                  />
                </div>
              </Drawer>

              <Drawer title="Layout widgets" textSizeClass="text-sm" headerColorClass="text-gray-600">
                <div className="is-flex">
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Title widget color"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__layout__titleColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__layout__titleColor}
                  />
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Text widget color"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__layout__textColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__layout__textColor}
                  />
                  <ColorInput
                    scrollableParentID="visual-settings-tab"
                    label="Separator color"
                    onChange={function (color) {
                      this.setState({
                        visualCustomizations__widgets__layout__separatorColor: color,
                      });
                    }.bind(this)}
                    color={this.state.visualCustomizations__widgets__layout__separatorColor}
                  />
                </div>
              </Drawer>
            </Drawer>

            {/* NEWS SECTION */}
            <Drawer title="News section">
              <div className="is-flex">
                <ColorInput
                  scrollableParentID="visual-settings-tab"
                  label="Text color"
                  onChange={function (color) {
                    this.setState({
                      visualCustomizations__news__textColor: color,
                    });
                  }.bind(this)}
                  color={this.state.visualCustomizations__news__textColor}
                />

                <ColorInput
                  scrollableParentID="visual-settings-tab"
                  label="Background color"
                  onChange={function (color) {
                    this.setState({
                      visualCustomizations__news__backgroundColor: color,
                    });
                  }.bind(this)}
                  color={this.state.visualCustomizations__news__backgroundColor}
                />
              </div>
            </Drawer>

            {/* FOOTER SECTION */}
            <Drawer title="Footer">
              <div className="is-flex">
                <ColorInput
                  scrollableParentID="visual-settings-tab"
                  label="Background color"
                  onChange={function (color) {
                    this.setState({
                      visualCustomizations__footer__backgroundColor: color,
                    });
                  }.bind(this)}
                  color={this.state.visualCustomizations__footer__backgroundColor}
                />

                <ColorInput
                  scrollableParentID="visual-settings-tab"
                  label="Text color"
                  onChange={function (color) {
                    this.setState({
                      visualCustomizations__footer__textColor: color,
                    });
                  }.bind(this)}
                  color={this.state.visualCustomizations__footer__textColor}
                />

                <ColorInput
                  scrollableParentID="visual-settings-tab"
                  label="Divider color"
                  onChange={function (color) {
                    this.setState({
                      visualCustomizations__footer__dividerColor: color,
                    });
                  }.bind(this)}
                  color={this.state.visualCustomizations__footer__dividerColor}
                />
              </div>
            </Drawer>
          </div>

          {/* MARKUP TAB  */}
          <div
            id="markup-settings-tab"
            style={{
              display: this.isActiveTab("markup-settings-tab") ? "block" : "none",
            }}
            className={visibleTabContentClasses}
          >
            <h3 className="tw-medium-header my-4">PPC Markup</h3>
            <p className="text-sm italic">
              The PPC markups you set here will apply to all your reports. However, you can set custom PPC markups
              directly in each report's settings.
            </p>
            <Provider store={store}>
              <PPCMarkup />
            </Provider>
          </div>

          {/* ADVANCED TAB  */}
          <div
            id="advanced-settings-tab"
            style={{
              display: this.isActiveTab("advanced-settings-tab") ? "block" : "none",
            }}
            className={visibleTabContentClasses}
          >
            <h3 className="tw-medium-header my-4">Advanced settings</h3>
            {/* Unsub notifications */}
            <h4 className="tw-small-header mb-2">Unsubscription notification</h4>
            <label className="tw-label my-2" htmlFor="agency_setting_unsubscription_notification">
              <input
                className="mr-2 tw-checkbox"
                type="checkbox"
                id="agency_setting_unsubscription_notification"
                checked={this.state.advanced__unsubscription_notification}
                onChange={function () {
                  this.setState({
                    advanced__unsubscription_notification: event.target.checked,
                  });
                }.bind(this)}
              />
              Receive an email every time someone unsubscribes from a report
            </label>

            {/* Custom delimiter and separator */}
            <h4 className="tw-small-header mt-8 mb-2">Custom number delimiter and separator</h4>
            <div className="my-4">
              <label className="tw-label" htmlFor="agency_setting_delimiter">
                Thousand delimiter
              </label>
              <input
                className="tw-field underline"
                style={{ textDecoration: "underline" }}
                onChange={function (e) {
                  this.setState({ advanced__delimiter: e.target.value || "" });
                }.bind(this)}
                value={this.state.advanced__delimiter}
                type="text"
              />
              <p className="my-2 text-sm italic">
                What characters do you want to separate thousands? Default is space, which looks like this: 123 456
              </p>
              <p className="my-2 text-sm">
                Example with your configured delimiter: <br />
                {"123456789.00"
                  .replace(".", this.state.advanced__separator)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, this.state.advanced__delimiter)}
              </p>
            </div>

            <div className="my-4">
              <label className="tw-label" htmlFor="agency_setting_separator">
                Digit separator
              </label>
              <input
                className="tw-field underline"
                style={{ textDecoration: "underline" }}
                onChange={function (e) {
                  this.setState({ advanced__separator: e.target.value || "" });
                }.bind(this)}
                value={this.state.advanced__separator}
                type="text"
              />
              <p className="my-2 text-sm italic">
                What characters do you want to be the digit separator? Default is dot (.), which looks like this: 123.00
              </p>
              <p className="my-2 text-sm">
                Example with your configured separator: <br />
                {"123456789.00"
                  .replace(".", this.state.advanced__separator)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, this.state.advanced__delimiter)}
              </p>
            </div>

            {/* Custom send times */}
            <h4 className="tw-small-header mt-8 mb-2">Custom send time</h4>
            <div className="my-4">
              <label className="tw-label" htmlFor="agency_setting_send_time">
                Custom report send time
              </label>
              <input
                className="tw-field disabled"
                disabled="disabled"
                type="text"
                value={this.state.advanced__custom_send_time || ""}
                id="agency_setting_send_time"
              />
              <p className="text-sm italic">
                All reports start to be sent at this time, no matter if they are daily, weekly or monthly. Reach out to
                us to set or change this.
              </p>
            </div>
            <div className="my-4">
              <label className="tw-label" htmlFor="agency_setting_send_timezone">
                Custom report send timezone
              </label>
              <input
                className="tw-field disabled"
                disabled="disabled"
                type="text"
                value={this.state.advanced__custom_send_timezone || ""}
                id="agency_setting_send_timezone"
              />
              <p className="text-sm italic">Reach out to us to set or change this.</p>
            </div>
          </div>

          <div className="lg:w-1/2 flex flex-col">
            <ReportPreviewRenderer
              renderRequestedAt={this.now()}
              businessSettings={this.businessSettingsForAPI()}
              settingsAreValid={this.allColorsAreValid()}
            />
          </div>
        </div>

        <div className="bottom-action-bar">
          <ButtonWithIcon
            onClick={function () {
              this.save();
            }.bind(this)}
            icon="fa-check"
            text="Save"
            extraClasses="tw-submit-button"
          />
          &nbsp;
          <Button
            onClick={function () {
              this.renderPreview();
            }.bind(this)}
            text="Refresh preview"
            extraClasses=""
          />
          &nbsp;
          <ReportPreview dataForAPI={this.businessSettingsForAPI()} />
          &nbsp;
          <Button
            onClick={function () {
              this.resetVisual();
            }.bind(this)}
            text="Reset visual customizations"
            extraClasses="tw-danger-button"
          />
          &nbsp;
        </div>
      </div>
    );
  }
}

export default BusinessSettings;
