import React from "react";
import { connect } from "react-redux";
// the editor is specific to Google Sheets
import GoogleSheetsSingleMetricEditor from "./gs_single_metric_editor";
import GoogleSheetsApi from "../../api";
import LoadSpinner from "../../../../components/utility/load_spinner";
import WidgetPreviewFetcher from "../../../../helpers/widget_preview/widget_preview_fetcher";
import GoogleSheetsActions from "../../google_sheets_actions";

let providerAccount;
let currentlyRendering = false;
let isCurrency = false;
let dataTypeList = [];
let sheetId;
let gsActions;

class SingleMetricEditor extends React.Component {
  constructor(props) {
    super(props);
    this.api = new GoogleSheetsApi();
    gsActions = new GoogleSheetsActions();

    this.state = {
      metrics: [],
    };

    if (typeof this.props.gsDataType !== "undefined") {
      dataTypeList = this.props.gsDataType;
    }
  }

  componentDidMount() {
    const googleSheetsMetrics = store.getState().widgetReducer.googleSheetsMetricsForCanvas;
    this.flagAccountSaved = false;
    if (typeof this.props.googleSheetsAccount !== "undefined") {
      this.flagAccountSaved = true;
    }
    this.setState({ metrics: googleSheetsMetrics });
  }

  saveAndClose() {
    if (
      !this.props.gsFileName ||
      !this.props.gsSheetId ||
      !this.props.googleSheetsAccount ||
      !this.props.metric_name ||
      !this.props.valueHandling ||
      !this.props.selectedSheet
    ) {
      store.dispatch(
        setFlashMessage("All fields are required", ["Please complete the form before saving and closing."])
      );
    } else {
      store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
      this.props.save();

      new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

      this.props.closeWidgetEditModal();
    }
  }

  deleteWidget() {
    store.dispatch(setFlashMessage(undefined, []));
    this.props.deleteWidget();
  }

  close() {
    // Validate if the widget was already saved before, and we can safely save it and assume it has a valid configuration.
    if (!this.flagAccountSaved) {
      store.dispatch(
        setFlashMessage("All field are required", [
          "Please complete the form and save before closing, or delete the widget. You can't close without saving a new widget.",
        ])
      );
    } else {
      store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
      this.props.closeWidgetEditModal();
    }
  }

  render() {
    // this section is use for adapte editor to select provider in widget

    let providerOptions = [];
    let integrationsAccountLists = [];
    let googleSheetsEditorsInfo = {};

    let dataTypeSelected = gsActions.return_data_type_by_metric(this.props.metric_name, dataTypeList);

    isCurrency = false;
    if (typeof dataTypeSelected !== "undefined") {
      if (dataTypeSelected.dataType === "CURRENCY") {
        isCurrency = true;
      }
    }

    providerAccount = this.props.googleSheetsAccount;
    sheetId = this.props.gsSheetId;

    googleSheetsEditorsInfo = {
      provider: "googlesheets",
      providerLabel: "Google Sheets Account",
      providerAccountId: this.props.googleSheetsAccount,
      fileName: { label: "File Name", value: this.props.gsFileName },
      valueHanding: this.props.valueHandling,
      sheetsList: this.props.gsSheetsList,
    };

    return (
      <div>
        {currentlyRendering === true && <LoadSpinner extraClasses="absolute" />}
        <GoogleSheetsSingleMetricEditor
          {...this.props}
          name="Google Sheets Single Metric Widget"
          metrics={this.props.gsMetricsList}
          filterOperators={[
            { value: "LIKE", label: "contains" },
            { value: "NOT LIKE", label: "does NOT contain" },
          ]}
          filterDimensionList={this.props.dimensions}
          filterHasORenabled={true}
          saveAndClose={this.saveAndClose.bind(this)}
          closeWidgetEditModal={this.close.bind(this)}
          deleteWidget={this.deleteWidget.bind(this)}
          isCurrency={isCurrency}
          googleSheetsEditorsInfo={googleSheetsEditorsInfo}
        />
        <p className="text-sm text-gray-500 mt-4">
          Please refer to{" "}
          <a
            href="https://help.metricswatch.com/article/5-how-to-use-the-google-sheets-integration"
            target="_blank"
            className="underline text-blue-500"
          >
            the Google Sheets integration documentation to learn more about how to format your data for Metrics Watch
          </a>
          .
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateLabel: (event) => {
      dispatch(updateEditorWidgetConfig("label", event.target.value, ownProps.widgetIndex));
    },
    updateMetric: (selected) => {
      let dataTypeSelected = gsActions.return_data_type_by_metric(selected.value, dataTypeList);

      isCurrency = false;
      if (typeof dataTypeSelected !== "undefined") {
        if (dataTypeSelected.dataType === "CURRENCY") {
          isCurrency = true;
        }
      }

      dispatch(updateEditorWidgetConfig("metric_name", selected.value, ownProps.widgetIndex));
      let labelForDisplay = selected.label.split(" -- ")[0];
      dispatch(updateEditorWidgetConfig("label", labelForDisplay, ownProps.widgetIndex));
    },
    updateCurrency: (selected) => {
      dispatch(updateEditorWidgetConfig("currency", selected.value, ownProps.widgetIndex));
    },
    updateInvertPositiveNegative: (event) => {
      dispatch(updateEditorWidgetConfig("invert_positive_negative", event.target.checked, ownProps.widgetIndex));
    },
    save: () => {
      dispatch(saveWidgetConfigFromEditor(ownProps.widgetIndex));
    },
    selectFile: () => {
      currentlyRendering = true;
      dispatch(updateEditorWidgetConfig("selectedSheet", "", ownProps.widgetIndex));
      if (!providerAccount) {
        store.dispatch(setFlashMessage("Please select an account.", ["Google sheets account"]));
        currentlyRendering = false;
        return;
      }
      gsActions.pick_file(
        dispatch,
        ownProps,
        providerAccount,
        () => {
          currentlyRendering = false;
          store.dispatch(setFlashMessage(undefined, []));
          dispatch(updateEditorWidgetConfig("selectedSheet", "", ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("metric_name", "", ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("label", "", ownProps.widgetIndex));
        },
        () => {
          currentlyRendering = false;
          dispatch(updateEditorWidgetConfig("googleSheetsAccount", providerAccount, ownProps.widgetIndex));
        }
      );
    },
    // the name onChangeProviderSelect is standard for reusable single metrics widget
    onChangeProviderSelector: (selected) => {
      currentlyRendering = true;
      providerAccount = selected.value;
      dispatch(updateEditorWidgetConfig("googleSheetsAccount", selected.value, ownProps.widgetIndex));
      gsActions.pick_file(
        dispatch,
        ownProps,
        providerAccount,
        () => {
          currentlyRendering = false;
          store.dispatch(setFlashMessage(undefined, []));
          dispatch(updateEditorWidgetConfig("selectedSheet", "", ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("metric_name", "", ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("label", "", ownProps.widgetIndex));
        },
        () => {
          currentlyRendering = false;
          dispatch(updateEditorWidgetConfig("googleSheetsAccount", providerAccount, ownProps.widgetIndex));
        }
      );
    },
    onChangeValueHandlingSelector: (selected) => {
      dispatch(updateEditorWidgetConfig("valueHandling", selected.value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("valueHandlingLabel", selected.label, ownProps.widgetIndex));
    },
    onChangeSheetsSelector: (selected) => {
      currentlyRendering = true;
      dispatch(updateEditorWidgetConfig("selectedSheet", selected.value, ownProps.widgetIndex));
      gsActions.setSheetValues(selected, dispatch, ownProps, sheetId, providerAccount, () => {
        currentlyRendering = false;
        dispatch(updateEditorWidgetConfig("metric_name", "", ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("label", "", ownProps.widgetIndex));
      });
    },
    updateDateFilter: (ranges, isEnabledDateFilter) => {
      if(isEnabledDateFilter){
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, ownProps.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, ownProps.widgetIndex));
      }
      
    },
    updateFiltersEnabled: (checked) => {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, ownProps.widgetIndex));
    },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleMetricEditor);
