import React from "react";
var createReactClass = require("create-react-class");
import WatcherFormLoader from "../../helpers/watcher_form_loader";
import FlashMessage from "./../flash_message.js";
import WatcherName from "./../watcher_name.js";
import GoogleAnalyticsProfiles from "./../google_analytics_profiles.js";
import MetricName from "./../metric_name.js";
import IntervalType from "./../interval_type.js";
import ComparisonType from "./../comparison_type.js";
import Threshold from "./../threshold.js";
import MinutesAgo from "./minutes_ago";
import EmailRecipients from "./../email_recipients.js";
import SmsRecipients from "./../sms_recipients.js";
import SlackRecipients from "./../slack_recipients.js";
import WatcherNote from "./../watcher_note.js";
import WatcherDisabledTimings from "./../watcher_times.js";
import WatcherSaveButtons from "./../watcher_save_buttons.js";
import WidgetFilterEditor from "./../canvas-widgets/widget_filter_editor";
import { ga4DimensionFilterOperators } from "./../../integrations/google-analytics-4/filter-operators";
import $ from "jquery";
import APIError from "../../helpers/api_error";

const WatcherFormGA4 = createReactClass({
  getInitialState: function () {
    return {
      filters: [],
      metricType: undefined,
      activeTab: "base-config",
      google_analytics_profile_id: undefined,
      gaServiceLevel: undefined,
      dimensions: [],
      dimensionListReady: false,
    };
  },

  componentDidMount: function () {
    WatcherFormLoader.init(this.props.match.params.id);

    this.storeSubscription = store.subscribe(
      function () {
        let newProfileID = store.getState().watcherFormReducer.google_analytics_profile_id;
        if (newProfileID == undefined) {
          this.setState({ dimensions: [] });
          return;
        }

        if (newProfileID != this.state.google_analytics_profile_id) {
          this.setState({
            google_analytics_profile_id: newProfileID,
          });

          this.loadDimensions(newProfileID);
        }

        // Manage filter state
        let newValue = store.getState().watcherFormReducer.googleAnalyticsFilters;
        if (newValue && this.state.filters !== newValue) {
          this.setState({ filters: newValue });
        }

        // GA Service Level
        let newGAServiceLevel = store.getState().watcherFormReducer.gaServiceLevel;
        if (newGAServiceLevel && this.state.gaServiceLevel !== newGAServiceLevel) {
          this.setState({ gaServiceLevel: newGAServiceLevel });
        }

        // Manage metric type (real-time or not)
        let newInterval = store.getState().watcherFormReducer.intervalType;
        let newMetricType = undefined;

        if (newInterval && (newInterval == "real_time" || newInterval == "real_time_vs_30_minutes_ago")) {
          newMetricType = "rt";
        } else if (newInterval) {
          newMetricType = "ga";
        }

        if (newMetricType && this.state.metricType !== newMetricType) {
          this.setState({ metricType: newMetricType });
        }
      }.bind(this)
    );
  },

  loadDimensions: function (propertyPath) {
    this.setState({ dimensionListReady: false });
    const url = `/api/v1/dimensions?provider=ga4&account_id=${propertyPath}`;

    $.ajax({
      url: url,
      dataType: "json",
      cache: false,
      success: function (data) {
        this.prepareDimensions(data);
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("GA4Dimensions", xhr, status, err);
      }.bind(this),
    });
  },

  prepareDimensions: function (data) {
    let dimensions = [];
    for (const key in data) {
      const item = data[key];
      let label = item.label;
      if (item.available_real_time == true && item.available_non_real_time == false) {
        label += " (for real-time intervals ONLY)";
      }

      if (item.available_real_time == true && item.available_non_real_time == true) {
        label += " (available for all intervals)";
      }

      if (item.available_real_time == false && item.available_non_real_time == true) {
        label += " (NOT available for real-time intervals)";
      }

      dimensions.push({
        value: key,
        label: label,
        available_non_real_time: item.available_non_real_time,
        available_real_time: item.available_real_time,
      });
    }

    store.dispatch(setAllDimensions(dimensions));
    this.setState({ dimensions: dimensions });
    this.setState({ dimensionListReady: true });
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  filterUpdate: function (filters) {
    store.dispatch(setGoogleAnalyticsFilters(filters));
    this.setState({ filters: filters });
  },

  setActiveTab: function (tabName) {
    if (["base-config", "integrations", "schedule", "settings"].includes(tabName)) {
      this.setState({ activeTab: tabName });
    }
  },

  isActiveTab: function (tabName) {
    return this.state.activeTab === tabName;
  },

  render: function () {
    let { isActiveTab, setActiveTab } = this;
    let tabClasses =
      "tab w-1/4 py-4 px-1 text-center border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300";
    let activeTabClasses =
      "tab w-1/4 py-4 px-1 text-center border-b-2 border-blue-mw font-medium text-sm leading-5 text-blue-mw focus:outline-none focus:text-blue-800 focus:border-blue-700";

    return (
      <div>
        <FlashMessage />

        <div className="mb-4 bg-white">
          <div className="sm:hidden">
            <select
              value={this.state.activeTab}
              onChange={(e) => setActiveTab(e.target.value)}
              aria-label="Selected tab"
              className="block w-full rounded-md"
            >
              <option value="base-config">Alert Configuration</option>
              <option value="schedule">Schedule &amp; Notes</option>
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex">
                <a
                  className={isActiveTab("base-config") ? activeTabClasses : tabClasses}
                  data-tab-content-id="base-config-tab"
                  onClick={() => setActiveTab("base-config")}
                >
                  <i className="fas fa-chart-bar mr-2" aria-hidden="true" /> Alert Configuration
                </a>
                <a
                  className={isActiveTab("schedule") ? activeTabClasses : tabClasses}
                  data-tab-content-id="schedule-tab"
                  onClick={() => setActiveTab("schedule")}
                  id=""
                >
                  <i className="fas fa-calendar-alt mr-2" aria-hidden="true" /> Schedule &amp; Notes
                </a>
              </nav>
            </div>
          </div>
        </div>

        <div id="report-tabs-content-container">
          <div
            id="base-config-tab"
            className="tab-content"
            style={{ display: isActiveTab("base-config") ? "block" : "none" }}
          >
            <div className="md:flex">
              <div className="md:w-1/2 md:my-2 md:mr-2">
                <h4 className="tw-medium-header mt-8 mb-4 text-center">Base configuration</h4>
                <div className="tw-content-box">
                  <div className="">
                    <WatcherName />
                  </div>

                  <GoogleAnalyticsProfiles form="watcher-ga4" />
                  <IntervalType ga_version="ga4" />

                  {this.state.metricType == "rt" && <MinutesAgo />}

                  <MetricName ga_version="ga4" />
                  <ComparisonType />
                  <Threshold />
                </div>
              </div>

              <div className="md:w-1/2 md:my-2 md:ml-2">
                <h4 className="tw-medium-header mt-8 mb-4 text-center">Delivery options</h4>
                <div className="tw-content-box">
                  <SmsRecipients />
                </div>
                <div className="tw-content-box">
                  <SlackRecipients />
                </div>
                <div className="tw-content-box mt-12">
                  <EmailRecipients />
                </div>
              </div>
            </div>

            <h4 className="tw-medium-header mt-8 mb-4 text-center">Segmentation</h4>
            <div className="my-4">
              {this.state.dimensionListReady && (
                <WidgetFilterEditor
                  dimensionList={this.state.dimensions}
                  operators={ga4DimensionFilterOperators}
                  dimensionListReady={this.state.dimensionListReady}
                  filters={this.state.filters}
                  filterUpdateCallback={function (filters) {
                    this.filterUpdate(filters);
                  }.bind(this)}
                  hasORenabled={true}
                  widgetIndex={this.props.widgetIndex}
                  addFilterButtonLabel={"Add filters to this alert"}
                />
              )}
            </div>
          </div>

          <div
            id="schedule-tab"
            className="tab-content"
            style={{ display: isActiveTab("schedule") ? "block" : "none" }}
          >
            <div className="lg:flex">
              <div className="lg:w-1/2 lg:my-2 lg:mr-2">
                <h3 className="tw-medium-header text-center mt-8 mb-4">Schedule</h3>
                <div className="tw-content-box">
                  <WatcherDisabledTimings />
                </div>
              </div>

              <div className="lg:w-1/2 lg:my-2 lg:ml-2">
                <h3 className="tw-medium-header text-center mt-8 mb-4">Notes</h3>
                <div className="tw-content-box">
                  <WatcherNote />
                </div>
              </div>
            </div>
          </div>
        </div>

        <WatcherSaveButtons ga_version="ga4" />
      </div>
    );
  },
});

export default WatcherFormGA4;
