// We'll probably want to structure the theme differently
// once we get it connected to the actual one

import React, { memo } from "react";
import _ from "lodash";
import PreviewParts from "./preview-widget-parts/preview-widget-parts";
import Chart from "./preview-widget-parts/chart";
import "./../../helpers/string/capitalize";
import consumer from "../../channels/consumer";
import LoadSpinner from "../../components/utility/load_spinner";
import WidgetPreviewFetcher from "./../../helpers/widget_preview/widget_preview_fetcher";
import { appsignal } from "../../appsignal";

import OldPreviewWidget from "./old-preview-widget";

const defaultTheme = {
  positive: "#078A5C",
  negative: "#AD2F1E",
  neutral: "#122540",
  graph1: "#9FCCDB",
  graph2: "#69B0C0",
  graph3: "#3F7EAD",
  graph4: "#2A4F96",
  graph5: "#2E1140",
  graph6: "#825562",
};

// Props for chart previews
const chartProps = {
  className: "mx-auto max-w-full h-auto",
  width: 550,
  height: 412,
};

class PreviewWidget extends React.Component {
  constructor(props) {
    super(props);
    let { metricType, indexAxis, chartType } = this.props;

    this.state = {
      error: "",
      singleMetricData: {
        lastPeriod: "",
        previousPeriod: "",
        percentage: 0,
        themeColor: "",
        arrowUrl: "",
      },
      data: 0,
      status: "initialized",
      disabled_live_builder: false,
      widget: {},
      reportID: 0,
      previewType: chartType || metricType,
    };
  }

  componentDidMount() {
    if (store.getState().reportFormReducer !== undefined) {
      this.setState({ reportID: store.getState().reportFormReducer.reportID });
    }

    // receive globals informations to report channel
    consumer.subscriptions.create("ReportChannel", {
      received: (data) => this.receivedData(data),
      // In case of disconnection, we want an error in Bugsnag. The idea is just to have a sense of how often it happens.
      disconnected: () => {
        const error_code = "MW-F-ERR-02";
        const errorMessage = `${error_code}: ReportChannel disconnected for widget ${this.props.widgetIndex} in report ${this.state.reportID}`;
        let e = {
          name: `PreviewWidget`,
          message: errorMessage,
        };
        Bugsnag.notify(e);
        appsignal.sendError(e);
      },
      // This manages the case where the user gets reconnected after a disconnection, and fetches a preview again in that case.
      connected: () => {
        new WidgetPreviewFetcher().fetch(this.props.widgetIndex);
      },
    });

    this.shouldWeForceFetchPreviewAfterLoad();
  }

  // This is to make sure that we have a job in backend to generate the preview
  shouldWeForceFetchPreviewAfterLoad() {
    const maxRetry = 20;
    let retryCount = 0;
    let delay = 3000; // check every 3 second
    let interval = setInterval(() => {
      if (retryCount >= maxRetry) {
        clearInterval(interval);
        const error_code = "MW-F-ERR-01";
        let e = {
          name: `PreviewWidget`,
          message: `${error_code}: Hit max retry for JUST INITIALIZED widget ${this.props.widgetIndex} in report ${this.state.reportID}`,
        };
        Bugsnag.notify(e);
        appsignal.sendError(e);
        this.setState({
          status: "error",
          error: {
            message: `${error_code}: Unable to generate preview for this widget. Our engineers have been notified and will look into it. You can continue working on your report, save it, and even send yourself an ad-hoc report with the appropriate button in the report action bar.`,
          },
        });
        return;
      }

      if (this.state.status === "initialized") {
        new WidgetPreviewFetcher().fetch(this.props.widgetIndex);
      } else {
        clearInterval(interval);
        return;
      }

      retryCount += 1;
    }, delay);
  }

  receivedData(data) {
    if (
      String(this.state.reportID) === String(data.report_id) &&
      String(data.widget_id) === String(this.props.widgetIndex)
    ) {
      if (data.status === "error") {
        this.setState({
          error: data.error,
          data: "",
          status: data.status,
        });
      }
    }

    // The global status allows to load all widgets when calling the report preview.
    if (data.global_status === "in progress" && String(this.state.reportID) === String(data.report_id)) {
      this.setState({ status: "in progress" });
    } else if (
      String(this.state.reportID) === String(data.report_id) &&
      String(data.widget_id) === String(this.props.widgetIndex)
    ) {
      // Define the global data, for the widget details they are defined in the case switch
      this.setState({
        disabled_live_builder: data.disabled_live_builder,
        widget: data.widget,
        label: data.widget.label,
        status: data.status,
        previewType: this.props.chartType || this.props.metricType,
      });

      switch (this.state.previewType) {
        case "Single Metric":
          if (data.status === "success") {
            let themeColor = data.theme_color;
            let arrowUrl = data.arrow_url;
            if (store.getState().reportFormReducer.single_metric_comparison_disabled === true) {
              themeColor = "neutral";
              arrowUrl = "";
            }

            this.setState({
              singleMetricData: {
                lastPeriod: data.data.last_period,
                previousPeriod: data.data.previous_period,
                percentage: data.percentage,
                themeColor: themeColor,
                arrowUrl: arrowUrl,
              },
            });
          }
          break;
        case "List":
          if (data.status === "success") {
            this.setState({
              data: data.data.list,
              columnHeaders: data.data.columns,
              all_ads_metadata: data.data.ads_metadata,
              data_type: data.data.data_type,
            });
          }
          break;

        case "Pie Chart":
        case "pie":
        case "Doughnut Chart":
        case "doughnut":
          if (data.status === "success") {
            this.setState({
              data: data.data.graphData,
            });
          }
          break;

        case "mixed":
        case "area":
        case "line":
        case "Bar Graph":
        case "bar":
          if (data.status === "success") {
            this.setState({
              data: data.data.graphData,
            });
          }
          break;
      }
    }
  }

  render() {
    let UpperPreview = false;
    let LowerPreview = false;
    let comparisonEnabled = true;
    let color = defaultTheme.neutral;
    switch (this.state.previewType) {
      case "Single Metric":
        UpperPreview = PreviewParts.SingleMetricUpper;
        if (store.getState().reportFormReducer.single_metric_comparison_disabled !== true) {
          LowerPreview = PreviewParts.SingleMetricLower;
        } else {
          comparisonEnabled = false;
        }

        if (this.state.singleMetricData.themeColor == "positive") {
          color = defaultTheme.positive;
        } else if (this.state.singleMetricData.themeColor == "negative") {
          color = defaultTheme.negative;
        } else {
          color = defaultTheme.neutral;
        }

        break;

      case "List":
        if (this.state.widget.dimension === "ad_group_ad.ad.name") {
          LowerPreview = PreviewParts.ListAdsPreview;
        } else {
          LowerPreview = PreviewParts.ListPreview;
        }
        break;

      case "Pie Chart":
      case "pie":
        if (this.state.data && this.state.data.datasets[0].data.length === 0) {
          LowerPreview = () => <p>There is no data for that widget within this date range.</p>;
        } else {
          LowerPreview = () => <Chart type="pie" data={this.state.data} {...chartProps} />;
        }
        break;

      case "Doughnut Chart":
      case "doughnut":
        if (this.state.data && this.state.data.datasets[0].data.length === 0) {
          LowerPreview = () => <p>There is no data for that widget within this date range.</p>;
        } else {
          LowerPreview = () => <Chart type="doughnut" data={this.state.data} {...chartProps} />;
        }
        break;

      case "Bar Graph":
      case "bar":
      case "line":
      case "area":
      case "mixed":
        //
        // Data comes already ready from the backend, we just need to set the options, legends and axis stuff
        //

        // This is to deal with older reports and templates that don't have the type set
        if (this.state.data.datasets) {
          for (let index = 0; index < this.state.data.datasets.length; index++) {
            if (this.state.data.datasets[index].type == undefined) {
              this.state.data.datasets[index].type = "bar";
            }
          }
        }

        let secondaryAxisEnabled = true;

        if (this.state.widget.secondaryAxisEnabled == false) {
          secondaryAxisEnabled = false;
        }

        let options = {
          plugins: {
            legend: {
              display: this.state.widget.secondaryMetricEnabled,
            },
          },
        };

        let indexAxis = "x";
        if (this.state.widget.indexAxis === "y") {
          indexAxis = "y";
        }
        let reverseAxis = indexAxis === "y" ? "x" : "y";

        options.indexAxis = indexAxis;

        options["scales"] = {};
        options.scales[reverseAxis] = {
          beginAtZero: true,
        };

        let position = "right";
        if (reverseAxis == "x") {
          position = "top";
        }

        if (secondaryAxisEnabled && this.state.data.datasets && this.state.data.datasets.length > 1) {
          options.scales[reverseAxis] = {
            title: {
              display: true,
              text: this.state.data.datasets[0].label,
            },
          };
          options.scales[reverseAxis + "1"] = {
            title: {
              display: true,
              text: this.state.data.datasets[1].label,
            },
            dispay: "auto",
            beginAtZero: true,
            position: position,
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          };
        }

        LowerPreview = () => <Chart data={this.state.data} options={options} {...chartProps} />;
        break;

      case "All Goals":
        LowerPreview = PreviewParts.AllGoals;
        break;
    }

    const singleLineLabelChange = (event) => {
      event.target.value = event.target.value.replace(/\n/g, "");
      this.props.onLabelChange(event);
      this.setState({ label: event.target.value });
    };

    let displayLowerList = true;
    if (this.state.data.length === 0 && this.state.previewType === "List") {
      displayLowerList = false;
    }

    if (this.state.disabled_live_builder) {
      return (
        <OldPreviewWidget
          label={this.state.label}
          providerName={this.props.providerName}
          metricType={this.props.metricType}
          onLabelChange={this.props.onLabelChange}
          fullWidth={this.props.fullWidth}
          dimension_label={this.state.widget.dimension_label}
          secondary_metric_label={this.state.widget.secondary_metric_label}
          third_metric_label={this.state.widget.third_metric_label}
          secondary_column_is_previous_period_comparison={
            this.state.widget.secondary_column_is_previous_period_comparison
          }
          comparison_column_label={this.state.widget.comparison_column_label}
          metric_label={this.state.widget.metric_label}
          indexAxis={this.props.indexAxis}
          chartType={this.props.chartType}
          secondaryMetricEnabled={this.state.widget.secondaryMetricEnabled}
          secondaryChartType={this.state.widget.secondaryChartType}
          widgetIndex={this.props.widgetIndex}
          theme={defaultTheme}
        />
      );
    } else {
      return (
        <div
          className={"widget " + (this.props.fullWidth ? "full-width-widget" : "single-metric-widget")}
          style={{
            backgroundColor: `${color}11`,
            color: color,
          }}
        >
          {(this.state.status === "in progress" || this.state.status === "initialized") &&
            this.state.previewType != "All Goals" && <LoadSpinner extraClasses="absolute" />}
          <div>
            <div className="widget-classification flex" style={{ backgroundColor: "#fff", color: color }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="8 0 16 24" stroke="currentColor" width="10">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>

              <div>
                <span className="provider">{this.props.providerName}</span>
                {this.state.previewType.capitalize()}
              </div>
            </div>

            {UpperPreview && this.state.status === "success" && (
              <UpperPreview
                number={this.state.singleMetricData.lastPeriod}
                arrowUrl={this.state.singleMetricData.arrowUrl}
                comparisonEnabled={comparisonEnabled}
              />
            )}
            {this.state.data.length > 9 && (
              <div className="italic text-sm">
                Note: we only show the first 10 lines here, but all lines will be in your email report.
              </div>
            )}

            {this.state.widget.label !== false && (
              <textarea className="label text-xl font-bold" value={this.state.label} onChange={singleLineLabelChange} />
            )}
            {!displayLowerList && this.state.status !== "error" && (
              <div>There is no data for that widget within this date range.</div>
            )}
            {this.state.status === "error" && (
              <div>
                <div>{this.state.error.message}</div>
              </div>
            )}
            {this.state.error.code === "disconnected" && (
              <span>
                <a href="/providers" target="_blank" className="underline">
                  Click here to reconnect the account
                </a>
                , then reload this page!
              </span>
            )}

            {this.state.error.code === "invalid_account" && (
              <span>
                <a
                  href="https://help.metricswatch.com/article/7-google-search-console-site-is-unverified-what-to-do"
                  target="_blank"
                  className="underline"
                >
                  Click here to learn how to fix this.
                </a>
              </span>
            )}

            {LowerPreview &&
              (this.state.status === "success" || this.state.previewType == "All Goals") &&
              displayLowerList && (
                <LowerPreview
                  color={color}
                  dimensionName={this.state.widget.dimension_label}
                  metricName={this.state.widget.metric_label}
                  theme={this.props.theme}
                  secondary_metric_label={this.state.widget.secondary_metric_label}
                  third_metric_label={this.state.widget.third_metric_label}
                  secondary_column_is_previous_period_comparison={
                    this.state.widget.secondary_column_is_previous_period_comparison
                  }
                  columnHeaders={this.state.columnHeaders}
                  number={this.state.singleMetricData.previousPeriod}
                  percentage={this.state.singleMetricData.percentage}
                  data={this.state.data}
                  all_ads_metadata={this.state.all_ads_metadata}
                  data_type={this.state.data_type}
                  widgetIndex={this.props.widgetIndex}
                />
              )}
          </div>
        </div>
      );
    }
  }
}

const sameRender = (prevProps, nextProps) => {
  const propsToCheck = [
    "custom_account",
    "dimension",
    "dimension_label",
    "chartType",
    "filters",
    "indexAxis",
    "label",
    "limit",
    "metricTtype",
    "metric_label",
    "metric_name",
    "name",
    "secondaryChartType",
    "secondaryMetricEnabled",
    "secondary_metric_label",
    "secondary_metric_name",
    "third_metric_label",
    "third_metric_name",
    "theme",
    "widgetIndex",
    "widgetType",
  ];

  for (let index = 0; index < propsToCheck.length; index++) {
    const propName = propsToCheck[index];
    if (!_.isEqual(prevProps[propName], nextProps[propName])) {
      return false;
    }
  }

  return true;
};

export default memo(PreviewWidget, sameRender);
