import React, { useState } from "react";
import { connect } from "react-redux";
import { setMarkup, setMarkupEnabled } from "../../actions/ppc_markup_actions";

class PPCMarkup extends React.Component {
  render() {
    return (
      <>
        {this.props.showEnableToggle && (
          <label className="tw-label" htmlFor="agency_setting_slim_header">
            <input
              id="use-ppc-markup"
              className="mr-2 tw-checkbox"
              type="checkbox"
              checked={this.props.ppcMarkupEnabled}
              onChange={this.props.updatePPCMarkupEnabled}
            />
            Enable custom PPC markup for this report
          </label>
        )}

        {(!this.props.showEnableToggle || this.props.ppcMarkupEnabled) && (
          <>
            <>
              <label className="tw-label">Facebook Ads (%)</label>
              <input type="number" value={this.props.facebookAds} onChange={this.props.updateFacebookAds} />
            </>
            <>
              <label className="tw-label">Google Ads (%)</label>
              <input type="number" value={this.props.googleAds} onChange={this.props.updateGoogleAds} />
            </>
            <>
              <label className="tw-label">LinkedIn Ads (%)</label>
              <input type="number" value={this.props.linkedInAds} onChange={this.props.updateLinkedInAds} />
            </>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return state.ppcMarkupReducer;
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateFacebookAds: (event) => {
      let val = event.target.value || 0;
      dispatch(setMarkup("facebookAds", parseInt(val, 10)));
    },
    updateGoogleAds: (event) => {
      let val = event.target.value || 0;
      dispatch(setMarkup("googleAds", parseInt(val, 10)));
    },
    updateLinkedInAds: (event) => {
      let val = event.target.value || 0;
      dispatch(setMarkup("linkedInAds", parseInt(val, 10)));
    },
    updatePPCMarkupEnabled: (event) => {
      dispatch(setMarkupEnabled(event.target.checked));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PPCMarkup);
