import React from "react";
var createReactClass = require("create-react-class");
import Select from "react-select";
import $ from "jquery";
import APIError from "../../helpers/api_error";
import { reportFormValidation } from "../../helpers/report_form_validation";
import WidgetPreviewFetcher from "../../../javascript/helpers/widget_preview/widget_preview_fetcher";
import { isEmpty } from "lodash";
import CommonAccountSelection from "../common_account_selection";
const MailchimpAccountSelector = createReactClass({
  getInitialState: function () {
    return {
      apiURL: "/api/v1/provider_accounts",
      mailchimp_list_id: undefined,
    };
  },

  componentDidMount() {
    this.loadMailchimpLists();
    const { disconnectStore, value } = this.props;

    if (disconnectStore && value !== null && value !== undefined) {
      this.setState({ mailchimp_list_id: value });
    } else {
      this.storeSubscription = store.subscribe(() => {
        let newValue = store.getState().reportFormReducer.mailchimp_list_id;
        if (this.state.mailchimp_list_id !== newValue) {
          this.setState({ mailchimp_list_id: newValue });
        }
      });
    }
  },

  componentWillUnmount() {
    if (!this.props.disconnectStore) {
      this.storeSubscription();
    }
  },

  componentDidUpdate(prevProps) {
    if (this.props.disconnectStore && (prevProps.value !== null && prevProps.value !== undefined)) {
      this.setState({ mailchimp_list_id: this.props.value });
    }

    const integrationsAccountLists = store.getState().integrationsReducer.integrationsAccountLists;
    const accountList = integrationsAccountLists["mailchimp"];
    if (!isEmpty(accountList) && (this.state.mailchimp_list_id === undefined || this.state.mailchimp_list_id === null)) {
      this.setState({ mailchimp_list_id: accountList[0].value });
      store.dispatch(setMailchimpList(accountList[0].value));
    }
  },

  loadMailchimpLists() {
    $.ajax({
      url: this.state.apiURL,
      dataType: "json",
      cache: false,
      success: (data) => {
        this.prepareOptions(data);
      },
      error: (xhr, status, err) => {
        new APIError("MailchimpList", xhr, status, err);
      },
    });
  },

  prepareOptions(data) {
    let options = [];
    data.data.forEach((provider) => {
      if (
        provider.type === "metrics_providers-mailchimps" &&
        Array.isArray(provider.attributes.provider_accounts.lists)
      ) {
        let lists = provider.attributes.provider_accounts.lists;
        lists.forEach((list) => {
          options.push({ value: list.id, label: list.name });
        });
      }
    });

    store.dispatch(setIntegrationAccountList("mailchimp", options));
    if (options.length > 0) {
      this.setState({ mailchimp_list_id: options[0].value });
      store.dispatch(setMailchimpList(options[0].value));
    }
  },

  listSelected(data) {
    let value = data ? data.value : undefined;

    const widgets = store.getState().widgetReducer.widgetConfigs;
    Object.keys(widgets).forEach((key) => {
      if (widgets[key].name.includes("Mailchimp")) {
        new WidgetPreviewFetcher().fetch(key);
      }
    });

    if (this.props.disconnectStore) {
      this.props.onChange(data);
    } else {
      store.dispatch(setMailchimpList(value));
    }
    this.setState({ mailchimp_list_id: value });
  },
  handleIntegrationButton: function () {
    let integrations = window.integrationStore.findIntegrationByOAuthProviderName("mailchimp");
    integrations.oAuthProviderPreRedirectNotice();
    window.location = integrations.oAuthProviderRedirectPath();
  },

  render: function () {
    var rootClassName = "field";
    let options = store.getState().integrationsReducer.integrationsAccountLists["mailchimp"];
    let display = "none";
    if (reportFormValidation.requiredIntegrations().includes("mailchimp")) {
      display = "block";
    }

    return (
      <div className={rootClassName} style={{ display: display }}>
        <CommonAccountSelection
          label={"Mailchimp List"}
          disconnectStore={this.props.disconnectStore}
          tabIndex={this.props.tabIndex}
          accountSelected={this.listSelected}
          value={this.state.mailchimp_list_id}
          options={options}
          handleIntegrationButton={this.handleIntegrationButton}
        />
      </div>
    );
  },
});

export default MailchimpAccountSelector;
