const MetricAndIntervalAreCompatible = function (metricName, intervalIsRT) {
  if (!metricName) {
    return false;
  }

  // For older GA versions, we used the beginning of the metric name to know if it's real time or not.
  var firstChars = metricName[0] + metricName[1] + metricName[2];
  if (firstChars == "ga:" || firstChars == "rt:") {
    if (firstChars == "rt:" && intervalIsRT) {
      return true;
    } else if (firstChars == "ga:" && !intervalIsRT) {
      return true;
    } else if (firstChars == "rt:" && !intervalIsRT) {
      return false;
    } else if (firstChars == "ga:" && intervalIsRT) {
      return false;
    }
  }

  // This is used only in GA4 right now.
  const allMetrics = store.getState().watcherFormReducer.allMetrics;
  const currentMetric = allMetrics[metricName];

  if (intervalIsRT && currentMetric.available_real_time) {
    return true;
  }

  if (intervalIsRT && !currentMetric.available_real_time) {
    return false;
  }

  if (!intervalIsRT && currentMetric.available_non_real_time) {
    return true;
  }

  if (!intervalIsRT && !currentMetric.available_non_real_time) {
    return false;
  }
};

export { MetricAndIntervalAreCompatible };
