import React from "react";
import Widget from "./widget";
import Editor from "./editor";
import WidgetBase from "../../../widgets/widget_base";

export default class MixedChart extends WidgetBase {
  constructor(widget, integration) {
    super(widget, integration);
  }

  name() {
    return this.widget.widgetName;
  }

  type() {
    return this.widget.widgetType;
  }

  width() {
    return "large";
  }

  height() {
    return 6;
  }

  config(chartType) {
    return {
      chartType: chartType || "bar",
      widgetType: this.type(),
      name: this.name(),
      label: this.widget.defaultLabel,
      metric_name: this.widget.defaultMetricName,
      metric_label: this.widget.defaultMetricLabel,
      dimension: this.widget.defaultDimensionName,
      limit: 6,
      filters: [],
    };
  }

  widgetComponent(widgetIndex) {
    return React.createElement(
      Widget,
      {
        widgetIndex: widgetIndex,
        key: widgetIndex,
        providerName: this.integration.name(),
      },
      null
    );
  }

  editorComponent(widgetIndex, closeAction, deleteWidget) {
    return React.createElement(
      Editor,
      {
        widgetIndex: widgetIndex,
        key: widgetIndex,
        closeWidgetEditModal: closeAction,
        deleteWidget: deleteWidget,
        metrics: this.integration.config.metrics(),
        dimensions: this.integration.config.dimensions(),
        filterOperators: this.integration.config.filterOperators,
        filterDimensionList: this.integration.config.filterDimensionList(),
        filterHasORenabled: this.integration.config.filterHasORenabled,
        isPeriodDimensionFilterable: this.integration.widgets().mixedChart.isPeriodDimensionFilterable,
        widgetName: this.name(),
        providerName: this.integration.name(),
        oAuthProviderName: this.integration.oAuthProviderName(),
      },
      null
    );
  }
}
