import { MetricAndIntervalAreCompatible } from "../helpers/metric_names";
import FilterValidation from "../helpers/filter_validation";

const watcherFormValidation = (function () {
  return {
    validate: function (formData, ga_version) {
      var state = { text: undefined, errors: [] };

      if (!formData.watcherName) {
        state.errors.push("Name is required");
      }
      if (!formData.google_analytics_profile_id) {
        state.errors.push("You need to configure the Google Analytics integration for this report.");
      }
      if (!formData.metricName) {
        state.errors.push("Metric is required");
      }
      if (!formData.intervalType) {
        state.errors.push("Interval is required");
      } else {
        const intervalIsRT = formData.intervalType.startsWith("real_time");
        if (!MetricAndIntervalAreCompatible(formData.metricName, intervalIsRT)) {
          state.errors.push("The selected metric is not compatible with the selected interval.");
        }
      }

      if (formData.startMinutesAgo < formData.endMinutesAgo) {
        state.errors.push(`Minutes ago range: "From" must be higher or equal to "To".`);
      }

      if (!formData.comparisonType) {
        state.errors.push("Comparison type is required");
      }
      if (!formData.threshold) {
        state.errors.push("Threshold is required");
      }

      if (
        (!formData.emailRecipients || formData.emailRecipients.length == 0) &&
        (!formData.smsRecipients || formData.smsRecipients.length == 0) &&
        (!formData.slackRecipients || formData.slackRecipients.length == 0)
      ) {
        state.errors.push("At least one recipient is required");
      }

      if (!FilterValidation.validate(formData.googleAnalyticsFilters)) {
        state.errors.push("At least one filters is incomplete");
      } else {
        if (formData.intervalType && ga_version === "ga4") {
          const intervalIsRT = formData.intervalType.startsWith("real_time");
          if (!FilterValidation.filterAndIntervalAreCompatible(formData.googleAnalyticsFilters, intervalIsRT)) {
            state.errors.push("At least one filter is not compatible with the selected interval.");
          }
        }
      }

      switch (state.errors.length) {
        case 0:
          // all good!
          break;
        case 1:
          state.text = "We could not save the alert due to this error:";
          break;
        default:
          state.text = "We could not save the alert due to those errors";
      }

      store.dispatch(setFlashMessage(state.text, state.errors));
      return state;
    },
  };
})();

export default watcherFormValidation;
