import React from "react";
import { connect } from "react-redux";
import PreviewWidget from "../../../../components/reports/preview-widget";

const SingleMetricWidget = (props) => {
  return (
    <PreviewWidget
      providerName="Facebook Pages"
      metricType="Single Metric"
      onLabelChange={props.updateLabel}
      {...props}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return state.widgetReducer.widgetConfigs[ownProps.widgetIndex];
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateLabel: (event) => {
      dispatch(updateWidgetConfig("label", event.target.value, ownProps.widgetIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleMetricWidget);
