import React from "react";
var createReactClass = require("create-react-class");
import ReactDOM from "react-dom";
import $ from "jquery";
import _ from "lodash";

var FlashMessage = createReactClass({
  getInitialState: function () {
    var state = _.clone(store.getState().watcherFormReducer.flashMessage);
    state.notificationClassName = "is-danger";
    state.text = "";
    state.errors = [];
    return state;
  },

  componentDidMount: function () {
    this.storeSubscription = store.subscribe(
      function () {
        let newState = _.assign({}, this.state, store.getState().watcherFormReducer.flashMessage);
        this.setState(newState);
        this.visibilityManager(newState);
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  visibilityManager: function (newState) {
    if (newState.errors && newState.errors.length == 0) {
      this.setState(_.assign({}, newState, { notificationClassName: "is-success" }));
    } else {
      this.setState(_.assign({}, newState, { notificationClassName: "is-danger" }));
    }
  },

  render: function () {
    this.errorIndex = 0;
    return (
      <div>
        {this.state.text && this.state.text.length > 0 && (
          <div ref="reactFlashMessage" className="react-flash-message">
            <div className={"notification tw-flash m-2 " + this.state.notificationClassName}>
              {this.state.errors && this.state.errors.length == 0 && <p ref="text">{this.state.text}</p>}

              {this.state.errors && this.state.errors.length > 0 && (
                <div>
                  <h4 className="subtitle is-4">{this.state.text}</h4>
                  <ul ref="flashMessageErrorList" className="list-disc ml-8 mt-4">
                    {this.state.errors.map(
                      function (err) {
                        this.errorIndex++;
                        // We're using dangerouslySetInnerHTML here because the errors are coming from other components
                        // and we sometime needs to have links in the error messages.
                        return <li key={"error" + this.errorIndex} dangerouslySetInnerHTML={{ __html: err }}></li>;
                      }.bind(this)
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  },
});

export default FlashMessage;
