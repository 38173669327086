import React from "react";
var createReactClass = require("create-react-class");
import Select from "react-select";
import $ from "jquery";
import APIError from "../helpers/api_error";

const SlackRecipients = createReactClass({
  apiURL: function () {
    return "/api/v1/slack";
  },

  getInitialState: function () {
    return {
      options: [],
      slackRecipients: [],
    };
  },

  componentDidMount: function () {
    this.loadAvailableSlack();

    this.storeSubscription = store.subscribe(
      function () {
        var newRecipients = store.getState().watcherFormReducer.slackRecipients;
        if (newRecipients != this.state.slackRecipients) {
          this.setState({ slackRecipients: newRecipients });
        }
      }.bind(this)
    );

    window.FeatureFlag.check("slack");
  },

  loadAvailableSlack: function () {
    $.ajax({
      url: this.apiURL(),
      dataType: "json",
      cache: false,
      success: function (data) {
        this.prepareOptions(data);
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("SlackRecipients.loadAvailableMobile", xhr, status, err);
      }.bind(this),
    });
  },

  prepareOptions: function (data) {
    let options = data.data.map(function (opt) {
      return { value: opt.id, label: `${opt.attributes.channel_name} (${opt.attributes.team_name})` };
    });
    this.setState({ options: options });

    // If we are editing an alert...
    if (store.getState().watcherFormReducer.slackRecipients) {
      this.setState({
        slackRecipients: store.getState().watcherFormReducer.slackRecipients,
      });
    }
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  onChange: function (data) {
    let val = data.map(function (option) {
      return option.value;
    });
    store.dispatch(setSlackRecipients(val));
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    let slackRecipients = this.state.slackRecipients;
    if (Array.isArray(slackRecipients)) {
      slackRecipients = slackRecipients.join(",");
    }

    return (
      <div className="feature-flag-parent-container">
        <div className="feature_flag_overlay-slack">
          <p>
            Slack is not available on your plan.{" "}
            <a href="/billing" className="underline">
              Upgrade your plan to unlock it.
            </a>
          </p>
        </div>

        <label className="tw-label pt-4 mb-2">Slack recipient(s)</label>
        <div className="slack_configuration_feature">
          <Select
            multi={true}
            tabIndex={tabIndex}
            value={slackRecipients}
            ref="select"
            options={this.state.options}
            onChange={this.onChange}
            className="sel2-slack-recipients"
          />
        </div>
      </div>
    );
  },
});

export default SlackRecipients;
