import React from "react";
var createReactClass = require("create-react-class");
import CreatableSelect from "react-select/lib/Creatable";
import _ from "lodash";

const EmailRecipients = createReactClass({
  getInitialState: function () {
    return {
      options: [
        {
          value: window.MetricsWatch.currentUser.email,
          label: window.MetricsWatch.currentUser.email,
        },
      ],
      emailRecipients: [],
    };
  },

  componentDidMount: function () {
    let storedRecipients = store.getState().watcherFormReducer.emailRecipients;
    if (storedRecipients) {
      this.loadRecipients(storedRecipients, function () {
        this.setState({ emailRecipients: storedRecipients });
      });
    }
    this.storeSubscription = store.subscribe(
      function () {
        var newEmailRecipients = store.getState().watcherFormReducer.emailRecipients;
        if (newEmailRecipients !== this.state.emailRecipients) {
          this.setState({ emailRecipients: newEmailRecipients });
        }
        if (newEmailRecipients) {
          this.loadRecipients(newEmailRecipients);
        }
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  loadRecipients: function (recipients, callback) {
    var allOptions = _.clone(this.state.options);
    for (let i = 0; i < recipients.length; i++) {
      let newOption = { value: recipients[i], label: recipients[i] };
      // if(!allOptions.include(newOption)){
      allOptions.push(newOption);
      // }
    }
    this.setState({ options: allOptions }, callback);
  },

  onChange: function (data, meta) {
    let val = data.map(function (emailObj) {
      return emailObj.value;
    });

    this.loadRecipients(
      val,
      function () {
        this.setState({ emailRecipients: val });
        store.dispatch(setEmailRecipients(val));
      }.bind(this)
    );
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    let emailRecipients = this.state.emailRecipients;
    if (Array.isArray(emailRecipients)) {
      emailRecipients = emailRecipients.join(",");
    }

    return (
      <div className="">
        <label className="tw-label">Email recipient(s)</label>
        <CreatableSelect
          multi={true}
          tabIndex={tabIndex}
          onChange={this.onChange}
          value={emailRecipients}
          options={this.state.options}
        />
      </div>
    );
  },
});

export default EmailRecipients;
