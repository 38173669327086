import React from "react";

const LoadSpinner = ({ extraClasses = "" }) => {
  return (
    <div className={"fixed top-0 left-0 w-full h-full flex justify-center align-center z-50 " + extraClasses}>
      <div className="mx-auto my-auto py-1 px-2 bg-blue-100 text-blue-600 rounded shadow flex items-center">
        <span className="mr-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 60 60"
            fill="none"
            className="animate-spin"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              d="M30 8C42.1503 8 52 17.8497 52 30C52 42.1503 42.1503 52 30 52C17.8497 52 8 42.1503 8 30"
              stroke="currentColor"
              strokeWidth="6"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M30 51C30 49.8954 29.1017 49.0111 28.0031 48.8963C18.4483 47.8984 11 39.819 11 30C11 19.5066 19.5066 11 30 11C39.819 11 47.8984 18.4483 48.8963 28.0031C49.0111 29.1017 49.8954 30 51 30H53C54.1046 30 55.0083 29.1014 54.9212 28.0003C53.9024 15.1281 43.134 5 30 5C16.1929 5 5 16.1929 5 30C5 43.134 15.1281 53.9024 28.0003 54.9212C29.1014 55.0083 30 54.1046 30 53V51Z"
              fill="currentColor"
            />
          </svg>
        </span>
        <span className="text-xs">Hang tight! Loading...</span>
      </div>
    </div>
  );
};

export default LoadSpinner;
