import React from "react";

import SingleMetricWidget from "./widget";
import SingleMetricEditor from "./editor";
import WidgetBase from "../../../../widgets/widget_base";

export default class SingleMetric extends WidgetBase {
  constructor() {
    super();
  }

  name() {
    return "Formatted Metric only for Adwords";
  }

  type() {
    return "formatted-metric";
  }

  width() {
    return "small";
  }

  config(chartType) {
    return {
      widgetType: this.type(),
      name: this.name(),
      adwords: true,
      label: "Sessions (AdWords)",
      metric_name: "ga:sessions",
      filters: [],
    };
  }

  widgetComponent(widgetIndex) {
    return React.createElement(
      SingleMetricWidget,
      {
        widgetIndex: widgetIndex,
        key: widgetIndex,
      },
      null
    );
  }

  editorComponent(widgetIndex, closeAction, deleteWidget) {
    return React.createElement(
      SingleMetricEditor,
      {
        widgetIndex: widgetIndex,
        key: widgetIndex,
        closeWidgetEditModal: closeAction,
        deleteWidget: deleteWidget,
      },
      null
    );
  }
}
