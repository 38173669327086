// http://stackoverflow.com/questions/1527803/generating-random-whole-numbers-in-javascript-in-a-specific-range
/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive)
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// query string: ?foo=lorem&bar=&baz
// var foo = getParameterByName('foo'); // "lorem"
// var bar = getParameterByName('bar'); // "" (present with empty value)
// var baz = getParameterByName('baz'); // "" (present with no value)
// var qux = getParameterByName('qux'); // null (absent)
function getParameterByName(name, url) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function showErrorMessageIfAnyInXhr(xhrResponseText) {
  if (xhrResponseText) {
    var errorMessage = {};
    try {
      errorMessage = JSON.parse(xhrResponseText);
    } catch (e) {}
    if (errorMessage.errors) {
      var errorArrayForFlash = [];

      Object.keys(errorMessage.errors).map(function (key) {
        let keyForDisplay = key.replace(/_/g, " ").capitalize(); // This supports keys with underscores, like "start_date" that will show up as "Start date" with this.

        errorArrayForFlash.push(`${keyForDisplay}: ${errorMessage.errors[key]}`);
      });

      store.dispatch(setFlashMessage("News item couldn't be saved:", errorArrayForFlash));
    }
  }
}
const formatDateRange = (startDate, endDate) => ({
  startDate: new Date(startDate),
  endDate: new Date(endDate),
  key: 'selection'
});

const selectedDateFormat = (startDate, endDate) => {
  const isValidDate = (date) => date instanceof Date && !isNaN(date);
  if (!isValidDate(startDate) || !isValidDate(endDate)) {
    return "Invalid date range";
  }
  const formattedStartDate = startDate.toISOString().split("T")[0];
  const formattedEndDate = endDate.toISOString().split("T")[0];
  return `Selected date between ${formattedStartDate} and ${formattedEndDate}`;
};

const getValidDate = (cookieDate) => {
  const date = new Date(cookieDate);
  return date instanceof Date && !isNaN(date) ? date : new Date();
};

export { getRandomInt, getParameterByName, showErrorMessageIfAnyInXhr,formatDateRange, selectedDateFormat, getValidDate };
