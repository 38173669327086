import $ from "jquery";
import APIError from "../helpers/api_error";

window.duplicate_report = function (id) {
  $.ajax({
    url: "/api/v1/reports/" + id + "/duplicate",
    method: "POST",
    cache: false,
    success: function (data) {
      document.location = "/reports/" + data.data.id + "/edit";
    }.bind(this),
    error: function (xhr, status, err) {
      // 405 = method not allowed is used when the limit to the plan is over
      if (xhr.status == 405) {
        store.dispatch(
          setFlashMessage("You've reached the maximum number of reports for your plan.", [
            "You need to upgrade your plan.",
          ])
        );
      } else {
        new APIError("DuplicateReport", xhr, status, err);
      }
    },
  });
  return false;
};
