import React from "react";
import WidgetBase from "../widget_base";
import RichTextWidget from "./rich-text_widget.jsx";
import RichTextEditor from "./rich-text_editor.jsx";

export default class RichText extends WidgetBase {
  constructor() {
    super();
  }

  name() {
    return "Layout -- Rich Text";
  }

  type() {
    return "layout-rich-text";
  }

  width() {
    return "large";
  }

  height() {
    return 3;
  }

  config(chartType) {
    return {
      widgetType: this.type(),
      widgetName: this.name(),
      text: "Your text here.<br><br>Oh, and it can be on multiple lines!<br><strong>Bold, center, etc</strong>",
    };
  }

  duplicateWidget(widgetId) {
    store.dispatch(duplicateWidgetOnCanvas(widgetId));
  }

  editorComponent(widgetIndex, closeAction, deleteWidget) {
    return React.createElement(
      RichTextEditor,
      {
        widgetIndex: widgetIndex,
        key: widgetIndex,
        closeWidgetEditModal: closeAction,
        deleteWidget: deleteWidget,
      },
      null
    );
  }

  renderInGrid(widgetLayout, showWidgetEditModal, deleteSelectedWidget) {
    return (
      <div
        className="canvas-grid-item layout-large-text-box"
        id={widgetLayout.i}
        key={widgetLayout.i}
        data-grid={widgetLayout}
      >
        <div>
          <div className="canvas-widget-edit-save">
            <a
              onClick={function () {
                return this.duplicateWidget(widgetLayout.i);
              }.bind(this)}
              className=""
            >
              <i className="fas fa-copy" />
            </a>
            <a
              onClick={function () {
                return showWidgetEditModal(widgetLayout.i);
              }.bind(this)}
            >
              <i className="fas fa-edit" />
            </a>
            <a
              onClick={function () {
                return deleteSelectedWidget(widgetLayout.i);
              }.bind(this)}
            >
              <i className="fas fa-times" />
            </a>
          </div>
        </div>
        <RichTextWidget widgetIndex={widgetLayout.i} key={widgetLayout.i} />
      </div>
    );
  }
}
