const isValidDimensionList = (mtrc, validDimensionsFilter) => {
  // if we don't have a list of valid dimensions for the metric, we want all metrics.
  if (mtrc.valid_dimensions === undefined) {
    return true;
  }

  // If valid_dimensions is not an array, it should not work as it's not valid, so removing this metric
  if (!Array.isArray(mtrc.valid_dimensions)) {
    return false;
  }

  // If the list of valid dimensions is empty, it's considered invalid / disabled
  if (mtrc.valid_dimensions.length === 0) {
    return false;
  }

  // If the valid dimension is in the metric valid dimensions, and in the filters...
  return mtrc.valid_dimensions.some((element) => validDimensionsFilter.includes(element));
};

const MetricFilterer = {
  byValidDimensions(metricList, validDimensionsFilter) {
    let metrics = metricList;
    if (Array.isArray(validDimensionsFilter)) {
      if (validDimensionsFilter.length == 0) {
        return metricList;
      }

      metrics = metrics.filter((mtrc) => isValidDimensionList(mtrc, validDimensionsFilter));
    }

    return metrics;
  },
};

export const DimensionFilterer = {
  byValidDimensions(dimensionList, validDimensionsFilter) {
    let dimensions = dimensionList;
    if (Array.isArray(validDimensionsFilter)) {
      if (validDimensionsFilter.length == 0) {
        return dimensionList;
      }

      dimensions = dimensionList.filter((dim) => validDimensionsFilter.includes(dim.value));
    }

    return dimensions;
  },
};

export default MetricFilterer;
