import React from "react";
import { connect } from "react-redux";
import CreatableSelect from "react-select/lib/Creatable";
import "./../../helpers/string/capitalize";

class ReportEmails extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let emailRecipients = this.props.emails(this.props.interval);
    let available = this.props.scheduled(this.props.interval);
    let options = [];

    if (Array.isArray(emailRecipients)) {
      options = emailRecipients.map(function (email) {
        return { label: email, value: email };
      });
      emailRecipients = emailRecipients.join(",");
    }

    return (
      <div className="field">
        <div>
          <label className="tw-label">Emails recipient(s)</label>
          <CreatableSelect
            className="mb-2"
            multi={true}
            onChange={function (values) {
              this.props.updateEmails(values, this.props.interval);
            }.bind(this)}
            value={emailRecipients}
            options={options}
            // disabled={!available}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let scheduled = false;
  return {
    emails: function (interval) {
      return store.getState().reportFormReducer["emails_" + interval];
    },
    scheduled: function (interval) {
      return store.getState().reportFormReducer["interval_" + interval];
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateEmails: (values, interval) => {
      let emails = values.map(function (val) {
        return val.value;
      });
      // Use bracket notation to dynamically call the appropriate action creator
      const actionCreator = "setReportEmails" + interval.charAt(0).toUpperCase() + interval.slice(1);
      dispatch(window[actionCreator](emails));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportEmails);
