import { createStore, combineReducers } from "redux";
import { reportNewsFormReducer } from "../reducers/report_news";
import { reportFormReducer } from "../reducers/report_";
import { integrationsReducer } from "../reducers/integrations";
import { widgetReducer } from "../reducers/widgets_";
import { watcherFormReducer } from "../reducers/watcher_form";
import { ppcMarkupReducer } from "../reducers/ppc_markup";

window.store = (window.devToolsExtension ? window.devToolsExtension()(createStore) : createStore)(
  combineReducers({
    watcherFormReducer: watcherFormReducer,
    reportFormReducer: reportFormReducer,
    widgetReducer: widgetReducer,
    reportNewsFormReducer: reportNewsFormReducer,
    integrationsReducer: integrationsReducer,
    ppcMarkupReducer: ppcMarkupReducer,
  })
);
