import IntegrationBase from "../integration_base";
import SingleMetric from "./widgets/single_metric/single_metric.js";
import List from "./widgets/list/list.js";
import PieChart from "./widgets/pie_chart/pie_chart";
import BarGraph from "./widgets/bar_graph/bar_graph";
import IntegrationIcon from "./logo/logo.png";
import GoogleDrivePicker from "./google-drive-picker";
import GoogleSheetsMandatoryClickGIF from "./img/google_sheets_mandatory_click.gif";
import btn_google_signin_dark_normal_web from "./img/btn_google_signin_dark_normal_web.png";
import React from "react";

export default class GoogleSheets extends IntegrationBase {
  constructor() {
    super();
    new GoogleDrivePicker();
    this.registerWidget(new SingleMetric());
    this.registerWidget(new List());
    this.registerWidget(new PieChart());
    this.registerWidget(new BarGraph());
  }

  name() {
    return "Google Sheets";
  }

  iconURL() {
    return IntegrationIcon;
  }

  oAuthProviderPreRedirectNotice() {
    return (
      <div className="text-center">
        <h2 className="text-4xl font-bold mb-12">Important notice</h2>
        <p className="my-2">
          To be able to access your Google Sheets data, on the next screen (on Google's consent screen), you will have
          to check this box:
        </p>
        <img className="border-2 border-red-400 m-auto" src={GoogleSheetsMandatoryClickGIF} />
        <p className="my-2">Otherwise, we will not be able to pull your data.</p>
      </div>
    );
  }

  oAuthProviderName() {
    return "googlesheets";
  }

  reducerAccountIDVariableName() {
    return "google_sheets_customer_id";
  }

  reportStoreDispatchFunction() {
    return setGoogleSheetsAccount;
  }

  signInButton() {
    return (
      <button
        onClick={() => {
          window.location = this.oAuthProviderRedirectPath();
        }}
      >
        <img src={btn_google_signin_dark_normal_web} />
      </button>
    );
  }
}
