import React from "react";
var createReactClass = require("create-react-class");
import { connect } from "react-redux";
import Select from "react-select";
import WidgetFilterEditor from "../../../../legacy-components/canvas-widgets/widget_filter_editor";
import Button from "../../../../legacy-components/generic/button.js.jsx";
import ButtonWithIcon from "../../../../legacy-components/generic/button_with_icon.js.jsx";
import FilterValidation from "../../../../helpers/filter_validation";
import FlashMessage from "../../../../legacy-components/flash_message.js.jsx";
import AccountSelector from "../../../../legacy-components/google_analytics_profiles.js";
import WidgetPreviewFetcher from "../../../../helpers/widget_preview/widget_preview_fetcher";

function mapFormattedMetricWidgetEditorStateToProps(state, ownProps) {
  return state.widgetReducer.editorConfig;
}
const FormattedMetricWidgetEditor = connect(mapFormattedMetricWidgetEditorStateToProps)(
  createReactClass({
    getInitialState: function () {
      return { label: this.props.label, labelEditedByUserFor: -1 };
    },
    updateDateFilter: function (ranges, isEnabledDateFilter) {
      if(isEnabledDateFilter){
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, this.props.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, this.props.widgetIndex));
      }
      
    },
    updateFiltersEnabled: function (checked) {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, this.props.widgetIndex));
    },

    saveAndClose: function () {
      let errors = [];

      if (!FilterValidation.validate(this.props.filters)) {
        errors.push("At least one filter has an unspecified value");
      }

      if (errors.length > 0) {
        store.dispatch(setFlashMessage("We could not save the widget:", errors));
        return;
      }

      store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set

      store.dispatch(saveWidgetConfigFromEditor(this.props.widgetIndex));

      this.close();
    },

    close: function () {
      new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

      store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
      this.props.closeWidgetEditModal();
    },
    componentWillReceiveProps: function (nextProps) {
      this.setState({ label: nextProps.label });
    },
    updateMetricAndLabel: function (val) {
      if (val.value != -1 && val.value != this.props.metric_name) {
        store.dispatch(updateEditorWidgetConfig("label", String(val.label).split(" - ").pop(), this.props.widgetIndex));
      }
      store.dispatch(updateEditorWidgetConfig("metric_name", val.value, this.props.widgetIndex));
    },
    updateCustomAccount: function (event) {
      let value = undefined;
      if (event) {
        value = event.value;
      }

      store.dispatch(updateEditorWidgetConfig("custom_account", value, this.props.widgetIndex));
    },
    render: function () {
      let enbaleWidgetDateFeature = store.getState().reportFormReducer.enbale_widget_date_feature;
      var widgetConfig = this.props;
      var gaMetricsForCanvas = store.getState().widgetReducer.gaMetricsForCanvas;
      return (
        <div className="widget widget-editor">
          <h3 className="tw-large-header mb-4">Configure Google Analytics / Adwords Single Metrics widget</h3>
          <div className="mb-4">
            <label className="tw-label">Label</label>
            <input
              className="tw-field"
              value={this.state.label}
              onChange={function (event) {
                store.dispatch(updateEditorWidgetConfig("label", event.target.value, this.props.widgetIndex));
                this.setState({
                  label: event.target.value,
                  labelEditedByUserFor: widgetConfig.metric_name,
                });
              }.bind(this)}
            />
          </div>

          <div className="mb-4">
            <label className="tw-label">Metric</label>
            <Select
              name="metric_name"
              value={widgetConfig.metric_name}
              options={gaMetricsForCanvas}
              onChange={this.updateMetricAndLabel}
            />
          </div>
          {
            enbaleWidgetDateFeature &&
            <DatePickerComponent
              updateFiltersEnabled={this.updateFiltersEnabled}
              updateDateFilter={this.updateDateFilter}
              selectedDate={store.getState().widgetReducer.editorConfig.date_filter_by_widget}
              lang={"en"} 
            />
          } 
          <WidgetFilterEditor hasORenabled={true} widgetIndex={this.props.widgetIndex} providerType={"ga"} />

          <AccountSelector disconnectStore onChange={this.updateCustomAccount} value={widgetConfig.custom_account} />

          <FlashMessage />

          <div className="flex flex-row mt-4">
            <ButtonWithIcon
              onClick={this.saveAndClose}
              text="Save &amp; close"
              icon="fa-check"
              extraClasses="tw-submit-button"
            />
            &nbsp;
            <Button onClick={this.close} text="Close without saving" />
            &nbsp;
            <ButtonWithIcon
              onClick={this.props.deleteWidget}
              icon="fa-times"
              extraClasses="bg-red-100 text-red-700 hover:bg-red-200 ml-auto"
              text="Delete this widget"
            />
          </div>
        </div>
      );
    },
  })
);

export default FormattedMetricWidgetEditor;
