import React from "react";
var createReactClass = require("create-react-class");
import Select from "react-select";
import $ from "jquery";
import APIError from "../helpers/api_error";

const SmsRecipients = createReactClass({
  apiURL: function () {
    return "/api/v1/mobiles";
  },

  getInitialState: function () {
    return {
      options: [],
      smsRecipients: [],
    };
  },

  componentDidMount: function () {
    this.loadAvailableMobile(function () {
      var recipients = store.getState().watcherFormReducer.smsRecipients;
      this.setState({ smsRecipients: recipients });
    });

    this.editLoaded = false;

    this.storeSubscription = store.subscribe(
      function () {
        var newRecipients = store.getState().watcherFormReducer.smsRecipients;
        if (newRecipients != this.state.smsRecipients) {
          this.setState({ smsRecipients: newRecipients });
        }
      }.bind(this)
    );

    FeatureFlag.check("sms");
  },

  loadAvailableMobile: function () {
    $.ajax({
      url: this.apiURL(),
      dataType: "json",
      cache: false,
      success: function (data) {
        this.prepareOptions(data);
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("SmsRecipients.loadAvailableMobile", xhr, status, err);
      }.bind(this),
    });
  },

  prepareOptions: function (data) {
    let options = data.data.map(function (opt) {
      return { value: opt.id, label: opt.attributes.name };
    });
    this.setState({ options: options });

    // If we are editing an alert...
    if (store.getState().watcherFormReducer.smsRecipients) {
      this.setState({
        smsRecipients: store.getState().watcherFormReducer.smsRecipients,
      });
    }
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  onChange: function (data) {
    let val = data.map(function (option) {
      return option.value;
    });
    store.dispatch(setSmsRecipients(val));
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    let smsRecipients = this.state.smsRecipients;
    if (Array.isArray(smsRecipients)) {
      smsRecipients = smsRecipients.join(",");
    }

    return (
      <div className="feature-flag-parent-container">
        <div className="feature_flag_overlay-sms">
          <p>
            SMS is not available on your plan.{" "}
            <a href="/billing" className="underline">
              Upgrade your plan to unlock it.
            </a>
          </p>
        </div>

        <label className="tw-label pt-4 mb-2">SMS recipient(s)</label>
        <div className="sms_configuration_feature">
          <Select
            multi={true}
            tabIndex={tabIndex}
            value={smsRecipients}
            ref="select"
            options={this.state.options}
            onChange={this.onChange}
            className="sel2-sms-recipients"
          />
        </div>
        <p className="text-xs mt-1 text-gray-500">
          Note: while alerts will be sent every time they cross the threshold by email, they will only be sent up to
          once per hour via SMS.
        </p>
      </div>
    );
  },
});

export default SmsRecipients;
