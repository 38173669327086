import SingleMetric from "../components/widgets/single_metric/single_metric";
import List from "../components/widgets/list/list";
import Pie from "../components/widgets/pie/pie";
import MixedChart from "../components/widgets/mixed_chart/mixed_chart";
import MetricLoader from "../helpers/metric_loader";
import DimensionLoader from "../helpers/dimension_loader";
import AccountLoader from "../helpers/account_loader";
import React from "react";

export default class IntegrationBase {
  constructor() {
    this.widgetStore = [];

    // forces it to run so we know if's properly overwritten by the integration, otherwise, it throws an error
    this.name();

    this.config = this.integrationConfig();

    if (this.config.autoRegisterWidgets.enabled === true) {
      this.autoRegisterWidgets();
    }

    if (this.config.autoloadMetrics.enabled === true) {
      this.autoloadMetrics();
    }

    if (this.config.autoloadDimensions.enabled === true) {
      this.autoloadDimensions();
    }

    if (this.config.accountSelector.enabled === true) {
      this.autoloadAccounts();
    }

    // To customize them, you need to overwrite this in the integration constructor.
    this.sortByOptions = [
      { label: "Dimension", value: "dimension" },
      { label: "Metric #1", value: "metric[0]" },
      { label: "Metric #2", value: "metric[1]" },
      { label: "Metric #3", value: "metric[2]" },
    ];
  }

  integrationConfig() {
    return {
      // Version 2.0.0 is prior to this new way of adding integrations. Latest version i 3.0.0
      metricsWatchIntegrationProtocolVersion: "2.0.0",

      // This is enabling the use of generic widgets, instead of integration-specific widgets like we used to do.
      autoRegisterWidgets: { enabled: false },

      // This is enabling the autoloading of metrics. When enabled, we need a to set metricLoaderCallback to handle the API response and format the metrics.
      autoloadMetrics: { enabled: false, metricLoaderCallback: undefined },

      // This is enabling the autoloading of metrics. When enabled, we need a to set dimensionLoaderCallback to handle the API response and format the metrics.
      autoloadDimensions: { enabled: false, dimensionLoaderCallback: undefined },

      // This is enabling the use of the generic account selector, instead of an integration-specific one.
      accountSelector: {
        enabled: false,
        databaseProviderType: undefined, // "metrics_providers-google_analytics4s",
        accountLoaderCallback: undefined, // this.formatAccounts.bind(this),
      },
    };
  }

  autoloadAccounts() {
    this.accounts = [];

    const accountLoader = new AccountLoader();
    accountLoader.fetch(
      this.config.accountSelector.databaseProviderType,
      this.config.accountSelector.accountLoaderCallback
    );
  }

  accountList() {
    return this.accounts;
  }

  autoloadMetrics() {
    this.metrics = [];

    const metricLoader = new MetricLoader();
    metricLoader.fetch(this.oAuthProviderName(), this.config.autoloadMetrics.metricLoaderCallback);
  }

  autoloadDimensions() {
    this.dimensions = [];

    const dimentionLoader = new DimensionLoader();
    dimentionLoader.fetch(this.oAuthProviderName(), this.config.autoloadDimensions.dimensionLoaderCallback);
  }

  autoRegisterWidgets() {
    if (this.widgets().singleMetric.enabled == true) {
      this.registerWidget(new SingleMetric(this.widgets().singleMetric, this));
    }

    if (this.widgets().list.enabled == true) {
      this.registerWidget(new List(this.widgets().list, this));
    }

    if (this.widgets().pieAndDoughnut.enabled == true) {
      this.registerWidget(new Pie(this.widgets().pieAndDoughnut, this));
    }

    if (this.widgets().mixedChart.enabled == true) {
      this.registerWidget(new MixedChart(this.widgets().mixedChart, this));
    }
  }

  name() {
    throw 'Integration needs to implement the name method. It should return something like "Mailchimp", for example.';
  }

  widgets() {
    return {
      singleMetric: {
        enabled: false,
      },
      list: {
        enabled: false,
      },
      pieAndDoughnut: {
        enabled: false,
      },
      mixedChart: {
        enabled: false,
      },
    };
  }

  iconURL() {
    throw 'Integration needs to implement the iconURL method. It should return something like "http://example.com/icon.png", for example. It will be used as the "src" in <img /> tags.';
  }

  oAuthProviderName() {
    throw 'Integration needs to implement the oAuthProviderName method. It should return something like "google-analytics", for example.';
  }

  oAuthProviderPreRedirectNotice() {
    return undefined;
  }

  oAuthProviderRedirectPath() {
    return `/providers/auth/${this.oAuthProviderName()}`;
  }

  reportStoreDispatchFunction() {
    if (this.integrationConfig().metricsWatchIntegrationProtocolVersion !== "3.0.0") {
      throw "Integration needs to implement the reportStoreDispatchFunction method.";
    }
  }

  reducerAccountIDVariableName() {
    if (this.integrationConfig().metricsWatchIntegrationProtocolVersion !== "3.0.0") {
      throw "Integration needs to implement the reducerAccountIDVariableName method.";
    }
  }

  registerIntegration(integrationStore) {
    integrationStore.registerIntegration(this);
  }

  registerWidget(w) {
    this.widgetStore.push(w);
  }

  allWidgetNames() {
    return this.widgetStore.map(function (w) {
      return w.name();
    });
  }

  allWidgetTypes() {
    return this.widgetStore.map(function (w) {
      return w.type();
    });
  }

  // returns undefined if not feature flagged. Otherwise, return the name of the feature flag.
  featureFlagName() {
    return undefined;
  }

  integrationFor() {
    return ["report"];
  }

  // widgetType can be "SingleMetric", "List", etc
  // This will return the configuration object of the widget type for the current integration.
  findIntegrationWidgetConfigByType(widgetType) {
    const widgetConfig = this.widgets()[widgetType];
    if (widgetConfig) {
      return widgetConfig;
    }
    return {};
  }

  // The name of this function is important. It's a hardcoded callback name used to autoload metrics.
  // This function is used to properly format the metrics returned for the integration, into the generic format.
  formatMetrics(metrics) {
    let formattedMetrics = [];

    for (let metric in metrics) {
      let label = metrics[metric].label;
      if (metrics[metric].description) {
        label = label + " -- " + metrics[metric].description;
      }
      let valid_dimensions = metrics[metric].valid_dimensions;
      let formatted = {
        value: metric,
        label: label,
      };

      if (Array.isArray(valid_dimensions)) {
        formatted["valid_dimensions"] = valid_dimensions;
      }

      formattedMetrics.push(formatted);
    }

    this.metrics = formattedMetrics;
  }

  formatDimensions(data) {
    this.dimensions = [];

    for (const key in data) {
      const item = data[key];
      this.dimensions.push({
        value: key,
        label: item.label,
      });
    }
  }

  // This is used only when there is a notice before the OAuth redirect.
  signInButton() {
    return (
      <button
        className="tw-submit-button"
        onClick={() => {
          window.location = this.oAuthProviderRedirectPath();
        }}
      >
        Continue
      </button>
    );
  }
}
