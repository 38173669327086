import IntegrationBase from "../integration_base";
import SingleMetric from "./widgets/single_metric/single_metric.js";
import List from "./widgets/list/list.js";
import Pie from "./widgets/pie_chart/pie_chart";
import Bar from "./widgets/bar_graph/bar_graph";
import IntegrationIcon from "./logo/facebook.png";

export default class FacebookPages extends IntegrationBase {
  constructor() {
    super();
    this.registerWidget(new SingleMetric());
    this.registerWidget(new List());
    this.registerWidget(new Pie());
    this.registerWidget(new Bar());
  }

  name() {
    return "Facebook Pages";
  }

  iconURL() {
    return IntegrationIcon;
  }

  oAuthProviderName() {
    return "facebook";
  }

  reducerAccountIDVariableName() {
    return "facebook_pages_id";
  }

  reportStoreDispatchFunction() {
    return setFacebookPagesAccount;
  }
}
