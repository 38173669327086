import IntegrationBase from "../integration_base";
import IntegrationIcon from "./logo/instagram.png";

export default class Instagram extends IntegrationBase {
  // This configures some autoloading
  integrationConfig() {
    return {
      // Metrics Watch Integration Protcol Version.
      metricsWatchIntegrationProtocolVersion: "3.0.0",

      // This is enabling the use of generic widgets, instead of integration-specific widgets like we used to do.
      autoRegisterWidgets: { enabled: true },

      // This is enabling the autoloading of metrics. When enabled, we need a to set metricLoaderCallback to handle the API response and format the metrics.
      autoloadMetrics: { enabled: true, metricLoaderCallback: this.formatMetrics.bind(this) },

      // This is enabling the autoloading of metrics. When enabled, we need a to set dimensionLoaderCallback to handle the API response and format the metrics.
      autoloadDimensions: { enabled: true, dimensionLoaderCallback: this.formatDimensions.bind(this) },

      // This is enabling the use of the generic account selector, instead of an integration-specific one.
      accountSelector: {
        enabled: true,
        databaseProviderType: "metrics_providers-instagrams",
        accountLoaderCallback: this.formatAccounts.bind(this),
      },

      metrics: () => {
        return this.metrics;
      },
      dimensions: () => {
        return this.dimensions;
      },
      filterDimensionList: () => {
        return [
          { label: "Media", value: "media" },
          { label: "Photo and video", value: "photo_and_video" },
        ];
        // return this.dimensions;
      },
      filterOperators: [
        { value: "LIKE", label: "contains" },
        { value: "NOT LIKE", label: "does NOT contain" },
      ],
      filterHasORenabled: true,
    };
  }

  // The name of this function is important. It's a hardcoded callback name used to autoload metrics.
  // This function is used to properly format the metrics returned for the integration, into the generic format.
  formatMetrics(metrics) {
    let formattedMetrics = [];

    for (let metric in metrics) {
      let label = metrics[metric].label;

      formattedMetrics.push({
        value: metric,
        label: label,
        valid_dimensions: metrics[metric].metric_level,
      });
    }

    this.metrics = formattedMetrics;
  }

  formatDimensions(data) {
    this.dimensions = [];

    for (const key in data) {
      const item = data[key];
      this.dimensions.push({
        value: key,
        label: item.label,
      });
    }
  }

  formatAccounts(accounts) {
    let options = [];
    for (let index = 0; index < accounts.length; index++) {
      const integrationAccount = accounts[index];
      let providerAccounts = integrationAccount.attributes.provider_accounts;
      if (integrationAccount.type == "metrics_providers-instagrams") {
        for (let j = 0; j < providerAccounts.length; j++) {
          const account = providerAccounts[j];
          options.push({
            value: account.instagram_business_account.id,
            label: account.name,
          });
        }
      }
    }

    store.dispatch(setIntegrationAccountList("instagram", options));
  }

  name() {
    return "Instagram";
  }

  iconURL() {
    return IntegrationIcon;
  }

  oAuthProviderName() {
    return "instagram";
  }

  integrationFor() {
    return ["report"];
  }

  widgets() {
    return {
      integration: this,
      singleMetric: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: false,
        widgetName: "Instagram Single Metric",
        widgetType: "instagram-single-metric",
        defaultLabel: "Followers",
        defaultMetricName: "followers_count",
        valid_dimensions: ["user", "user-insights"],
      },
      list: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        sortEnabled: true, // if we want to to overwrite the sorting optins we need to set `this.sortByOptions` in this class (see how it's down in the `IntegrationBase` class)
        sortByOptions: [
          // this is to overwrite the defaults
          { label: "Publication date", value: "timestamp" },
          { label: "Dimension", value: "dimension" },
          { label: "Metric #1", value: "metric[0]" },
          { label: "Metric #2", value: "metric[1]" },
          { label: "Metric #3", value: "metric[2]" },
        ],
        filterByCurrentDimensionOnly: true,
        widgetName: "Instagram List",
        widgetType: "instagram-list",
        defaultLabel: "Media with the most likes",
        defaultDimensionName: "media",
        defaultDimensionLabel: "Media",
        defaultMetricName: "like_count",
        defaultMetricLabel: "Likes",
        // noSecondaryMetric: [],
      },
      pieAndDoughnut: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        filterByCurrentDimensionOnly: true,
        widgetName: "Instagram Pie Chart",
        widgetType: "instagram-pie-chart",
        defaultLabel: "Media with the most likes",
        defaultDimensionName: "media",
        defaultDimensionLabel: "Media",
        defaultMetricName: "like_count",
        defaultMetricLabel: "Likes",
      },
      mixedChart: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        filterByCurrentDimensionOnly: true,
        // filterOnPeriodEnabled: false,
        widgetName: "Instagram Bar Graph",
        widgetType: "instagram-bar-graph",
        defaultLabel: "Followers trend",
        defaultDimensionName: "MW--period",
        defaultMetricName: "followers_count",
        defaultMetricLabel: "Followers",
        valid_dimensions__for_period: ["user", "user-insights"],
        isPeriodDimensionFilterable: false,
      },
    };
  }
}
