window.setAgencySettings = function (agencySettings) {
  return {
    type: "SET_AGENCY_SETTINGS",
    agencySettings: agencySettings,
  };
};

window.setReportName = function (newName) {
  return {
    type: "SET_REPORT_NAME",
    reportName: newName,
  };
};

window.setReportMessageMonthly = function (newMessage) {
  return {
    type: "SET_REPORT_MESSAGE_MONTHLY",
    message: newMessage,
  };
};

window.setReportMessageWeekly = function (newMessage) {
  return {
    type: "SET_REPORT_MESSAGE_WEEKLY",
    message: newMessage,
  };
};

window.setReportMessageDaily = function (newMessage) {
  return {
    type: "SET_REPORT_MESSAGE_DAILY",
    message: newMessage,
  };
};

window.setReportEmailsMonthly = function (newEmails) {
  return {
    type: "SET_REPORT_EMAILS_MONTHLY",
    emails: newEmails,
  };
};

window.setReportEmailsWeekly = function (newEmails) {
  return {
    type: "SET_REPORT_EMAILS_WEEKLY",
    emails: newEmails,
  };
};

window.setReportEmailsDaily = function (newEmails) {
  return {
    type: "SET_REPORT_EMAILS_DAILY",
    emails: newEmails,
  };
};

window.setReportSubjectMonthly = function (newSubject) {
  return {
    type: "SET_REPORT_SUBJECT_MONTHLY",
    subject: newSubject,
  };
};

window.setReportSubjectWeekly = function (newSubject) {
  return {
    type: "SET_REPORT_SUBJECT_WEEKLY",
    subject: newSubject,
  };
};

window.setReportSubjectDaily = function (newSubject) {
  return {
    type: "SET_REPORT_SUBJECT_DAILY",
    subject: newSubject,
  };
};

window.setLogoUrl = function (logoUrl) {
  return {
    type: "SET_LOGO_URL",
    logo_url: logoUrl,
  };
};

window.setPreviewRecipient = function (recipient) {
  return {
    type: "SET_PREVIEW_RECIPIENT",
    preview_recipient: recipient,
  };
};

window.setReportIntervalMonthly = function (enabled) {
  return {
    type: "SET_REPORT_INTERVAL_MONTHLY",
    enabled: enabled,
  };
};
window.setReportIntervalWeekly = function (enabled) {
  return {
    type: "SET_REPORT_INTERVAL_WEEKLY",
    enabled: enabled,
  };
};
window.setReportIntervalDaily = function (enabled) {
  return {
    type: "SET_REPORT_INTERVAL_DAILY",
    enabled: enabled,
  };
};

window.setCompareYearToYear = function (checked, interval) {
  return {
    type: "SET_COMPARE_YEAR_TO_YEAR",
    checked: checked,
    interval: interval,
  };
};

window.setCompareDailyOptionsSameDayOfLastWeek = function (checked) {
  return {
    type: "SET_COMPARE_DAILY_OPTIONS_SAME_DAY_OF_LAST_WEEK",
    checked: checked,
  };
};

window.setCompareDailyOptions = function (options) {
  return {
    type: "SET_COMPARE_DAILY_OPTIONS",
    options: options,
  };
};

window.setCompareDailyOptionsMonthToDate = function (options) {
  return {
    type: "SET_COMPARE_DAILY_OPTIONS_MONTH_TO_DATE",
    options: options,
  };
};

window.setReportDOW = function (dow) {
  return {
    type: "SET_REPORT_DAY_OF_WEEK",
    reportDayOfWeek: dow,
  };
};

window.setReportCanvaId = function (val) {
  return {
    type: "SET_REPORT_CANVA_ID",
    report_canva_id: val,
  };
};

window.setProviderTypeRequired = function (val) {
  return {
    type: "SET_PROVIDER_TYPE_REQUIRED",
    provider_type_required: val,
  };
};

window.setReportLanguage = function (newLanguage) {
  return {
    type: "SET_REPORT_LANGUAGE",
    reportLanguage: newLanguage,
  };
};

window.setFacebookAdsAccount = function (facebook_ads_account) {
  return {
    type: "SET_REPORT_FACEBOOK_ADS_ACCOUNT",
    facebook_ads_account: facebook_ads_account,
  };
};

window.setFacebookPagesAccount = function (facebook_pages_id) {
  return {
    type: "SET_REPORT_FACEBOOK_PAGES_ACCOUNT",
    facebook_pages_id: facebook_pages_id,
  };
};

window.setInstagramAccount = function (instagram_account_id) {
  return {
    type: "SET_REPORT_INSTAGRAM_ACCOUNT",
    instagram_account_id: instagram_account_id,
  };
};

window.setGoogleAdsAccount = function (google_ads_customer_id) {
  return {
    type: "SET_REPORT_GOOGLE_ADS_ACCOUNT",
    google_ads_customer_id: google_ads_customer_id,
  };
};

window.setGoogleSheetsAccount = function (google_sheets_customer_id) {
  return {
    type: "SET_REPORT_GOOGLE_SHEETS_ACCOUNT",
    google_sheets_customer_id: google_sheets_customer_id,
  };
};

window.setLinkedInAdsAccount = function (linkedin_ads_account_id) {
  return {
    type: "SET_REPORT_LINKED_IN_ACCOUNT",
    linkedin_ads_account_id: linkedin_ads_account_id,
  };
};

window.setLinkedInPagesAccount = function (linkedin_pages_account_id) {
  return {
    type: "SET_REPORT_LINKED_IN_PAGES_ACCOUNT",
    linkedin_pages_account_id: linkedin_pages_account_id,
  };
};

window.setMailchimpList = function (mailchimp_list_id) {
  return {
    type: "SET_REPORT_MAILCHIMP_LIST",
    mailchimp_list_id: mailchimp_list_id,
  };
};

window.setGoogleSearchConsoleSite = function (val) {
  return {
    type: "SET_REPORT_GOOGLE_SEARCH_CONSOLE_SITE",
    google_search_console_site: val,
  };
};

window.setReportID = function (id) {
  return {
    type: "SET_REPORT_ID",
    newReportId: id,
  };
};

window.setReportAlwaysSendPreviewFirst = function (checked) {
  return {
    type: "SET_ALWAYS_SEND_PREVIEW_FIRST",
    checked: checked,
  };
};

window.setCustomVariables = function (variablesObject) {
  return {
    type: "SET_CUSTOM_VARIABLES",
    variablesObject,
  };
};

window.setReportShared = (checked) => {
  return {
    type: "SET_REPORT_SHARED",
    checked: checked,
  };
};

window.setSharedAceessKey = (checked) => {
  return {
    type: "SET_SHARED_REPORT_ACCESS_KEY",
    checked: checked,
  };
};

window.setIsInitialReportShared = (isInitialReportShared) => {
  return {
    type: "SET_IS_INITIAL_REPORT_SHARED",
    isInitialReportShared: isInitialReportShared,
  };
};

window.setIncludePublicLinkInEmail = (checked) => {
  return {
    type: "SET_REPORT_INCLUDE_PUBLIC_LINK_IN_EMAIL",
    checked: checked,
  };
};

window.setSingleMetricComparisonDisabled = (checked) => {
  return {
    type: "SET_REPORT_SINGLE_METRIC_COMPARISON_DISABLED",
    checked: checked,
  };
};

window.setReportPubliclyShareableURL = (url) => {
  return {
    type: "SET_REPORT_PUBLICL_URL",
    url: url,
  };
};

window.setReportAccessKey = (accessKey) => {
  return {
    type: "SET_REPORT_ACCESS_KEY",
    accessKey: accessKey,
  };
};

window.setGlobalReportIntegrationAccountID = function (integration, accountID) {
  return {
    type: "SET_GLOBAL_REPORT_INTEGRATION_ACCOUNT_ID",
    integration: integration,
    accountID: accountID,
  };
};
window.set_FF_EnableDatePicker = function (enbale_widget_date_feature) {
  return {
    type: "SET_ENABLE_DATE_PICKER",
    enbale_widget_date_feature,
  };
};

// The new easier, faster and more generic of doing things
window.setReportSetting = function (settingName, value) {
  return {
    type: "SET_REPORT_SETTING",
    settingName: settingName,
    value: value,
  };
};

window.setReportDraft = function (isDraft) {
  return {
    type: "SET_REPORT_DRAFT",
    isDraft: isDraft,
  };
};

window.set_FF_EnableIntegrationModal = function (enable_integration_modal) {
  return {
    type: "SET_ENABLE_INTEGRATION_MODAL",
    enable_integration_modal,
  };
}