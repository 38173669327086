import _ from "lodash";

const initialState = {
  ppcMarkupEnabled: false,
  facebookAds: 0,
  googleAds: 0,
  linkedInAds: 0,
};

const ppcMarkupReducer = (state, action) => {
  state = state || _.clone(initialState);

  switch (action.type) {
    case "SET_PPC_MARKUP":
      return _.assign({}, state, {
        [action.platform]: action.markup,
      });
    case "SET_PPC_MARKUP_ENABLED":
      return _.assign({}, state, { ppcMarkupEnabled: action.checked });
    default:
      return state;
  }
};

export { ppcMarkupReducer };
