import _ from "lodash";

var reportFormReducerInitialState = {
  reportID: undefined,
  name: "",
  business_name: "",
  message_monthly: "",
  message_weekly: "",
  message_daily: "",
  subject_monthly: "",
  subject_weekly: "",
  subject_daily: "",
  logo_url: "",
  preview_recipient: "",
  interval_monthly: false,
  interval_weekly: false,
  interval_daily: false,
  emails_monthly: [],
  emails_weekly: [],
  emails_daily: [],
  monthly_compare_year_to_year: false,
  weekly_compare_year_to_year: false,
  compare_daily_options: {
    compare_to_same_day_of_last_week: false,
  },
  day_of_week: "",
  language: "en",
  single_metric_comparison_disabled: false,
  shared: false,
  include_public_link_in_email: false,
  report_canva_id: "",

  facebook_pages_id: undefined,
  google_search_console_site: undefined,
  mailchimp_list_id: undefined,
  linkedin_ads_account_id: undefined,
  linkedin_pages_account_id: undefined,
  integration_account_ids: {},

  provider_type_required: [],
  always_send_preview_first: false,
  adhoc_recipients: "",
  isDraft: false,
};

const reportFormReducer = function (state, action) {
  if (state == null) {
    state = _.clone(reportFormReducerInitialState, true);
  }

  switch (action.type) {
    case "SET_AGENCY_SETTINGS":
      return _.assign({}, state, { agencySettings: action.agencySettings });
    case "SET_REPORT_NAME":
      return _.assign({}, state, {
        name: action.reportName,
      });
    case "SET_REPORT_MESSAGE_MONTHLY":
      return _.assign({}, state, {
        message_monthly: action.message,
      });
    case "SET_REPORT_MESSAGE_WEEKLY":
      return _.assign({}, state, {
        message_weekly: action.message,
      });
    case "SET_REPORT_MESSAGE_DAILY":
      return _.assign({}, state, {
        message_daily: action.message,
      });
    case "SET_REPORT_EMAILS_MONTHLY":
      return _.assign({}, state, {
        emails_monthly: action.emails,
      });
    case "SET_REPORT_EMAILS_WEEKLY":
      return _.assign({}, state, {
        emails_weekly: action.emails,
      });
    case "SET_REPORT_EMAILS_DAILY":
      return _.assign({}, state, {
        emails_daily: action.emails,
      });
    case "SET_REPORT_SUBJECT_MONTHLY":
      return _.assign({}, state, {
        subject_monthly: action.subject,
      });
    case "SET_REPORT_SUBJECT_WEEKLY":
      return _.assign({}, state, {
        subject_weekly: action.subject,
      });
    case "SET_REPORT_SUBJECT_DAILY":
      return _.assign({}, state, {
        subject_daily: action.subject,
      });
    case "SET_LOGO_URL":
      return _.assign({}, state, {
        logo_url: action.logo_url,
      });
    case "SET_PREVIEW_RECIPIENT":
      return _.assign({}, state, {
        preview_recipient: action.preview_recipient,
      });
    case "SET_REPORT_INTERVAL_MONTHLY":
      return _.assign({}, state, {
        interval_monthly: action.enabled,
      });
    case "SET_REPORT_INTERVAL_WEEKLY":
      return _.assign({}, state, {
        interval_weekly: action.enabled,
      });
    case "SET_REPORT_INTERVAL_DAILY":
      return _.assign({}, state, {
        interval_daily: action.enabled,
      });
    case "SET_COMPARE_YEAR_TO_YEAR":
      if (action.interval == "weekly") {
        return _.assign({}, state, {
          weekly_compare_year_to_year: action.checked,
        });
      }

      return _.assign({}, state, {
        monthly_compare_year_to_year: action.checked,
      });

    case "SET_COMPARE_DAILY_OPTIONS_SAME_DAY_OF_LAST_WEEK":
      return _.assign({}, state, {
        compare_daily_options: {
          compare_to_same_day_of_last_week: action.checked,
        },
      });
    case "SET_COMPARE_DAILY_OPTIONS_MONTH_TO_DATE":
      return _.assign({}, state, {
        compare_daily_options: {
          month_to_date: action.options,
        },
      });
    case "SET_COMPARE_DAILY_OPTIONS":
      return _.assign({}, state, {
        compare_daily_options: action.options,
      });
    case "SET_REPORT_DAY_OF_WEEK":
      return _.assign({}, state, {
        day_of_week: action.reportDayOfWeek,
      });
    case "SET_REPORT_CANVA_ID":
      return _.assign({}, state, {
        report_canva_id: action.report_canva_id,
      });
    case "SET_PROVIDER_TYPE_REQUIRED":
      return _.assign({}, state, {
        provider_type_required: action.provider_type_required,
      });
    case "SET_REPORT_LANGUAGE":
      return _.assign({}, state, {
        language: action.reportLanguage,
      });
    case "SET_REPORT_FACEBOOK_ADS_ACCOUNT":
      return _.assign({}, state, {
        facebook_ads_account: action.facebook_ads_account,
      });
    case "SET_REPORT_FACEBOOK_PAGES_ACCOUNT":
      return _.assign({}, state, {
        facebook_pages_id: action.facebook_pages_id,
      });
    case "SET_REPORT_INSTAGRAM_ACCOUNT":
      return _.assign({}, state, {
        instagram_account_id: action.instagram_account_id,
      });
    case "SET_REPORT_GOOGLE_ADS_ACCOUNT":
      return _.assign({}, state, {
        google_ads_customer_id: action.google_ads_customer_id,
      });
    case "SET_REPORT_LINKED_IN_ACCOUNT":
      return _.assign({}, state, {
        linkedin_ads_account_id: action.linkedin_ads_account_id,
      });
    case "SET_REPORT_LINKED_IN_PAGES_ACCOUNT":
      return _.assign({}, state, {
        linkedin_pages_account_id: action.linkedin_pages_account_id,
      });
    case "SET_REPORT_MAILCHIMP_LIST":
      return _.assign({}, state, {
        mailchimp_list_id: action.mailchimp_list_id,
      });
    case "SET_REPORT_GOOGLE_SEARCH_CONSOLE_SITE":
      return _.assign({}, state, {
        google_search_console_site: action.google_search_console_site,
      });
    case "SET_REPORT_GOOGLE_SHEETS_ACCOUNT":
      return _.assign({}, state, {
        google_sheets_customer_id: action.google_sheets_customer_id,
      });
    case "SET_REPORT_ID":
      return _.assign({}, state, {
        reportID: action.newReportId,
      });
    case "SET_REPORT_PUBLICL_URL":
      return _.assign({}, state, {
        public_shareable_url: action.url,
      });
    case "SET_REPORT_SHARED":
      return _.assign({}, state, { shared: action.checked });
    case "SET_REPORT_INCLUDE_PUBLIC_LINK_IN_EMAIL":
      return _.assign({}, state, { include_public_link_in_email: action.checked });
    case "SET_REPORT_SINGLE_METRIC_COMPARISON_DISABLED":
      return _.assign({}, state, { single_metric_comparison_disabled: action.checked });
    case "SET_ALWAYS_SEND_PREVIEW_FIRST":
      return _.assign({}, state, {
        always_send_preview_first: action.checked,
      });
    case "SET_CUSTOM_VARIABLES":
      return _.assign({}, state, {
        custom_variables: action.variablesObject,
      });
    case "SET_GLOBAL_REPORT_INTEGRATION_ACCOUNT_ID":
      let new_integration_account_ids = _.clone(state.integration_account_ids);
      new_integration_account_ids[action.integration] = action.accountID;
      return _.assign({}, state, { integration_account_ids: new_integration_account_ids });

    // New, easier, faster and more generic way of updating report settings
    case "SET_REPORT_SETTING":
      let changes = {};
      changes[action.settingName] = action.value;
      return _.assign({}, state, changes);
    case "SET_REPORT_ACCESS_KEY":
      return _.assign({}, state, { accessKey: action.accessKey });
    case "SET_SHARED_REPORT_ACCESS_KEY":
      return _.assign({}, state, { isSharedAccessKey: action.checked });
    case "SET_IS_INITIAL_REPORT_SHARED":
      return _.assign({}, state, { isInitialReportShared: action.isInitialReportShared });
    case "SET_ENABLE_DATE_PICKER":
      return _.assign({}, state, { enbale_widget_date_feature: action.enbale_widget_date_feature });
    case "SET_REPORT_DRAFT":
      return _.assign({}, state, { isDraft: action.isDraft });
    case "SET_ENABLE_INTEGRATION_MODAL": 
      return _.assign({}, state, { enable_integration_modal: action.enable_integration_modal });
    default:
      return state;
  }
};

// reportFormReducerInitialState is exported to be used in tests
export { reportFormReducer, reportFormReducerInitialState };
