export default class LayoutWidgetStore {
  constructor() {
    this.layoutWidgets = [];
    this.globalWidgetStore = {};
    this.widgetTypeToNameStore = {};
  }

  registerWidget(widget) {
    this.layoutWidgets.push(widget);
    this.globalWidgetStore[widget.name()] = {
      type: widget.type(),
      name: widget.name(),
      widget: widget,
    };
    this.widgetTypeToNameStore[widget.type()] = widget.name();
  }

  getWidgetByName(name) {
    const widgetDefinition = this.globalWidgetStore[name];
    if (widgetDefinition) {
      return widgetDefinition.widget;
    }
    return undefined;
  }

  getWidgetByType(type) {
    const widgetName = this.widgetTypeToNameStore[type];
    if (widgetName) {
      return this.getWidgetByName(widgetName);
    }

    return undefined;
  }
}
