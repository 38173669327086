import React from "react";
import { connect } from "react-redux";

class Title extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="widget full-width-widget">
        <div className="widget-classification flex" style={{ backgroundColor: "#fff" }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="8 0 16 24" stroke="currentColor" width="10">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            />
          </svg>

          <div>
            <span className="provider">Layout</span>
            Title
          </div>
        </div>
        <input
          ref="title"
          className="label mt-4"
          type="text"
          value={this.props.title}
          onChange={this.props.updateTitle}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return state.widgetReducer.widgetConfigs[ownProps.widgetIndex];
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateTitle: (event) => {
      dispatch(updateWidgetConfig("title", event.target.value, ownProps.widgetIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Title);
