export default class IntegrationAccountsPreparator {
  prepare() {
    let integrationAccounts = {
      google: store.getState().watcherFormReducer.google_analytics_profile_id,
      "google-search-console": store.getState().reportFormReducer.google_search_console_site,
      mailchimp: store.getState().reportFormReducer.mailchimp_list_id,
      facebook: store.getState().reportFormReducer.facebook_pages_id,
      googleads: store.getState().reportFormReducer.google_ads_customer_id,
      instagram: store.getState().reportFormReducer.instagram_account_id,
      "linkedin-ads": store.getState().reportFormReducer.linkedin_ads_account_id,
      "linkedin-pages": store.getState().reportFormReducer.linkedin_pages_account_id,
    };

    const integrationKeys = Object.keys(store.getState().reportFormReducer.integration_account_ids);

    for (let index = 0; index < integrationKeys.length; index++) {
      const integration = integrationKeys[index];
      integrationAccounts[integration] = store.getState().reportFormReducer.integration_account_ids[integration];
    }

    return integrationAccounts;
  }
}
