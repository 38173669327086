const setMarkup = (platform, markup) => {
  return {
    type: "SET_PPC_MARKUP",
    platform: platform,
    markup: markup,
  };
};

const setMarkupEnabled = (checked) => {
  return {
    type: "SET_PPC_MARKUP_ENABLED",
    checked: checked,
  };
};

export { setMarkup, setMarkupEnabled };
