import React from "react";
import Select from "react-select";
import Modal from "../modal";
import Button from "../../legacy-components/generic/button.js";
import { reportFormValidation } from "../../helpers/report_form_validation";
import {
  subDays,
  addMonths,
  format,
  endOfYesterday,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  differenceInDays,
  subYears,
  subMonths,
} from "date-fns";
import { frCA, enUS } from "date-fns/locale";

import { DateRangePicker, createStaticRanges } from "react-date-range";
import { appsignal } from "../../appsignal";

import axios from "axios";

export const defaultStaticRanges = createStaticRanges([
  {
    label: "Yesterday",
    range: () => ({
      startDate: subDays(new Date(), 1),
      endDate: subDays(new Date(), 1),
    }),
  },
  {
    label: "Last Week",
    range: () => ({
      startDate: startOfWeek(subDays(new Date(), 7)),
      endDate: endOfWeek(subDays(new Date(), 7)),
    }),
  },
  {
    label: "Last 7 days",
    range: () => ({
      startDate: subDays(new Date(), 7),
      endDate: subDays(new Date(), 1),
    }),
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: startOfMonth(addMonths(new Date(), -1)),
      endDate: endOfMonth(addMonths(new Date(), -1)),
    }),
  },
  {
    label: "Last 30 days",
    range: () => ({
      startDate: subDays(new Date(), 30),
      endDate: subDays(new Date(), 1),
    }),
  },
  {
    label: "Last 90 days",
    range: () => ({
      startDate: subDays(new Date(), 90),
      endDate: subDays(new Date(), 1),
    }),
  },
]);

export default class ReportSenderModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: window.MetricsWatch.currentUser.email,
      subject: "", // This is set the first time the modal is opened. See in `componentDidUpdate`
      message: "",
      enablePreviewChecked: false,
      startDate: subDays(new Date(), 7),
      endDate: subDays(new Date(), 1),
      compareStartDate: subDays(new Date(), 8),
      compareEndDate: subDays(new Date(), 14),
      modalError: undefined,
      firstOpenHappened: false,
      customComparisonDateRanges: false,
      ranges: [],
      comparisonDateRange: {},
      comparisonPreference: "previous_period",
    };
  }

  componentDidUpdate() {
    if (!this.state.firstOpenHappened && this.props.modalOpen) {
      this.setState({
        firstOpenHappened: true,
        subject: `Report for ${store.getState().reportFormReducer.name}`,
      });
    }
  }

  changeComparisonPreference(e) {
    // Reset the comparison dates if the user changes the comparison preference
    if (e.value != "custom") {
      this.setState({
        compareStartDate: undefined,
        compareEndDate: undefined,
      });
    }

    this.setState({ comparisonPreference: e.value });
  }

  onDateRangeInputChange(val) {
    let key = Object.keys(val)[0]; // 'selection' or 'compare', to know which one is being changed
    if (key == "selection") {
      this.setState({ startDate: val[key].startDate, endDate: val[key].endDate });
    } else if (key == "compare") {
      this.setState({ compareStartDate: val[key].startDate, compareEndDate: val[key].endDate });
    }
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onSubjectChange(event) {
    this.setState({ subject: event.target.value });
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }

  onEnablePreviewChange(event) {
    this.setState({ enablePreviewChecked: !this.state.enablePreviewChecked });
  }

  onSendButtonClick(event) {
    event.preventDefault();

    if (String(this.state.email).trim() == "" || this.state.startDate == undefined || this.state.endDate == undefined) {
      this.setState({
        modalError: "You need at least one email and a date range to send a report.",
      });
      return;
    } else if (this.state.startDate >= endOfYesterday || this.state.endDate >= endOfYesterday) {
      this.setState({
        modalError: "Dates needs to be in the past. You can not report on today's (incomplete) data or future data.",
      });
      return;
    }

    if (
      this.state.comparisonPreference == "custom" &&
      (this.state.compareStartDate == undefined || this.state.compareEndDate == undefined)
    ) {
      this.setState({
        modalError:
          "You need to set a date range for the comparison period, or change your comparison date comparison preference.",
      });
      return;
    }

    const requiredIntegrations = reportFormValidation.requiredIntegrations();

    // Validating integrations with max 90 days of data at a time
    const integrationsWithMax90days = ["facebook"];
    const daysInRange = differenceInDays(this.state.endDate, this.state.startDate);
    const hasMax90daysIntegration = integrationsWithMax90days.some((val) => requiredIntegrations.includes(val));
    if (hasMax90daysIntegration && daysInRange > 90) {
      this.setState({
        modalError: `Reports with widgets from Facebook can not have more than 90 days of data. This is a limitation from Facebook. Your date range has ${daysInRange} days. You can change the dates and try sending it again.`,
      });
      return;
    }

    // Validating integrations that can't go further than 2 years ago in the data (from current date)
    const integrationsWithMax2yearsOfHistory = ["facebook", "instagram"];
    const date2yearsAgo = subYears(new Date(), 2);
    const haxMax2yearsIntegration = integrationsWithMax2yearsOfHistory.some((val) =>
      requiredIntegrations.includes(val)
    );
    if (haxMax2yearsIntegration && this.state.startDate < date2yearsAgo) {
      this.setState({
        modalError: `Reports with widgets from Facebook and/or Instagram can not include data from more than 2 years ago. This is a limitation from Facebook & Instagram. You can change the dates and try sending it again.`,
      });
      return;
    }

    // Validating integrations that can't go further than 37 months ago in the data (from current date)
    const integrationsWithMax37MonthsOfHistory = ["facebookads"];
    const date37monthsAgo = subMonths(new Date(), 37);
    const hasMax37monthsIntegration = integrationsWithMax37MonthsOfHistory.some((val) =>
      requiredIntegrations.includes(val)
    );
    if (hasMax37monthsIntegration && this.state.startDate < date37monthsAgo) {
      this.setState({
        modalError: `Reports with widgets from Facebook Ads can not include data from more than 37 months ago. This is a limitation from Facebook. You can change the dates and try sending it again.`,
      });
      return;
    }

    // Validating integrations that can't go further than 14 months
    const integrationsWithMax14MonthsOfHistory = ["linkedin-pages"];
    const date14monthsAgo = subMonths(new Date(), 14);
    const hasMax14monthsIntegration = integrationsWithMax14MonthsOfHistory.some((val) =>
      requiredIntegrations.includes(val)
    );
    if (hasMax14monthsIntegration && this.state.startDate < date14monthsAgo) {
      this.setState({
        modalError: `Reports with widgets from LinkedIn Pages can not include data from more than 14 months ago. This is a limitation from LinkedIn. You can change the dates and try sending it again.`,
      });
      return;
    }

    this.setState({
      modalError: undefined,
    });
    this.sendToAPI();
  }

  sendToAPI() {
    let compareStartDate,
      compareEndDate = undefined;
    if (this.state.comparisonPreference == "custom") {
      compareStartDate = format(this.state.compareStartDate, "yyyy-MM-dd");
      compareEndDate = format(this.state.compareEndDate, "yyyy-MM-dd");
    }

    axios
      .post(`/api/v1/reports/${this.props.reportID}/send`, {
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
        enable_preview: this.state.enablePreviewChecked,
        startDate: format(this.state.startDate, "yyyy-MM-dd"),
        endDate: format(this.state.endDate, "yyyy-MM-dd"),
        compareStartDate: compareStartDate,
        compareEndDate: compareEndDate,
        comparisonPreference: this.state.comparisonPreference,
      })
      .then(
        function (_response) {
          store.dispatch(setFlashMessage("The report is being prepared and will be sent in a moment.", []));
          this.props.closeCallback();
        }.bind(this)
      )
      .catch(
        function (error) {
          this.setState({
            modalError:
              "There was an unexpected error. Our team was notified, but feel free to reach our for help via our live chat or email.",
          });
          Bugsnag.notify(error);
          appsignal.sendError(error);
        }.bind(this)
      );
  }

  render() {
    let ranges = [];
    if (this.state.comparisonPreference == "custom") {
      ranges = [
        {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          key: "selection",
        },
        {
          startDate: this.state.compareStartDate,
          endDate: this.state.compareEndDate,
          key: "compare",
        },
      ];
    } else {
      ranges = [
        {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          key: "selection",
        },
      ];
    }

    return (
      <Modal visible={this.props.modalOpen} closeCallback={this.props.closeCallback} noDefaultButtons fullScreen>
        <div className={"modal is-active"} ref="modal">
          {this.state.modalError && <p className="mt-4 text-red-800">{this.state.modalError}</p>}

          <h4 className="font-bold mb-2">Send ad-hoc report</h4>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="mb-4">
                <label className="tw-label">Email Recipient(s)</label>
                <p className="text-xs mb-2 text-gray-600">
                  Enter one or more email address. They need to separated by commas.
                </p>
                <input
                  onChange={this.onEmailChange.bind(this)}
                  value={this.state.email}
                  className="tw-field"
                  type="email"
                  placeholder="jane@example.com"
                />
              </div>

              <div className="mb-4">
                <label className="tw-label">Email Subject</label>
                <p className="text-xs mb-2 text-gray-600">
                  This is the subject of the email-based report you're about to send.
                </p>
                <input
                  onChange={this.onSubjectChange.bind(this)}
                  value={this.state.subject}
                  className="tw-field"
                  type="text"
                  placeholder="jane@example.com"
                />
              </div>
              <div className="mb-4">
                <label className="tw-label">Message (optional)</label>
                <p className="text-xs mb-2 text-gray-600">
                  Optional message to be placed just before the widgets / metrics.
                </p>
                <textarea onChange={this.onMessageChange.bind(this)} value={this.state.message} className="tw-field" />
              </div>

              <div className="mb-4">
                <label className="tw-label">
                  <input
                    className="tw-checkbox mr-2"
                    type="checkbox"
                    ref="sendPreview"
                    checked={this.state.enablePreviewChecked}
                    onChange={this.onEnablePreviewChange.bind(this)}
                  />
                  Enable preview (optional)
                </label>

                <p className="text-xs mb-2 text-gray-600">
                  <em>
                    When enabled, this sends you a preview of the report before it gets to its recipients. This allows
                    you to review the report, and if you wish to do so, add a message for your clients before sending
                    it. The preview will be sent to the account owner or can be sent to a custom email address
                    configured in the "Settings" tag of this report.
                  </em>
                </p>
              </div>
            </div>
            <div>
              <div className="mb-4">
                <label className="tw-label">Report's Date Range</label>
                <DateRangePicker
                  locale={this.props.lang == "fr" ? frCA : enUS}
                  ranges={ranges}
                  inputRanges={[]}
                  staticRanges={defaultStaticRanges}
                  months={1}
                  direction="vertical"
                  scroll={{ enabled: true }}
                  maxDate={subDays(new Date(), 1)}
                  onChange={this.onDateRangeInputChange.bind(this)}
                />
              </div>

              <div className="mb-4">
                <label className="tw-label">Date Comparison Preference</label>
                <Select
                  className="w-96 mr-2"
                  value={this.state.comparisonPreference}
                  label="Date Comparison Preference"
                  options={[
                    { value: "previous_period", label: "Previous Period (default)" },
                    { value: "year_over_year", label: "Year over year" },
                    { value: "custom", label: "Custom Comparison Dates" },
                  ]}
                  onChange={this.changeComparisonPreference.bind(this)}
                />
              </div>
            </div>
          </div>

          <Button text="Send" extraClasses="tw-submit-button mr-2" onClick={this.onSendButtonClick.bind(this)} />
          <Button text="Close" extraClasses="bg-blue-200 hover:bg-blue-300" onClick={this.props.closeCallback} />
        </div>
      </Modal>
    );
  }
}
