import IntegrationBase from "../integration_base";
import IntegrationIcon from "./logo/logo.svg";
import RedirectNoticeImage from "./img/redirect-notice.jpg";
import React from "react";

export default class GoogleBusinessProfile extends IntegrationBase {
  // This configures some autoloading
  integrationConfig() {
    return {
      // Metrics Watch Integration Protcol Version.
      metricsWatchIntegrationProtocolVersion: "3.0.0",

      // This is enabling the use of generic widgets, instead of integration-specific widgets like we used to do.
      autoRegisterWidgets: { enabled: true },

      // This is enabling the autoloading of metrics. When enabled, we need a to set metricLoaderCallback to handle the API response and format the metrics.
      autoloadMetrics: { enabled: true, metricLoaderCallback: this.formatMetrics.bind(this) },

      // This is enabling the autoloading of metrics. When enabled, we need a to set dimensionLoaderCallback to handle the API response and format the metrics.
      autoloadDimensions: { enabled: true, dimensionLoaderCallback: this.formatDimensions.bind(this) },

      // This is enabling the use of the generic account selector, instead of an integration-specific one.
      accountSelector: {
        enabled: true,
        databaseProviderType: "metrics_providers-google_business_profiles",
        accountLoaderCallback: this.formatAccounts.bind(this),
      },

      metrics: () => {
        return this.metrics;
      },
      dimensions: () => {
        return this.dimensions;
      },
      filterDimensionList: () => {
        return this.dimensions;
      },
      filterOperators: [],
      filterHasORenabled: true,
    };
  }

  formatAccounts(accounts) {
    let options = [];
    for (let index = 0; index < accounts.length; index++) {
      const integrationAccount = accounts[index];
      let providerAccounts = integrationAccount.attributes.provider_accounts;
      if (
        integrationAccount.type == this.integrationConfig().accountSelector.databaseProviderType &&
        providerAccounts
      ) {
        for (let j = 0; j < providerAccounts.length; j++) {
          let account = providerAccounts[j];
          let locations = account.locations || [];
          for (let location_index = 0; location_index < locations.length; location_index++) {
            const location = locations[location_index];
            options.push({
              value: location.name,
              label: location.title,
            });
          }
        }
      }
    }

    store.dispatch(setIntegrationAccountList(this.oAuthProviderName(), options));
  }

  name() {
    return "Google Business Profile";
  }

  iconURL() {
    return IntegrationIcon;
  }

  oAuthProviderName() {
    return "google-business-profile";
  }

  oAuthProviderPreRedirectNotice() {
    return (
      <div className="text-center">
        <h2 className="text-4xl font-bold mb-12">Important notice</h2>
        <p className="my-2">
          To be able to access your Google Business Profile data, on the next screen (on Google's consent screen), you
          will have to check this box:
        </p>
        <img className="border-2 border-red-400 m-auto" src={RedirectNoticeImage} />
        <p className="my-2">Otherwise, we will not be able to pull your data.</p>
      </div>
    );
  }

  integrationFor() {
    return ["report"];
  }

  widgets() {
    return {
      integration: this,
      singleMetric: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: false,
        widgetName: "Google Business Profile Single Metric",
        widgetType: "google-business-profile-single-metric",
        defaultLabel: "Call Clicks",
        defaultMetricName: "CALL_CLICKS",
        valid_dimensions: ["location"],
      },
      list: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: false,
        widgetName: "Google Business Profile List",
        widgetType: "google-business-profile-list",
        defaultLabel: "Keywords",
        defaultDimensionName: "keyword",
        defaultDimensionLabel: "Keyword",
        defaultMetricName: "KEYWORD_IMPRESSIONS",
        defaultMetricLabel: "Impressions",
        valid_dimensions: ["keyword", "reviews"],
      },
      customFunnel: {
        enabled: false,
        isBeta: false,
        soon: false,
      },
      pieAndDoughnut: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: false,
        widgetName: "Google Business Profile Pie Chart",
        widgetType: "google-business-profile-pie-chart",
        defaultLabel: "Main Keywords",
        defaultDimensionName: "keyword",
        defaultDimensionLabel: "Keyword",
        defaultMetricName: "KEYWORD_IMPRESSIONS",
        defaultMetricLabel: "Impressions",
        valid_dimensions: ["keyword", "reviews"],
      },
      mixedChart: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: false,
        widgetName: "Google Business Profile Mixed Chart",
        widgetType: "google-business-profile-mixed-chart",
        defaultLabel: "Call Clicks over time",
        defaultMetricName: "CALL_CLICKS",
        defaultDimensionName: "MW--period",
        valid_dimensions__for_period: ["location"],
        valid_dimensions: ["keyword", "reviews"],
      },
    };
  }
}
