import React from "react";
import { connect } from "react-redux";
import GenericPieChartEditor from "../../../../widgets/data/pie_chart_editor";
import FilterValidation from "../../../../helpers/filter_validation";
import WidgetPreviewFetcher from "../../../../helpers/widget_preview/widget_preview_fetcher";
import AccountSelector from "../../../../legacy-components/account_selectors/facebook_pages_account_selector";

let activeFilters = [];
const basicFilterOperators = [
  { value: "LIKE", label: "contains" },
  { value: "NOT LIKE", label: "does NOT contain" },
];
const dimensions = [
  { label: "Audience Demographics", value: "MW-page-user-demographics" }, // "Page User Demographics" in the APi doc
  { label: "Posts", value: "posts" },
  { label: "Videos", value: "videos" },
];
const filters = [
  { label: "Posts", value: "posts" },
  { label: "Videos", value: "videos" },
];
class FacebookPagesPieChartEditor extends React.Component {
  constructor(props) {
    super(props);
  }

  saveAndClose() {
    let errors = [];

    if (!this.props.metric_name) {
      errors.push("Select the metric you want");
    }

    if (!this.props.dimension) {
      errors.push("Select the dimension you want");
    }

    if (!FilterValidation.validate(this.props.filters)) {
      errors.push("At least one filter has an unspecified value");
    }

    if (errors.length > 0) {
      store.dispatch(setFlashMessage("We could not save the widget:", errors));
      return;
    }

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    store.dispatch(saveWidgetConfigFromEditor(this.props.widgetIndex));

    this.close();
  }

  close() {
    new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    this.props.closeWidgetEditModal();
  }

  render() {
    const metrics = store.getState().widgetReducer.fbPagesMetricsForCanvas;
    let metricsList = [];
    let filtersList = [];

    activeFilters = this.props.filters;

    switch (this.props.dimension) {
      case "posts":
        metricsList = metrics.filter((metric) => metric.metricLevel.includes("post"));
        filtersList = filters.filter((filter) => filter.value.includes("post"));
        break;

      case "videos":
        metricsList = metrics.filter((metric) => metric.metricLevel.includes("video"));
        filtersList = filters.filter((filter) => filter.value.includes("video"));
        break;

      default:
        metricsList = metrics.filter((metric) => metric.metricLevel.includes(this.props.dimension));
        filtersList = null;
        break;
    }

    return (
      <GenericPieChartEditor
        {...this.props}
        name="Facebook Pages Pie &amp; Doughnut"
        dimensions={dimensions}
        metricsList={metricsList}
        filterOperators={basicFilterOperators}
        filterDimensionList={filtersList}
        filterHasORenabled={true}
        saveAndClose={this.saveAndClose.bind(this)}
        closeWidgetEditModal={this.close.bind(this)}
        deleteWidget={this.props.deleteWidget.bind(this)}
        customAccountSelector={
          <AccountSelector
            disconnectStore
            onChange={this.props.updateCustomAccount}
            value={this.props.custom_account}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateCustomAccount: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("custom_account", value, ownProps.widgetIndex));
    },
    updateLabel: (e) => {
      dispatch(updateEditorWidgetConfig("label", e.target.value, ownProps.widgetIndex));
    },
    updateDimension: (e) => {
      dispatch(setFlashMessage(undefined, []));
      let value;
      if (e) {
        value = e.value;
      }
      // if the dimensions
      if (value !== "posts" && value !== "videos") {
        if (activeFilters.length > 0) {
          dispatch(
            setFlashMessage("This dimension is not filtrable:", [
              "Please delete your filters before changing the dimension.",
            ])
          );
          return;
        }
      }
      dispatch(updateEditorWidgetConfig("dimension", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("metric_name", undefined, ownProps.widgetIndex));
    },
    updateMetric: (e) => {
      dispatch(updateEditorWidgetConfig("metric_name", e.value, ownProps.widgetIndex));
    },
    updateLimit: (e) => {
      dispatch(updateEditorWidgetConfig("limit", e.target.value, ownProps.widgetIndex));
    },
    updateChartType: (e) => {
      dispatch(updateEditorWidgetConfig("chartType", e.value, ownProps.widgetIndex));
    },
    updateDateFilter: (ranges, isEnabledDateFilter) => {
      if(isEnabledDateFilter){
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, ownProps.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, ownProps.widgetIndex));
      }
      
    },
    updateFiltersEnabled: (checked) => {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, ownProps.widgetIndex));
    },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FacebookPagesPieChartEditor);
