import React from "react";
import GoogleAnalyticsProfiles from "../../legacy-components/google_analytics_profiles.js";
import TemplateList from "../../legacy-components/template_list.js";

class NewFromTemplate extends React.Component {
  render() {
    return (
      <div>
        <h2 className="tw-medium-header mt-8 mb-4 text-center">Step 1: select the site</h2>
        <div className="tw-content-box">
          <div className="m-2">
            <GoogleAnalyticsProfiles />
          </div>
        </div>

        <h2 className="tw-medium-header mt-8 mb-4 text-center">
          Step 2: select the alerts you want and set the threshold
        </h2>
        <TemplateList />
      </div>
    );
  }
}
export default NewFromTemplate;
