import React from "react";
import { connect } from "react-redux";
import "./../../helpers/string/capitalize";

class ReportSchedule extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let sending = this.props.scheduled(this.props.interval);

    return (
      <div className="field">
        <div>
          <label
            className={
              "tw-label text-lg mb-4 -m-4 p-4 " +
              (sending ? " bg-green-100 text-green-800" : " bg-gray-50")
            }
          >
            <input
              type="checkbox"
              className="tw-checkbox mr-2"
              checked={sending}
              onChange={function (event) {
                this.props.updateSchedule(event, this.props.interval);
              }.bind(this)}
            />
            Send {this.props.interval} reports
          </label>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let scheduled = false;
  return {
    scheduled: function (interval) {
      return store.getState().reportFormReducer["interval_" + interval];
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateSchedule: (event, interval) => {
      const actionCreator = "setReportInterval" + interval.charAt(0).toUpperCase() + interval.slice(1);
      dispatch(window[actionCreator](event.target.checked));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportSchedule);
