import React from "react";

const WidgetButtonIcon = ({ type }) => {
  if (iconForType.hasOwnProperty(type)) {
    return iconForType[type];
  } else {
    return null;
  }
};

const iconForType = {
  "Single Metric": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-2 mr-4"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9 6.41421L10.3975 7.81167C10.788 8.2022 11.4212 8.2022 11.8117 7.81167C12.2022 7.42115 12.2022 6.78798 11.8117 6.39746L8.70711 3.29289C8.31658 2.90237 7.68342 2.90237 7.29289 3.29289L4.18832 6.39746C3.7978 6.78798 3.7978 7.42115 4.18832 7.81167C4.57885 8.2022 5.21201 8.2022 5.60254 7.81167L7 6.41421V12C7 12.5523 7.44772 13 8 13C8.55228 13 9 12.5523 9 12V6.41421Z"
        fill="currentColor"
      />
    </svg>
  ),
  List: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-2 mr-4"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C10 1.44772 9.55229 1 9 1L2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3L9 3C9.55228 3 10 2.55229 10 2ZM15 2C15 1.44772 14.5523 1 14 1H13C12.4477 1 12 1.44772 12 2C12 2.55229 12.4477 3 13 3H14C14.5523 3 15 2.55229 15 2ZM9 5C9.55229 5 10 5.44772 10 6C10 6.55229 9.55228 7 9 7L2 7C1.44772 7 1 6.55228 1 6C1 5.44772 1.44772 5 2 5L9 5ZM15 6C15 5.44772 14.5523 5 14 5H13C12.4477 5 12 5.44772 12 6C12 6.55229 12.4477 7 13 7H14C14.5523 7 15 6.55229 15 6ZM9 9C9.55229 9 10 9.44772 10 10C10 10.5523 9.55228 11 9 11H2C1.44772 11 1 10.5523 1 10C1 9.44772 1.44772 9 2 9H9ZM15 10C15 9.44772 14.5523 9 14 9H13C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11H14C14.5523 11 15 10.5523 15 10ZM9 13C9.55229 13 10 13.4477 10 14C10 14.5523 9.55228 15 9 15H2C1.44772 15 1 14.5523 1 14C1 13.4477 1.44772 13 2 13H9ZM15 14C15 13.4477 14.5523 13 14 13H13C12.4477 13 12 13.4477 12 14C12 14.5523 12.4477 15 13 15H14C14.5523 15 15 14.5523 15 14Z"
        fill="currentColor"
      />
    </svg>
  ),
  "Pie Chart": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-2 mr-4"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.24795 2.25984C6.8022 2.09088 7.39049 2 8 2C10.973 2 13.441 4.16229 13.917 7H8.61803L6.24795 2.25984ZM4.45963 3.15534C2.96843 4.24693 2 6.01037 2 8C2 11.3137 4.68629 14 8 14C10.973 14 13.441 11.8377 13.917 9H8C7.62123 9 7.27496 8.786 7.10557 8.44721L4.45963 3.15534ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0Z"
        fill="currentColor"
      />
    </svg>
  ),
  "Doughnut Chart": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-2 mr-4"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.08296 7H4.12602C4.57006 5.27477 6.13616 4 8 4C8.51898 4 9.01488 4.09884 9.46989 4.27871L10.6717 2.6262C9.86716 2.22541 8.95991 2 8 2C5.027 2 2.55904 4.16229 2.08296 7ZM12.2884 3.80362L11.0868 5.45586C11.5777 6.05082 11.9 6.79014 11.9803 7.60059L13.9994 7.91121C13.9762 6.31293 13.328 4.86592 12.2884 3.80362ZM13.6969 9.88821L11.6769 9.57745C11.0649 11.0022 9.64897 12 8 12C6.13616 12 4.57006 10.7252 4.12602 9H2.08296C2.55904 11.8377 5.027 14 8 14C10.654 14 12.9056 12.2768 13.6969 9.88821ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6Z"
        fill="currentColor"
      />
    </svg>
  ),
  "Area Chart": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-2 mr-4"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 13V11.7808L5.99999 11.0308L9.75746 11.9701C9.98904 12.028 10.2337 12.0012 10.4472 11.8944L13 10.618V13L3 13ZM1 13.9997L1 11.0213C0.999674 11.0065 0.999675 10.9919 1 10.9772L1 6.5235C0.999633 6.5079 0.999635 6.49233 1 6.4768L1 2C1 1.44772 1.44772 1 2 1H2.0011H14C14.5523 1 15 1.44772 15 2L15 2.0014V4.49491C15 4.49803 15 4.50115 15 4.50427V8.97865C15.0003 8.99238 15.0003 9.0061 15 9.01979V13.9986L15 14C15 14.5523 14.5523 15 14 15H13.9986L2 15C1.44772 15 1 14.5523 1 14V13.9997ZM13 4.04623V3L3 3V5.057L5.64887 4.06367C5.96841 3.94384 6.32699 3.99524 6.6 4.2L9.95152 6.71364L13 4.04623ZM3 7.193L5.83822 6.12867L9.4 8.8C9.77802 9.08351 10.3029 9.06373 10.6585 8.75258L13 6.70376V8.38196L9.88365 9.94014L6.24253 9.02986C6.08329 8.99005 5.9167 8.99005 5.75746 9.02986L3 9.71922V7.193Z"
        fill="currentColor"
      />
    </svg>
  ),
  "Bar Graph": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-2 mr-4"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 11C10.5523 11 11 10.5523 11 10L11 3C11 2.44772 10.5523 2 10 2C9.44772 2 9 2.44772 9 3L9 10C9 10.5523 9.44772 11 10 11ZM15 10C15 10.5523 14.5523 11 14 11C13.4477 11 13 10.5523 13 10L13 4C13 3.44772 13.4477 3 14 3C14.5523 3 15 3.44772 15 4L15 10ZM2 15C1.44772 15 1 14.5523 1 14C1 13.4477 1.44772 13 2 13L14 13C14.5523 13 15 13.4477 15 14C15 14.5523 14.5523 15 14 15L2 15ZM7 10C7 10.5523 6.55229 11 6 11C5.44772 11 5 10.5523 5 10L5 6C5 5.44772 5.44772 5 6 5C6.55229 5 7 5.44772 7 6L7 10ZM2 11C2.55229 11 3 10.5523 3 10L3 4C3 3.44772 2.55229 3 2 3C1.44772 3 1 3.44772 1 4L1 10C1 10.5523 1.44772 11 2 11Z"
        fill="currentColor"
      />
    </svg>
  ),
  "Line Chart": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-2 mr-4"
    >
      <path
        d="M2 15C1.44772 15 1 14.5523 1 14V14C1 13.4477 1.44772 13 2 13L14 13C14.5523 13 15 13.4477 15 14V14C15 14.5523 14.5523 15 14 15L2 15Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6247 2.21913C15.056 2.56414 15.1259 3.19343 14.7809 3.62469L10.7809 8.62469C10.4863 8.9929 9.97454 9.1053 9.55278 8.89443L6.10557 7.17082L2.6 9.8C2.15817 10.1314 1.53137 10.0418 1.2 9.6C0.868627 9.15817 0.95817 8.53137 1.4 8.2L5.4 5.2C5.70302 4.97274 6.10843 4.93618 6.44721 5.10557L9.72414 6.74404L13.2191 2.3753C13.5641 1.94404 14.1934 1.87412 14.6247 2.21913Z"
        fill="currentColor"
      />
    </svg>
  ),
  "Mixed Chart": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-2 mr-4"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.53 2.84799C14.9983 2.55528 15.1407 1.93833 14.848 1.47C14.5553 1.00166 13.9383 0.859288 13.47 1.152L9.83032 3.4268L6.24253 2.52985C6.04579 2.48067 5.83875 2.49246 5.64887 2.56367L1.64887 4.06367C1.13175 4.25759 0.869747 4.834 1.06367 5.35112C1.25759 5.86824 1.834 6.13025 2.35112 5.93633L6.05955 4.54566L9.75746 5.47014C10.0209 5.53599 10.2997 5.4919 10.53 5.34799L14.53 2.84799ZM1 14C1 14.5523 1.44772 15 2 15L14 15C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13L2 13C1.44772 13 1 13.4477 1 14ZM15 11C15 11.5523 14.5523 12 14 12C13.4477 12 13 11.5523 13 11L13 9C13 8.44771 13.4477 8 14 8C14.5523 8 15 8.44772 15 9V11ZM10 12C10.5523 12 11 11.5523 11 11V8C11 7.44772 10.5523 7 10 7C9.44772 7 9 7.44772 9 8L9 11C9 11.5523 9.44771 12 10 12ZM7 11C7 11.5523 6.55228 12 6 12C5.44771 12 5 11.5523 5 11L5 7C5 6.44772 5.44772 6 6 6C6.55229 6 7 6.44772 7 7L7 11ZM2 12C2.55228 12 3 11.5523 3 11L3 9C3 8.44772 2.55228 8 2 8C1.44772 8 1 8.44771 1 9L0.999999 11C0.999999 11.5523 1.44771 12 2 12Z"
        fill="currentColor"
      />
    </svg>
  ),
  Separator: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-2 mr-4"
    >
      <path
        d="M15 7C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9L1 9C0.447715 9 -2.41411e-08 8.55228 0 8C2.41411e-08 7.44772 0.447715 7 1 7L15 7Z"
        fill="currentColor"
      />
    </svg>
  ),
  Title: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-2 mr-4"
    >
      <path d="M13 5H9V14H7V5H3V3H13V5Z" fill="currentColor" />
    </svg>
  ),
  Text: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-2 mr-4"
    >
      <path
        d="M13 3C13.5523 3 14 3.44772 14 4C14 4.55229 13.5523 5 13 5L3 5C2.44772 5 2 4.55228 2 4C2 3.44772 2.44772 3 3 3L13 3Z"
        fill="currentColor"
      />
      <path
        d="M13 7C13.5523 7 14 7.44772 14 8C14 8.55229 13.5523 9 13 9H3C2.44772 9 2 8.55228 2 8C2 7.44772 2.44772 7 3 7L13 7Z"
        fill="currentColor"
      />
      <path
        d="M10 12C10 11.4477 9.55229 11 9 11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H9C9.55228 13 10 12.5523 10 12Z"
        fill="currentColor"
      />
    </svg>
  ),
};

export default WidgetButtonIcon;
