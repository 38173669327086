window.addWidgetOnCanvas = function (widget, chartType) {
  return {
    type: "ADD_WIDGET",
    widgetType: widget,
    chartType: chartType,
  };
};

window.duplicateWidgetOnCanvas = function (widgetId) {
  return {
    type: "DUPLICATE_WIDGET",
    widgetId: widgetId,
  };
};

window.updateWidgetsLayout = function (newLayout) {
  return {
    type: "UPDATE_WIDGET_LAYOUT",
    newLayout: newLayout,
  };
};

window.updateWidgetConfig = function (key, value, index) {
  return {
    type: "UPDATE_WIDGET_CONFIG",
    configKey: key,
    configValue: value,
    widgetIndex: index,
  };
};
window.updateEditorWidgetConfig = function (key, value, index) {
  return {
    type: "UPDATE_EDITOR_WIDGET_CONFIG",
    configKey: key,
    configValue: value,
    widgetIndex: index,
  };
};
window.saveWidgetConfigFromEditor = function (index) {
  return {
    type: "SAVE_WIDGET_CONFIG_FROM_EDITOR",
    widgetIndex: index,
  };
};
window.closeWidgetEditor = function () {
  return {
    type: "CLOSE_WIDGET_EDITOR",
  };
};
window.saveWidgetStateBeforeEdit = function (index) {
  return {
    type: "SAVE_WIDGET_CONFIG_BEFORE_EDIT",
    widgetIndex: index,
  };
};
window.gotReportCanvasConfig = function (data) {
  return {
    type: "GOT_REPORT_CONFIG_DATA",
    data: data,
  };
};
window.gotReportNewsConfig = function (data) {
  return {
    type: "GOT_REPORT_NEWS_CONFIG_DATA",
    data: data,
  };
};
window.updateReportNewsAttribute = function (attribute, value) {
  return {
    type: "UPDATE_REPORT_NEWS_ATTR",
    attribute: attribute,
    value: value,
  };
};

window.setWidgetFilters = function (newFilters) {
  return {
    type: "SET_WIDGET_FILTERS",
    widgetFilters: newFilters,
  };
};

window.setGaMetricsForCanvasCache = function (metrics) {
  return {
    type: "SET_GA_METRICS_FOR_CANVAS_CACHE",
    metrics: metrics,
  };
};
window.setMailchimpMetricsForCanvasCache = function (metrics) {
  return {
    type: "SET_MAILCHIMP_METRICS_FOR_CANVAS_CACHE",
    metrics: metrics,
  };
};
window.setFacebookPagesMetricsForCanvasCache = function (metrics) {
  return {
    type: "SET_FB_PAGES_METRICS_FOR_CANVAS_CACHE",
    metrics: metrics,
  };
};

window.setInstagramMetricsForCanvasCache = function (metrics) {
  return {
    type: "SET_INSTAGRAM_METRICS_FOR_CANVAS_CACHE",
    metrics: metrics,
  };
};

window.setGoogleAdsMetricsForCanvasCache = function (metrics) {
  return {
    type: "SET_GOOGLE_ADS_METRICS_FOR_CANVAS_CACHE",
    metrics: metrics,
  };
};

window.setLinkedINMetricsForCanvasCache = function (metrics) {
  return {
    type: "SET_LINKEDIN_METRICS_FOR_CANVAS_CACHE",
    metrics: metrics,
  };
};

window.setLinkedINPagesMetricsForCanvasCache = function (metrics) {
  return {
    type: "SET_LINKEDIN_PAGES_METRICS_FOR_CANVAS_CACHE",
    metrics: metrics,
  };
};

window.setFacebookAdsMetricsForCanvasCache = function (metrics) {
  return {
    type: "SET_FB_ADS_METRICS_FOR_CANVAS_CACHE",
    metrics: metrics,
  };
};

window.setFacebookAdsDimensionsForCanvasCache = function (dimensions) {
  return {
    type: "SET_FB_ADS_DIMENSIONS_FOR_CANVAS_CACHE",
    dimensions: dimensions,
  };
};

window.setIntegrations = function (integrations) {
  return {
    type: "SET_INTEGRATIONS",
    integrations: integrations,
  };
};

window.removeWidget = function (widgetId) {
  return {
    type: "REMOVE_WIDGET",
    id: widgetId,
  };
};

window.setCanvasDroppingItem = function (layout, config, newFullLayout) {
  return {
    type: "SET_CANVAS_DROPPING_ITEM",
    newFullLayout: newFullLayout,
    layout: layout,
    config: config,
  };
};

window.setLayout = function (newLayout) {
  return {
    type: "SET_LAYOUT",
    newLayout: newLayout,
  };
};
