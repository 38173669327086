import React from "react";
import Select from "react-select";
import Button from "../../../../../javascript/legacy-components/generic/button.js.jsx";
import ButtonWithIcon from "../../../../../javascript/legacy-components/generic/button_with_icon.js";
import WidgetFilterEditor from "../../../../../javascript/legacy-components/canvas-widgets/widget_filter_editor";
import FlashMessage from "../../../../../javascript/legacy-components/flash_message.js";
import GoogleSheetsAccountSelector from "../../account_selector";
import DatePickerComponent from "../../../../legacy-components/DateRangePicker.jsx";

const GoogleSheetsPieChartEditor = ({
  name,
  dimensions,
  metricsList,
  chartType,
  multilineFilter = false,
  shortFilterVariable = false,
  googleSheetsEditorsInfo,
  ...props // Generic props passed from the parent editor component
}) => {
  const valueHandlingOptions = [
    { value: "sum", label: "Sum" },
    { value: "avg", label: "Average" },
  ];
  const enbaleWidgetDateFeature = store.getState().reportFormReducer.enbale_widget_date_feature;

  return (
    <div className="widget widget-editor">
      <h3 className="tw-large-header mb-4">{name}</h3>
      <div className="mb-4">
        <label className="tw-label">Label</label>
        <input className="tw-field mw-key-label" value={props.label} onChange={props.updateLabel} />
      </div>

     {enbaleWidgetDateFeature &&
      <DatePickerComponent
          updateFiltersEnabled={props.updateFiltersEnabled}
          updateDateFilter={props.updateDateFilter}
          selectedDate={store.getState().widgetReducer.editorConfig.date_filter_by_widget}
          lang={props.language}  
      />
      }

      <div className="md:flex flex-row">
        <div className="flex-1 md:mr-2">
          <div className="widget widget-editor">
            <GoogleSheetsAccountSelector {...props} />

            <div>
              <label className="tw-label">{googleSheetsEditorsInfo.fileName.label}</label>
              <p>{googleSheetsEditorsInfo.fileName.value}</p>
            </div>
            <div>
              <label className="tw-label">Sheets Names</label>
              <Select
                name="sheets_names"
                className="mw-key-metric_name w-full"
                value={props.selectedSheet}
                options={googleSheetsEditorsInfo.sheetsList}
                onChange={(e) => {
                  // always use this name in map props for function in this case
                  props.onChangeSheetsSelector(e);
                }}
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="tw-label">Dimension</label>
            <Select
              name="dimension"
              className="mw-key-dimension"
              value={props.dimension}
              options={dimensions}
              onChange={props.updateDimension}
            />
          </div>
        </div>
        <div className="mt-8 md:mt-0 md:ml-2 flex-1">
          <div className="md:flex flex-row">
            <div className="flex-1 md:mr-2">
              <label className="tw-label">Metric</label>
              <Select
                name="metric_name"
                className="mw-key-metric_name"
                value={props.metric_name}
                options={metricsList}
                onChange={props.updateMetric}
              />
            </div>
            <div className="mt-8 md:mt-0 md:ml-2 flex-1">
              <label className="tw-label">Value handling</label>
              <Select
                name="value_handling"
                className="mw-key-metric_name w-full"
                value={props.valueHandlingMetricOne}
                options={valueHandlingOptions}
                onChange={(e) => {
                  // always use this name in map props for function in this case
                  props.onChangeValueHandlingSelector(e, "One");
                }}
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="tw-label">Limit</label>
            <input className="tw-field mw-key-limit" value={props.limit} onChange={props.updateLimit} />
            <p className="text-xs text-gray-700 mb-4 mt-2">
              This is the maximum number of items in the graph, and we will add an extra one called "Other" which will
              be the sum of all other results.
            </p>
          </div>

          <div className="mb-4">
            <label className="tw-label">Chart Type</label>
            <Select
              name="chart-type"
              value={chartType || "pie"}
              options={[
                { value: "pie", label: "Pie" },
                { value: "doughnut", label: "Doughnut" },
              ]}
              onChange={props.updateChartType}
            />
          </div>
        </div>
      </div>
      {Array.isArray(props.filterOperators) && Array.isArray(props.filterDimensionList) && (
        <WidgetFilterEditor
          operators={props.filterOperators}
          dimensionList={props.filterDimensionList}
          hasORenabled={props.filterHasORenabled}
          multilineFilter={multilineFilter}
          shortFilterVariable={shortFilterVariable}
        />
      )}

      <FlashMessage />

      <div className="flex flex-row mt-4">
        <ButtonWithIcon
          onClick={props.saveAndClose}
          text="Save &amp; close"
          icon="fa-check"
          extraClasses="tw-submit-button"
        />
        &nbsp;
        <Button onClick={props.closeWidgetEditModal} text="Close without saving" />
        &nbsp;
        <ButtonWithIcon
          onClick={props.deleteWidget}
          icon="fa-times"
          extraClasses="bg-red-100 text-red-700 hover:bg-red-200 ml-auto"
          text="Delete this widget"
        />
      </div>
    </div>
  );
};

export default GoogleSheetsPieChartEditor;
