import React from "react";
var createReactClass = require("create-react-class");

var ButtonWithIcon = createReactClass({
  onClick: function (event) {
    if (this.props.onClick) {
      this.props.onClick(event, this.props.refID);
    }
  },

  render: function () {
    var className = "tw-button " + this.props.extraClasses;
    var iconClass = "fas " + this.props.icon;
    var iconBefore = this.props.iconBefore;
    var hasText = this.props.text && this.props.text !== "";
    return (
      <a href={this.props.href} className={className} onClick={this.onClick} data-ref-id={this.props.refID}>
        {iconBefore === true && (
          <span className={"icon is-small" + (hasText ? " mr-2" : "")}>
            <i className={iconClass} />
          </span>
        )}

        {hasText && <span>{this.props.text}</span>}

        {iconBefore !== true && (
          <span className={"icon is-small" + (hasText ? " ml-2" : "")}>
            <i className={iconClass} />
          </span>
        )}
      </a>
    );
  },
});

export default ButtonWithIcon;
