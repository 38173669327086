import React from "react";

import Widget from "./widget";
import Editor from "./editor";
import WidgetBase from "../../../../widgets/widget_base";

export default class BarGraph extends WidgetBase {
  constructor() {
    super();
  }

  name() {
    return "LinkedIn Pages Bar Graph";
  }

  type() {
    return "linkedin-pages-bar-graph";
  }

  width() {
    return "large";
  }

  height() {
    return 6;
  }

  config(chartType) {
    return {
      chartType: chartType || "bar",
      widgetType: this.type(),
      name: this.name(),
      label: "Like (LinkedIn Pages)",
      metric_name: "likeCount",
      metric_label: "Like",
      limit: 6,
      filters: [],
    };
  }

  widgetComponent(widgetIndex) {
    return React.createElement(
      Widget,
      {
        widgetIndex: widgetIndex,
        key: widgetIndex,
      },
      null
    );
  }

  editorComponent(widgetIndex, closeAction, deleteWidget) {
    return React.createElement(
      Editor,
      {
        widgetIndex: widgetIndex,
        key: widgetIndex,
        closeWidgetEditModal: closeAction,
        deleteWidget: deleteWidget,
      },
      null
    );
  }
}
