import React from "react";
import { isEmpty } from "lodash";
var createReactClass = require("create-react-class");
import Select from "react-select";
import { reportFormValidation } from "../helpers/report_form_validation";
import CommonAccountSelection from "../legacy-components/common_account_selection";


const AccountSelector = createReactClass({
  getInitialState: function () {
    return {
      accountListFunction: this.props.accountListFunction,
      account_id: undefined,
      integration: this.props.integrationProviderName,
      label: this.props.accountSelectorLabel,
      options: [],
    };
  },

  componentDidMount: function () {
    if (this.props.disconnectStore) {
      this.setState({ account_id: this.props.value });
    } else {
      this.setState({
        account_id: store.getState().reportFormReducer.integration_account_ids[this.state.integration],
        options: store.getState().integrationsReducer.integrationsAccountLists[this.state.integration],
      });
      
      this.storeSubscription = store.subscribe(
        function () {
          let newValue = store.getState().reportFormReducer.integration_account_ids[this.state.integration];
          if (this.state.account_id !== newValue) {
            this.setState({ account_id: newValue });
          }
        }.bind(this)
      );
    }
  },

  componentWillUnmount: function () {
    if (!this.props.disconnectStore) {
      this.storeSubscription();
    }
  },

  accountSelected: function (data) {
    let value = undefined;
    if (data) {
      value = data.value;
    }

    if (this.props.disconnectStore) {
      this.props.onChange(data);
    } else {
      store.dispatch(setGlobalReportIntegrationAccountID(this.state.integration, value));
    }
  },

  componentWillReceiveProps: function (nextProps) {
    if (this.props.disconnectStore) {
      this.setState({ account_id: nextProps.value });
    }

    const integrationsAccountLists = store.getState().integrationsReducer.integrationsAccountLists;
    const accountList = integrationsAccountLists[this.state.integration];
    this.setState({ options: store.getState().integrationsReducer.integrationsAccountLists[this.state.integration] });
    if (!isEmpty(accountList) && !this.state.account_id && !this.props.disconnectStore) {
      this.setState({ account_id: accountList[0].value });
      store.dispatch(setGlobalReportIntegrationAccountID(this.state.integration, accountList[0].value));
    } 
  },

  handleIntegrationButton: function () {
    let integrations = window.integrationStore.findIntegrationByOAuthProviderName(this.state.integration);
    integrations.oAuthProviderPreRedirectNotice();
    window.location = integrations.oAuthProviderRedirectPath();
  },

  render: function () {
    var rootClassName = "field";
    let display = "none";

    if (this.props.disconnectStore) {
      display = "block";
    } else {
      if (reportFormValidation.requiredIntegrations().includes(this.state.integration)) {
        display = "block";
      }
    }

    return (
      <div className={rootClassName} style={{ display }}>
        <CommonAccountSelection
          label={this.state.label}
          disconnectStore={this.props.disconnectStore}
          tabIndex={this.props.tabIndex}
          accountSelected={this.accountSelected}
          value={this.state.account_id}
          options={store.getState().integrationsReducer.integrationsAccountLists[this.state.integration]}
          handleIntegrationButton={this.handleIntegrationButton}
        />
      </div>
    );
  },
});

export default AccountSelector;

