import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { subDays } from 'date-fns';
import { frCA, enUS } from 'date-fns/locale';
import Cookies from 'js-cookie';
import ButtonWithIcon from './generic/button_with_icon.js';
import { formatDateRange, selectedDateFormat, getValidDate } from '../helpers/utils.js';
import { defaultStaticRanges } from './reportCanvas.js';

const DatePickerComponent = (props) => {
  const [dateRange, setDateRange] = useState(() => {
    const selectedDate = props.selectedDate || {};
    const startDate = getValidDate(selectedDate.startDate ?? Cookies.get('reportStartDate'));
    const endDate = getValidDate(selectedDate.endDate ?? Cookies.get('reportEndDate'));
    return formatDateRange(startDate, endDate);
  });

  const [isDateModalOpen, setIsModalOpen] = useState(false);
  const [selectedDateView, setSelectedDateView] = useState('');
  const [enableDateRangePicker, setEnableDateRangePicker] = useState(store.getState().widgetReducer.editorConfig?.is_enabled_date_filter ?? false);

  useEffect(() => {
    
    const updateDateView = (startDate, endDate) => {
      const formattedDate = formatDateRange(startDate, endDate);
      setSelectedDateView(selectedDateFormat(formattedDate.startDate, formattedDate.endDate));
    };

    if (props.selectedDate) {
      updateDateView(props.selectedDate.startDate, props.selectedDate.endDate);
    } else {
      updateDateView(dateRange.startDate, dateRange.endDate);
    }
  }, [props.selectedDate, dateRange]);

  useEffect(() => {
    let firstInputElement = null;

    if (isDateModalOpen) {
      firstInputElement = document.querySelector('.rdrDateRangePickerWrapper input');
      firstInputElement && firstInputElement.focus();
    }

    return () => {
      if (!isDateModalOpen && firstInputElement) {
        document.querySelector('.tw-button').focus();
      }
    };
  }, [isDateModalOpen]);

  const handleSelect = (ranges) => {
    const { selection } = ranges;
    if (selection) {
      setDateRange(selection);
      if (typeof props.updateDateFilter === 'function') {
        props.updateDateFilter(selection, enableDateRangePicker);
      }
    } else {
      console.error('Unexpected structure:', ranges);
    }
  };

  const handleShow = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleEnableDateRangePicker = (event) => {
   
    props.updateFiltersEnabled( event.target.checked);
    setEnableDateRangePicker(event.target.checked);
    props.updateDateFilter(dateRange, event.target.checked);
    
  };

  return (
    <div>
      <input
        type="checkbox"
        checked={enableDateRangePicker}
        onChange={handleEnableDateRangePicker}
      />
      <label className="ml-2">Enable and select date for this widget</label>
      {enableDateRangePicker && (
        <>
          <p className="my-2 text-sm italic text-gray-600 ml-2">Please click on the calendar icon to select a date range </p>
          <h3 className="italic normal-case font-light tw-medium-header my-1 py-1 text-left">
            <ButtonWithIcon
              icon="fa-calendar-alt"
              className="tw-button align-center px-2 py-0 m-7"
              onClick={handleShow}
            />
            <span className="px-2">{selectedDateView}</span>
          </h3>
          {isDateModalOpen && (
            <DateRangePicker
              locale={props.lang === 'fr' ? frCA : enUS}
              ranges={[dateRange]}
              inputRanges={[]}
              staticRanges={defaultStaticRanges}
              months={window.innerWidth < 768 ? 1 : 2}
              direction="horizontal"
              scroll={{ enabled: true }}
              maxDate={subDays(new Date(), 1)}
              onChange={handleSelect}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DatePickerComponent;
