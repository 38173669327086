import IntegrationBase from "../integration_base";
import IntegrationIcon from "./logo/google-search-console-logo.png";

export default class GoogleSearchConsole extends IntegrationBase {
  integrationConfig() {
    return {
      // Metrics Watch Integration Protcol Version.
      metricsWatchIntegrationProtocolVersion: "3.0.0",

      // This is enabling the use of generic widgets, instead of integration-specific widgets like we used to do.
      autoRegisterWidgets: { enabled: true },

      // This is enabling the autoloading of metrics. When enabled, we need a to set metricLoaderCallback to handle the API response and format the metrics.
      autoloadMetrics: { enabled: false, },

      // This is enabling the autoloading of metrics. When enabled, we need a to set dimensionLoaderCallback to handle the API response and format the metrics.
      autoloadDimensions: { enabled: false, },

      dimensions: () => {
        return [
          {
            value: "country",
            label: "Country (3-letter country code, lowercase)",
          },
          { value: "device", label: "Device (DESKTOP, MOBILE or TABLET)" },
          { value: "query", label: "Keywords (query)" },
          { value: "page", label: "Page (URL)" },
        ]
      },

      filterDimensionList: () => {
        return this.dimensions;
      },

      // This is enabling the use of the generic account selector, instead of an integration-specific one.
      accountSelector: {
        enabled: true,
        databaseProviderType: "metrics_providers-google_search_consoles",
        accountLoaderCallback: this.formatAccounts.bind(this),
      },

      metrics: () => {
        return [
          { value: "clicks", label: "Clicks" },
          { value: "impressions", label: "Impressions" },
          { value: "ctr", label: "CTR" },
          { value: "position", label: "Position" },
        ];
      },
      filterHasORenabled: true,
    };
  }

  name() {
    return "Google Search Console";
  }

  iconURL() {
    return IntegrationIcon;
  }

  oAuthProviderName() {
    return "google-search-console";
  }

  reducerAccountIDVariableName() {
    return "google_search_console_site";
  }

  reportStoreDispatchFunction() {
    return setGoogleSearchConsoleSite;
  }

  formatAccounts(accounts) {
    let options = [];
    for (var i = 0; i < accounts.length; i++) {
      var provider = accounts[i];
      if (provider.type == "metrics_providers-google_search_consoles") {
        var allSites = provider.attributes.provider_accounts.siteEntry;

        for (var j = 0; j < allSites.length; j++) {
          var gsc_site = allSites[j];
          var value = gsc_site.siteUrl;
          var label = value;
          let validAccount = true;
          if (gsc_site.permissionLevel == "siteUnverifiedUser") {
            label += " (UNVERIFIED - needs to be verified in Google Search Console)";
            validAccount = false;
          }
          options.push({ value: value, label: label, validAccount: validAccount });

          // We're checking if the currently selected site is unverified, if so, show the notice
          // This is a bit hacky, but it works: when we first load the state in "disconnectStore" / standalone mode / widget editor mode,
          // we need to see if the site is unverified and show the notice if that's the case.
          if (!validAccount) {
            this.showUnverifiedNotice();
          }
        }
      }
    }

    store.dispatch(setIntegrationAccountList("google-search-console", options));
  }

   showUnverifiedNotice() {
    store.dispatch(
      setFlashMessage("This site is unverified in your Google Search Console account:", [
        "Select another site or verify this site in Google Search Console.",
        "You can find out how to fix that in this <a href='https://help.metricswatch.com/article/7-google-search-console-site-is-unverified-what-to-do' target='_blank'>here</a>.",
      ])
    );
   }
  
    widgets() {
    return {
      integration: this,
      singleMetric: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: false,
        widgetName: "Google Search Console Single Metric",
        widgetType: "google-search-console-single-metric",
        defaultLabel: "Clicks",
        defaultMetricName: "clicks",
      },
      list: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        sortEnabled: true, // if we want to to overwrite the sorting optins we need to set `this.sortByOptions` in this class (see how it's down in the `IntegrationBase` class)
        widgetName: "Google Search Console List",
        widgetType: "google-search-console-list",
        defaultLabel: "List of keywords",
        defaultDimensionName: "query",
        defaultDimensionLabel: "Keyword",
        defaultMetricName: "clicks",
        defaultMetricLabel: "Clicks",
      },
      pieAndDoughnut: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        widgetName: "Google Search Console Pie Chart",
        widgetType: "google-search-console-pie-chart",
        defaultLabel: "Clicks per country (Google Search Console)",
        defaultDimensionName: "country",
        defaultDimensionLabel: "Country",
        defaultMetricName: "clicks",
        defaultMetricLabel: "Clicks",
      },
      mixedChart: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        widgetName: "Google Search Console Bar Graph",
        widgetType: "google-search-console-bar-graph",
        defaultDimensionName: "country",
        defaultDimensionLabel: "Country",
        defaultMetricName: "clicks",
        defaultMetricLabel: "Clicks",
      },
    };
  }
}
