import IntegrationBase from "../integration_base";
import IntegrationIcon from "./logo/logo.svg";

export default class MicrosoftAdvertising extends IntegrationBase {
  // This configures some autoloading
  integrationConfig() {
    return {
      // Metrics Watch Integration Protcol Version.
      metricsWatchIntegrationProtocolVersion: "3.0.0",

      // This is enabling the use of generic widgets, instead of integration-specific widgets like we used to do.
      autoRegisterWidgets: { enabled: true },

      // This is enabling the autoloading of metrics. When enabled, we need a to set metricLoaderCallback to handle the API response and format the metrics.
      autoloadMetrics: { enabled: true, metricLoaderCallback: this.formatMetrics.bind(this) },

      // This is enabling the autoloading of metrics. When enabled, we need a to set dimensionLoaderCallback to handle the API response and format the metrics.
      autoloadDimensions: { enabled: true, dimensionLoaderCallback: this.formatDimensions.bind(this) },

      // This is enabling the use of the generic account selector, instead of an integration-specific one.
      accountSelector: {
        enabled: true,
        databaseProviderType: "metrics_providers-microsoft_advertisings",
        accountLoaderCallback: this.formatAccounts.bind(this),
      },

      metrics: () => {
        return this.metrics;
      },
      dimensions: () => {
        return this.dimensions;
      },
      filterDimensionList: () => {
        // return this.dimensions;
        return [];
      },
      filterOperators: [
        // Those are for filtering on our end only (for now)
        { value: "LIKE", label: "contains" },
        { value: "NOT LIKE", label: "does NOT contain" },
        { value: "EQUAL", label: "equal" },
        { value: "NOT EQUAL", label: "NOT equal" },
      ],
      filterHasORenabled: false,
    };
  }

  formatAccounts(accounts) {
    let options = [];
    for (let index = 0; index < accounts.length; index++) {
      const integrationAccount = accounts[index];
      let providerAccounts = integrationAccount.attributes.provider_accounts;
      if (
        integrationAccount.type == this.integrationConfig().accountSelector.databaseProviderType &&
        providerAccounts
      ) {
        for (let j = 0; j < providerAccounts.length; j++) {
          options.push({
            value: providerAccounts[j].account_id,
            label: providerAccounts[j].account_name,
          });
        }
      }
    }

    store.dispatch(setIntegrationAccountList(this.oAuthProviderName(), options));
  }

  name() {
    return "Microsoft Advertising";
  }

  iconURL() {
    return IntegrationIcon;
  }

  oAuthProviderName() {
    return "ms-ads";
  }

  integrationFor() {
    return ["report"];
  }

  widgets() {
    return {
      integration: this,
      singleMetric: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: false,
        widgetName: "MS Ads Single Metric",
        widgetType: "ms-ads-single-metric",
        defaultLabel: "Clicks",
        defaultMetricName: "Clicks",
      },
      list: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: false,
        widgetName: "MS Ads List",
        widgetType: "ms-ads-list",
        defaultLabel: "Campaigns",
        defaultDimensionName: "CampaignName",
        defaultDimensionLabel: "Campaign",
        defaultMetricName: "Spend",
        defaultMetricLabel: "Spend",
        // noSecondaryMetric: [],
      },
      pieAndDoughnut: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: false,
        widgetName: "MS Ads Pie Chart",
        widgetType: "ms-ads-pie-chart",
        defaultLabel: "Campaigns",
        defaultDimensionName: "CampaignName",
        defaultDimensionLabel: "Campaign",
        defaultMetricName: "Spend",
        defaultMetricLabel: "Spend",
      },
      mixedChart: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: false,
        widgetName: "MS Ads Mixed Chart",
        widgetType: "ms-ads-mixed-chart",
        defaultLabel: "Spend over time",
        defaultDimensionName: "MW--period",
        defaultMetricName: "Spend",
        defaultMetricLabel: "Spend",
      },
    };
  }
}
