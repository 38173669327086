import $ from "jquery";
import APIError from "../helpers/api_error";
import WatcherFilterFormatter from "../helpers/watcher_filter_formatter";

const WatcherFormLoader = (function () {
  const initialize = function (watcherID) {
    if (watcherID) {
      loadWatcher(watcherID);
    }
  };

  const loadWatcher = function (watcherID) {
    let url = "/api/v1/alerts/" + watcherID;
    $.ajax({
      url: url,
      dataType: "json",
      cache: false,
      success: function (data) {
        let attr = data.data.attributes;
        store.dispatch(setWatcherID(watcherID));
        store.dispatch(setWatcherName(attr.name));
        store.dispatch(setWatcherNote(attr.note));
        store.dispatch(selectIntervalType(attr.interval_type));
        store.dispatch(selectComparisonType(attr.comparison_type));
        store.dispatch(setThreshold(attr.threshold));
        store.dispatch(setStartMinutesAgo(attr.start_minutes_ago));
        store.dispatch(setEndMinutesAgo(attr.end_minutes_ago));
        store.dispatch(setGoogleAnalyticsServiceLevel(attr.ga_service_level));
        store.dispatch(selectGoogleAnalyticsProfile(attr.google_analytics_profile_id));
        store.dispatch(selectMetricName(attr.metric_name));
        store.dispatch(setEmailRecipients(attr.email_recipients));
        store.dispatch(setSmsRecipients(attr.mobile_recipients));
        store.dispatch(setSlackRecipients(attr.slack_recipients));
        store.dispatch(setAlertSchedule(attr.schedule));
        if (attr.google_analytics_filters && attr.google_analytics_filters != null) {
          let formattedFilters = WatcherFilterFormatter.loadFromAPIFormat(attr.google_analytics_filters);
          store.dispatch(setGoogleAnalyticsFilters(formattedFilters));
        }
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("WatcherFormLoader", xhr, status, err);
      }.bind(this),
    });
  };

  return {
    init: function (watcherID) {
      initialize(watcherID);
    },
    getCurrentPath: function () {
      return window.location.pathname;
    },
  };
})();

export default WatcherFormLoader;
