import _ from "lodash";

var GA4NonRealtimeInveralTypes = [
  {
    value: "today",
    label:
      "Today (every 45 minutes we look at the data of the day, as seen in Google Analytics) -- Alert triggers only once per day.",
  },
  { value: "yesterday", label: "Yesterday (checked at 5AM, daily)" },
  { value: "last_week", label: "Last week (checked at 5AM, daily)" },
  { value: "last_month", label: "Last month (checked at 5AM, daily)" },
  { value: "yesterday_vs_day_before", label: "Yesterday vs the day before (checked at 5AM, daily)" },
  {
    value: "yesterday_vs_average_previous_7_days",
    label: "Yesterday vs the average of the previous 7 days (checked at 5AM, daily)",
  },
  {
    value: "yesterday_vs_same_day_last_week",
    label: "Yesterday vs the same day of last week (checked at 5AM, daily)",
  },
  { value: "last_week_vs_week_before", label: "Last week vs the week before (checked at 5AM, daily)" },
  {
    value: "last_month_vs_month_before",
    label: "Last month vs the month before (checked at 5AM, daily)",
  },
  {
    value: "last_month_vs_same_month_last_year",
    label: "Last month vs the same month of last year (checked at 5AM, daily)",
  },
];

var NonRealtimeInveralTypes = [
  {
    value: "last_hour",
    label: "Last hour (we look at the last full hour of data)",
  },
  {
    value: "two_hours_ago",
    label: "2 hours ago (we look at the full hours of data. At 4, we look at the data from 2:00 to 2:59)",
  },
  {
    value: "three_hours_ago",
    label: "3 hours ago (we look at the full hours of data. At 4, we look at the data from 1:00 to 1:59)",
  },
  {
    value: "six_hours_ago",
    label: "6 hours ago (we look at the full hours of data. At 8, we look at the data from 2:00 to 2:59)",
  },
  {
    value: "last_two_hours",
    label:
      "Last 2 hours, combined (we look at the full hours of data. This interval is disabled from midnight to 2:01 AM. Ex: At 4, we look at the data from 2:00 to 3:59)",
  },
  {
    value: "last_three_hours",
    label:
      "Last 3 hours, combined (we look at the full hours of data. This interval is disabled from midnight to 3:01 AM. Ex: At 4, we look at the data from 1:00 to 3:59)",
  },
  {
    value: "last_six_hours",
    label:
      "Last 6 hours, combined (we look at the full hours of data. This interval is disabled from midnight to 6:01 AM. Ex: At 7, we look at the data from 1:00 to 6:59)",
  },
  {
    value: "today",
    label:
      "Today (every 45 minutes we look at the data of the day, as seen in Google Analytics) -- Alert triggers only once per day.",
  },
  {
    value: "today_vs_last_7_days",
    label:
      "Today vs. average of the last 7 days (every 45 minutes, we look at the data of the day, as seen in Google Analytics, and compare it to the average for the same time frame of the last days. Ex: if a check happens at 2:25 PM, it will compare data from midnight through the latest of today's data, to the average of the last 7 days, from midnight to 1:59 PM for those days.) -- Alert triggers only once per day.",
  },
  { value: "yesterday", label: "Yesterday (checked at 5AM, daily)" },
  { value: "last_week", label: "Last week (checked at 5AM, daily)" },
  { value: "last_month", label: "Last month (checked at 5AM, daily)" },
  { value: "last_hour_vs_hour_before", label: "Last hour vs the hour before" },
  {
    value: "two_hours_ago_vs_hour_before",
    label: "Two hours ago vs the hour before",
  },
  {
    value: "three_hours_ago_vs_hour_before",
    label: "Three hours ago vs the hour before",
  },
  { value: "yesterday_vs_day_before", label: "Yesterday vs the day before (checked at 5AM, daily)" },
  {
    value: "yesterday_vs_average_previous_7_days",
    label: "Yesterday vs the average of the previous 7 days (checked at 5AM, daily)",
  },
  {
    value: "yesterday_vs_same_day_last_week",
    label: "Yesterday vs the same day of last week (checked at 5AM, daily)",
  },
  { value: "last_week_vs_week_before", label: "Last week vs the week before (checked at 5AM, daily)" },
  {
    value: "last_month_vs_month_before",
    label: "Last month vs the month before (checked at 5AM, daily)",
  },
  {
    value: "last_month_vs_same_month_last_year",
    label: "Last month vs the same month of last year (checked at 5AM, daily)",
  },
];

var RealtimeIntervalTypes = [
  { value: "real_time", label: "Real-time" },
  { value: "real_time_vs_30_minutes_ago", label: "Real-time vs 30 minutes ago" },
];

var AllIntervalTypes = _.clone(RealtimeIntervalTypes).concat(_.clone(NonRealtimeInveralTypes));
var AllGA4IntervalTypes = _.clone(RealtimeIntervalTypes).concat(_.clone(GA4NonRealtimeInveralTypes));

export { NonRealtimeInveralTypes, RealtimeIntervalTypes, AllIntervalTypes, AllGA4IntervalTypes };
