import _ from "lodash";

var integrationsReducerInitialState = {
  integrationsAccountLists: {},
};

const integrationsReducer = function (state, action) {
  if (state == null) {
    state = _.clone(integrationsReducerInitialState);
  }

  switch (action.type) {
    case "SET_INTEGRATION_ACCOUNT_LIST":
      state["integrationsAccountLists"][action.integration] = action.list;
      return state;
    default:
      return state;
  }
};

export { integrationsReducer, integrationsReducerInitialState };
