import React from "react";
import { connect } from "react-redux";
import Button from "../../../legacy-components/generic/button.js.jsx";
import ButtonWithIcon from "../../../legacy-components/generic/button_with_icon.js.jsx";
import ReactQuill from "react-quill";

class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);

    this.quillModules = {
      toolbar: [
        // [{ header: [1, 2, false] }],
        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        ["bold", "italic", "underline", "strike"],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        // ["link", "image"],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"],
        // [{ font: [] }],
      ],
    };

    this.state = {
      text: this.props.text,
    };
  }

  saveAndClose() {
    this.props.save();
    this.props.closeWidgetEditModal();
  }

  onChange(value) {
    this.setState({ text: value });
    store.dispatch(updateEditorWidgetConfig("text", value, this.props.widgetIndex));
  }

  render() {
    return (
      <div className="widget widget-editor">
        <h3 className="tw-large-header mb-4">Layout / Rich Text box</h3>
        <div className="mb-4 rich-text-editor">
          <label className="tw-label">Text</label>
          <ReactQuill
            theme="snow"
            value={this.state.text}
            onChange={this.onChange.bind(this)}
            modules={this.quillModules}
          />
        </div>
        <div className="flex flex-row mt-4">
          <ButtonWithIcon
            onClick={this.saveAndClose.bind(this)}
            text="Save &amp; close"
            icon="fa-check"
            extraClasses="tw-submit-button"
          />
          &nbsp;
          <Button onClick={this.props.closeWidgetEditModal} text="Close without saving" />
          &nbsp;
          <ButtonWithIcon
            onClick={this.props.deleteWidget.bind(this)}
            icon="fa-times"
            extraClasses="bg-red-100 text-red-700 hover:bg-red-200 ml-auto"
            text="Delete this widget"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    save: () => {
      dispatch(saveWidgetConfigFromEditor(ownProps.widgetIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RichTextEditor);
