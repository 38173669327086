import GoogleSheetsApi from "./api";

export default class GoogleSheetsActions {
  constructor() {
    this.api = new GoogleSheetsApi();
  }
  setSheetValues(sheetName, dispatch, ownProps, sheetId, providerAccount, callback) {
    const api = new GoogleSheetsApi();
    let dataType = [];
    let columnIndexError = [];
    let columnIndexIsValid = [];

    api.getlistColumns(sheetId, sheetName.value, providerAccount).then((sheetHeaderDetail) => {
      let errorMessage = "";

      // Header validation
      if (sheetHeaderDetail.length < 1 || sheetHeaderDetail[0] === undefined || sheetHeaderDetail[1] === undefined) {
        errorMessage = "It doesn't have a supported header.";
      } else {
        for (let i = 0; i < sheetHeaderDetail[0].length; i++) {
          if (sheetHeaderDetail[0][i].userEnteredValue === undefined) {
            columnIndexError.push(i);
          } else {
            columnIndexIsValid.push(i);
          }
        }
      }

      // if the invalid column is in the grid, put an error message because it breaks the suite of the index of the header array for the API treatment.
      for (let i = 0; i < columnIndexIsValid.length; i++) {
        if (i !== columnIndexIsValid[i]) {
          errorMessage = "One or more columns in the sheet has values but no header.";
          break;
        }
      }

      // Deleted the invalid columns because, if this column is on the end of the grid, the treatment is ok for the API
      for (var i = columnIndexError.length - 1; i >= 0; i--) {
        sheetHeaderDetail[0].splice(columnIndexError[i], 1);
        sheetHeaderDetail[1].splice(columnIndexError[i], 1);
      }

      // Create array with header information
      if (errorMessage === "") {
        for (let i = 0; i < sheetHeaderDetail[1].length; i++) {
          if (sheetHeaderDetail[0][i] !== undefined) {
            if (sheetHeaderDetail[1][i].userEnteredFormat !== undefined) {
              dataType.push({
                columnNumber: i,
                dataType: sheetHeaderDetail[1][i].userEnteredFormat.numberFormat.type,
                name: sheetHeaderDetail[0][i].userEnteredValue.stringValue,
              });
            } else {
              dataType.push({
                columnNumber: i,
                dataType: "STRING",
                name: sheetHeaderDetail[0][i].userEnteredValue.stringValue,
              });
            }
          }
        }
        // Extract metrics to save in widget and extract string column number for enventualy filtered data
        let dimensions = [];
        let metrics = [];
        let isDate = false;

        dataType.map((data) => {
          switch (data.dataType) {
            case "STRING":
            case "TEXT":
              dimensions.push({ label: data.name, value: data.name.toLowerCase() });
              break;
            case "DATE":
              isDate = true;
              break;
            default:
              metrics.push({ label: data.name, value: data.name.toLowerCase() });
          }
        });

        if (isDate) {
          store.dispatch(setFlashMessage("", [""]));
          dispatch(updateEditorWidgetConfig("gsDataType", dataType, ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("gsMetricsList", metrics, ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("dimensions", dimensions, ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("gsDataType", dataType, ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("filtersList", dimensions, ownProps.widgetIndex));
          callback();
        } else {
          store.dispatch(setFlashMessage("This sheet is not supported.", ["It doesn't contain a column date."]));
          dispatch(updateEditorWidgetConfig("selectedSheet", "", ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("metric_name", "", ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("label", "", ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("gsDataType", [], ownProps.widgetIndex));
          dispatch(updateEditorWidgetConfig("filtersList", [], ownProps.widgetIndex));
          callback();
        }
      } else {
        store.dispatch(setFlashMessage("This sheet is not supported.", [errorMessage]));
        dispatch(updateEditorWidgetConfig("selectedSheet", "", ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("metric_name", "", ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("label", "", ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("gsDataType", [], ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("filtersList", [], ownProps.widgetIndex));
        callback();
      }
    });
  }

  pick_file(dispatch, ownProps, providerAccount, onCallback, onCancel) {
    let event = new CustomEvent("open-google-drive-picker", {
      detail: {
        id: providerAccount,
        callback: (fileDetails) => {
          if (fileDetails !== null) {
            // save the google sheets informations for the selected sheets in the widget
            dispatch(updateEditorWidgetConfig("gsSheetId", fileDetails.spreadsheetId, ownProps.widgetIndex));
            dispatch(updateEditorWidgetConfig("gsFileName", fileDetails.properties.title, ownProps.widgetIndex));
            let sheetsList = [];
            fileDetails.sheets.map((sheet) => {
              sheetsList.push({ label: sheet.properties.title, value: sheet.properties.title });
            });
            dispatch(updateEditorWidgetConfig("gsSheetsList", sheetsList, ownProps.widgetIndex));

            onCallback();
          } else {
            onCancel();
          }
        },
      },
    });
    window.dispatchEvent(event);
  }
  return_data_type_by_metric(metric_name, dataTypeList) {
    let type = dataTypeList.find((m) => m.name.toLowerCase() === metric_name.toLowerCase());

    return type;
  }
}
