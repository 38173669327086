import React from "react";
var createReactClass = require("create-react-class");
import {
  googleAnalyticsDimensionList,
  googleAnalyticsDimensionFilterOperators,
  googleAnalyticsRealtimeDimensionList,
} from "../helpers/dimensions";
import ButtonWithIcon from "./generic/button_with_icon.js";
import Select from "react-select";

var WidgetDimensionFilter = createReactClass({
  onChangeAnything: function (key, val) {
    var changed = {};
    changed[key] = val;
    this.props.onChangeCallback(changed);
  },

  onClickOR: function () {
    this.props.onAddORFilterCallback(this.props.fullFilterID);
  },

  onClickRemove: function () {
    this.props.onRemoveCallback();
  },

  dimensionDetails: function (dimensionValue) {
    for (let index = 0; index < this.props.dimensionList.length; index++) {
      const dimension = this.props.dimensionList[index];
      if (dimension.value == dimensionValue) {
        return dimension;
      }
    }
  },

  render: function () {
    var refId = this.props.filterConfig.refID || 0;
    var selectedDimension = this.props.filterConfig.dimension || this.props.filterConfig.selectedDimension;
    var selectedOperator = this.props.filterConfig.operator || this.props.filterConfig.selectedOperator;
    var specifiedValue = this.props.filterConfig.value || this.props.filterConfig.specifiedValue;
    var dimensionList = undefined;
    let valuesEnum = [];
    let multiEnum = false;
    let shortFilterVariable = this.props.shortFilterVariable;
    let multiLineFilter = this.props.multilineFilter;

    switch (this.props.providerType) {
      case "ga":
        var dimensionList = googleAnalyticsDimensionList;
        var operators = googleAnalyticsDimensionFilterOperators;
        break;
      case "rt":
        var dimensionList = googleAnalyticsRealtimeDimensionList;
        var operators = googleAnalyticsDimensionFilterOperators;
        break;
      default:
        var dimensionList = this.props.dimensionList;
        var operators = this.props.operators;
    }

    // The following lines were added for Google Ads only originally.
    // When this.props.dimensionList is set, we can assume it's not supported.
    // Not only that, but if this.props.dimensionList this will just crash right now.
    if (selectedDimension && this.props.dimensionList) {
      const currentDimension = this.dimensionDetails(selectedDimension);

      if (currentDimension && currentDimension.operators) {
        operators = currentDimension.operators;
      }

      if (currentDimension && currentDimension.enum) {
        valuesEnum = currentDimension.enum;
      } else {
        valuesEnum = [];
      }

      if (selectedOperator) {
        for (let index = 0; index < operators.length; index++) {
          const op = operators[index];
          if (op.value === selectedOperator) {
            multiEnum = op.multi;
          }
        }
      }
    }

    let filterChunkNames = [];
    if (shortFilterVariable) {
      filterChunkNames = ["dimension", "operator", "value"];
    } else {
      filterChunkNames = ["selectedDimension", "selectedOperator", "specifiedValue"];
    }

    return (
      <div>
        {multiLineFilter && (
          <div className="field" key={"dimension-filter-" + refId}>
            <ButtonWithIcon
              onClick={this.onClickRemove}
              key={"button-" + refId}
              data-ref-id={refId}
              text="Remove filter"
              icon="fa-times"
              extraClasses="bg-red-100 text-red-700 hover:bg-red-200 ml-auto mb-2"
            />
            <Select
              ref="dimension"
              label="Dimension"
              onChange={function (val) {
                return this.onChangeAnything(filterChunkNames[0], val.value);
              }.bind(this)}
              value={selectedDimension}
              options={dimensionList}
              clearable={false}
            />
            <Select
              ref="operator"
              label="Operator"
              className=" filter-dimension-operators"
              value={selectedOperator}
              onChange={function (val) {
                return this.onChangeAnything(filterChunkNames[1], val.value);
              }.bind(this)}
              options={operators}
              clearable={false}
            />
            <textarea
              className="tw-field filter-dimension-value textarea mb-2"
              ref="value"
              label="Value"
              type="text"
              value={specifiedValue}
              placeholder="One value per line"
              onChange={function (event) {
                return this.onChangeAnything(filterChunkNames[2], event.target.value);
              }.bind(this)}
              key={"value-" + refId}
              data-ref-id={refId}
            />
          </div>
        )}
        {!multiLineFilter && (
          <>
            <div className="rounded-lg bg-gray-100 my-2 p-1">
              <div className="my-2 p-1 flex justify-start items-center">
                <div className="mr-1 flex-grow">
                  <Select
                    ref="dimension"
                    label="Dimension"
                    onChange={function (val) {
                      return this.onChangeAnything(filterChunkNames[0], val.value);
                    }.bind(this)}
                    value={selectedDimension}
                    options={dimensionList}
                    clearable={false}
                  />
                </div>
                <div className="mx-1 flex-grow">
                  <Select
                    ref="operator"
                    label="Operator"
                    className=" filter-dimension-operators"
                    value={selectedOperator}
                    onChange={function (val) {
                      return this.onChangeAnything(filterChunkNames[1], val.value);
                    }.bind(this)}
                    options={operators}
                    clearable={false}
                  />
                </div>
              </div>

              <div className="flex justify-start items-center mb-2">
                {valuesEnum.length === 0 && (
                  <div className="mx-1 flex flex-grow flex-wrap">
                    <input
                      className="tw-field filter-dimension-value h-full"
                      ref="value"
                      label="Value"
                      type="text"
                      placeholder="Value"
                      onChange={function (event) {
                        return this.onChangeAnything(filterChunkNames[2], event.target.value);
                      }.bind(this)}
                      value={specifiedValue}
                    />
                  </div>
                )}

                {valuesEnum.length > 0 && (
                  <div className="mx-1 flex flex-grow flex-wrap">
                    <Select
                      removeSelected={true}
                      className="filter-dimension-value h-full w-full"
                      multi={multiEnum}
                      ref="select"
                      placeholder={multiEnum ? "Select one or more values" : "Select a value"}
                      options={valuesEnum}
                      onChange={function (data) {
                        let val;
                        // If multiple values are possible, data is an array. Otherwise, it's an object.
                        if (Array.isArray(data)) {
                          val = data.map(function (option) {
                            return option.value;
                          });
                        } else {
                          val = data.value;
                        }
                        return this.onChangeAnything("specifiedValue", val);
                      }.bind(this)}
                      value={specifiedValue}
                    />
                  </div>
                )}
                <div className="ml-1">
                  {this.props.hasORenabled && (
                    <ButtonWithIcon
                      icon="fa-plus"
                      iconBefore={true}
                      extraClasses="is-primary is-small"
                      onClick={this.onClickOR}
                      text="OR"
                    />
                  )}
                  <ButtonWithIcon
                    icon="fa-times"
                    extraClasses="text-red-600 float-right mx-2"
                    onClick={this.onClickRemove}
                    text=""
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  },
});

export default WidgetDimensionFilter;
