import React from "react";
import { connect } from "react-redux";
import ButtonWithIcon from "../../../legacy-components/generic/button_with_icon.js.jsx";
import axios from "axios";

class PubliclySharedReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      isEdit: false,
      errorMessage: "",
      successResponse: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.performApiAction = this.performApiAction.bind(this);
    this.handleSaveAccessPassword = this.handleSaveAccessPassword.bind(this);
    this.handleDeleteAccessPassword = this.handleDeleteAccessPassword.bind(this);
    this.handleSharedAccessPassword = this.handleSharedAccessPassword.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.accessKey !== this.props.accessKey) {
      this.setState({ isEdit: !this.props.accessKey });
    }

    if (prevState.isEdit && !this.state.isEdit) {
      this.setState({ errorMessage: "" });
    }
  }

  handleInputChange(event) {
    this.setState({ inputValue: event.target.value });
  }

  performApiAction(reportID, payload) {
    const url = `/api/v1/reports/${reportID}/update-access-password`;
    axios
      .post(url, payload)
      .then((response) => {
        this.setState({ successResponse: response.data.message });
        if (payload.access_password) {
          store.dispatch(setReportAccessKey(response.data.access_key));
        }

        if (payload.is_shared_access_password) {
          store.dispatch(setIsInitialReportShared(response.data.isInitialReportShared));
          store.dispatch(setSharedAceessKey(response.data.is_shared_access_password));
        }
        if (payload.delete_access_password) {
          store.dispatch(setReportAccessKey(response.data.access_key));
          store.dispatch(setSharedAceessKey(response.data.is_shared_access_password));
        }
        this.setState({ inputValue: "", errorMessage: "" });
      })
      .catch((error) => {
        this.setState({ errorMessage: "Error submitting form: " + error.response.data.message });
        store.dispatch(setSharedAceessKey(error.response.data.is_shared_access_password));
      });
  }

  handleSaveAccessPassword() {
    const { inputValue } = this.state;
    const { reportID } = this.props;
    const payload = { access_password: inputValue };
    if (inputValue.length >= 5) {
      this.performApiAction(reportID, payload);
    } else {
      this.setState({ errorMessage: "Input field cannot be empty or less than 5 characters" });
    }
  }

  handleDeleteAccessPassword() {
    const { reportID } = this.props;
    const payload = { delete_access_password: true };
    this.performApiAction(reportID, payload);
  }
  handleSharedAccessPassword(event) {
    const { reportID } = this.props;
    const payload = { is_shared_access_password: event.target.checked };
    this.performApiAction(reportID, payload);
    this.props.upadateEnableSharedAccessKey(event);
  }

  render() {
    const {
      shared,
      updateEnableSharedReport,
      accessKey,
      isSharedAccessKey,
      isInitialReportShared,
      public_shareable_url,
      include_public_link_in_email,
      updateIncludePublicLinkInEmail,
    } = this.props;

    const { isEdit, successResponse, errorMessage, inputValue } = this.state;
    return (
      <>
        <label className="tw-label" htmlFor="agency_setting_slim_header">
          <input
            id="use-ppc-markup"
            className="mr-2 tw-checkbox"
            type="checkbox"
            checked={shared}
            onChange={updateEnableSharedReport}
          />
          Enable publicly shared URL &amp; PDF
        </label>

        {shared && (
          <>
            <p className="text-sm italic">
              You can share this URL with clients or colleagues. PDF download is available on this page.
            </p>

            <p className="text-sm italic my-2">
              <strong>NOTE</strong>: You need to save the report before this URL will be enabled.
            </p>

            <div className="my-2">
              <label className="tw-label" htmlFor="agency_setting_access_key">
                <input
                  id="access-key"
                  className="mr-2 tw-checkbox"
                  type="checkbox"
                  checked={isSharedAccessKey && isInitialReportShared}
                  onChange={this.handleSharedAccessPassword}
                />
                Enable Access Key to secure your shared report
              </label>

              {successResponse && <p className="text-green-500 text-sm mt-2">{successResponse}</p>}
              {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}

              {isSharedAccessKey && isInitialReportShared && (
                <pre className="p-4 bg-gray-100 rounded-md mt-4 text-sm overflow-scroll xl:overflow-hidden">
                  <div className="flex justify-between items-center">
                    <div>
                      {!isEdit && accessKey && (
                        <ButtonWithIcon
                          text="Copy"
                          icon="fa-clipboard"
                          extraClasses="tw-button mr-2"
                          onClick={() => {
                            navigator.clipboard.writeText(accessKey);
                          }}
                        />
                      )}
                      <strong>Access Key: </strong>
                      {isEdit || !accessKey ? (
                        <>
                          <input
                            type="text"
                            id="inputField"
                            name="inputField"
                            accessKey="1"
                            placeholder="secret1"
                            value={inputValue}
                            onChange={this.handleInputChange}
                            className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300  "
                          />
                          <button
                            onClick={this.handleSaveAccessPassword}
                            className=" ml-2 px-4 py-2 bg-blue-600 text-white font-semibold rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Save
                          </button>
                        </>
                      ) : (
                        accessKey
                      )}
                    </div>

                    <div>
                      {isEdit && (
                        <ButtonWithIcon
                          icon="fa-times"
                          extraClasses="tw-button mr-2"
                          onClick={() => {
                            this.setState({ isEdit: false });
                          }}
                        />
                      )}
                      {!isEdit && accessKey && (
                        <>
                          <ButtonWithIcon
                            text="Edit"
                            icon="fa-edit"
                            extraClasses="tw-button mr-2"
                            onClick={() => {
                              this.setState({ isEdit: true });
                            }}
                          />
                          <ButtonWithIcon
                            text="Delete"
                            icon="fa-trash"
                            extraClasses="tw-button mr-2 bg-erorr"
                            onClick={this.handleDeleteAccessPassword}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </pre>
              )}
            </div>

            <div className="">
              <pre className="p-4 bg-gray-100 rounded-md mt-4 text-sm overflow-scroll xl:overflow-hidden">
                <ButtonWithIcon
                  text="Copy"
                  icon="fa-clipboard"
                  extraClasses="tw-button mr-2"
                  onClick={() => {
                    navigator.clipboard.writeText(public_shareable_url);
                  }}
                />

                <a target="_blank" href={public_shareable_url}>
                  {public_shareable_url}
                </a>
              </pre>
            </div>

            <label className="tw-label" htmlFor="agency_setting_slim_header">
              <input
                id="use-ppc-markup"
                className="mr-2 tw-checkbox"
                type="checkbox"
                checked={include_public_link_in_email}
                onChange={updateIncludePublicLinkInEmail}
              />
              Include public link in emails (in the footer section)
            </label>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return state.reportFormReducer;
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateEnableSharedReport: (event) => {
      dispatch(setReportShared(event.target.checked));
    },
    updateIncludePublicLinkInEmail: (event) => {
      dispatch(setIncludePublicLinkInEmail(event.target.checked));
    },
    upadateEnableSharedAccessKey: (event) => {
      dispatch(setSharedAceessKey(event.target.checked));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PubliclySharedReport);
