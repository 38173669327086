import $ from "jquery";

class Onboarding {
  constructor() {
    $(document).ready(
      function () {
        if ($("#mw-onboarding-wrapper").length > 0) {
          $(".onboarding-goto-select-product").on(
            "click",
            function (e) {
              e.preventDefault();
              this.showStep("2");
            }.bind(this)
          );

          $("#goto-first-screen").on(
            "click",
            function (e) {
              e.preventDefault();
              this.showStep("1");
            }.bind(this)
          );

          $("#onboarding-goto-alert-plans").on(
            "click",
            function (e) {
              e.preventDefault();
              this.showStep("alert-plans");
            }.bind(this)
          );

          $("#onboarding-goto-report-plans").on(
            "click",
            function (e) {
              e.preventDefault();
              this.showStep("report-plans");
            }.bind(this)
          );

          $(".interval-switcher button").on(
            "click",
            function (e) {
              e.preventDefault();
              this.changeBillingInterval(e.target.dataset.interval);
            }.bind(this)
          );
        }
      }.bind(this)
    );
  }

  changeBillingInterval(newInterval) {
    var activeButtonClasses = "border-gray-200 shadow-sm bg-white";
    switch (newInterval) {
      case "year":
        $(".onboarding-monthly-plans").addClass("hidden");
        $(".onboarding-yearly-plans").removeClass("hidden");
        $(".interval-switcher button[data-interval='year']").addClass(activeButtonClasses);
        $(".interval-switcher button[data-interval='month']").removeClass(activeButtonClasses);
        break;
      case "month":
        $(".onboarding-monthly-plans").removeClass("hidden");
        $(".onboarding-yearly-plans").addClass("hidden");
        $(".interval-switcher button[data-interval='year']").removeClass(activeButtonClasses);
        $(".interval-switcher button[data-interval='month']").addClass(activeButtonClasses);
        break;
      default:
        break;
    }
  }

  showStep(step) {
    switch (step) {
      case "1":
        $("#onboarding-plan-step-2").addClass("hidden");
        $("#onboarding-plan-step-1").removeClass("hidden");
        $("#billing-overview").removeClass("hidden");
        break;
      case "2":
        $("#onboarding-plan-step-1").addClass("hidden");
        $("#billing-overview").addClass("hidden");
        $("#onboarding-plan-step-3-report-plans").addClass("hidden");
        $("#onboarding-plan-step-3-alert-plans").addClass("hidden");
        $("#onboarding-plan-step-2").removeClass("hidden");
        break;
      case "alert-plans":
        $("#onboarding-plan-step-2").addClass("hidden");
        $("#onboarding-plan-step-3-alert-plans").removeClass("hidden");
        break;
      case "report-plans":
        $("#onboarding-plan-step-2").addClass("hidden");
        $("#onboarding-plan-step-3-report-plans").removeClass("hidden");
        break;
      default:
        break;
    }
  }
}

export default Onboarding;
