import React from "react";
var createReactClass = require("create-react-class");
import { connect, Provider } from "react-redux";
import Button from "./generic/button.js";
import ButtonWithIcon from "./generic/button_with_icon.js";
import { getParameterByName } from "../helpers/utils";
import $ from "jquery";
import APIError from "../helpers/api_error";

const ReportCanvasList = createReactClass({
  getInitialState: function () {
    return {
      allReportCanvas: [],
    };
  },
  componentDidMount: function () {
    this.showSavedFlashMessage();
    this.loadAllReportCanvas();
  },
  onEditClick: function (index) {
    document.location.href = "/report_canvas/" + index + "/edit";
  },
  showSavedFlashMessage: function () {
    if (getParameterByName("saved") !== null) {
      return "Canvas successfully created!";
    }
    if (getParameterByName("updated") !== null) {
      return "Canvas successfully updated!";
    }
    return false;
  },
  deleteReportCanvas: function (id) {
    if (confirm("Are you sure you want to delete this report canvas?")) {
      $.ajax({
        url: "/api/v1/report_canvas/" + id,
        method: "DELETE",
        cache: false,
        success: function (data) {
          document.location.reload();
        }.bind(this),
        error: function (xhr, status, err) {
          new APIError("ReportCanvasDelete", xhr, status, err);
        }.bind(this),
      });
    }
    return false;
  },

  loadAllReportCanvas: function () {
    $.ajax({
      url: "/api/v1/report_canvas",
      dataType: "json",
      method: "GET",
      success: function (data) {
        this.setState({ allReportCanvas: data.data });
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("ReportCanvasLoadAll", xhr, status, err);
      }.bind(this),
    });
  },
  duplicateCanvas: function (id, button) {
    $.ajax({
      url: "/api/v1/report_canvas/" + id + "/duplicate",
      dataType: "json",
      method: "POST",
      success: function (data) {
        this.redirectsToCanvas(data.data.id);
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("ReportCanvasDuplicate", xhr, status, err);
      }.bind(this),
    });
  },

  redirectsToCanvas: function (id) {
    document.location.href = "/report_canvas/" + id + "/edit";
  },

  render: function () {
    var flashMessage = this.showSavedFlashMessage();
    return (
      <div>
        {flashMessage ? (
          <div className="notification is-success">
            <span>{flashMessage}</span>
          </div>
        ) : (
          false
        )}
        <Button extraClasses="is-info canvas-save-button" text="Add a canvas" href="/report_canvas/new" />

        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {this.state.allReportCanvas.map(
              function (canvas) {
                return (
                  <tr key={canvas.id}>
                    <td>
                      <a href={"/report_canvas/" + canvas.id + "/edit"}>{canvas.attributes.name}</a>
                    </td>
                    <td className="actions">
                      <ButtonWithIcon
                        icon="fa-edit"
                        extraClasses="is-small is-outlined"
                        text="Edit"
                        href={"/report_canvas/" + canvas.id + "/edit"}
                      />
                      &nbsp;
                      <ButtonWithIcon
                        ref={"duplicateButton-" + canvas.id}
                        icon="fa-clone"
                        extraClasses="is-small is-outlined"
                        text="Duplicate"
                        href="#"
                        onClick={function () {
                          return this.duplicateCanvas(canvas.id);
                        }.bind(this)}
                      />
                      &nbsp;
                      <ButtonWithIcon
                        icon="fa-times"
                        extraClasses="is-small is-danger is-outlined"
                        text="Delete"
                        href="#"
                        onClick={function (event) {
                          return this.deleteReportCanvas(canvas.id, event.target);
                        }.bind(this)}
                      />
                    </td>
                  </tr>
                );
              }.bind(this)
            )}
          </tbody>
        </table>
      </div>
    );
  },
});

function mapReportCanvasListStateToProps(state, ownProps) {
  return state.widgetReducer;
}

const ReportCanvasListConnect = connect(mapReportCanvasListStateToProps, null, null)(ReportCanvasList);

const ReportCanvasListWithProvider = createReactClass({
  render: function () {
    return (
      <Provider store={store}>
        <ReportCanvasListConnect ref="canvasList" />
      </Provider>
    );
  },
});

export default ReportCanvasListWithProvider;
