export default class WidgetBase {
  constructor(widget, integration) {
    this.widget = widget;
    this.integration = integration;

    this.name();
    this.type();
    this.width();
    this.config();
    this.widgetComponent();
    this.editorComponent();
  }

  name() {
    throw `${this.constructor.name} needs to implement the name method. It should return something like "My Widget Name", for example.`;
  }

  type() {
    throw `${this.constructor.name} needs to implement the type method. It should return something like "mailchimp-single-metric", for example.`;
  }

  // Possible values: 'small' or 'large'
  width() {
    throw `${this.constructor.name} needs to implement the width method. It should return something like "small", for example.`;
  }

  height() {
    return 2;
  }

  config(chartType) {
    throw `${this.constructor.name} needs to implement the config method. It should return a config object, which could be different for each widget.`;
  }

  widgetComponent() {
    throw `${this.constructor.name} needs to implement the widgetComponent method. It should return the widget component of this widget.`;
  }

  editorComponent() {
    throw `${this.constructor.name} needs to implement the editorComponent method. It should return the editor component of this widget.`;
  }
}
