const mainColor = "#FFFFFF";
const secondaryColor = "#4A4A4A";

export const defaultThemeLogo = "default";

export const defaultTheme = {
  global: {
    backgroundColor: mainColor,
    text: {
      color: secondaryColor,
    },
  },
  header: {
    slim: false,
    hideLogo: false,
    hideSiteName: false,
    backgroundColor: mainColor,
    text: {
      color: "#000000",
    },
  },
  footer: {
    divider: {
      color: "#CAD6D2",
    },
    backgroundColor: mainColor,
    text: {
      color: secondaryColor,
    },
  },
  news: {
    backgroundColor: mainColor,
    text: {
      color: secondaryColor,
    },
  },
  widgets: {
    global: {
      backgroundColor: mainColor,
    },
    singleMetrics: {
      positiveTextColor: "#008053",
      positiveBackgroundColor: "#F6F9F8",
      negativeTextColor: "#9C1200",
      negativeBackgroundColor: "#F7F6F2",
      neutralTextColor: "#5C756C",
      neutralBackgroundColor: "#F6F9F8",
    },
    list: {
      backgroundColor: "#F6F9F8",
      title: {
        color: "#017985",
      },
      text: {
        color: "#535353",
      },
    },
    graphBox: {
      backgroundColor: "#F6F9F8",
      title: {
        color: "#017985",
      },
      graphTheme: {
        colors: {
          color_1: "#C9EABE", // light green
          color_2: "#79BECD", // turquoise
          color_3: "#3F7EAD", // blue
          color_4: "#12316C", // indigo
          color_5: "#9C1200", // red
          color_6: "#EFAA43", // pastel orange
          color_7: "#000000",
        },
        markerColor: "#535353",
        fontColor: "#535353",
        // 'background_colors' => '#F6F9F8',
      },
    },
    layout: {
      title: {
        color: secondaryColor,
      },
      text: {
        color: secondaryColor,
      },
      separator: {
        color: "#CAD6D2",
      },
    },
  },
};
