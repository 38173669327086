import _ from "lodash";
import React from "react";
import ButtonWithIcon from "../generic/button_with_icon.js";
import WidgetDimensionFilter from "../widget_dimension_filter.js";

class WidgetFilterEditor extends React.Component {
  constructor(props) {
    super(props);

    let filters = this.props.filters || [];

    this.state = {
      filters: filters,
    };
  }

  componentDidMount() {
    if (!this.props.filterUpdateCallback && store.getState().widgetReducer.editorConfig.filters) {
      this.setState({ filters: store.getState().widgetReducer.editorConfig.filters });
    }

    if (!this.filterUpdateCallback) {
      this.storeSubscription = store.subscribe(
        function () {
          let newValue = store.getState().widgetReducer.editorConfig.filters;
          if (newValue && this.state.filters !== newValue) {
            this.setState({ filters: newValue });
          }
        }.bind(this)
      );
    }
  }

  componentWillUnmount() {
    if (!this.filterUpdateCallback) {
      this.storeSubscription();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.filterUpdateCallback) {
      this.setState({ filters: nextProps.filters });
    }
  }

  addFilter() {
    let filterCounter = 0;
    var filters = _.clone(this.state.filters, true);
    filters.push(this.emptyFilterTemplate());

    // for short variable, we have to create hash with element otherwise
    // the element containt long name variable ex :selectedDimension
    if (this.props.shortFilterVariable) {
      filterCounter++;
      var newFilters = _.clone(this.state.filters);
      newFilters.push({
        refID: filterCounter,
        dimension: undefined,
        operator: undefined,
        value: undefined,
      });

      filters = newFilters;
    }
    this.updateFiltersInStore(filters);
  }

  addORFilter(fullFilterID) {
    var filters = _.clone(this.state.filters, true);

    let filterIndex = this.getFilterIndex(fullFilterID);
    let filterGroup = filters[filterIndex];

    // By default, each filters are objects...but as soon as we want to add one to the
    // group, we need to make sure it's an array before adding to it.
    if (!Array.isArray(filterGroup)) {
      filterGroup = [filterGroup];
    }

    filterGroup.push(this.emptyFilterTemplate());

    filters[filterIndex] = filterGroup;

    this.updateFiltersInStore(filters);
  }

  emptyFilterTemplate() {
    if (this.props.shortFilterVariable) {
      return {
        dimension: "",
        operator: "",
        value: "",
      };
    } else {
      return {
        selectedDimension: "",
        selectedOperator: "",
        specifiedValue: "",
      };
    }
  }

  updateFiltersInStore(filters) {
    if (this.props.filterUpdateCallback) {
      // If the caller wants to be updated with the filters, instead of the default behavior
      // (for example, the Funnel widget, which has multiple sets of filters (one per step)
      this.props.filterUpdateCallback(filters);
    } else {
      // This is the default behaviour.
      store.dispatch(updateEditorWidgetConfig("filters", filters, this.props.widgetIndex));
    }
  }

  filterChanged(filterID, changed) {
    var filters = _.clone(this.state.filters, true);

    let filterSubIndex = this.getFilterSubIndex(filterID);
    let filterIndex = this.getFilterIndex(filterID);

    if (Array.isArray(filters[filterIndex])) {
      filters[filterIndex][filterSubIndex] = _.assign({}, filters[filterIndex][filterSubIndex], changed);
    } else {
      filters[filterIndex] = _.assign({}, filters[filterIndex], changed);
    }

    this.updateFiltersInStore(filters);
  }

  filterRemoved(filterID) {
    var filters = _.clone(this.state.filters, true);

    let filterSubIndex = this.getFilterSubIndex(filterID);
    let filterIndex = this.getFilterIndex(filterID);

    if (Array.isArray(filters[filterIndex])) {
      filters[filterIndex].splice(filterSubIndex, 1);

      // If this was the last filter of the group, remove the group.
      if (filters[filterIndex].length === 0) {
        filters.splice(filterIndex, 1);
      }
    } else {
      filters.splice(filterIndex, 1);
    }
    store.dispatch(setFlashMessage("", []));
    this.updateFiltersInStore(filters);
  }

  // Format of FilterID is composed of a filterIndex, which is the po
  //  is either just an integer, in which case it is index of the filter, in the filter array.
  // In case where there is a integer, followed by a forward slash and another integer, then the first one is
  // the group index, and second one the filter index, within the group
  // Example:
  // {
  //     { selectedDimension: '', selectedOperator: '==', specifiedValue: ''},      // filterID => 0
  //     { selectedDimension: '', selectedOperator: '==', specifiedValue: ''},      // filterID => 1
  //     [
  //         { selectedDimension: '', selectedOperator: '==', specifiedValue: ''},  // filterID => '2/0'
  //         { selectedDimension: '', selectedOperator: '==', specifiedValue: ''},  // filterID => '2/1'
  //         { selectedDimension: '', selectedOperator: '==', specifiedValue: ''}   // filterID => '2/2'
  //     ],
  //     { selectedDimension: '', selectedOperator: '==', specifiedValue: ''}      // filterID => 3
  // }
  buildFilterID(filterIndex, filterSubIndex) {
    if (!filterSubIndex) {
      filterSubIndex = 0;
    }

    return `${filterIndex}/${filterSubIndex}`;
  }

  getFilterIndex(filterID) {
    return filterID.split("/")[0];
  }

  getFilterSubIndex(filterID) {
    return filterID.split("/")[1];
  }
  // the renderGroup is called when the filter is in a "OR" condition
  renderGroup(filterGroup, filterIndex, hasORenabled, lastIndex) {
    return (
      <div className="" key={`filter-group-${filterIndex}`}>
        <div className="">
          {filterGroup.map(
            function (filter, filterSubIndex) {
              let fullFilterID = this.buildFilterID(filterIndex, filterSubIndex);

              return (
                <div className="" key={`filter-group-${filterIndex}-filter-${filterSubIndex}`}>
                  <WidgetDimensionFilter
                    onChangeCallback={function (changed) {
                      return this.filterChanged(fullFilterID, changed);
                    }.bind(this)}
                    onRemoveCallback={function () {
                      return this.filterRemoved(fullFilterID);
                    }.bind(this)}
                    onAddORFilterCallback={function (fullFilterID) {
                      return this.addORFilter(fullFilterID);
                    }.bind(this)}
                    fullFilterID={fullFilterID}
                    filterConfig={filter}
                    providerType={this.props.providerType}
                    operators={this.props.operators}
                    dimensionList={this.props.dimensionList}
                    hasORenabled={hasORenabled}
                    multilineFilter={this.props.multilineFilter}
                    shortFilterVariable={this.props.shortFilterVariable}
                    key={`${filterIndex} - ${filterSubIndex}`}
                  />
                </div>
              );
            }.bind(this)
          )}
        </div>

        {lastIndex !== filterIndex && <p className="my-2 text-center">AND</p>}
      </div>
    );
  }

  // the renderSingleFilter is called when the filter is in a "AND" condition
  renderSingleFilter(filter, i, hasORenabled, lastIndex) {
    let fullFilterID = this.buildFilterID(i);

    return (
      <div className="" key={`filter-single-${i}`}>
        <div className="">
          <WidgetDimensionFilter
            onChangeCallback={function (changed) {
              return this.filterChanged(fullFilterID, changed);
            }.bind(this)}
            onRemoveCallback={function () {
              return this.filterRemoved(fullFilterID);
            }.bind(this)}
            onAddORFilterCallback={function (fullFilterID) {
              return this.addORFilter(fullFilterID);
            }.bind(this)}
            fullFilterID={fullFilterID}
            filterConfig={filter}
            providerType={this.props.providerType}
            operators={this.props.operators}
            dimensionList={this.props.dimensionList}
            hasORenabled={hasORenabled}
            multilineFilter={this.props.multilineFilter}
            shortFilterVariable={this.props.shortFilterVariable}
            key={i}
          />
        </div>

        {lastIndex !== i && <p className="my-2 text-center">AND</p>}
      </div>
    );
  }

  render() {
    if (this.props.dimensionList) {
      let newFilters = this.state.filters;
      newFilters.map((val) => {
        let dimension;

        // If the dimension currently selected is not in the list of dimension, remove its value so that the user can select a new one.
        // This happens when some dimensions are not available to be filtered on, based on the overall dimension of a list, for example.
        this.props.dimensionList.forEach((d) => {
          if (val.selectedDimension === d["value"]) {
            dimension = val.selectedDimension;
          }
        });
        val.selectedDimension = dimension;
        return val;
      });

      if (this.state.filters !== newFilters) {
        this.setState({ filters: newFilters });
      }
    }

    let filterCount = this.state.filters.length;
    let lastIndex = filterCount - 1;

    // Can we add "OR" type filters or not? By default, we can NOT.
    let hasORenabled = this.props.hasORenabled || false;

    return (
      <div className="google-analytics-filter-list">
        <label className="tw-label">Filters</label>
        {this.state.filters.map(
          function (filter, i) {
            // if filter is an array, this array containt de element of "OR" condition
            if (Array.isArray(filter)) {
              return this.renderGroup(filter, i, hasORenabled, lastIndex);
            } else {
              return this.renderSingleFilter(filter, i, hasORenabled, lastIndex);
            }
          }.bind(this)
        )}
        {filterCount === 0 && (
          <ButtonWithIcon
            iconBefore={true}
            onClick={function () {
              this.addFilter();
            }.bind(this)}
            icon="fa-plus"
            text={this.props.addFilterButtonLabel || "Add filters to this widget"}
            extraClasses="is-primary is-small"
          />
        )}

        {filterCount !== 0 && (
          <div className="text-center">
            <ButtonWithIcon
              iconBefore={true}
              onClick={function () {
                this.addFilter();
              }.bind(this)}
              icon="fa-plus"
              text="AND"
              extraClasses="is-primary is-small"
            />
          </div>
        )}
      </div>
    );
  }
}

export default WidgetFilterEditor;
