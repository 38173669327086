import _ from "lodash";
const reportNewsFormReducerInitialState = {
  id: undefined,
  title: "",
  start_date: getDateYYYYMMDD(false),
  end_date: getDateYYYYMMDD(true),
  text: "",
  image: "",
};

function getDateYYYYMMDD(nextMonth) {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();

  if (nextMonth) {
    mm++;
    if (mm > 12) {
      mm = 1;
      yyyy++;
    }
  }

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return yyyy + "-" + mm + "-" + dd;
}

function prepareReportNewsFormDataFromStoreForApi(store) {
  return {
    id: store.id,
    start_date: store.start_date,
    end_date: store.end_date,
    image: store.image,
    text: store.text,
    title: store.title,
  };
}

const reportNewsFormReducer = function (state, action) {
  if (state == null) {
    state = _.clone(reportNewsFormReducerInitialState, true);
  }

  switch (action.type) {
    case "UPDATE_REPORT_NEWS_ATTR":
      var stateWithNewAttr = {};
      stateWithNewAttr[action.attribute] = action.value;
      return _.assign({}, state, stateWithNewAttr);
    case "GOT_REPORT_NEWS_CONFIG_DATA":
      var newState = action.data.attributes;
      newState.id = action.data.id;
      return _.assign({}, state, newState);
    default:
      return state;
  }
};

export { getDateYYYYMMDD, reportNewsFormReducer, prepareReportNewsFormDataFromStoreForApi };
