import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { appsignal } from "../src/javascript/appsignal";
import { ErrorBoundary } from "@appsignal/react";
import ReportCanvasWithProvider from "../src/javascript/legacy-components/reportCanvas.js.jsx";
import NewFromTemplate from "../src/javascript/components/watchers/NewFromTemplate";
import EmailConfirmationResend from "../src/javascript/components/email_confirmation_resend";
import WatcherForm from "../src/javascript/legacy-components/watcher_form.js";
import WatcherFormGA4 from "../src/javascript/legacy-components/ga4/watcher_form.js";
import ReportNewsListWithProvider from "../src/javascript/legacy-components/ReportNewsList.js";
import ReportNewsWithProvider from "../src/javascript/legacy-components/reportNews.js";
import ReportCanvasListWithProvider from "../src/javascript/legacy-components/reportCanvasList.js";
import ReportForm from "../src/javascript/legacy-components/report_form.js";
import ReportPreview from "../src/javascript/legacy-components/report_preview.js";
import BusinessSettings from "../src/javascript/components/reports/business_settings";
import NewReportForm from "../src/javascript/components/reports/new_report_form";
import IntegrationsList from "../src/javascript/components/integrations/list";
import FlashMessage from "../src/javascript/legacy-components/flash_message.js.jsx";

const FallbackComponent = () => (
  <div>There was an error with this request. Our engineers were notified. Sorry about that!</div>
);

const Routes = () => (
  <ErrorBoundary
    instance={appsignal}
    fallback={(error) => <FallbackComponent />}
  >
    <Router>
      <span>
        {
          // Reports
        }
        <Route exact path="/report_canvas/:id/edit" component={ReportCanvasWithProvider} />
        <Route exact path="/report_canvas" component={ReportCanvasListWithProvider} />
        <Route exact path="/report_canvas/new" component={ReportCanvasWithProvider} />
        <Route expact path="/agency_settings/edit" component={ReportPreview} />
        <Route expact path="/business_settings" component={BusinessSettings} />
        <Route exact path="/reports" component={FlashMessage} />
        <Route exact path="/reports/new" component={NewReportForm} />
        <Route exact path="/reports/:id/edit" component={ReportForm} />
        <Route exact path="/report_news" component={ReportNewsListWithProvider} />
        <Route exact path="/report_news/new" component={ReportNewsWithProvider} />
        <Route exact path="/report_news/:id/edit" component={ReportNewsWithProvider} />

        {
          // Watchers
        }

        <Route exact path="/watchers/new-from-template" component={NewFromTemplate} />
        <Route exact path="/watchers/new" component={WatcherForm} />
        <Route exact path="/watchers/new-ga4" component={WatcherFormGA4} />
        <Route exact path="/watchers/:id/edit" component={WatcherForm} />
        <Route exact path="/watchers/:id/edit-ga4" component={WatcherFormGA4} />

        <Route exact path="/providers" component={IntegrationsList} />
        <Route exact path="/email_confirmation_pending" component={EmailConfirmationResend} />
      </span>
    </Router>
  </ErrorBoundary>
);

export default Routes;
