import React from 'react'
import Select from "react-select";

class CommonAccountSelection extends React.Component {
  render() {
    const {
      options,
      disconnectStore,
      label,
      handleIntegrationButton,
      accountSelected,
      value,
      tabIndex,
    } = this.props;
      const { enable_integration_modal } = store.getState().reportFormReducer; 
      
    return (
      <div className="mt-2">
        {disconnectStore && (
          <p className="flex text-sm italic mb-2">
            You can select the account in the "Integration" tab of the report to apply to all your widgets. If you need
            a different account for this widget, you can select it here.
          </p>
        )}
        <div className="flex flex-row justify-between items-center">
          <label className="tw-label">{label}</label>
          {!options?.length && enable_integration_modal ? (
            <button className="tw-submit-button" onClick={handleIntegrationButton}>
              Connect account
            </button>
          ) : (
            <Select
              className={"w-1/2"}
              tabIndex={tabIndex || "0"}
              ref="select"
              label={label}
              options={options}
              value={value || null}
              onChange={accountSelected}
            />
          )}
        </div>
      </div>
    );
  }
}

export default CommonAccountSelection;