import React from 'react'
import Modal from '../components/modal.jsx';
import AccountSelectorsList from './accountSelectorsList.jsx';

export const IntegrationAccountsModal = ({ modalOpen, closeModal }) => {
    return (
        <Modal visible={modalOpen} closeCallback={closeModal} widthCanBeLarge title="Integrations Required" >
            <div>
                <h3 className="tw-medium-header text-center mt-8 mb-4">Integrations</h3>
                <p className="text-sm italic mb-2">
                    To use this template, you'll need to connect the following accounts.
                </p>

                <AccountSelectorsList />
            </div>

        </Modal>
    )
}
