import React from "react";
import Select from "react-select";
import Button from "../../legacy-components/generic/button.js.jsx";
import ButtonWithIcon from "../../legacy-components/generic/button_with_icon.js.jsx";
import WidgetFilterEditor from "../../legacy-components/canvas-widgets/widget_filter_editor";
import FlashMessage from "../../legacy-components/flash_message.js.jsx";
import {
  addCustomDimensionsToBaseDimensions,
  addCustomMetricsToBaseMetrics,
} from "./../../integrations/custom_definitions.js";
import DatePickerComponent from "../../legacy-components/DateRangePicker.jsx";

const BarGraphEditor = ({
  name,
  dimensions,
  metricsList,
  chartType,
  dimensionSupportsSecondaryMetric,
  multilineFilter = false,
  shortFilterVariable = false,
  ...props // Generic props passed from the parent editor component
}) => {
  let dimensionList = dimensions;
  if (dimensionList && dimensionList[0].value !== "MW--period") {
    dimensionList = Object.assign([], dimensionList); // clone the object, to prevent adding the period to the list of filterable dimensions, too.
    dimensionList.unshift({
      value: "MW--period",
      label: "Period - One bar / point represent either one day, week or month, based on the report schedule.",
    });
  }

  metricsList = addCustomMetricsToBaseMetrics(
    props.oAuthProviderName,
    metricsList,
    store.getState().widgetReducer.editorConfig.custom_account
  );

  dimensionList = addCustomDimensionsToBaseDimensions(
    props.oAuthProviderName,
    dimensionList,
    store.getState().widgetReducer.editorConfig.custom_account
  );

  const filterDimensionList = addCustomDimensionsToBaseDimensions(
    props.oAuthProviderName,
    props.filterDimensionList,
    store.getState().widgetReducer.editorConfig.custom_account
  );

  if (props.secondaryAxisEnabled == undefined) {
    props.secondaryAxisEnabled = true;
  }
  const enbaleWidgetDateFeature = store.getState().reportFormReducer.enbale_widget_date_feature;
  return (
    <div className="widget widget-editor">
      <h3 className="tw-large-header mb-4">{name}</h3>

      <div className="mb-4">
        <label className="tw-label">Label</label>
        <input className="tw-field mw-key-label" value={props.label} onChange={props.updateLabel} />
      </div>

      {dimensionList && (
        <div className="mb-4">
          <label className="tw-label">Dimension</label>
          <Select
            name="dimension"
            className="mw-key-dimension"
            value={props.dimension || "MW--period"}
            options={dimensionList}
            onChange={props.updateDimension}
          />
        </div>
      )}

      {/* Primary metric */}
      <div className="md:flex flex-row">
        <div className="flex-1 md:mr-2 md:w-1/2">
          <label className="tw-label mt-0">Metric</label>
          <Select
            name="metric_name"
            className="mw-key-metric_name"
            value={props.metric_name}
            options={metricsList}
            onChange={props.updateMetric}
          />
        </div>

        <div className="flex-initial md:ml-2 md:w-1/4">
          <label className="tw-label mt-0">Chart Type</label>
          <Select
            name="chart-type"
            value={chartType || "bar"}
            options={[
              { value: "bar", label: "Bar" },
              { value: "line", label: "Line" },
              { value: "area", label: "Area" },
            ]}
            onChange={props.updateChartType}
          />
        </div>
        <div className="md:ml-2 md:w-1/4">
          {dimensionSupportsSecondaryMetric !== false && !props.secondaryMetricEnabled && (
            <ButtonWithIcon
              iconBefore={true}
              onClick={function () {
                props.addSecondaryMetrics();
              }.bind(this)}
              icon="fa-plus"
              text={"Add a secondary metric"}
              extraClasses="md:mt-8"
            />
          )}
        </div>
      </div>
      {/* End of primary metric */}

      {/* Secondary metric */}
      {dimensionSupportsSecondaryMetric !== false && props.secondaryMetricEnabled && (
        <div>
          <div className="md:flex flex-row mt-4">
            <div className="flex-1 md:mr-2 md:w-1/2">
              <label className="tw-label mt-0">Metric</label>
              <Select
                name="secondary_metric_name"
                className="mw-key-secondary_metric_name"
                value={props.secondary_metric_name}
                options={metricsList}
                onChange={props.updateSecondaryMetric}
              />
            </div>

            <div className="flex-initial md:ml-2 md:w-1/4">
              <label className="tw-label mt-0">Chart Type</label>
              <Select
                name="chart-type"
                value={props.secondaryChartType}
                options={[
                  { value: "bar", label: "Bar" },
                  { value: "line", label: "Line" },
                  { value: "area", label: "Area" },
                ]}
                onChange={props.updateSecondaryChartType}
              />
            </div>

            <div className="md:ml-2 md:w-1/4">
              <ButtonWithIcon
                iconBefore={true}
                onClick={function () {
                  props.removeSecondaryMetrics();
                }.bind(this)}
                icon="fa-plus"
                text={"Remove secondary metric"}
                extraClasses="md:mt-8 bg-red-100 text-red-700 hover:bg-red-200 ml-auto"
              />
            </div>
          </div>

          <label className="tw-label mb-4 mt-2 pt-0">
            <input
              type="checkbox"
              className="mr-2 tw-checkbox"
              checked={props.secondaryAxisEnabled}
              onChange={props.updateSecondaryAxisEnabled}
            />
            Use a different axis, and scale for secondary metric.
          </label>
        </div>
      )}
      {/* End of Secondary metric */}

      <div className="mb-4">
        <label className="tw-label">Limit</label>
        <input className="tw-field mw-key-limit" value={props.limit} onChange={props.updateLimit} />
      </div>

      <div className="mb-4">
        <label className="tw-label">Axis</label>
        <Select
          name="axis"
          value={props.indexAxis || "x"}
          options={[
            { value: "x", label: "Vertical (default)" },
            { value: "y", label: "Horizontal" },
          ]}
          onChange={props.updateIndexAxis}
        />
      </div>

      <div className="mb-4"></div>
      {enbaleWidgetDateFeature &&
        <DatePickerComponent
          updateFiltersEnabled={props.updateFiltersEnabled}
          updateDateFilter={props.updateDateFilter}
          selectedDate={store.getState().widgetReducer.editorConfig.date_filter_by_widget}
          lang={props.language}  
      />
      }
      {
        // `props.filtersEnabled` is undefined for older integrations, so we need to check if it's undefined or true
        // And we need to also make sure we have operators and dimensions to filter on. Then and only then we
        // enable the filter stuff.
        (props.filtersEnabled == undefined || props.filtersEnabled === true) &&
          Array.isArray(props.filterOperators) &&
          Array.isArray(filterDimensionList) && (
            <WidgetFilterEditor
              operators={props.filterOperators}
              dimensionList={filterDimensionList}
              hasORenabled={props.filterHasORenabled}
              multilineFilter={multilineFilter}
              shortFilterVariable={shortFilterVariable}
            />
          )
      }
      {props.customAccountSelector}
      <FlashMessage />

      <div className="flex flex-row mt-4">
        <ButtonWithIcon
          onClick={props.saveAndClose}
          text="Save &amp; close"
          icon="fa-check"
          extraClasses="tw-submit-button"
        />
        &nbsp;
        <Button onClick={props.closeWidgetEditModal} text="Close without saving" />
        &nbsp;
        <ButtonWithIcon
          onClick={props.deleteWidget}
          icon="fa-times"
          extraClasses="bg-red-100 text-red-700 hover:bg-red-200 ml-auto"
          text="Delete this widget"
        />
      </div>
    </div>
  );
};

export default BarGraphEditor;
