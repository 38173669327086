import $ from "jquery";

document.addEventListener("DOMContentLoaded", function () {
  window.fbAsyncInit = function () {
    FB.init({
      appId: $("body").data("facebook-app-id"),
      autoLogAppEvents: true,
      xfbml: true,
      version: "v2.10",
    });
    FB.AppEvents.logPageView();
    // Get a new token
    FB.getLoginStatus(function (response) {
      if (response.status === "connected") {
        let request = $.ajax({
          url: "/api/v1/facebook_ads_token",
          method: "PUT",
          data: {
            short_lived_token: response.authResponse.accessToken,
            user_id: response.authResponse.userID,
            authenticity_token: $("meta[name=csrf-token]").attr("content"),
          },
        });
        request.done(function (msg, a, req) {
          // console.log(msg)
        });
        request.fail(function (jqXHR, textStatus) {
          // alert('failed?!?')
        });
      } else {
        // console.log('do nothing...')
      }
    });
  };

  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
});
