import React from "react";
import { SketchPicker } from "react-color";

import ButtonWithIcon from "../../legacy-components/generic/button_with_icon.js";
import { hexColorCodeIsValid } from "../../helpers/color_code_validator";

class ColorInput extends React.Component {
  constructor(props) {
    super(props);

    let valid = hexColorCodeIsValid(this.props.color);
    if (this.props.validWhenEmpty == true) {
      valid = true;
    }

    this.state = {
      displayColorPicker: false,
      styleTop: undefined,
      valid: valid,
    };
  }

  componentDidMount() {
    window.addEventListener("ColorInput--open-color-input", () => {
      this.setState({ displayColorPicker: false });
    });

    if (this.props.scrollableParentID) {
      const scrollableParent = document.getElementById(this.props.scrollableParentID);

      // This is just a hack to prevent errors in specs...I don't have the time to fix it nicely now. TODO: make it React-like, with refs. (FYI: refs were coming in null for some reason, no time to dig)
      if (scrollableParent) {
        this.setState({ scrollableParent: scrollableParent });
        scrollableParent.addEventListener("scroll", () => {
          this.setState({ displayColorPicker: false });
        });
      }
    }
  }

  isValidCode(hexCode) {
    let valid = hexColorCodeIsValid(hexCode);
    if (this.props.validWhenEmpty == true && (hexCode == undefined || hexCode.length == 0)) {
      valid = true;
    }

    if (this.state.valid != valid) {
      this.setState({ valid: valid });
    }
    return valid;
  }

  componentDidUpdate() {
    this.isValidCode(this.props.color);
    this.setPosition();
  }

  onInputChange(e) {
    let color = e.target.value;
    this.props.onChange(color, this.isValidCode(color));
  }

  onPickerChangeComplete(color) {
    this.props.onChange(color.hex, this.isValidCode(color.hex));
    this.togglePickerVisibility();
  }

  togglePickerVisibility() {
    // if it is about to open...
    if (!this.state.displayColorPicker) {
      let openPickerEvent = new Event("ColorInput--open-color-input");
      window.dispatchEvent(openPickerEvent);
    }

    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  setPosition() {
    const margin = 10;

    // by default, we show the color picker right under the color input...
    let position = "bottom";
    let top = undefined;

    if (this.state.displayColorPicker === false && this.state.styleTop) {
      // We reset the top position when hiding, so that we calcualte it again next time (maybe the person scrolled, for example)
      this.setState({ styleTop: undefined });
    }

    if (this.state.displayColorPicker) {
      if (this.state.styleTop === undefined) {
        var bounding = this.refs.colorInputWrapper.getBoundingClientRect();

        if (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        ) {
          // In the viewport, so do not change the position.
        } else {
          // Not in the viewport...so show with 'top' position instead
          position = "top";
        }
      }

      if (position == "bottom") {
        top = this.refs.colorFormItems.offsetTop;
        top = top + this.refs.colorFormItems.clientHeight + margin;

        if (this.state.scrollableParent) {
          top = top - this.state.scrollableParent.scrollTop;
        }

        if (this.state.styleTop === undefined) {
          this.setState({ styleTop: top });
        }
      }

      if (position == "top") {
        top = this.refs.colorFormItems.offsetTop - this.refs.colorInputWrapper.clientHeight - margin;

        if (this.state.scrollableParent) {
          top = top - this.state.scrollableParent.scrollTop;
        }

        if (this.state.styleTop === undefined) {
          this.setState({ styleTop: top });
        }
      }
    }
  }

  render() {
    return (
      <div className="mx-4 mb-4">
        <label ref="colorFormLabel" className="tw-label">
          {this.props.label}
        </label>
        <div ref="colorFormItems" className="">
          <div className="flex flex-row">
            <span
              onClick={this.togglePickerVisibility.bind(this)}
              className="border rounded-l-sm border-gray-200 p-0 m-0"
              style={{
                width: "20px",
                backgroundColor: this.isValidCode(this.props.color) ? this.props.color : "",
              }}
            ></span>
            <input
              style={{ width: "100px" }}
              className={
                "tw-field rounded-none border-r-0 border-l-0 border-gray-200 " + (this.state.valid ? "" : "is-danger")
              }
              type="text"
              onChange={this.onInputChange.bind(this)}
              value={this.props.color}
            />
            <ButtonWithIcon
              onClick={this.togglePickerVisibility.bind(this)}
              icon="fa-palette"
              text=""
              extraClasses="is-primary rounded-l-none border border-blue-200"
            />
          </div>
        </div>

        {this.state.valid == false && (
          <p className="help is-danger">This color code is invalid. It needs to be in the "#012ABC" format.</p>
        )}

        {this.props.description && <p className="mt-2 text-gray-700 text-sm italic">{this.props.description}</p>}

        {this.state.displayColorPicker == true && (
          <div
            ref="colorInputWrapper"
            style={{
              position: "absolute",
              zIndex: 12314,
              top: this.state.styleTop,
            }}
          >
            <SketchPicker color={this.props.color} onChangeComplete={this.onPickerChangeComplete.bind(this)} />
          </div>
        )}
      </div>
    );
  }
}

export default ColorInput;
