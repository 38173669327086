import React from "react";
import ReactDOM from "react-dom";
import Modal from "./components/modal";
import Cookies from "js-cookie";

class AnnouncementModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };
  }

  close() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <Modal
        widthCanBeLarge
        visible={this.state.visible}
        title="Announcement: Meta integrations not listing all of your accounts"
        htmlMessage={
          <span>
            <p className="mt-2">
              February 15th, 2024: Meta integrations (Facebook, Instagram, and Facebook Ads) are having issues listing
              some accounts. This is a bug with their API; we are waiting for a fix. It can take a few days before they
              fix the issue. If an account is missing in your account list, this is why.
            </p>

            <p className="mt-2">Thanks for understanding!</p>
          </span>
        }
        closeCallback={() => this.close()}
      />
    );
  }
}

export default class Announcement {
  constructor() {
    document.addEventListener("DOMContentLoaded", () => {
      this.showModal();
    });
  }

  showModal() {
    const modalContainer = document.getElementById("announcement-modal-container");
    if (modalContainer && Cookies.get("announcement-modal") !== "true") {
      Cookies.set("announcement-modal", "true", { expires: 1 });
      ReactDOM.render(<AnnouncementModal />, modalContainer);
    }
  }
}
