import React from "react";
import { connect } from "react-redux";

import WidgetPreviewFetcher from "../../../helpers/widget_preview/widget_preview_fetcher";
import GenericSingleMetricEditor from "../../../widgets/data/single_metric_editor";
import AccountSelector from "../../../integrations/account_selector";
import MetricFilterer from "../../../helpers/metric_filterer";

class SingleMetricEditor extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    const integration = integrationStore.findIntegrationByOAuthProviderName(this.props.oAuthProviderName);
    const widgetConfig = integration.findIntegrationWidgetConfigByType("singleMetric");
    let metrics = MetricFilterer.byValidDimensions(props.metrics, widgetConfig.valid_dimensions);

    this.state = {
      integration: integration,
      metrics: metrics,
    };
  }

  saveAndClose() {
    this.props.save();

    new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

    this.props.closeWidgetEditModal();
  }

  render() {
    const widgetConfig = this.state.integration.findIntegrationWidgetConfigByType("singleMetric");

    // List of metrics available for this widget
    let availbleMetrics = this.state.metrics;
    if (typeof widgetConfig.valid_dimensions !== "undefined") {
      availbleMetrics = this.state.metrics.filter((metric) =>
        widgetConfig.valid_dimensions.some((dimension) => metric.valid_dimensions.includes(dimension))
      );
    }

    return (
      <GenericSingleMetricEditor
        {...this.props}
        name={this.props.providerName + " Single Metric"}
        metrics={availbleMetrics}
        saveAndClose={this.saveAndClose.bind(this)}
        deleteWidget={this.props.deleteWidget.bind(this)}
        filtersEnabled={widgetConfig.filtersEnabled !== false}
        customAccountSelector={
          <AccountSelector
            disconnectStore
            onChange={this.props.updateCustomAccount}
            value={this.props.custom_account}
            integrationProviderName={this.props.oAuthProviderName}
            accountSelectorLabel="Account (optional)"
            accountListFunction={this.state.integration.accountList}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateCustomAccount: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("custom_account", value, ownProps.widgetIndex));
    },
    updateLabel: (event) => {
      dispatch(updateEditorWidgetConfig("label", event.target.value, ownProps.widgetIndex));
    },
    updateMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label.split(" -- ")[0]
      }

      dispatch(updateEditorWidgetConfig("metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("label", label, ownProps.widgetIndex));
    },
    updateDateFilter: (ranges, isEnabledDateFilter) => {
      if(isEnabledDateFilter){
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, ownProps.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, ownProps.widgetIndex));
      }
      
    },
    updateFiltersEnabled: (checked) => {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, ownProps.widgetIndex));
    },
    save: () => {
      dispatch(saveWidgetConfigFromEditor(ownProps.widgetIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleMetricEditor);
