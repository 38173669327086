// Calculate shades for the charts & pies
function generateAdjustedShades(hexColor, numberOfShades) {
  // Convert hex to RGB
  let r = parseInt(hexColor.substr(1, 2), 16);
  let g = parseInt(hexColor.substr(3, 2), 16);
  let b = parseInt(hexColor.substr(5, 2), 16);

  // Convert RGB to HSL
  (r /= 255), (g /= 255), (b /= 255);
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  // Adjust base lightness upwards to avoid too dark shades
  l = Math.min(l + 0.2, 0.8); // Increase base lightness, cap to avoid too light

  // Generate shades with adjusted lightness range
  let shades = [];
  for (let i = 0; i < numberOfShades; i++) {
    // Calculate lightness for shades, ensuring a broad but lighter range
    const adjustedLightness = Math.max(Math.min(l + (i - numberOfShades / 2) * (0.8 / numberOfShades), 1), 0);
    shades.push(hslToHex(h, s, adjustedLightness));
  }

  return shades;
}

function hslToHex(h, s, l) {
  let r, g, b;
  const hue2rgb = (p, q, t) => {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };
  const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  const p = 2 * l - q;
  r = hue2rgb(p, q, h + 1 / 3);
  g = hue2rgb(p, q, h);
  b = hue2rgb(p, q, h - 1 / 3);
  return `#${Math.round(r * 255)
    .toString(16)
    .padStart(2, "0")}${Math.round(g * 255)
    .toString(16)
    .padStart(2, "0")}${Math.round(b * 255)
    .toString(16)
    .padStart(2, "0")}`;
}

// Calculate text color for the pie parts
function getTextColorForBackground(hexColor) {
  // Convert hex to RGB
  let r = parseInt(hexColor.substr(1, 2), 16);
  let g = parseInt(hexColor.substr(3, 2), 16);
  let b = parseInt(hexColor.substr(5, 2), 16);

  // Convert RGB to HSL
  (r /= 255), (g /= 255), (b /= 255);
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let l = (max + min) / 2;

  // Choose text color based on lightness
  return l > 0.5 ? "#000000" : "#FFFFFF";
}

// Generate the theme from the 3 colors.
const ThemeGenerator = (color1, color2, color3) => {
  const shades = generateAdjustedShades(color1, 8);
  const pieTextColor = getTextColorForBackground(color1);

  return {
    baseColors: {
      color1: color1,
      color2: color2,
      color3: color3,
    },
    global: {
      backgroundColor: color1,
      text: {
        color: color2,
      },
    },
    header: {
      slim: false,
      hideLogo: false,
      hideSiteName: false,
      backgroundColor: color1,
      text: {
        color: color2,
      },
    },
    footer: {
      divider: {
        color: color2,
      },
      backgroundColor: color1,
      text: {
        color: color2,
      },
    },
    news: {
      backgroundColor: color1,
      text: {
        color: color2,
      },
    },
    widgets: {
      global: {
        backgroundColor: color1,
      },
      singleMetrics: {
        positiveTextColor: color3,
        positiveBackgroundColor: color2,
        negativeTextColor: color3,
        negativeBackgroundColor: color2,
        neutralTextColor: color3,
        neutralBackgroundColor: color2,
      },
      list: {
        backgroundColor: color2,
        title: {
          color: color3,
        },
        text: {
          color: color3,
        },
      },
      graphBox: {
        backgroundColor: color2,
        title: {
          color: color3,
        },
        graphTheme: {
          colors: {
            color_1: shades[0],
            color_2: shades[1],
            color_3: shades[2],
            color_4: shades[3],
            color_5: shades[4],
            color_6: shades[5],
            color_7: shades[6],
          },
          markerColor: color3,
          pieMarkerColor: pieTextColor,
          fontColor: color3,
        },
      },
      layout: {
        title: {
          color: color2,
        },
        text: {
          color: color2,
        },
        separator: {
          color: color2,
        },
      },
    },
  };
};

export default ThemeGenerator;
