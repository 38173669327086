import React from "react";
import { connect } from "react-redux";
import GenericPieChartEditor from "../../../widgets/data/pie_chart_editor";
import FilterValidation from "../../../helpers/filter_validation";
import AccountSelector from "../../../integrations/account_selector";
import MetricFilterer from "../../../helpers/metric_filterer";
import { DimensionFilterer } from "../../../helpers/metric_filterer";

import WidgetPreviewFetcher from "../../../helpers/widget_preview/widget_preview_fetcher";

class PieChartEditor extends React.Component {
  constructor(props) {
    super(props);

    const integration = integrationStore.findIntegrationByOAuthProviderName(this.props.oAuthProviderName);
    const widgetConfig = integration.findIntegrationWidgetConfigByType("pieAndDoughnut");
    let metrics = MetricFilterer.byValidDimensions(props.metrics, widgetConfig.valid_dimensions);
    let dimensions = DimensionFilterer.byValidDimensions(props.dimensions, widgetConfig.valid_dimensions);

    this.state = {
      integration: integration,
      metrics: metrics,
      dimensions: dimensions,
    };
  }

  saveAndClose() {
    let errors = [];
    const filterParamNames = {
      dimension: "dimension",
      operator: "operator",
      value: "value",
    };

    if (!this.props.metric_name) {
      errors.push("Select the metric you want");
    }

    if (!this.props.dimension) {
      errors.push("Select the dimension you want");
    }

    if (!FilterValidation.validate(this.props.filters, filterParamNames)) {
      errors.push("At least one filter has an unspecified value");
    }

    if (errors.length > 0) {
      store.dispatch(setFlashMessage("We could not save the widget:", errors));
      return;
    }

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    store.dispatch(saveWidgetConfigFromEditor(this.props.widgetIndex));

    new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

    this.close();
  }

  close() {
    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    this.props.closeWidgetEditModal();
  }

  render() {
    const widgetConfig = this.state.integration.findIntegrationWidgetConfigByType("pieAndDoughnut");

    // List of metrics available for this widget
    let metricsList = this.state.metrics.filter(
      (metric) => metric.valid_dimensions == undefined || metric.valid_dimensions.includes(this.props.dimension)
    );

    // Keep only the dimensions we can actually filter on
    let availableFilterDimension = this.props.filterDimensionList;
    if (widgetConfig.filterByCurrentDimensionOnly === true) {
      availableFilterDimension = this.props.filterDimensionList.filter(
        (dimension) => dimension.value === this.props.dimension
      );
    }

    return (
      <GenericPieChartEditor
        {...this.props}
        name={this.props.providerName + " Pie & Doughnut"}
        dimensions={this.state.dimensions}
        metricsList={metricsList}
        filterDimensionList={availableFilterDimension}
        filterHasORenabled={true}
        shortFilterVariable={true}
        saveAndClose={this.saveAndClose.bind(this)}
        closeWidgetEditModal={this.close.bind(this)}
        deleteWidget={this.props.deleteWidget.bind(this)}
        filtersEnabled={widgetConfig.filtersEnabled !== false}
        customAccountSelector={
          <AccountSelector
            disconnectStore
            onChange={this.props.updateCustomAccount}
            value={this.props.custom_account}
            integrationProviderName={this.props.oAuthProviderName}
            accountSelectorLabel="Account (optional)"
            accountListFunction={this.state.integration.accountList}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateCustomAccount: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("custom_account", value, ownProps.widgetIndex));
    },
    updateLabel: (e) => {
      dispatch(updateEditorWidgetConfig("label", e.target.value, ownProps.widgetIndex));
    },
    updateDimension: (e) => {
      let value;
      if (e) {
        value = e.value;
      }

      dispatch(updateEditorWidgetConfig("dimension", value, ownProps.widgetIndex));

      // We need to reset the filter dimensions if dimension change AND that we can only filter on current dimension
      const integration = integrationStore.findIntegrationByOAuthProviderName(ownProps.oAuthProviderName);
      const widgetConfig = integration.findIntegrationWidgetConfigByType("List");
      if (widgetConfig.filterByCurrentDimensionOnly) {
        let state = store.getState().widgetReducer.editorConfig;
        let newFilters = state.filters.map((filter) => {
          if (filter.dimension) {
            filter.dimension = undefined;
          }
          if (filter.selectedDimension) {
            filter.selectedDimension = undefined;
          }
          return filter;
        });

        dispatch(updateEditorWidgetConfig("filters", newFilters, ownProps.widgetIndex));
      }
    },
    updateMetric: (e) => {
      dispatch(updateEditorWidgetConfig("metric_name", e.value, ownProps.widgetIndex));
    },
    updateLimit: (e) => {
      dispatch(updateEditorWidgetConfig("limit", e.target.value, ownProps.widgetIndex));
    },
    updateChartType: (e) => {
      dispatch(updateEditorWidgetConfig("chartType", e.value, ownProps.widgetIndex));
    },
      updateDateFilter: (ranges, isEnabledDateFilter) => {
      if(isEnabledDateFilter){
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, ownProps.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, ownProps.widgetIndex));
      }
      
    },
    updateFiltersEnabled: (checked) => {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, ownProps.widgetIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieChartEditor);
