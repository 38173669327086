import React from "react";
var createReactClass = require("create-react-class");
import $ from "jquery";

const ReportSiteName = createReactClass({
  getInitialState: function () {
    return { value: undefined };
  },

  onChange: function (event) {
    var reportName = event.target.value;
    store.dispatch(setReportName(reportName));
  },

  componentDidMount: function () {
    if (store.getState().reportFormReducer.name) {
      this.setState({ value: store.getState().reportFormReducer.name });
    }

    this.storeSubscription = store.subscribe(
      function () {
        this.setState({ value: store.getState().reportFormReducer.name });
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    return (
      <div className="field mw-report-site-name mb-4">
        <label className="tw-label">Site Name</label>
        <input
          ref="input"
          className="tw-field"
          tabIndex={tabIndex}
          placeholder="Example.com"
          type="text"
          onChange={this.onChange}
          value={this.state.value || ""}
        />
      </div>
    );
  },
});

export default ReportSiteName;
