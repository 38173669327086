import React from "react";
import { connect } from "react-redux";
import Button from "../../../legacy-components/generic/button.js.jsx";
import ButtonWithIcon from "../../../legacy-components/generic/button_with_icon.js";

class LargeTextBoxEditor extends React.Component {
  constructor(props) {
    super(props);
  }

  saveAndClose() {
    this.props.save();
    this.props.closeWidgetEditModal();
  }

  render() {
    return (
      <div className="widget widget-editor">
        <h3 className="tw-large-header mb-4">Layout / Plain Text &amp; HTML</h3>
        <div className="mb-4">
          <label className="tw-label">Text</label>
          <textarea ref="text" className="tw-field" value={this.props.text} onChange={this.props.updateText} />
        </div>
        <div className="flex flex-row mt-4">
          <ButtonWithIcon
            onClick={this.saveAndClose.bind(this)}
            text="Save &amp; close"
            icon="fa-check"
            extraClasses="tw-submit-button"
          />
          &nbsp;
          <Button onClick={this.props.closeWidgetEditModal} text="Close without saving" />
          &nbsp;
          <ButtonWithIcon
            onClick={this.props.deleteWidget.bind(this)}
            icon="fa-times"
            extraClasses="bg-red-100 text-red-700 hover:bg-red-200 ml-auto"
            text="Delete this widget"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateText: (event) => {
      dispatch(updateEditorWidgetConfig("text", event.target.value, ownProps.widgetIndex));
    },

    save: () => {
      dispatch(saveWidgetConfigFromEditor(ownProps.widgetIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LargeTextBoxEditor);
