import React from "react";
import WidgetBase from "../widget_base";

export default class PDFPageBreak extends WidgetBase {
  constructor() {
    super();
  }

  name() {
    return "Layout -- PDF Page Break";
  }

  type() {
    return "layout-pdf-page-break";
  }

  width() {
    return "large";
  }

  height() {
    return 1;
  }

  config(chartType) {
    return {
      widgetType: this.type(),
      widgetName: this.name(),
    };
  }

  duplicateWidget(widgetId) {
    store.dispatch(duplicateWidgetOnCanvas(widgetId));
  }

  renderInGrid(widgetLayout, showWidgetEditModal, deleteSelectedWidget) {
    return (
      <div
        className="canvas-grid-item border border-dashed border-gray-300 hover:border-gray-500 flex flex-column rounded px-4"
        id={widgetLayout.i}
        key={widgetLayout.i}
        data-grid={widgetLayout}
      >
        <div className="text-center">
          <p className="mt-6 text-sm text-gray-500 italic">
            NOTE: This will not show in your email reports, but will add a page break when exported to PDF.
          </p>
        </div>
        <div className="canvas-widget-edit-save">
          <a
            onClick={function () {
              return this.duplicateWidget(widgetLayout.i);
            }.bind(this)}
            className=""
          >
            <i className="fas fa-copy" />
          </a>
          <a
            onClick={function () {
              return deleteSelectedWidget(widgetLayout.i);
            }.bind(this)}
          >
            <i className="fas fa-times" />
          </a>
        </div>
      </div>
    );
  }
}
