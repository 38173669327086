import IntegrationBase from "../integration_base";
import IntegrationIcon from "./logo/logo.png";

export default class FacebookAds extends IntegrationBase {
  // This configures some autoloading
  integrationConfig() {
    return {
      // Metrics Watch Integration Protcol Version.
      metricsWatchIntegrationProtocolVersion: "3.0.0",

      // This is enabling the use of generic widgets, instead of integration-specific widgets like we used to do.
      autoRegisterWidgets: { enabled: true },

      // This is enabling the autoloading of metrics. When enabled, we need a to set metricLoaderCallback to handle the API response and format the metrics.
      autoloadMetrics: { enabled: true, metricLoaderCallback: this.formatMetrics.bind(this) },

      // This is enabling the autoloading of metrics. When enabled, we need a to set dimensionLoaderCallback to handle the API response and format the metrics.
      autoloadDimensions: { enabled: true, dimensionLoaderCallback: this.formatDimensions.bind(this) },

      // This is enabling the use of the generic account selector, instead of an integration-specific one.
      accountSelector: {
        enabled: true,
        databaseProviderType: "metrics_providers-facebooks",
        accountLoaderCallback: this.formatAccounts.bind(this),
      },

      metrics: () => {
        return this.metrics;
      },
      dimensions: () => {
        return this.dimensions;
      },
      filterDimensionList: () => {
        return this.dimensions;
      },
      filterOperators: [
        { value: "EQUAL", label: "equal" },
        { value: "NOT_EQUAL", label: "do NOT equal" },
        { value: "CONTAIN", label: "contains" },
        { value: "NOT_CONTAIN", label: "does NOT contain" },
        { value: "STARTS_WITH", label: "starts with" },
      ],
      filterHasORenabled: false,
    };
  }

  formatAccounts(accounts) {
    let options = [];
    for (let index = 0; index < accounts.length; index++) {
      const integrationAccount = accounts[index];
      let providerAccounts = integrationAccount.attributes.provider_accounts;
      if (
        integrationAccount.type == this.integrationConfig().accountSelector.databaseProviderType &&
        providerAccounts
      ) {
        for (let j = 0; j < providerAccounts.length; j++) {
          let customMetrics = [];

          let customConversions = providerAccounts[j].custom_conversions;

          for (var i = 0; i < customConversions.length; i++) {
            customMetrics.push({
              value: "MW-custom-conversion-" + customConversions[i].id,
              label: customConversions[i].name,
            });
          }

          options.push({
            value: providerAccounts[j].id,
            label: providerAccounts[j].name,
            customMetrics: customMetrics,
          });
        }
      }
    }

    store.dispatch(setIntegrationAccountList(this.oAuthProviderName(), options));
  }

  name() {
    return "Facebook & Instagram Ads";
  }

  iconURL() {
    return IntegrationIcon;
  }

  oAuthProviderName() {
    return "facebookads";
  }

  integrationFor() {
    return ["report"];
  }

  widgets() {
    return {
      integration: this,
      singleMetric: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        filtersViaAPI: true, // The default is undefined or true, and it's when we use the API to filter. THe alternative is filterint on our end with the data we have.
        widgetName: "FB Ads Single Metric",
        widgetType: "fb-ads-single-metric",
        defaultLabel: "CPC (FB ADS)",
        defaultMetricName: "cpc",
        // valid_dimensions: ["dimension for which we should metrics here"], // This is optional. Needs to be an array. If it is not provided, all metrics will be available.
      },
      list: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        filtersViaAPI: true, // The default is undefined or true, and it's when we use the API to filter. THe alternative is filterint on our end with the data we have.
        sortEnabled: false, // if we want to to overwrite the sorting optins we need to set `this.sortByOptions` in this class (see how it's down in the `IntegrationBase` class)
        // sortByOptions: [
        //   // this is to overwrite the defaults for sort options
        //   { label: "Publication date", value: "timestamp" },
        //   { label: "Dimension", value: "dimension" },
        //   { label: "Metric #1", value: "metric[0]" },
        //   { label: "Metric #2", value: "metric[1]" },
        //   { label: "Metric #3", value: "metric[2]" },
        // ],
        widgetName: "FB Ads List",
        widgetType: "fb-ads-list",
        defaultLabel: "CPC per campaign",
        defaultDimensionName: "campaign",
        defaultDimensionLabel: "Campaign",
        defaultMetricName: "cpc",
        defaultMetricLabel: "CPC",
        // noSecondaryMetric: [],
        // valid_dimensions: ["dimension for which we should metrics here"], // This is optional. Needs to be an array. If it is not provided, all metrics will be available.
      },
      pieAndDoughnut: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        filtersViaAPI: true, // The default is undefined or true, and it's when we use the API to filter. THe alternative is filterint on our end with the data we have.
        // filterByCurrentDimensionOnly: true,
        widgetName: "FB Ads Pie Chart",
        widgetType: "fb-ads-pie-chart",
        defaultLabel: "Clicks per country (Facebook Ads)",
        defaultDimensionName: "country",
        // defaultDimensionLabel: "Browser",
        defaultMetricName: "clicks",
        // defaultMetricLabel: "Sessions",
        // valid_dimensions: ["dimension for which we should metrics here"], // This is optional. Needs to be an array. If it is not provided, all metrics will be available.
      },
      mixedChart: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        filtersViaAPI: true, // The default is undefined or true, and it's when we use the API to filter. THe alternative is filterint on our end with the data we have.
        // filterByCurrentDimensionOnly: true,
        // filterOnPeriodEnabled: true, // default: true
        isPeriodDimensionFilterable: true,
        widgetName: "FB Ads Bar Graph",
        widgetType: "fb-ads-bar-graph",
        defaultLabel: "Clicks (Facebook Ads)",
        defaultDimensionName: "MW--period",
        defaultMetricName: "clicks",
        defaultMetricLabel: "Clicks",
        // valid_dimensions: ["dimension for which we should metrics here"], // This is optional. Needs to be an array. If it is not provided, all metrics will be available.
        // valid_dimensions__for_period: ["dimension for MW--period metrics"], // what is the dimension for Single Metrics? Put it here. Supports multiple dimensions here
      },
    };
  }
}
