import React from "react";
import { connect } from "react-redux";
import GenericBarGraphEditor from "../../../widgets/data/bar_graph_editor";
import FilterValidation from "../../../helpers/filter_validation";
import AccountSelector from "../../../integrations/account_selector";
import MetricFilterer from "../../../helpers/metric_filterer";
import { DimensionFilterer } from "../../../helpers/metric_filterer";

import WidgetPreviewFetcher from "../../../helpers/widget_preview/widget_preview_fetcher";

let activeFilters = [];

class MixedChartEditor extends React.Component {
  constructor(props) {
    super(props);

    const integration = integrationStore.findIntegrationByOAuthProviderName(this.props.oAuthProviderName);
    const widgetConfig = integration.findIntegrationWidgetConfigByType("mixedChart");
    let dimensions = DimensionFilterer.byValidDimensions(props.dimensions, widgetConfig.valid_dimensions);

    this.state = {
      integration: integration,
      metrics: props.metrics,
      dimensions: dimensions,
    };
  }

  saveAndClose() {
    let errors = [];
    const filterParamNames = {
      dimension: "dimension",
      operator: "operator",
      value: "value",
    };

    if (!this.props.metric_name) {
      errors.push("Select the metric you want");
    }

    if (this.props.secondaryMetricEnabled && (!this.props.secondary_metric_name || !this.props.secondaryChartType)) {
      errors.push("Secondary metric details are missing");
    }

    if (!FilterValidation.validate(this.props.filters, filterParamNames)) {
      errors.push("At least one filter has an unspecified value");
    }

    if (errors.length > 0) {
      store.dispatch(setFlashMessage("We could not save the widget:", errors));
      return;
    }

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    store.dispatch(saveWidgetConfigFromEditor(this.props.widgetIndex));

    this.close();
  }

  close() {
    new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    this.props.closeWidgetEditModal();
  }

  render() {
    const widgetConfig = this.state.integration.findIntegrationWidgetConfigByType("mixedChart");

    // Keep only the metrics for the currently selected dimension
    let metricsList = [];
    if (this.props.dimension === undefined || this.props.dimension == "MW--period") {
      // If the currently selected dimension is "Period", we use a different array of valid dimensions to filter metrics
      metricsList = MetricFilterer.byValidDimensions(this.state.metrics, widgetConfig.valid_dimensions__for_period);
    } else {
      metricsList = MetricFilterer.byValidDimensions(this.state.metrics, [this.props.dimension]);
    }

    // Filter list
    let filtersList = this.props.filterDimensionList;

    if (!this.props.isPeriodDimensionFilterable) {
      let dimension = this.props.dimension || "MW--period";
      if (dimension.includes("MW--period")) {
        filtersList = null;
      }
    }

    // Keep only the dimensions we can actually filter on
    if (widgetConfig.filterByCurrentDimensionOnly === true) {
      filtersList = this.props.filterDimensionList.filter((dimension) => dimension.value === this.props.dimension);
    }

    activeFilters = this.props.filters;

    return (
      <GenericBarGraphEditor
        {...this.props}
        name={this.props.providerName + " Mixed Chart"}
        dimensions={this.state.dimensions}
        metricsList={metricsList}
        filterDimensionList={filtersList}
        shortFilterVariable={true}
        saveAndClose={this.saveAndClose.bind(this)}
        closeWidgetEditModal={this.close.bind(this)}
        deleteWidget={this.props.deleteWidget.bind(this)}
        filtersEnabled={widgetConfig.filtersEnabled !== false}
        customAccountSelector={
          <AccountSelector
            disconnectStore
            onChange={this.props.updateCustomAccount}
            value={this.props.custom_account}
            integrationProviderName={this.props.oAuthProviderName}
            accountSelectorLabel="Account (optional)"
            accountListFunction={this.state.integration.accountList}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateCustomAccount: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("custom_account", value, ownProps.widgetIndex));
    },
    updateLabel: (e) => {
      dispatch(updateEditorWidgetConfig("label", e.target.value, ownProps.widgetIndex));
    },
    updateMetric: (selected) => {
      let value;
      if (selected) {
        value = selected.value;
      }

      dispatch(updateEditorWidgetConfig("metric_name", value, ownProps.widgetIndex));
    },
    updateDimension: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label;
      }

      if (!ownProps.isPeriodDimensionFilterable && value === "MW--period") {
        if (activeFilters.length > 0) {
          dispatch(
            setFlashMessage("This dimension is not filtrable:", [
              "Please delete your filters before changing the dimension.",
            ])
          );
          return;
        }
      }

      let previousDimension = store.getState().widgetReducer.editorConfig.dimension;
      if (previousDimension != value) {
        // We need to reset the filter dimensions if dimension change AND that we can only filter on current dimension
        const integration = integrationStore.findIntegrationByOAuthProviderName(ownProps.oAuthProviderName);
        const widgetConfig = integration.findIntegrationWidgetConfigByType("List");
        if (widgetConfig.filterByCurrentDimensionOnly) {
          let state = store.getState().widgetReducer.editorConfig;
          let newFilters = state.filters.map((filter) => {
            if (filter.dimension) {
              filter.dimension = undefined;
            }
            if (filter.selectedDimension) {
              filter.selectedDimension = undefined;
            }
            return filter;
          });

          dispatch(updateEditorWidgetConfig("filters", newFilters, ownProps.widgetIndex));
        }
      }

      dispatch(updateEditorWidgetConfig("dimension", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("metric_name", undefined, ownProps.widgetIndex));
    },
    updateIndexAxis: (e) => {
      dispatch(updateEditorWidgetConfig("indexAxis", e.value, ownProps.widgetIndex));
    },
    updateLimit: (e) => {
      dispatch(updateEditorWidgetConfig("limit", e.target.value, ownProps.widgetIndex));
    },
    updateChartType: (e) => {
      dispatch(updateEditorWidgetConfig("chartType", e.value, ownProps.widgetIndex));
    },

    updateSecondaryMetric: (e) => {
      dispatch(updateEditorWidgetConfig("secondary_metric_name", e.value, ownProps.widgetIndex));
    },
    updateSecondaryChartType: (e) => {
      dispatch(updateEditorWidgetConfig("secondaryChartType", e.value, ownProps.widgetIndex));
    },
    updateSecondaryAxisEnabled: (e) => {
      dispatch(updateEditorWidgetConfig("secondaryAxisEnabled", e.target.checked, ownProps.widgetIndex));
    },
    updateDateFilter: (ranges, isEnabledDateFilter) => {
      if(isEnabledDateFilter){
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, ownProps.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, ownProps.widgetIndex));
      }
      
    },
    updateFiltersEnabled: (checked) => {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, ownProps.widgetIndex));
    },
    addSecondaryMetrics: (e) => {
      dispatch(updateEditorWidgetConfig("secondaryMetricEnabled", true, ownProps.widgetIndex));
    },
    removeSecondaryMetrics: (e) => {
      dispatch(updateEditorWidgetConfig("secondaryMetricEnabled", false, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("secondary_metric_name", undefined, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("secondaryChartType", undefined, ownProps.widgetIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MixedChartEditor);
