import React from "react";
var createReactClass = require("create-react-class");
import $ from "jquery";
import "./../helpers/string/capitalize";

const ReportEmailSubject = createReactClass({
  getInitialState: function () {
    return { value: undefined };
  },

  onChange: function (event) {
    var emailTitle = event.target.value;
    var actionCreatorName =
      "setReportSubject" + this.props.interval.charAt(0).toUpperCase() + this.props.interval.slice(1);
    store.dispatch(window[actionCreatorName](emailTitle));
  },

  componentDidMount: function () {
    this.storeSubscription = store.subscribe(
      function () {
        this.setState({ value: store.getState().reportFormReducer["subject_" + this.props.interval] });
      }.bind(this)
    );
  },

  componentWillUnmount: function () {
    this.storeSubscription();
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    return (
      <div className="field mw-report-email-subject">
        <label className="tw-label">Email subject</label>
        <input
          ref="input"
          className="tw-field mb-2"
          tabIndex={tabIndex}
          placeholder="Report for Example.com"
          type="text"
          onChange={this.onChange}
          value={this.state.value || ""}
        />
      </div>
    );
  },
});

export default ReportEmailSubject;
