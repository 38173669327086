import React from "react";
import { connect } from "react-redux";

import GenericSingleMetricEditor from "../../../../widgets/data/single_metric_editor";
import FilterValidation from "../../../../helpers/filter_validation";
import WidgetPreviewFetcher from "../../../../helpers/widget_preview/widget_preview_fetcher";
import AccountSelector from "../../account_selector";

const filterDimensionList = [
  { label: "Campaign Name", value: "campaign" },
  { label: "Campaign Group Name", value: "campaign_group" },
  // { label: "Creative", value: "creative" } // Too complex for the very rare use case it'll be. Skipping for now
];

class SingleMetricEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      metrics: [],
    };
  }

  componentDidMount() {
    const metrics = store.getState().widgetReducer.linkedInMetricsForCanvas;

    this.setState({ metrics: metrics });
  }

  saveAndClose() {
    let errors = [];

    if (!this.props.metric_name) {
      errors.push("Select the metric you want");
    }

    if (!FilterValidation.validate(this.props.filters)) {
      errors.push("At least one filter has an unspecified value");
    }

    if (errors.length > 0) {
      store.dispatch(setFlashMessage("We could not save the widget:", errors));
      return;
    }

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    this.props.save();

    this.close();
  }

  close() {
    new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    this.props.closeWidgetEditModal();
  }

  render() {
    return (
      <GenericSingleMetricEditor
        {...this.props}
        name="LinkedIn Ads Single Metric Widget"
        metrics={this.state.metrics}
        filterOperators={[
          { value: "equals", label: "equal" },
          { value: "not_equals", label: "do NOT equal" },
          { value: "contains", label: "contains" },
          { value: "not_contains", label: "does NOT contain" },
        ]}
        filterDimensionList={filterDimensionList}
        filterHasORenabled={false}
        shortFilterVariable={false}
        saveAndClose={this.saveAndClose.bind(this)}
        closeWidgetEditModal={this.close.bind(this)}
        deleteWidget={this.props.deleteWidget.bind(this)}
        customAccountSelector={
          <AccountSelector
            disconnectStore
            onChange={this.props.updateCustomAccount}
            value={this.props.custom_account}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateCustomAccount: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("custom_account", value, ownProps.widgetIndex));
    },
    updateLabel: (event) => {
      dispatch(updateEditorWidgetConfig("label", event.target.value, ownProps.widgetIndex));
    },
    updateMetric: (selected) => {
      let value;
      if (selected) {
        value = selected.value;
      }

      dispatch(updateEditorWidgetConfig("metric_name", value, ownProps.widgetIndex));

      if (value) {
        let labelForDisplay = selected.label.split(" -- ")[0];
        dispatch(updateEditorWidgetConfig("label", labelForDisplay, ownProps.widgetIndex));
      }
    },
    updateDateFilter: (ranges, isEnabledDateFilter) => {
      if(isEnabledDateFilter){
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, ownProps.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, ownProps.widgetIndex));
      }
      
    },
    updateFiltersEnabled: (checked) => {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, ownProps.widgetIndex));
    },

    save: () => {
      dispatch(saveWidgetConfigFromEditor(ownProps.widgetIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleMetricEditor);
