import { validate_date_range_per_integration } from "../integration_date_validation";
import IntegrationAccountsPreparator from "./integration_accounts_preparator";
import { appsignal } from "../../appsignal";

import axios from "axios";
export default class ReportPreviewFetcher {
  fetch() {
    const reportID = store.getState().reportFormReducer.reportID;
    const url = `/api/v1/reports/${reportID}/refresh_report/${reportID}`;

    const widgetsConfigs = store.getState().widgetReducer.widgetConfigs;

    let error = [];

    Object.keys(widgetsConfigs).forEach((key) => {
      widgetsConfigs[key].error = validate_date_range_per_integration(widgetsConfigs[key].name);
    });

    axios
      .post(url, {
        error: error,
        widgets_configs: widgetsConfigs,
        integration_accounts: new IntegrationAccountsPreparator().prepare(),
      })
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        Bugsnag.notify(error);
        appsignal.sendError(error);
      });
  }
}
