import React from "react";
var createReactClass = require("create-react-class");
import Select from "react-select";
import $ from "jquery";
import APIError from "../../helpers/api_error";
import { reportFormValidation } from "../../helpers/report_form_validation";
import { isEmpty } from "lodash";
import CommonAccountSelection from "../../legacy-components/common_account_selection";

const LinkedInPagesAccountSelector = createReactClass({
  apiURL: function () {
    return "/api/v1/provider_accounts";
  },

  onChange: function (data) {
    let val = undefined;
    if (data) {
      val = data.value;
    }

    this.setState({ linkedInAccount: val });

    if (this.props.disconnectStore) {
      this.props.onChange(data);
    } else {
      store.dispatch(setLinkedInPagesAccount(val));
    }
  },

  getInitialState: function () {
    return {
      linkedInAccount: undefined,
      modalVisible: false,
    };
  },

  componentDidMount: function () {
    this.loadLinkedInPagesAccounts();
    if (this.props.disconnectStore && this.props.value) {
      this.setState({ linkedInAccount: this.props.value });
    } else {
      this.storeSubscription = store.subscribe(
        function () {
          let newValue = store.getState().reportFormReducer.linkedin_pages_account_id;
          if (this.state.linkedInAccount !== newValue) {
            this.setState({ linkedInAccount: newValue });
          }
        }.bind(this)
      );
    }
  },

  componentWillUnmount: function () {
    if (!this.props.disconnectStore) {
      this.storeSubscription();
    }
  },

  componentWillReceiveProps: function (nextProps) {
    if (this.props.disconnectStore && (nextProps.value !== null && nextProps.value !== undefined)) {
      this.setState({ linkedInAccount: nextProps.value });
    }

    const integrationsAccountLists = store.getState().integrationsReducer.integrationsAccountLists;
    const accountList = integrationsAccountLists["linkedin-pages"];
    if (!isEmpty(accountList) && (this.state.linkedInAccount === undefined || this.state.linkedInAccount === null)) {
      this.setState({ linkedInAccount: accountList[0].value });
      store.dispatch(setLinkedInPagesAccount(accountList[0].value));
    }
  },

  loadLinkedInPagesAccounts: function () {
    $.ajax({
      url: this.apiURL(),
      dataType: "json",
      cache: false,
      success: function (data) {
        this.prepareOptions(data);
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("LinkedInPagesAccounts", xhr, status, err);
      }.bind(this),
    });
  },

  prepareOptions: function (data) {
    var options = [];
    for (var i = 0; i < data.data.length; i++) {
      var provider = data.data[i];
      if (
        provider.type == "metrics_providers-linked_in_pages" &&
        Array.isArray(provider.attributes.provider_accounts)
      ) {
        for (var j = 0; j < provider.attributes.provider_accounts.length; j++) {
          var acc = provider.attributes.provider_accounts[j];
          let name = acc.name;
          let accouuntId = String(acc.id);
          if (name == null) {
            name = `(No name available for account ${accouuntId} - you probably have insuffice access rights)`;
          }

          options.push({ value: accouuntId, label: name });
        }
      }
    }
    if (options.length > 0) {
      this.setState({ linkedInAccount: options[0].value });
      store.dispatch(setLinkedInPagesAccount(options[0].value));
    }
    store.dispatch(setIntegrationAccountList("linkedin-pages", options));
  },
  handleIntegrationButton: function () {
    let integrations = window.integrationStore.findIntegrationByOAuthProviderName("linkedin-pages");
    integrations.oAuthProviderPreRedirectNotice();
    window.location = integrations.oAuthProviderRedirectPath();
  },
  render: function () {
    var tabIndex = this.props.tabIndex || "0";
    let options = store.getState().integrationsReducer.integrationsAccountLists["linkedin-pages"];
    let display = "none";
    if (reportFormValidation.requiredIntegrations().includes("linkedin-pages")) {
      display = "block";
    }

    return (
      <div className="field" style={{ display: display }}>
        <CommonAccountSelection
          label="LinkedIn Pages account"
          disconnectStore={this.props.disconnectStore}
          tabIndex={tabIndex}
          accountSelected={this.onChange}
          value={this.state.linkedInAccount}
          options={options}
          handleIntegrationButton={this.handleIntegrationButton}
        />
      </div>
    );
  },
});

export default LinkedInPagesAccountSelector;
