import IntegrationBase from "../integration_base";
import IntegrationIcon from "./logo/logo_lockup_analytics_icon_vertical_black_2x.png";
import SingleMetric from "./widgets/single_metric/single_metric";
import SingleAdwordsMetric from "./widgets/single_metric/single_adwords_metric";
import List from "./widgets/list/list";
import BarGraph from "./widgets/bar_graph/bar_graph";
import PieChart from "./widgets/pie_chart/pie_chart";
import AllGoals from "./widgets/all_goals/all_goals";

export default class GoogleAnalytics extends IntegrationBase {
  constructor() {
    super();
    this.registerWidget(new SingleMetric());
    this.registerWidget(new SingleAdwordsMetric());
    this.registerWidget(new List());
    this.registerWidget(new BarGraph());
    this.registerWidget(new PieChart());
    this.registerWidget(new AllGoals());
  }

  name() {
    return "Google Analytics";
  }

  iconURL() {
    return IntegrationIcon;
  }

  oAuthProviderName() {
    return "google";
  }

  reducerAccountIDVariableName() {
    return "google_analytics_profile_id";
  }

  reportStoreDispatchFunction() {
    return selectGoogleAnalyticsProfile;
  }

  integrationFor() {
    return ["alert", "report"];
  }
}
