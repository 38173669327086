import React from "react";
import { getRandomInt } from "../../helpers/utils";
import WidgetButtonIcon from "../../components/reports/widget-button-icon";
import { getNewWidgetLayoutFor, getNewWidgetConfigFor } from "../../reducers/widgets_";

const betaBadge = (
  <span className="inline-flex items-center px-2 py-0.5 ml-2 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800">
    beta
  </span>
);

const soonBadge = (
  <span className="inline-flex items-center px-2 py-0.5 ml-2 rounded-full text-xs font-medium leading-4 bg-red-100 text-red-800">
    soon
  </span>
);

const WidgetButton = ({
  label,
  icon,
  widgetName,
  chartType,
  isBeta = false,
  soon = false,
  gridElement = {},
  addWidget,
  storeState,
}) => (
  <p
    className={"tw-add-widget-button flex align-center"}
    onClick={() => {
      if (!soon) {
        addWidget(widgetName, chartType);
      }
    }}
    draggable={soon ? false : true}
    onDragStart={function (e) {
      // <FOR FIREFOX SUPPORT>
      gridElement.ondragover = function (e) {
        e.preventDefault();
      };
      // </FOR FIREFOX SUPPORT>

      // Enable the drag status on grid
      gridElement.classList.add("new-widget-drag-active");

      // A test with dataTransfer, which should be the way to transfer data to where the item is dropped.
      // BUT, we also need to transfer the width of the widget, but dataTransfer's data is
      // NOT available in the ReactGridLayout onDragStart event. So I kept our hacky global way of doing things.
      // e.dataTransfer.setData('some key', 'value')
      let draggedElement = {
        layout: {
          w: undefined,
          h: 2,
          i: getRandomInt(400, 999999) + "",
        },
        config: getNewWidgetConfigFor(widgetName, chartType),
        widgetName: widgetName,
      };
      const widget = getNewWidgetLayoutFor(widgetName, draggedElement.i, storeState.widgetReducer.widgetLayouts);

      draggedElement.layout.w = widget.w;
      draggedElement.layout.h = widget.h;

      window.currentlyDraggedElement = draggedElement;

      // Enable the bool saying that we are currently adding a widget via drag & drop
      window.isCurrentlyAddingWidget = true;
    }}
    onDragEnd={function () {
      // We cancelled adding the widget (dragged it outside of the report layout), so we are not dragging a new widget anymore
      window.isCurrentlyAddingWidget = false;

      // Disable the drag status on grid
      gridElement.classList.remove("new-widget-drag-active");
    }}
    unselectable="on"
  >
    {icon && (
      <span className="opacity-50">
        <WidgetButtonIcon type={icon} />
      </span>
    )}
    {label}
    {isBeta ? betaBadge : false}
    {soon ? soonBadge : false}
  </p>
);

export default WidgetButton;
