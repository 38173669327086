import React from "react";
var createReactClass = require("create-react-class");
import $ from "jquery";
import Button from "./generic/button.js";
import APIError from "../helpers/api_error";
import Modal from "../components/modal";

const ReportPreview = createReactClass({
  getInitialState: function () {
    return {
      errorVisible: false,
      modalOpen: false,
      modalActiveClass: "",
      email: MetricsWatch.currentUser.email,
      apiURL: "/api/v1/report_previews",
    };
  },

  onPreviewButtonClick: function (event) {
    event.preventDefault();
    this.setState({ modalOpen: !this.state.modalOpen });
  },

  onSendButtonClick: function (event) {
    event.preventDefault();
    this.sendToSampleReportAPI();
  },

  onEmailChange: function (event) {
    this.setState({ email: event.target.value });
  },

  sendToSampleReportAPI: function () {
    let dataForAPI = this.props.dataForAPI;

    dataForAPI["to"] = this.state.email;

    $.ajax({
      url: this.state.apiURL,
      dataType: "json",
      method: "POST",
      data: this.props.dataForAPI,
      cache: false,
      success: function (data) {
        this.onSuccess();
      }.bind(this),
      error: function (xhr, status, err) {
        this.onError();

        new APIError("SampleReport", xhr, status, err);
      }.bind(this),
    });
  },

  onSuccess: function () {
    this.closeModal();
  },

  onError: function () {
    this.setState({ errorVisible: true });
  },

  closeModal: function () {
    this.setState({ modalOpen: false, errorVisible: false });
  },

  onClickCloseModal: function (e) {
    e.preventDefault();
    this.closeModal();
  },

  render: function () {
    return (
      <span>
        <Button text="Send a report preview" onClick={this.onPreviewButtonClick} />

        <Modal visible={this.state.modalOpen} noDefaultButtons>
          <div className="modal-content">
            <div className="box">
              <h4 className="font-bold mb-4">To whom should we send the preview?</h4>

              <div
                style={{
                  display: this.state.errorVisible ? "block" : "none",
                }}
                className="mt-4 text-red-800"
              >
                There was an unknown error.
              </div>

              <label className="tw-label">Email</label>
              <input
                onChange={this.onEmailChange}
                value={this.state.email}
                className="tw-field mb-4"
                type="email"
                placeholder="jane@example.com"
              />

              <Button text="Send preview" extraClasses="tw-submit-button mr-2" onClick={this.onSendButtonClick} />
              <Button text="Close" extraClasses="bg-blue-200 hover:bg-blue-300" onClick={this.onClickCloseModal} />
            </div>
          </div>
        </Modal>
      </span>
    );
  },
});

export default ReportPreview;
